<template>
  <div v-show="showHeaderMenu">
    <div class="d-flex md-stepper-horizontal">
      <div v-bind:class="[this.isActivePage(1) ? activeClass : '', notActiveClass]" v-on:click="goToUserSettings">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'user']" />
        </div>
        <div class="md-step-title">My Account</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(2) ? activeClass : '', notActiveClass]" v-on:click="goToSettings">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'adjust']" />
        </div>
        <div class="md-step-title">Charts</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(3) ? activeClass : '', notActiveClass]" v-on:click="goToDashboardLayout">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'th-large']" />
        </div>
        <div class="md-step-title">Dashboard</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(4) ? activeClass : '', notActiveClass]" v-on:click="goToAdvisor">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'briefcase']" />
        </div>
        <div class="md-step-title">Advisor</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import VueRouter from "vue-router";
import Vue from "vue";
import MenuMixins from "../../mixins/MenuMixins.vue";
import GlobalMixins from "../../mixins/GlobalMixins.vue";
export default {
  name: "CompanyMenu",
  components: {},
  mixins: [MenuMixins,GlobalMixins],
  router,
  props: {},
  data() {
    return {};
  },
  methods: {
    goToDashboardLayout: function() {
      this.$router.replace({ name: "dashboard-layout" });
    },
    goToUserSettings: function() {
      let userId = Number(this.currentUser.id);
      this.$router.replace({ name: "user-settings",params: { initialMode: 'edit-user', initialUserId: userId, initialUserCode: this.currentUser.userCode } });
    },
    goToSettings: function() {
      this.$router.replace({ name: "client-settings" });
    },
    goToAdvisor: function() {
      this.$router.replace({ name: "advisor" });
    },
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {}
};
</script>
