<template>
  <div class="saved-reports" style="max-width: 100%; min-height: 210px;">
    <div class="save-chart title mt-3">
      <span class="align-middle pl-2">Generated Reports</span>
      <div class="float-right ml-auto">
        <span class="wizard-icon">
          <!--
          <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>        
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>          
         </span>
        <i v-show="!isDashboardView && this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="!isDashboardView && this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>

    </div>
    <chart-menu></chart-menu>
    <div class="mx-auto" style="width: 95%">
      <div v-show="$root.getShowWizardContent()" class="wizard-content">
        <div class="wizard-content-column wizard-border col-4">
          <div class="circle">1</div>
          <div class="wizard-text">
            Cards in Generated Reports come from 
            previous reports that were saved.
          </div>
        </div>
        <div class="wizard-content-column wizard-border col-4">
          <div class="circle">2</div>
          <div class="wizard-text">
              Click on the View drop-down to sort 
              your Cards, or push the Detailed View 
              button to see your Cards in a list.
        
          </div>
        </div>
        <div class="wizard-content-column col-4">
          <div class="circle">3</div>
          <div class="wizard-text">
            Click to download and save The W 
            Report™ to your folder. Click to delete 
            The W Report™ Card from your list.
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div :class="isDashboardView ? 'col-xl-6 col-md-8' : 'col-2'">
          <select id="sortBy" name="sortBy" class="custom-select required" v-model="sortBy">
            <option value>Sort By</option>
            <option v-for="(sort_by_option, idxSortByOptions) in this.sort_by_options" v-bind:value="sort_by_option.value" v-bind:key="idxSortByOptions">
              {{ sort_by_option.name }}
            </option>
          </select>
        </div>
        <div v-show="!isDashboardView" class="col">
          <div class="show-hide-wrapper">
            <span class="saved-cards-label">Card View</span>
            <toggle-button v-model="useDetailedView" color="rgb(191, 203, 217)" />
            <span class="saved-cards-label">List View</span>
          </div>
        </div>
      </div>
      <div v-show="useDetailedView" class="form-row">
        <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
          <thead></thead>
          <tbody></tbody>
        </table>
      </div>
      <div v-show="!useDetailedView" class="row">
        <div :class="isDashboardView ? 'col-6' : 'col-2'" v-for="(clientReport, idxClientReport) in this.displayedReports"
          v-bind:key="idxClientReport">
          <div class="card mb-3 shadow chart-card">
            <div class="chart-card-top p-1">
              <div class="d-flex">
                <div class="side-icons">
                  <div @click="savePin(clientReport)">
                    <font-awesome-icon :icon="['fas', 'thumb-tack']" :class=" clientReport.pin == 'Y' ? 'clicked-icon' : 'unclicked-icon'" />
                  </div>
                  <div @click="initiateDownloadItem(clientReport)">
                    <font-awesome-icon :icon="['fa', 'cloud-download']" style="height: 28px; color: #000" />
                  </div>
                </div>
                <div class="main-report-icon mx-auto">
                  <button type="button" class="saved-reports-edit-button" :disabled="disableDeleteReportButton" @click="initiateEditReport(clientReport)">
                      <!-- <font-awesome-icon :icon="['fas', 'images']" style="color: #000" /> -->
                      <i class="fa-duotone fa-file-powerpoint" style="font-size:40px;color:#000" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="ml-auto">
                  <button type="button" class="btn btn-primary saved-reports-button" :disabled="disableDeleteReportButton" @click="initiateDeleteReport(clientReport)">
                    <font-awesome-icon :icon="['fas', 'window-close']" style="width: 20px;height: 20px;" />
                  </button>
                </div>
              </div>
            </div>
            <div class="chart-card-row">
              <span v-if="clientReport.filePath.length <= maximumCharacterCount"> {{ clientReport.filePath }} </span>
              <span v-else data-placement="bottom" :title="clientReport.filePath"> {{ clientReport.filePath.substring(0, maximumCharacterCount) + "...." }} </span>
            </div>
          </div>
        </div>
        <div v-show="!isDashboardView" class="text-center w-100">
          <button class="btn btn-primary mr-2" v-if="displayShowMoreButton" @click="showMore">Show More Reports</button>
          <button class="btn btn-primary mr-2" v-if="displayShowLessButton" @click="showLess">Show Less Reports</button>
          <button class="btn btn-primary mr-2" v-if="displayShowAllButton" @click="showAll">Show All Reports</button>
        </div>      
      </div>
      <div v-show="displayedReports.length == 0" class="text-center">
        No generated reports to show
      </div>
    </div>
    <modal
      :modal-id="'Confirmation Report'"
      :modal-title="'Delete Confirmation'"
      :modal-content="'Are you sure you want delete the report ?'"
      :modal-result-id="selectedClientReportId"
      :include-confirmation="true"
      @confirm="deleteClientReport"
    />
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";

import UserSettingsService from "../services/user.settings.service";
import UserService from "../services/user.service";
import AdminService from "../services/admin.service";
import ClientService from "../services/client.service";
import ReportService from "../services/report.service";
import ContentService from "../services/content.service";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import ViewMixins from "../mixins/ViewMixins";
import GlobalMixins from "../mixins/GlobalMixins";
import ChartMenu from "../views/menu/ChartMenu.vue";
import { useRouter } from 'vue-router';

export default {
  name: "SavedReports",
  mixins: [DataTableMixins, ViewMixins, GlobalMixins],
  components: {ChartMenu},
  setup() {
    const router = useRouter();

    const printReport = (clientReport) => {
      router.push({ 
        name: 'edit-print-report', 
        params: { initialMode: 'edit', initialClientReportId: clientReport.clientReportId } 
      });          
    };
    return {
      printReport
    };
  },  
  data() {
    return {
      maximumCharacterCount: 38,
      reportsToShow: 12,
      maxReportsToShow: 12,
      clientReportIds: [],
      selectedClientReportId: 0,
      downloadReportName: "",
      useDetailedView: UserSettingsService.getDetailedViewForSavedCards(),
      sortBy: "date-added-newest",
      table: {
        id: "saved-reports-data",
        configuration: {
          dom: '<"top"if>rtp<"bottom"><"clear">',
          searching: false,
          paging: true,
          select: true,
          destroy: true,
          info: false,
          ordering: true,
          bSort: true,
          bSortable: true,
          pageLength: 12,
          keys: true
        },
        columns: ReportService.getSavedReportsTableColumnData()
      },
      tableSortBy: [1, "asc"],
      sort_by_options: [
        { name: "Date Added Newest", value: "date-added-newest" },
        { name: "Date Added Oldest", value: "date-added-oldest" },
        { name: "Title Ascending", value: "title-ascending" },
        { name: "Title Descending", value: "title-descending" },
        { name: "Organization Ascending", value: "organization-ascending" },
        { name: "Organization Descending", value: "organization-descending" }
      ],
      tooltipConfigShow: ContentService.getWizardTooltip(),     
      displayedReports: []
    };
  },
  props: {
    entrySurveyId: {
      type: Number,
      default: 0
    },
    entrySurveyName: {
      type: String,
      default: ""
    },
    viewType: {
      type: String,
      default: "standard"
    }, 
    entryClientObject: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    isDashboardView: function() {
      return this.viewType ==  "dashboard"
    },   
    displayShowMoreButton: function() {
      return !this.isDashboardView && (this.reportsToShow < this.entryClientObject.clientReports.length || 
              this.entryClientObject.clientReports.length > this.reportsToShow);
    },
    displayShowLessButton: function() {
      return !this.isDashboardView && (this.reportsToShow >= this.entryClientObject.clientReports.length && 
              this.entryClientObject.clientReports.length > this.maxReportsToShow);
    },
    displayShowAllButton: function() {
      return this.entryClientObject.clientReports.length > 0 && this.entryClientObject.clientReports.length > this.reportsToShow;
    },
    disableDeleteReportButton: function() {
      return this.isW5OUser || this.isInsiderUser;
    },    
  },
  mounted() {
    this.buildSavedReportTable();
  },
  created() {},
  methods: {
    getDisplayedSavedReports(){
      if (this.isDashboardView){
        let pinnedSavedCards = this.entryClientObject.clientReports.filter(savedCard => {return savedCard.pin == "Y";});
        let unpinnedSavedCards = this.entryClientObject.clientReports.filter(savedCard => {return savedCard.pin == "N";});
        this.displayedReports = JSON.parse(JSON.stringify(pinnedSavedCards)); 
        this.displayedReports.push(...JSON.parse(JSON.stringify(unpinnedSavedCards)));
      } else {
        this.displayedReports = JSON.parse(JSON.stringify(this.entryClientObject.clientReports)); 
      }  
      this.displayedReports = this.displayedReports.slice(0,this.reportsToShow);
    },   
    savePin: function(clientReport){
      let message = ClientService.displayPinMessage(clientReport, clientReport.filePath);
      let newPin = clientReport.pin == "N" ? "Y" : "N";
      this.$root.processServerRequest(message);
      ClientService.savePinForClientReport(clientReport.clientReportId, newPin, this.savePinCallback, this);
    },
    savePinCallback: function(response){
      this.$root.processServerResponse();
      let message = response.data.message;
      let clientReports = response.data.data;
      this.clientObject.clientReports = [...clientReports];
      ClientService.saveClient(this.clientObject);
      this.$root.setClientObject(this.clientObject);
      this.getDisplayedSavedReports();
    },    
    showMore: function(){
      this.reportsToShow += this.maxReportsToShow;
      this.getDisplayedSavedReports();
    },
    showLess: function(){
      if (this.reportsToShow <= (this.maxReportsToShow * 2) ){
        this.reportsToShow = this.maxReportsToShow;
      } else {
        this.reportsToShow -= this.maxReportsToShow;
      }
      this.getDisplayedSavedReports();
    },    
    showAll: function() { 
      this.reportsToShow = this.entryClientObject.clientReports.length;
      this.getDisplayedSavedReports();
    },
    initiateDeleteReport(clientReport) {
      this.selectedClientReportId = clientReport.clientReportId;
      this.$modal.show("Confirmation Report");
    },
    initiateEditReport(clientReport) {
      if (this.isDashboardView == true){
        this.printReport(clientReport);
      } else {
        this.$emit("edit-report",clientReport);
      }
    },
    deleteClientReport(result) {
      this.$root.processServerRequest("Removing report");
      ReportService.deleteClientReport(result, this.deleteClientReportCallback, this);
    },
    deleteClientReportCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message, duration: 1000 });
        this.resetClientReports(response.data.data);
        this.buildSavedReportTable();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    resetClientReports(result) {
      let match = this.entryClientObject.clientReports.find(x => x.clientReportId == result);
      if (typeof match !== "undefined") {
        //vue 3 migration changes
        let localEntryClientObject = this.entryClientObject;
        localEntryClientObject.clientReports.splice($.inArray(match, this.entryClientObject.clientReports), 1);
        ClientService.saveClient(localEntryClientObject);
        this.$root.setClientObject(localEntryClientObject);
      }
      this.getDisplayedSavedReports();
    },
    getDownloadReportUrl: function(clientReportId) {
      return AdminService.getBaseUrl() + "/api/v1/printreport/download-report?clientReportId=" + clientReportId;
    },
    async downloadItem() {
      this.setupDownloadItem();
      ReportService.downloadReport(this.selectedClientReportId,this.downloadItemCallback,this);
    },
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      const blob = new Blob([response.data], { type: "application/pdf" });
      this.clickLink(blob);
      this.setModalLoading(false);
      AdminService.displaySuccessMessage({ text: "Download Complete" });
    },
    getDownloadFilename(){
      return this.downloadReportName;
    },
    initiateDownloadItem(clientChart) {
      this.selectedClientReportId = clientChart.clientReportId;
      this.downloadReportName = clientChart.filePath;
      this.downloadItem();
    },
    buildSavedReportTable() {
      if (this.isDashboardView){
        this.reportsToShow = 2;
        this.maxReportsToShow = 2;
        this.useDetailedView = false;
      } else {
        this.reportsToShow = this.entryClientObject.clientReports.length;
      }
      this.getDisplayedSavedReports();
      let response = {
        data: {
          data: {}
        }
      };
      this.table.configuration.order = this.tableSortBy;
      response.data.data = this.displayedReports;
      this.getTableDataCallback(response);
      let vm = this;
      $("#saved-reports-data tbody").on("click", ".btn-download", function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
          vm.selectedClientReportId = data.clientReportId;
          vm.downloadReportName = data.filePath;
          vm.downloadItem();
        }
      });
      $("#saved-reports-data tbody").on("click", ".btn-edit", function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
          vm.initiateEditReport(data);
        }
      });
      $("#saved-reports-data tbody").on("click", ".btn-delete", function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
          vm.initiateDeleteReport(data);
        }
      });
    },
    saveUserSettingCallback: function (response) {
      UserSettingsService.saveAllUserSettings(response.data.data.userSettings);
    }
  },
  watch: {
    entryClientObject: function() {
      this.buildSavedReportTable();
    },
    useDetailedView: function() {
      if (this.useDetailedView == true) {
        this.reportsToView = this.displayedReports.length;
        this.table.dataTable.order(this.tableSortBy).draw();
      } else {
        this.reportsToView = this.maxReportsToShow;
      }
      if (!this.isDashboardView){
        UserSettingsService.saveUserSetting("Detailed View - Saved Cards", this.useDetailedView ? "Y" : "N", 0, this.saveUserSettingCallback, this);
      }
    },
    sortBy: function() {
      this.$emit("sort-reports", this.sortBy);
      if (this.sortBy == "title-ascending") {
        this.tableSortBy = [1, "asc"];
      } else if (this.sortBy == "title-descending") {
        this.tableSortBy = [1, "desc"];
      } else if (this.sortBy == "organization-ascending") {
        this.tableSortBy = [3, "asc"];
      } else if (this.sortBy == "organization-descending") {
        this.tableSortBy = [3, "desc"];
      } else if (this.sortBy == "date-added-newest") {
        this.tableSortBy = [7, "desc"];
      } else if (this.sortBy == "date-added-oldest") {
        this.tableSortBy = [7, "asc"];
      }
      this.getDisplayedSavedReports();
      if (this.useDetailedView == true) {
        this.table.dataTable.order(this.tableSortBy).draw();
      }
    }
  }
};
</script>
