<template>
    <div v-if="displayQuestion" class="response-rates-segment-question">
        <div class="response-rates-segment-question-left">
            <div class="form-group">
                <label class="control-label pt-1 pl-0 pr-0 my-auto">Segment</label>
                <select
                    id="question"
                    name="question"
                    class="custom-select required shadow"
                    @change="changeQuestion($event)"
                    v-model="selectedQuestion.label"
                >
                    <option value="">Select</option>
                    <option v-for="(option, idxPeriodOptions) in inputSegmentQuestions" v-bind:value="option.label" v-bind:key="idxPeriodOptions">
                    {{ option.label }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                    <multiselect
                        ref="optionSelect"
                        v-model="optionSelections"
                        :options="optionsList"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :max-height="250"
                        select-label=""
                        deselect-label=""
                        selected-label=""
                        placeholder="Select"
                        name="multi-option"
                        label="name"
                        track-by="name"
                        @select="selectOption"
                        @remove="removeOption"                    
                        :preselect-first="false"
                    >
                        <template #option="props">
                            <span class="checkbox-label" @click.self="select(props.option)">
                            <input name="multi-option" class="mr-1" type="checkbox"  v-model="props.option.checked" @focus.prevent />
                            {{ props.option.name }}
                            </span>
                        </template>                        
                    </multiselect>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import $ from "jquery";

export default {
  name: "ResponseRateSegmentQuestion",
  mixins: [],
  components: {Multiselect},
  data() {
    return {
        optionsList: [],
        optionSelections: [],
        selectedQuestion: {
            label: "",
            optionsList: [],
        }
    };
  },
  props: {
    inputQuestionLabel: {
        type: String,
        default: ""
    },    
    inputSegmentQuestions: {
        type: Array,
        default: function() {
            return [];
        }
    },
    inputOptionsList: {
        type: Array,
        default: function() {
            return [];
        }
    }
  },
  computed: {
    displayQuestion: function(){
        return (typeof this.inputQuestionLabel != "undefined" && this.inputQuestionLabel != null && this.inputQuestionLabel != "");
    }
  },
  mounted() {},
  created() {},
  methods: {
    resetOptionSelections() {
      for (let i = 0; i < this.optionSelections.length; i++) {
        this.optionSelections[i].checked = false;
      }
      this.optionSelections = [];
      this.selectedQuestion.optionsList = [];
    },    
    changeQuestion: function(event) {
        this.selectedQuestion.label = event.target.value;
        this.setOptionsList();
        this.$emit("set-segment-question", this.selectedQuestion);
    },
    getDefaultQuestionLabel: function(){
        let label = "";
        if (this.inputSegmentQuestions.length > 0) {
            label = this.inputSegmentQuestions[0].label;
        }
        return label;
    },
    resetSegmentQuestion: function(){
        this.resetOptionSelections();
        this.$emit("set-segment-question", this.selectedQuestion);
    },
    setOptionsList: function(){
        if (this.$parent.autoGenerateChartRun == false){
            this.optionSelections = [];
        }
        this.optionsList = [];
        if (this.selectedQuestion.label == "") {
            this.selectedQuestion.label = this.getDefaultQuestionLabel();
        }
        if (this.inputSegmentQuestions.length > 0){
            let selectedQuestion = JSON.parse(JSON.stringify(this.inputSegmentQuestions.find(item => item.label == this.selectedQuestion.label)));
            if (typeof selectedQuestion != "undefined" && selectedQuestion != null && selectedQuestion.optionNames.length > 0) {
                for (let optionIndex = 0; optionIndex < selectedQuestion.optionNames.length; optionIndex++) {
                    let option = {
                        name: selectedQuestion.optionNames[optionIndex],
                        checked: false
                    };
                    this.optionsList.push(option);
                }
            }        
        }
    },
    selectOption: function(option){
        option.checked = true;
        this.selectedQuestion.optionsList.push(option.name);
        this.$emit("set-segment-question", this.selectedQuestion);
    },
    removeOption: function(option){
        option.checked = false;
        let match = this.selectedQuestion.optionsList.find(item => item == option.name);
        if (typeof match !== "undefined") {
            this.selectedQuestion.optionsList.splice($.inArray(match, this.selectedQuestion.optionsList), 1);
        }
       this.$emit("set-segment-question", this.selectedQuestion);
    },
    processSavedOptionsList: function(){
        if (this.$parent.autoGenerateChartRun && this.inputOptionsList.length > 0){
            for (let optionIndex = 0; optionIndex < this.inputOptionsList.length; optionIndex++) {
                    let option = {
                        name: this.inputOptionsList[optionIndex],
                        checked: true
                    };                    
                    this.optionSelections.push(option);
                    this.optionsList.find(item => item.name == option.name).checked = true;
            }
            this.selectedQuestion.optionsList = JSON.parse(JSON.stringify(this.inputOptionsList));
            this.$emit("set-segment-question", this.selectedQuestion);
        }   
    }
  },
  watch: {
    inputQuestionLabel: function() {
        if (typeof this.inputQuestionLabel != "undefined" && this.inputQuestionLabel != null && this.inputQuestionLabel != "" && this.selectedQuestion.label != this.inputQuestionLabel){
            this.selectedQuestion.label = this.inputQuestionLabel;
            this.setOptionsList();
        }        
        this.$emit("set-segment-question", this.selectedQuestion);
    },
    inputSegmentQuestions: function() {
        if (this.selectedQuestion.label != this.inputQuestionLabel){
            this.setOptionsList();
        }
        
    },
    inputOptionsList: function() {
      this.processSavedOptionsList();
    }
  },
};
</script>
