<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/ranks" @click="$parent.logViewDetailsEvent(title)">
            Competitive <br/>
            Rank
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Competitive Rank" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('competitive-rank-save-chart-confirmation')"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-competitive-rank">
        <dashboard-card-dropdown label="Competitor Set" :read-only="readOnly" :options="rival_set_options" :default-value="competitorSet" @change="saveCompetitorSet"></dashboard-card-dropdown>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div class="dashboard-card-body">
      <div class="competitve-rank-dashboard-chart" :style="dashboardChartStyle">
        <chart :options="options"></chart>
      </div>
      <div id="competitive-rank-mini">
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
      </div> 
    </div>
    <!-- :updatedModalChartOptions.sync="options.series[0].data" -->
    <dashboard-modal :modal-id="dashboardId" :chart="this.chart" :client-chart="this.clientChart" :dashboard-card-report-page-type-name="reportTypeName" :modal-title="title" :modal-chart-options="options" 
    :displayModal="displayModal" :key="$displayModal"/>
    <vue-modal :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import DashboardCardDropdown from "./DashboardCardDropdown";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";

import Vue from "vue";
import echarts from "echarts";
import $ from "jquery";

export default {
  name: "DashboardCompetitiveRank",
  mixins: [DashboardMixins],
  components: { DashboardCardDropdown, SaveChart,DashboardCardSkeleton },
  data() {
    return {
      title: "Competitive Rank",
      modalName: "competitive-rank-save-chart-confirmation",
      competitorSet: "survey",
      baseSeries: {
        name: "",
        type: "scatter",
        symbolSize: 12,
        itemStyle: {
          color: "#0000ff"
        },
        label: {
          fontFamily: "Franklin Gothic Demi Cond",
          offset: [0, 1]
        },
        data: [],
        animationDelay: function(idx) {
          return idx * 5;
        }
      }
    };
  },
  props: {
    dashboardLayoutCard: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {},
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardCompetitiveRank::mounted");
    this.chartTitle = "Competitive Rank";
    this.chartTypeName = "competitive-rank";
    this.containerId = "competitive-rank-mini"
	  this.reportTypeName = "Dashboard - Competitive Rank"
  },
  created() {},
  methods: {
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isCompetitorSet = criteriaParameters[0] == "competitorSet" ? true : false;
  		if (isCompetitorSet){
        this.competitorSet = criteriaParameters[1];
  		}
    },
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("competitorSet",this.competitorSet);
      return params;
    },      

    initChart: function(dynamicOptions) {
      // https://echarts.apache.org/examples/en/editor.html?c=scatter-punchCard
      let chartDiv = $(".competitve-rank-dashboard-chart")[0];
      this.chart = echarts.init(chartDiv);
      let vm = this;
      this.chart.on('finished', () => {
        vm.saveImage();
      });
      this.options = DashboardService.getCompetitiveRankOptions(this.competitorSet, this.rankCompanyCount);
      this.options.series = DashboardService.setSeries(this.baseSeries, this.options.series, dynamicOptions);
      this.options.legend.data = DashboardService.setLegend(this.options.legend.data, dynamicOptions);
      //this.printOptions();
      if (this.autoGenerateChartRun == true){
        this.options.legend.show = true;
      }
      this.chart.setOption(this.options, true);
    },

    loadData: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCompetitiveRank::loadData");
      this.displayDashboard = false;
      await DashboardService.getCompetitorSet(this.getCompetitorSetCallback, this);
      if (this.autoGenerateChartRun){
        this.processAutoGenerateChart();
      } else {
        this.competitorSet = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_COMPETITIVE_RANK_COMPETITOR_SET_SETTING_TYPE, "survey", this.rival_set_options);
      }     
      DashboardService.getCompetitiveRank(this.timePeriodId, this.competitorSet, this.getCompetitiveRankCallback, this);
    },
    saveCompetitorSet: function(value) {
      this.competitorSet = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_COMPETITIVE_RANK_COMPETITOR_SET_SETTING_TYPE, this.competitorSet);
      this.updateCard();
    },
    updateCard: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardCompetitiveRank::updateCard");
      this.resetChart();
      this.displayDashboard = false;
      let vm = this;
      if (!vm.autoGenerateChartRun){
        this.competitorSet = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_COMPETITIVE_RANK_COMPETITOR_SET_SETTING_TYPE, this.competitorSet, this.rival_set_options);
      }
      DashboardService.getCompetitiveRank(this.timePeriodId, this.competitorSet, this.getCompetitiveRankCallback, this);
    },
    getCompetitiveRankCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.displayDashboard = true;
        let dynamicOptions = response.data.data.options;
        this.rankCompanyCount = response.data.data.rankCompanyCount;
        this.initChart(dynamicOptions);
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        }      
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  watch: {
    entrySurveyCode: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardPerformanceTrends::entrySurveyCode");
      this.resetChart();
      this.competitorSet = "survey";      
    },
  }
};
</script>
