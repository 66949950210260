<template>
    <div class="user-settings">
      <div class="row no-gutters pb-2">
        <div class="dashboard-system-menu">System Menu Defaults</div>
      </div>
      <div class="row no-gutters">
        <div class="col-3 form-group form-inline pt-1">
          <input
            id="showMainMenuTitles"
            name="showMainMenuTitles"
            type="checkbox"
            true-value="Y"
            false-value="N"
            v-model="showMainMenuTitles"
            @click="saveCheckboxValue($event, 'Show Main Menu')"
          />
          <label class="pl-2">Show Main Menu</label>
        </div>
        <div class="col-3 form-group form-inline pt-1">
          <input
            id="showHeaderMenuTitles"
            name="showHeaderMenuTitles"
            type="checkbox"
            true-value="Y"
            false-value="N"
            v-model="showHeaderMenuTitles"
            @click="saveCheckboxValue($event, 'Show Header Menu')"
          />
          <label class="pl-2">Show Header Menu</label>
        </div>
        <div class="col-3 form-group form-inline pt-1">
          <input
            id="showHeaderMenuTitles"
            name="showHeaderMenuTitles"
            type="checkbox"
            true-value="Y"
            false-value="N"
            v-model="showSavePeriodWidgetOptions"
            @click="saveCheckboxValue($event, 'Save Period/Widget Options')"
          />
          <label class="pl-2">Save Period & Widget Options</label>
        </div>
        <div class="col-3 form-group form-inline pt-1">
          <input
            id="showDetailedViewSavedCards"
            name="showDetailedViewSavedCards"
            type="checkbox"
            true-value="Y"
            false-value="N"
            v-model="showDetailedViewSavedCards"
            @click="saveCheckboxValue($event, 'Detailed View - Saved Cards')"
          />
          <label class="pl-2">Show List View for Saved Cards</label>
        </div>
      </div>
    </div>
</template>

<script>
import DashboardMixins from "../mixins/DashboardMixins.vue";
import GoogleService from "../services/google.service";
import UserSettingsService from "../services/user.settings.service";
import AdminService from "../services/admin.service";
import UserService from "../services/user.service";

export default {
  name: "UserSettings",
  mixins: [DashboardMixins],
  components: {},
  data() {
    return {
      title: "User Settings",
      showMainMenuTitles: "Y",
      showHeaderMenuTitles: "N",
      showSavePeriodWidgetOptions: "Y",
      showDetailedViewSavedCards: "N",
    };
  },
  props: {},
  computed: {},
  mounted() {
    UserSettingsService.getUserSettingsFromDatabase(UserService.getUserId(),  UserService.getUserCode(), this.processUserSettings, this);

  },
  created() {},
  methods: {
    saveCheckboxValue: async function (event, userSettingTypeName) {
      let checkboxValue = event.target.checked ? "Y" : "N";
      GoogleService.logClickEvent("Dashboard Layout", userSettingTypeName, 0, this);
      await UserSettingsService.saveUserSetting(userSettingTypeName, checkboxValue, 0, this.saveUserSettingCallback, this);
      if (userSettingTypeName == "Show Main Menu"){
        if (checkboxValue == "Y") {
          this.$root.setShowMainMenu(true);
          this.$parent.$parent.$parent.$refs.mainMenu.collapsed = false;
        } else {
          this.$root.setShowMainMenu(false);
          this.$parent.$parent.$parent.$refs.mainMenu.collapsed = true;
        }
      } else if (userSettingTypeName == "Save Period/Widget Options"){
          UserSettingsService.resetUserDashboardSetings(this.resetUserDashboardSetingsCallback,this);
      } else  if (userSettingTypeName == "Show Header Menu"){
          this.$root.setShowHeaderMenu(checkboxValue == "Y");
      }
    },
    resetUserDashboardSetingsCallback: function (response) {
    },
    saveUserSettingCallback: function (response) {
      this.$root.processServerResponse();
      UserSettingsService.saveAllUserSettings(response.data.data.userSettings);
      AdminService.displayInfoMessage({ text: response.data.message, duration: 1000 });
    },
    processUserSettings: function (response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.settings = response.data.data;
        var showMainMenu = this.settings.find((x) => x.userSettingTypeName == "Show Main Menu");
        if (typeof showMainMenu != "undefined") {
          this.showMainMenuTitles = showMainMenu.userSettingValue;
        }
        var showHeaderMenu = this.settings.find((x) => x.userSettingTypeName == "Show Header Menu");
        if (typeof showHeaderMenu != "undefined") {
          this.showHeaderMenuTitles = showHeaderMenu.userSettingValue;
        }
        var inputShowSavePeriodWidgetOptions = this.settings.find((x) => x.userSettingTypeName == "Save Period/Widget Options");
        if (typeof inputShowSavePeriodWidgetOptions != "undefined") {
          this.showSavePeriodWidgetOptions = inputShowSavePeriodWidgetOptions.userSettingValue;
        }
        var inputShowDetailedViewSavedCards = this.settings.find((x) => x.userSettingTypeName == "Detailed View - Saved Cards");
        if (typeof inputShowDetailedViewSavedCards != "undefined") {
          this.showDetailedViewSavedCards = inputShowDetailedViewSavedCards.userSettingValue;
        }
      }
    }
  },
  watch: {},
};
</script>
