<template>
    <div class="form-group form-inline mt-1">
        <input
        type="checkbox"
        class="form-check-input"
        id="showLabels"
        name="showLabels"
        v-model="showLabels"
        @click="changeShowLabels($event)"
        />
        <label for="showLabels">Data Labels &nbsp;<modal-link modal-id="Data Labels" :content-list="contentList"></modal-link></label>
    </div>                   
</template>
<script>
import ModalLink from "../ModalLink.vue";

export default {
  name: "ShowLabel",
  mixins: [],
  components: {ModalLink},
  data() {
    return {
        showLabels: true,
    };
  },
  props: {
    inputShowLabels: {
        type: Boolean,
        default: true
    },    
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    },    
  },
  computed: {
  },
  mounted() {},
  created() {},
  methods: {
    changeShowLabels: function(event) {
        this.showLabels = event.target.checked;
        this.$parent.$emit("set-show-labels", this.showLabels);
    },
  },
  watch: {
    inputShowLabels: function(){
        this.showLabels = this.inputShowLabels;
    },    
  }
};
</script>
