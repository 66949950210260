import OktaSignIn from "@okta/okta-signin-widget";
import { OktaAuth } from "@okta/okta-auth-js";
const signInWidgetConfig = {
  // Enable or disable widget functionality with the following options. Some of these features require additional configuration in your Okta admin settings. Detailed information can be found here: https://github.com/okta/okta-signin-widget#okta-sign-in-widget
  // Look and feel changes:
  logo: "//gt.wratings.com/img/main-wratings-logo.a4eb8f1a.png", // Try changing "okta.com" to other domains, like: "workday.com", "splunk.com", or "delmonte.com"
  language: "en", // Try: [fr, de, es, ja, zh-CN] Full list: https://github.com/okta/okta-signin-widget#language-and-text
  i18n: {
    //Overrides default text when using English. Override other languages by adding additional sections.
    en: {
      "primaryauth.title": " ", // Changes the sign in text
      "primaryauth.submit": "Login" // Changes the sign in button
      // More e.g. [primaryauth.username.placeholder,  primaryauth.password.placeholder, needhelp, etc.].
      // Full list here: https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/dist/properties/login.properties
    }
  },
  // Changes to widget functionality
  features: {
    //registration: true,                 // Enable self-service registration flow
    rememberMe: true, // Setting to false will remove the checkbox to save username
    multiOptionalFactorEnroll: true, // Allow users to enroll in multiple optional factors before finishing the authentication flow.
    //selfServiceUnlock: true,          // Will enable unlock in addition to forgotten password
    //smsRecovery: true,                // Enable SMS-based account recovery
    //callRecovery: true,               // Enable voice call-based account recovery
    router: true // Leave this set to true for the API demo
  },
  baseUrl: process.env.VUE_APP_OKTA_BASE_URL,
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + process.env.VUE_APP_OKTA_CALLBACK_FUNCTION,
  authParams: {
    issuer: process.env.VUE_APP_OKTA_BASE_URL + "/oauth2/aus3th4vzk2PcrEnE5d7",
    responseType: ["id_token", "token"],
    scopes: ["openid", "email", "profile"],
    pkce: true,
    display: "page"
  },
  helpLinks: {
    forgotPassword: '/forgot-password',
  },
};
const authWidgetConfig = {
  issuer: process.env.VUE_APP_OKTA_BASE_URL + "/oauth2/aus3th4vzk2PcrEnE5d7",
  client_id: process.env.VUE_APP_OKTA_CLIENT_ID,
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirect_uri: window.location.origin + process.env.VUE_APP_OKTA_CALLBACK_FUNCTION,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  tokenManager: {
    autoRenew: true
  }
};
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

import Auth from "@okta/okta-vue";
import Vue from "vue";
Vue.use(Auth, authWidgetConfig);

const oktaSignIn = new OktaSignIn(signInWidgetConfig);
const oktaAuth = new OktaAuth(authWidgetConfig);
oktaAuth.start();
const oktaAuthLogout = async (oktaAuth) => {
  let idToken = await oktaAuth.tokenManager.get('idToken');
  let accessToken = await oktaAuth.tokenManager.get('accessToken');
  await oktaAuth.signOut({
    idToken: idToken,
    accessToken: accessToken,
    postLogoutRedirectUri: window.location.origin + "/logout/callback"
  });
  oktaAuth.stop();
};
const onAuthRequired = async (from, to, next) => {
  let isAuthenticated = await Vue.prototype.$auth.isAuthenticated();
  if (from.matched.some(record => record.meta.requiresAuth) && !(isAuthenticated)) {
    let executedLogout = oktaAuthLogout(oktaAuth);
  } else {
    let loggedInUser = UserService.getUser();
    if (AuthService.hasSessionExpired(loggedInUser)) {
      let executedLogout = oktaAuthLogout(oktaAuth);
    }     
    next();
  }
};
export { oktaAuth, oktaSignIn, onAuthRequired, oktaAuthLogout };
