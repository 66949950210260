<template>
  <div class="form-group">
      <label for="filterByStatus" class="mr-1 w-100">Status</label>
      <select id="filterByStatus" name="filterByStatus" class="custom-select required w-100" v-model="filterByStatus">
          <option value="">Select Status</option>
          <option v-for="(status_option, idxStatusOptions) in this.statusOptions" v-bind:value="status_option.value" v-bind:key="idxStatusOptions">
          {{ status_option.name }}
          </option>
      </select>
  </div>
</template>
<script>

export default {
  name: "FilterByStatus",
  mixins: [],
  components: {  },
  data() {
    return {
        filterByStatus: ""
    };
  },    
  props: {
    statusOptions: {
      type: Array,
      default: function() {
        return [
            { name: "Ready", value: "R" },
            { name: "Complete", value: "C" }
        ];
      }
    },
    executeReset: {
      type: Boolean,
      default: false
    }   
  },
  methods: {
    reset: function(){
      this.filterByStatus = "";
    },
  },
  computed: {}, 
  watch: {
    filterByStatus: function(){
      this.$emit("setFilterValue", this.filterByStatus);
    },
    executeReset: function(){
      this.reset();
    }
  },
  created() {},
  mounted() {},
};

</script>