import axios from "axios";
import $ from "jquery";
import moment from "moment-timezone";


import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";

const API_ADVISOR_URL = AdminService.getBaseUrl() + "/api/v1/advisor";

class AdvisorService {
  async assignAdvisor(advisorId, advisorUserId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("advisorId", advisorId);
    params.append("advisorUserId", advisorUserId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADVISOR_URL + "/assign-advisor", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.assignAdvisor", error, "survey-designer", chartApp);
      });
  }  
  async deleteAdvisor(advisorId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("advisorId", advisorId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADVISOR_URL + "/delete-advisor", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.deleteAdvisor", error, "survery-designer", chartApp);
      });
  }
  async getAdvisorList( user_id, client_id, status_selected, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("advisorClientId", client_id);
    params.append("advisorUserId", user_id);
    params.append("status",status_selected),
    params.append("userId", UserService.getUserId());

    await axios
    .post(API_ADVISOR_URL + "/get-advisor-list", params,{ headers: await authHeader() })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      ErrorService.processError("AdvisorService.getAdvisorList", error, "survery-designer", chartApp);
    });
  }
  async unsubscribeAdditionalUser(advisorConfigurationCode, emailAddress, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("advisorConfigurationCode", advisorConfigurationCode);
    params.append("emailAddress", emailAddress);
    await axios
      .post(API_ADVISOR_URL + "/unsubscribe-additional-user-from-advisor-configuration", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.unsubscribeAdditionalUser", error, "admin", chartApp);
      });
  }
  async unsubscribeAdvisorConfiguration(advisorConfigurationCode, advisorNotificationFrequencyTypeId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("advisorConfigurationCode", advisorConfigurationCode);
    params.append("advisorNotificationFrequencyTypeId", advisorNotificationFrequencyTypeId);
    await axios
      .post(API_ADVISOR_URL + "/unsubscribe-advisor-configuration", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.unsubscribeAdvisorConfiguration", error, "admin", chartApp);
      });
  }
  async getAdvisorUsers(advisorId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("advisorId", advisorId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADVISOR_URL + "/get-advisor-users", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.getOptions", error, "admin", chartApp);
      });
  }
  async getOptions(clientCode, advisorConfigurationId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("advisorConfigurationId",advisorConfigurationId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADVISOR_URL + "/get-advisor-options", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.getOptions", error, "admin", chartApp);
      });
  }
  async getOptionsForAdvisorUnsubsubscribe(callback, chartApp) {
    const params = new URLSearchParams();
    await axios
      .post(API_ADVISOR_URL + "/get-advisor-unsubscribe-options", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.getOptionsForAdvisorUnsubsubscribe", error, "admin", chartApp);
      });
  }
  async saveAdvisorConfiguration(userId, clientCode, advisorConfiguration, advisorNotificationData, advisorReports, advisorOrganizations,advisorDestinationEmails,advisorTimePeriod,callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", userId);
    params.append("advisorConfiguration", JSON.stringify(advisorConfiguration));
    params.append("advisorNotificationData", JSON.stringify(advisorNotificationData));
    params.append("advisorReports", JSON.stringify(advisorReports)); 
    params.append("advisorOrganizations", JSON.stringify(advisorOrganizations));
    params.append("advisorDestinationEmails", JSON.stringify(advisorDestinationEmails));
    params.append("advisorTimePeriod", JSON.stringify(advisorTimePeriod));
    await axios
      .post(API_ADVISOR_URL + "/save-advisor-configuration", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.saveAdvisorConfiguration", error, "admin", chartApp);
      });
  }
  async previewAdvisorEmail(advisorId, callback, chartApp){
    const params = new URLSearchParams();
    params.append("advisorId", advisorId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADVISOR_URL + "/preview-advisor-email", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.previewAdvisorEmail", error, "admin", chartApp);
      });
  }
  async sendTestAdvisorEmail(advisorId, emailAddress, callback, chartApp){
    const params = new URLSearchParams();
    params.append("advisorId", advisorId);
    params.append("userId", UserService.getUserId());
    params.append("emailAddress", emailAddress);
    await axios
      .post(API_ADVISOR_URL + "/send-test-advisor-email", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("AdvisorService.sendTestAdvisorEmail", error, "admin", chartApp);
      });
  }  
  getAdvisorTableColumnAttributes() {
    return [
      {
        title: "Advisor Id",
        data: "advisorId",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "advisorId",
        width: "5rem"
      },
      {
        title: "User Id",
        data: "userId",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "userId",
        width: "4rem"
      },
      {
        title: "Advisor Configuration Id",
        data: "advisorConfigurationId",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "advisorConfigurationId",
        width: "7rem"
      },
      { 
        title: "Destination Email", 
        data: "userEmail", 
        name: "userEmail", 
        width: "7rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },
        className: "",
      },      
      { 
        title: "Client Name", 
        data: "clientName", 
        name: "clientName", 
        width: "6rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },
        className: "",
      },
      { 
        title: "Frequency Type", 
        data: "advisorNotificationFrequencyTypeName", 
        name: "advisorNotificationFrequencyTypeName", 
        width: "7rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },
        className: "",
      },
      {
        title: "Time Period",
        data: "timePeriodRange",
        name: "timePeriodRange",
        width: "7rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },        
      },      
      {
        title: "Previous Time Period",
        data: "previousTimePeriodRange",
        name: "previousTimePeriodRange",
        width: "9rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },        
      },      
      { 
        title: "Only Send Changes", 
        data: "onlySendChanges", 
        name: "onlySendChanges", 
        width: "6rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },
        className: "",
      },      
      { 
        title: "Include Main Client", 
        data: "includePrimaryClient", 
        name: "includePrimaryClient", 
        width: "5rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },
        className: "",
      },      
      { 
        title: "Organizations Included", 
        data: "advisorClientList", 
        name: "advisorClientList", 
        width: "16rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },
        className: "",
      },      
      { 
        title: "Additional Emails", 
        data: "advisorEmails", 
        name: "advisorEmails", 
        width: "8rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },
        className: "",
      },      
      {
        title: "Status",
        data: "status",
        name: "status",
        width: "5rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },        
        render: function(data, type, row) {
          var statusMap = {
            C: { label: "Complete", style: "success" },
            R: { label: "Ready", style: "warning" },
            E: { label: "Error", style: "danger" }
          };
          if (type === "display") {
            return "<p class='m-0 p-0'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
          } else {
            return data;
          }
        }
      },
      {
        title: "Last Run Date",
        data: "lastRunDate",
        type: "date",
        name: "lastRunDate",
        width: "6rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        },
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },        
      },
      {
        title: "Next Run Date",
        data: "dateRun",
        type: "date",
        name: "dateRun",
        width: "6rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        },
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },        
      },
      {
        title: "Date Created",
        data: "dateCreated",
        type: "date",
        name: "dateCreated",
        width: "6rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },        
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      }
    ];
  }  
}
export default new AdvisorService();
