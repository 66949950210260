import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import ChartService from "./chart.service";
import SurveyService from "./survey.service";
import ReportService from "./report.service"

import moment from "moment-timezone";
import Vue from "vue";

Vue.prototype.moment = moment;

const API_CLIENT_SETTINGS_URL = AdminService.getBaseUrl() + "/api/v1/client-settings";
const API_CLIENT_URL = AdminService.getBaseUrl() + "/api/v1/client";
const API_ADMIN_URL = AdminService.getBaseUrl() + "/api/v1/admin";
const API_CLIENT_CHART_URL = AdminService.getBaseUrl() + "/api/v1/client-chart";

class ClientChartService {
    filterSavedCardsByName(originalList, name){
      if (name == ""){
        return originalList;
      }
      let returnList = originalList.filter(x => x.name.toLowerCase().includes(name.trim().toLowerCase()) || 
                                                x.clientName.toLowerCase().includes(name.trim().toLowerCase()) ||
                                                x.surveyName.toLowerCase().includes(name.trim().toLowerCase()));
      return returnList;
    }
    getClientChartsBySurvey(originalClientCharts, surveyIdToCompare, displayDashboardCards){
      if (typeof originalClientCharts != "undefined") {
        if (displayDashboardCards){
          return originalClientCharts.filter(clientChartInput => Number(clientChartInput.surveyId) == Number(surveyIdToCompare));
        } else {
          return originalClientCharts.filter(clientChartInput => Number(clientChartInput.surveyId) == Number(surveyIdToCompare))
                                   .filter(clientChartInput => ReportService.isSavedDashboardCard(clientChartInput.chartTypeId) == false);
        }
      } 
      return {};
    }
    getClientChartsByClient(originalClientCharts, clientIdToCompare, displayDashboardCards){
      if (typeof originalClientCharts != "undefined") {
        if (displayDashboardCards){
          return originalClientCharts.filter(clientChartInput => Number(clientChartInput.clientId) == Number(clientIdToCompare));
        } else {
          return originalClientCharts.filter(clientChartInput => Number(clientChartInput.clientId) == Number(clientIdToCompare))
                                   .filter(clientChartInput => ReportService.isSavedDashboardCard(clientChartInput.chartTypeId) == false);
        }
      } 
      return {};
    }
    buildClientOptionList(savedCardList) {
      const list = [];
      list.push({ name: "Select Client", value: 0 });
      const clientFilteredList = [...new Set(savedCardList.map(x => x.clientId))]
        .map(clientId => {
          return {
            clientId: clientId,
            clientName: savedCardList.find(s => s.clientId == clientId).clientName
          };
        });
      if (clientFilteredList.length > 0) {
        clientFilteredList.sort(ReportService.compareValues("clientName", "asc"));
        clientFilteredList.forEach(savedCardClient => {
          const option = {
            name: savedCardClient.clientName,
            value: savedCardClient.clientId,
          };
          list.push(option);
        });

      }
      return list;
    }
    buildSurveyOptionList(savedCardList, clientId = 0) {
      const list = [];
      list.push({ name: "Select Survey", value: 0 });
      if (clientId != 0){
        savedCardList = savedCardList.filter(s => Number(s.clientId) == Number(clientId));
      }
      const surveyFilteredList = [...new Set(savedCardList.map(x => x.surveyId))]
        .map(surveyId => {
          return {
            surveyId: surveyId,
            surveyName: savedCardList.find(s => s.surveyId == surveyId).surveyName
          };
        });
      if (surveyFilteredList.length > 0) {
        surveyFilteredList.sort(ReportService.compareValues("surveyName", "asc"));
        surveyFilteredList.forEach(savedCardClient => {
          const option = {
            name: savedCardClient.surveyName,
            value: savedCardClient.surveyId,
          };
          list.push(option);
        });
      }
      return list;
    }
    async copyClientChart(clientChart,newCardName,surveyGroupId,callback,app){
      const params = new URLSearchParams();
      params.append("sourceClientChart", JSON.stringify(clientChart));
      params.append("newCardName", newCardName);
      params.append("surveyGroupId", surveyGroupId)
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_CHART_URL + "/copy-client-chart", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          if (error.response.status == 504) {
            let timeoutError = "This process takes more than 5 minutes. Please check your email. We will send a response once the process completes";
            AdminService.displayInfoMessage({ text: timeoutError, duration: -1 });
          } else {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ClientChartService.copyClientChart", error, "admin", app);
          }
        });

    }
    async saveClientChart(clientChart,callback,app){
      const params = new URLSearchParams();
      clientChart.surveyId = SurveyService.getSurveyId();
      params.append("clientChart", JSON.stringify(clientChart));
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_CHART_URL + "/save-client-chart", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientChartService.saveClientChart", error, "admin", app);
        });

    }
    async getSavedCardList(callback,app){
      const params = new URLSearchParams();
      await axios
        .post(API_ADMIN_URL + "/get-saved-card-list", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientChartService.getSavedCardList", error, "admin", app);
        });

    }
    async deleteClientCharts(clientChartIds,callback,app){
      const params = new URLSearchParams();
      params.append("clientChartIds", clientChartIds);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_CHART_URL + "/delete-client-charts", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientChartService.deleteClientCharts", error, "admin", app);
        });
    }
    async deleteClientChart(clientChartId,callback,app){
      const params = new URLSearchParams();
      params.append("clientChartId", clientChartId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_CHART_URL + "/delete-client-chart", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientChartService.deleteClientChart", error, "admin", app);
        });
    }
    getSortByOptions(){
      return [
        { name: "Date Added Newest", value: "date-added-newest" },
        { name: "Date Added Oldest", value: "date-added-oldest" },
        { name: "Name Ascending", value: "name-ascending" },
        { name: "Name Descending", value: "name-descending" },
        { name: "Organization Ascending", value: "organization-ascending" },
        { name: "Organization Descending", value: "organization-descending" },
        { name: "Survey Ascending", value: "survey-ascending" },
        { name: "Survey Descending", value: "survey-descending" }
      ];
    }
    getDeleteSavedCardCheckbox(){
      return           {
        title: "",
        bVisible: true,
        data: "id",
        name: "id",
        width: "3rem",
        className: "select-checkbox text-center",
        render: function(data, type, full, meta) {
          return '<input type="checkbox" name="id[]" value="' + data.clientChartId + '">';
        }
      };
    }
    getIncludeInWReport(){
      return           {
        title: "Include in wReport",
        bVisible: true,
        data: "id",
        name: "id",
        width: "3rem",
        className: "select-checkbox text-center",
        render: function(data, type, full, meta) {
          return '<input type="checkbox" name="id[]" value="' + data.surveyId + '">';
        }
      };
    }
    getViewButton(){
      return {
        title: "",
        bVisible: true,
        data: "id",
        name: "id",
        width: "5rem",
        className: "select-checkbox text-center",
        render: function(data, type, full, meta) {
          let viewClickHtml = '<button type="button" class="btn btn-primary view-card" value="';
          viewClickHtml += data;
          viewClickHtml += ">";
          viewClickHtml += '<span aria-hidden="true">View</span>';
          viewClickHtml += "</button>";

          return viewClickHtml;
        }
      };
    }
    getDeleteButton(){
      return {
        title: "",
        bVisible: true,
        data: "id",
        name: "id",
        width: "5rem",
        className: "select-checkbox text-center",
        render: function(data, type, full, meta) {
          let viewClickHtml = '<button type="button" class="btn btn-primary delete-card" value="';
          viewClickHtml += data;
          viewClickHtml += ">";
          viewClickHtml += '<span aria-hidden="true">Delete</span>';
          viewClickHtml += "</button>";

          return viewClickHtml;
        }
      };
    }
    getCopySavedErrorMessageTableColumnData() {
      return [
        { title: "Survey", data: "surveyName", name: "surveyName", width: "20rem" },
        { title: "Error Message", data: "message", name: "message", width: "40rem" },
      ];        
    }
    getAdminSavedCardTableColumnData() {
      let columnData = [];
      let baseData = this.getBaseSavedCardTableColumnData();
      columnData.push(this.getDeleteSavedCardCheckbox());
//      columnData.push(this.getViewButton());
      columnData.push({ title: "Client Chart Id", data: "clientChartId", name: "clientChartId", className: "text-left", width: "8rem" });
      columnData = columnData.concat(baseData);
      return columnData;
    }
    getBaseSavedCardTableColumnData() {
        return [
          { title: "Saved Card Name", data: "name", name: "name", width: "10rem" },
          { title: "Time Period", data: "period", name: "period", width: "7rem" },
          { title: "Client Name", data: "clientName", name: "clientName", width: "10rem" },
          { title: "Survey/Survey Group Name", data: "surveyName", name: "surveyName", width: "12rem" },
          {
            title: "Chart Type",
            data: "chartTypeId",
            name: "chartTypeId",
            width: "6rem",
            render: function(data, type, row) {
              if (Number.isInteger(data)) {
                return ChartService.findChartTypeById(data).name;
              } else {
                return "--";
              }
            }
          },
          {
            title: "Criteria",
            data: "detailedCriteriaList",
            name: "chartTypeId",
            width: "15rem",
            render: function(data, type, row) {
              let returnValue = "";
              for (let criteria of data) {
                let name = criteria.name;
                let displayValue = criteria.displayValue;
                if (displayValue != ''){
                  returnValue += "<span style='font-weight: bold'>" + name + "</span> : " + displayValue + "<br>";
                }
              }
              return returnValue;
            }
          },
          { title: "Use Current Time Period", data: "useCurrentTimePeriod", name: "useCurrentTimePeriod", width: "7rem" },
          {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            className: "text-left",
            width: "6rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          },
          {
            title: "Last Modified",
            data: "dateModified",
            type: "date",
            name: "dateModified",
            className: "text-left",
            width: "6rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          }
        ];
    }
    getSavedCardTableColumnData() {
      let columnData = [];
      columnData.push(this.getIncludeInWReport());
      columnData.push(this.getViewButton());
      columnData.push(this.getDeleteButton());
      columnData = columnData.concat(this.getBaseSavedCardTableColumnData());
      return columnData;
    }
      
}

export default new ClientChartService();
