<template>
  <div></div>
</template>
<script>
import Vue from "vue";
import VModal from "vue-js-modal";
Vue.use(VModal, { componentName: "vue-modal" });

import ModalLink from "../components/ModalLink.vue";
import Modal from "../components/Modal.vue";
import ContentService from "../services/content.service";
import SmartCenterService from "../services/smart.center.service";

export default {
  name: "ContentMixins",
  components: {
    /* eslint-disable vue/no-unused-components */
    ModalLink,
    Modal
  },
  data() {
    return {
      error: false,
      message: "",
      pageContent: []
    };
  },
  props: {},
  created() {},
  methods: {
    async getAllSmartCenterPages() {
      await SmartCenterService.getAllSmartCenterPages(this.getSmartCenterPagesByPageCallback, this.$root.isSmartCenterEnabled(), this);
    },
    async getSmartCenterPagesByPage(pageName) {
      await SmartCenterService.getSmartCenterPagesByPage(pageName, this.getSmartCenterPagesByPageCallback, this.$root.isSmartCenterEnabled(), this);
    },
    async getPageContentByPage(pageName) {
      ContentService.getPageContentByPage(pageName, this.getPageContentCallBack, this);
    },
    async getPageContent(pageName) {
      ContentService.getPageContent(pageName, this.getPageContentCallBack, this);
    },
    async getSmartCenterPagesByPageCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        SmartCenterService.saveSmartCenterMapping(response.data.data);
      }
    },
    getPageContentCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContent = response.data.data;
      }
    }
  },
  watch: {},
  mounted() {},
  computed: {}
};
</script>
