<template>
  <div class="d-flex">
    <div class="form-group">
        <label for="filterByStartDate" class="mr-1">Start</label>
        <datepicker format="MMM dd yyyy" v-model="filterByStartDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="filterByStartDate"
        ></datepicker>
    </div>
    <div class="form-group ml-2">
        <label for="filterByEndDate" class="mr-1">End</label>
        <datepicker format="MMM dd yyyy" v-model="filterByEndDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="filterByEndDate"></datepicker>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";


export default {
  name: "FilterByDate",
  mixins: [],
  components: { Datepicker },
  data() {
    return {
        filterByStartDate: new Date(2020, 0, 1),
        filterByEndDate: new Date(),    
    };
  },    
  props: {
    filterType: {
      type: String,
      default: "created"
    },
    executeReset: {
      type: Boolean,
      default: false
    }    
  },
  methods: {
    reset: function(){
      this.filterByStartDate = new Date(2020, 0, 1);
      this.filterByEndDate = new Date();
    },
  },
  computed: {
    isCreatedDate: function(){
      return this.filterType == "created";
    },
    isCompletedDate: function(){
      return this.filterType == "completed";
    },
    isModifiedDate: function(){
      return this.filterType == "modified";
    },    
  }, 
  watch: {
    filterByStartDate: function(){
      this.$emit("setFilterStartDate", this.filterByStartDate, this.filterType);
    },
    filterByEndDate: function(){
      this.$emit("setFilterEndDate", this.filterByEndDate, this.filterType);
    },
    executeReset: function(){
      if (this.executeReset){
        this.reset();
      }
    }
  },
  created() {},
  mounted() {},
};

</script>