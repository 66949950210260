<template>
  <vue-modal :name="modalId" :clickToClose="false" :draggable="true" :scrollable="true" :adaptive="true"  @opened="loadData" :resizable="true" :width="368" :minHeight="700" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
            <div :class="isError ? 'form-group form-row text-danger' : 'form-group form-row text-success'">
                {{ modalReturnMessage }}
            </div>
            <div style="min-height: 100px;">
                <div class="form-row form-group form-inline">
                    <label for="countryId" class="col-2 mr-1">*Country</label>
                    <select id="countryId" name="countryId" class="w-75 custom-select required" v-model="locale.countryId">
                        <option value="0">Select Country</option>
                        <option v-for="(country_option, idxCountryOptions) in this.countryOptions" v-bind:value="country_option.countryId" v-bind:key="idxCountryOptions">
                            {{ country_option.name }}
                        </option>
                    </select>
                </div>
                <div class="form-row form-group form-inline">
                    <label for="languageId" class="col-2 mr-1">*Language</label>
                    <select id="languageId" name="languageId" class="w-75 custom-select required" v-model="locale.languageId">
                        <option value="0">Select Language</option>
                        <option v-for="(language_option, idxLanguageOptions) in this.languageOptions" v-bind:value="language_option.languageId" v-bind:key="idxLanguageOptions">
                            {{ language_option.name }}
                        </option>
                    </select>
                </div>
                <div class="form-row form-group form-inline">
                    <label for="languageId" class="col-2 mr-1">Description</label>
                    <input type="text" class="form-control w-75" id="description" name="description" v-model="locale.localeDescription" />
                </div>
            </div>                
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="saveLocale" :disabled="disableSave">Save</button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import AdminService from "../services/admin.service";
import LocaleService from "../services/locale.service";
import ViewMixins from "../mixins/ViewMixins.vue";
import AlertService from "../services/alert.service";

export default {
  name: "LocaleConfig",
  mixins: [ViewMixins],
  components: {},
  data() {
    return {
      modalReturnMessage: "",
      isError: false,
      disableSave: false,
      locale: LocaleService.getDefaultLocale(),
    };
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    saveLocale() {
      this.modalReturnMessage = "";
      AlertService.closeAlert();
      this.isError = false;
      if (this.locale.countryId == 0) {
          this.modalReturnMessage += "Country field is required. ";
          this.isError = true;
      } 
      if (this.locale.languageId == 0) {
          this.modalReturnMessage += "Language field is required";
          this.isError = true;
      } 
      if (this.isError == false){
        this.$root.processServerRequest("Saving locale");
        LocaleService.saveLocale(this.locale, this.mode, this.saveLocaleCallback, this);
      } else {
        AdminService.displayErrorMessage({ text: this.modalReturnMessage });
      }

    },
    saveLocaleCallback(response) {
      this.isError = false;
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.$emit("reload"); 
        this.locale =  LocaleService.getDefaultLocale();
      } else {
        this.isError = true;
        this.modalReturnMessage = response.data.message;
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async loadData(){
        this.modalReturnMessage = "";
        if (this.mode == "add"){
          this.locale = LocaleService.getDefaultLocale();
        } else {
          this.locale = JSON.parse(JSON.stringify(this.inputLocale));
        }
    },
    getLocaleCallback: function (response) {
      this.isError = false;
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        this.setDisplayView(true);
        this.user_options= response.data.data;
        AdminService.displaySuccessMessage({ text: response.data.message, duration: 1000 });
      } else {
        this.isError = true;
        this.modalReturnMessage = response.data.message;
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },    
    closeModal(){
      this.modalReturnMessage = "";
      this.$modal.hide(this.modalId);
    },    
  },
  watch: {
    inputLocale: function (newVal, oldVal) {
      this.locale = newVal;
    },
  },
  props: {
    inputLocale: {
      type: Object,
      default: function () {
        return {};
      },
    },    
    languageOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },    
    countryOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },    
    mode: {
      type: String,
      default: "add",
    },
    modalId: {
      type: String,
      default: "locale-config",
    },
    modalTitle: {
      type: String,
      default: "Configure Locale",
    },
  }
};
</script>
