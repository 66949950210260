<template>
  <div class="dashboard-card-expand">
    <a href="#!" @click="showModal()" class="btn-lg">
      <font-awesome-icon :icon="['fas', 'expand']" />
    </a>
  </div>
</template>

<script>
import GoogleService from "../../services/google.service";
import SurveyService from "../../services/survey.service";

export default {
  name: "DashboardCardExpand",
  mixins: [],
  components: {},
  data() {
    return {};
  },
  props: {
    dashboardId: {
      type: String,
      default: "expanded-dashboard"
    }
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    showModal: function() {
      GoogleService.logActionEvent("click", "Dashboard", this.$parent.title, SurveyService.getSurveyName(), "Expand", this);
      this.$parent.displayModal="true";
      this.$modal.show(this.dashboardId);
    }
  },
  watch: {}
};
</script>
