import axios from "axios";
import authHeader from "./auth-header";
import jQuery from "jquery";
import ErrorService from "./error.service";
import AdminService from "./admin.service";

import $ from "jquery";
import moment from "moment-timezone";
import Vue from "vue";
Vue.prototype.moment = moment;

const API_USER_URL = AdminService.getBaseUrl() + "/api/v1/user";

class UserService {
  saveUserToLocalStorage(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  isLoggedIn() {
    return typeof this.getUserId() != "undefined";
  }
  getUser() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user"));
    }
  }
  getUserTypeName() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user")).userTypeName;
    }
  }  
  getUserId() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user")).id;
    }
  }
  getNumberOfLogins() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user")).numberOfLogins;
    }
    return 0;
  }
  getDisplayAgreement() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
        return JSON.parse(localStorage.getItem("user")).displayAgreement;
    }
    return "N";
  }

  getUserCode() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user")).userCode;
    }
  }
  isW5OUser(client, isAdmin){
    if (isAdmin == false){
      return client.clientId == 37611 || client.parentClientId == 37611;
    }
    return false;
  }
  isExecutiveUser(user) {
    if (user && user.userTypeName) {
      return user.userTypeName == "Executive User";
    } else {
      return false;
    }
  }
  isAdvancedUser(user) {
    if (user && user.userTypeName) {
      return user.userTypeName == "Advanced User";
    } else {
      return false;
    }
  }
  isInsiderUser(user) {
    if (user && user.userTypeName) {
      return user.userTypeName == "Insider User";
    } else {
      return false;
    }
  }  
  isAdmin(user) {
    if (user && user.userTypeName) {
      return user.userTypeName == "Admin";
    } else {
      return false;
    }
  }
  async acceptUserAgreement(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userCode", this.getUserCode());
    await axios
      .post(API_USER_URL + "/accept-user-agreement", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.deleteSurvey", error, "admin", chartApp);
      });
  }

  async deleteUser(deleteUserCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("deleteUserCode", deleteUserCode);
    params.append("userId", this.getUserId());
    await axios
      .post(API_USER_URL + "/delete-user", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.deleteSurvey", error, "admin", chartApp);
      });
  }
  getUserTypeId() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user")).userTypeId;
    }
  }
  async saveUser(mode, formUserObject, userClients, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("mode", mode);
    params.append("userObject", JSON.stringify(formUserObject));
    params.append("adminUserId", this.getUserId());
    params.append("userClients", JSON.stringify(userClients));
    await axios
      .post(API_USER_URL + "/save-user", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.saveUser", error, "admin", chartApp);
      });
  }
  async getUserFromServer(userCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userCode", userCode);
    params.append("adminUserId", this.getUserId());
    await axios
      .post(API_USER_URL + "/get-user", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.getUser", error, "admin", chartApp);
      });
  }
  async loadUserTypes(callback, chartApp){
    const params = new URLSearchParams();
    await axios
      .get(API_USER_URL + "/get-all-user-types", { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.loadUserTypes", error, "admin", chartApp);
      });
  }
  async getUserList(callback, chartApp){
    await axios
    .get(API_USER_URL + "/get-user-list", { headers: await authHeader() })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      ErrorService.processError("UserService.getTableData", error, "admin", chartApp);
    });
  }
  
  async getLogoDocument(documentId, callback, chartApp){
    const formData = new FormData();
    formData.append("userId", this.getUserId());
    if (documentId == null){
      documentId = 0;
    }
    formData.append("documentId", documentId);
    await axios
      .post(AdminService.getBaseUrl() + "/api/v1/content/get-logo-document", formData, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.getLogoDocument", error, "admin", chartApp);
      });
  }
  async deleteLogo(callback, chartApp){
    const formData = new FormData();
    formData.append("userId", this.getUserId());
    await axios
      .post(AdminService.getBaseUrl() + "/api/v1/content/delete-logo", formData, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.deleteLogo", error, "admin", chartApp);
      });
  }
  async saveLogo(documentObject, logo, callback, chartApp){
    const formData = new FormData();
    formData.append("documentObject", JSON.stringify(documentObject));
    formData.append("logo", logo);
    formData.append("userId", this.getUserId());
    await axios
      .post(AdminService.getBaseUrl() + "/api/v1/content/save-logo", formData, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserService.saveLogo", error, "admin", chartApp);
      });
  }
  getUserTableColumnData() {
    return [
      {
        data: "id",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        width: "5rem",
        name: "id"
      },
      { data: "email", name: "email", width: "11rem" },
      { data: "userTypeId", name: "userTypeId", width: "7rem", class:"text-left" },
      { data: "userTypeName", name: "userTypeName", width: "6rem" },
      { data: "clientId", name: "clientId", width: "6rem", class:"text-left" },
      { data: "clientName", name: "clientName", width: "12rem" },
      { data: "firstName", name: "firstName", width: "7rem" },
      { data: "lastName", name: "lastName", width: "7rem" },
      { data: "numberOfLogins", name: "numberOfLogins", width: "7rem", class:"text-left" },
      { data: "displayAgreement", name: "displayAgreement", width: "7rem" },
      { data: "oktaUserId", name: "oktaUserId", width: "9rem" },
      { data: "email", name: "email", width: "12rem" },
      {
        data: "active",
        className: "text-center",
        name: "status",
        width: "5rem",
        render: function(data, type, row) {
          var statusMap = { N: { label: "Deactive", style: "warning" }, Y: { label: "Active", style: "success" } };
          if (type === "display") {
            return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
          } else {
            return data;
          }
        }
      },
      {
        data: "subscriptionEndDate",
        type: "date",
        name: "subscriptionEndDate",
        width: "10rem",
        class:"text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        data: "dateCreated",
        type: "date",
        name: "dateCreated",
        width: "7rem",
        class:"text-left",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        data: "dateModified",
        type: "date",
        name: "dateModified",
        width: "7rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      }
    ];
}  
}

export default new UserService();
