class ValidatorService {
  passwordErrorMessage = "Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, no parts of your username";
  emailErrorMessage = "Please insert/enter a valid email address."
  passwordCheck(value, element, param) {
    if (this.optional(element)) {
      return true;
    } else if (!/[A-Z]/.test(value)) {
      return false;
    } else if (!/[a-z]/.test(value)) {
      return false;
    } else if (!/[0-9]/.test(value)) {
      return false;
    }
    return true;
  }
  emailCheck(email) {
    /* Define the recommended regular expression. */
    var emailExp = new RegExp(/^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i);
 
    /* Test the email given against the expression and return the result. */
    return emailExp.test(email);
  }  
}
export default new ValidatorService();
