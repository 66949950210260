<template>
  <div v-if="displayFeature" class="form-row form-inline">
    <label for="placeholder" class="col-1">Placeholder</label>
    <input v-model="placeholder" id="placeholder" name="placeholder" type="text" class="col-4" @blur="updatePlaceholder" />
  </div>
</template>
<script>
export default {
  name: "QuestionPlaceholder",
  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      placeholder: ""
    };
  },
  props: {
    initialPlaceholder: {
      type: String,
      default: ""
    },    
    displayFeature: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updatePlaceholder: function() {
      this.$emit("save", this.placeholder);
    }
  },
  mounted() {
    this.placeholder = this.initialPlaceholder;
  },
  umnounted() {}
};
</script>
