<template>
  <div class="winners">
    <h3 class="text-primary pt-3">Winners Map</h3>
    <div class="winners-header">
        <div class="winners-header-col winners-header-border p-2">Drag and Drop functional and emotional needs into their investment bucket, and click Generate Profile to view your Winners Map.</div>        
        <div class="winners-header-col">To auto-arrange the map using algorithms, click the button that best matches your preferences. Click Undo to revert to your previous arrangement.</div>                
        <div class="d-flex justify-content-end col-3">        
          <div>
            <div class="map-header">Arrange</div>
            <button type="submit" class="btn btn-primary float-right mr-2" :disabled="!previousEffortSet.enablePreviousButton" @click="switchToPrevious()">
              <span>Undo</span>
            </button>
          </div>
          <div class="card shadow chart-type-card" :class="{ 'chart-type-selected': this.winnerTypeSelected == 'Default Needs' }" @click="switchToDefault('Default Needs')">
      <!--      <font-awesome-icon :icon="['fa', 'map-signs']" style="color: #379660;margin-left: 0.5rem !important;" size="2x" /> -->
            <i class="fa-duotone fa-road" style="color: #379660;margin-left: 0.5rem !important; font-size: 23px;"></i>
            <div :class="getChartCardClass()">By Need</div>
          </div>        
          <div class="card shadow chart-type-card" :class="{ 'chart-type-selected': this.winnerTypeSelected == 'Default Moat' }" @click="switchToDefault('Default Moat')">
<!--            <font-awesome-icon :icon="['fa', 'map-signs']" style="color: #e6af00;margin-left: 0.5rem !important;" size="2x" /> -->
            <i class="fa-duotone fa-road" style="color: #e6af00;margin-left: 0.5rem !important; font-size: 23px;"></i>
            <div :class="getChartCardClass()">By Moat</div>
          </div>        
          <div class="card shadow chart-type-card" :class="{ 'chart-type-selected': this.winnerTypeSelected == 'Default Value' }" @click="switchToDefault('Default Value')">
<!--                      <font-awesome-icon :icon="['fa', 'map-signs']" style="color: #68afff;margin-left: 0.5rem !important;" size="2x" /> -->
            <i class="fa-duotone fa-road" style="color: #68afff;margin-left: 0.5rem !important; font-size: 23px;"></i>
            <div :class="getChartCardClass()">By Value</div>
          </div>        
      </div>
    </div>
    <div class="form-row">
      <div class="winners-effort">
        <h4 class="text-primary">Table Stakes</h4>
        <div class="description">
          Maintain, reduce or abandon efforts <br />
          that add little or no current value
        </div>
        <VueDraggable
          v-model="conserveEfforts"
          :disabled="!enabled"
          class="list-group draggable-container"
          ghost-class="draggable-ghost"
          :move="checkMove"
          :sort="true"
          @start="dragging = true"
          @end="endOfDragConserveEfforts"
          options="people"
        >
          <div class="list-group-item draggable-card mx-auto" v-for="element in conserveEfforts" :key="element.name" :index="element.name">
            <i class="fa fa-caret-right" aria-hidden="true"></i>
            <span :class="element.needType + ' pl-1'">{{ element.name }}</span>
          </div>
        </VueDraggable>
      </div>
      <div class="winners-effort">
        <h4 class="text-primary">Core</h4>
        <div class="description">
          Continue or raise efforts to manage <br />
          the core business that creates value today
        </div>
        <VueDraggable
          v-model="coreEfforts"
          :disabled="!enabled"
          class="list-group draggable-container"
          ghost-class="draggable-ghost"
          :move="checkMove"
          :sort="true"
          @start="dragging = true"
          @end="endOfDragCoreEfforts"
          options="people"
        >
          <div class="list-group-item draggable-card mx-auto" v-for="element in coreEfforts" :key="element.name" :index="element.name">
            <i class="fa fa-caret-right" aria-hidden="true"></i>
            <span :class="element.needType + ' pl-1'">{{ element.name }}</span>
          </div>
        </VueDraggable>
      </div>
      <div class="winners-effort" style="border-right: none;">
        <h4 class="text-primary">Diverge</h4>
        <div class="description">
          Create new efforts that separate <br />
          from the status quo to build compelling value
        </div>
        <VueDraggable
          v-model="divergeEfforts"
          :disabled="!enabled"
          class="list-group draggable-container"
          ghost-class="draggable-ghost"
          :move="checkMove"
          :sort="true"
          @start="dragging = true"
          @end="endOfDragDivergeEfforts"
          options="people"
        >
          <div class="list-group-item draggable-card mx-auto" v-for="element in divergeEfforts" :key="element.name" :index="element.name">
            <i class="fa fa-caret-right" aria-hidden="true"></i>
            <span :class="element.needType + ' pl-1'">{{ element.name }}</span>
          </div>
        </VueDraggable>
      </div>
    </div>
  </div>
</template>
<script>
import WinnersService from "../../services/chart/winners.service";
import AdminService from "../../services/admin.service";

import { VueDraggable } from 'vue-draggable-plus';
import $ from "jquery";

export default {
  name: "Winners",
  mixins: [],
  components: { VueDraggable },
  props: {
    surveyCode: {
      type: String,
      default: ""
    },
    originalWinnerType: {
      type: String,
      default: ""
    },
    clientChartObject: {
      type: Object,
      default: function() {
        return {
          clientChartId: 0
        };
      }
    }
  },
  data() {
    return {
      winnerTypeSelected: "",
      enabled: true,
      conserveEfforts: [],
      coreEfforts: [],
      divergeEfforts: [],
      previousEffortSet: {
        name : "Previous", isPopulated: false, enablePreviousButton: false
      },
      defaultEffortSets: [
        {name: "Manual", conserveEfforts: [], coreEfforts: [],divergeEfforts: [] },
        {name: "Default Needs",conserveEfforts:[], coreEfforts: [],divergeEfforts: [] },
        {name: "Default Value",conserveEfforts: [], coreEfforts: [],divergeEfforts: [] }
      ]
    };
  },
  methods: {
    getChartCardClass() {
      let returnClassName = "chart-card-row chart-card-name ";
      return returnClassName;
    },
    savePrevious: function(defaultTypeName){
      if (!this.previousEffortSet.isPopulated){
        this.previousEffortSet.conserveEfforts = this.conserveEfforts;
        this.previousEffortSet.coreEfforts = this.coreEfforts;
        this.previousEffortSet.divergeEfforts = this.divergeEfforts;
        this.previousEffortSet.isPopulated =  true;
      }
      if(this.previousEffortSet.isPopulated){
        this.previousEffortSet.enablePreviousButton = true;
      }
    },
    switchToPrevious: function(){
      if (!$.isEmptyObject(this.previousEffortSet)) {
        let conserveEfforts = JSON.parse(JSON.stringify(this.previousEffortSet.conserveEfforts));
        let coreEfforts = JSON.parse(JSON.stringify(this.previousEffortSet.coreEfforts));
        let divergeEfforts = JSON.parse(JSON.stringify(this.previousEffortSet.divergeEfforts));
        if (!this.isDefaultTypeEmpty(conserveEfforts,coreEfforts,divergeEfforts)){
          this.setEffortSet(conserveEfforts,coreEfforts,divergeEfforts);
          this.previousEffortSet.enablePreviousButton = false;
          this.winnerTypeSelected = "";
        }
      }
    },
    switchToDefault: function(defaultTypeName){
      let defaultEffortSet = this.defaultEffortSets.find(x => x.name == defaultTypeName)
      if (!$.isEmptyObject(defaultEffortSet)) {
        this.winnerTypeSelected = defaultTypeName;
        let conserveEfforts = JSON.parse(JSON.stringify(defaultEffortSet.conserveEfforts));
        let coreEfforts = JSON.parse(JSON.stringify(defaultEffortSet.coreEfforts));
        let divergeEfforts = JSON.parse(JSON.stringify(defaultEffortSet.divergeEfforts));
        if (!this.isDefaultTypeEmpty(conserveEfforts,coreEfforts,divergeEfforts)){
          this.savePrevious(defaultTypeName);
          this.setEffortSet(conserveEfforts,coreEfforts,divergeEfforts);
        }
      }
    },    
    endOfDragConserveEfforts: function(event) {},
    endOfDragCoreEfforts: function(event) {},
    endOfDragDivergeEfforts: function(event) {},
    checkMove: function(e) {
    },
    setupWinnersMapForSave: function() {
      for (let conserveEffortsIndex = 0; conserveEffortsIndex < this.conserveEfforts.length; conserveEffortsIndex++) {
        this.conserveEfforts[conserveEffortsIndex].sequenceNumber = conserveEffortsIndex + 1;
        this.conserveEfforts[conserveEffortsIndex].winnerEffortTypeId = 1;
      }
      for (let coreEffortsIndex = 0; coreEffortsIndex < this.coreEfforts.length; coreEffortsIndex++) {
        this.coreEfforts[coreEffortsIndex].sequenceNumber = coreEffortsIndex + 1;
        this.coreEfforts[coreEffortsIndex].winnerEffortTypeId = 2;
      }
      for (let divergeEffortsIndex = 0; divergeEffortsIndex < this.divergeEfforts.length; divergeEffortsIndex++) {
        this.divergeEfforts[divergeEffortsIndex].sequenceNumber = divergeEffortsIndex + 1;
        this.divergeEfforts[divergeEffortsIndex].winnerEffortTypeId = 3;
      }
    },
    saveWinnersMap: async function() {
      this.setupWinnersMapForSave();
      await WinnersService.saveWinnersMap(this.surveyCode, this.conserveEfforts, this.coreEfforts, this.divergeEfforts, this.saveWinnersMapCallback, this);
    },
    saveWinnersMapCallback: function(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displayInfoMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData: function() {
      if (this.originalWinnerType != "") {
        this.winnerTypeSelected = this.originalWinnerType;
      }
      if (this.surveyCode != "") {
        WinnersService.getWinnersMapBySurveyCode(this.surveyCode, this.clientChartId, this.loadDataCallback, this);
      }
    },
    loadDataCallback: function(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let conserveEfforts = response.data.data.conserveEfforts;
        let coreEfforts = response.data.data.coreEfforts;
        let divergeEfforts = response.data.data.divergeEfforts;
        this.defaultEffortSets = response.data.data.defaultEffortSets;
        if (!this.isDefaultTypeEmpty(conserveEfforts,coreEfforts,divergeEfforts)){
          this.conserveEfforts = conserveEfforts;
          this.coreEfforts = coreEfforts;
          this.divergeEfforts = divergeEfforts;
        } else {
          for (let index = 0; index < this.defaultEffortSets.length; index++) {
            let defaultSet = this.defaultEffortSets[index];
            if (!this.isDefaultTypeEmpty(defaultSet.conserveEfforts,defaultSet.coreEfforts,defaultSet.divergeEfforts)){
              this.switchToDefault(defaultSet.name);
              break;
            }
          }
          
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    isDefaultTypeEmpty: function(conserveEfforts, coreEfforts, divergeEfforts){
      if (typeof conserveEfforts == "undefined" || typeof coreEfforts == "undefined" || typeof divergeEfforts == "undefined"){
        return true;
      }
      return conserveEfforts.length == 0 && coreEfforts.length == 0 && divergeEfforts.length == 0;
    },
    setEffortSet: function(conserveEfforts, coreEfforts, divergeEfforts){
      this.conserveEfforts = conserveEfforts;
      this.coreEfforts = coreEfforts;
      this.divergeEfforts = divergeEfforts;
    }    
  },
  created() {},
  mounted() {
    this.loadData();
  },
  watch: {
    clientChartId: function() {
      this.loadData();
    },
    surveyCode: function() {
      this.loadData();
    }
  },
  computed: {
    clientChartId: function() {
      return this.clientChartObject.clientChartId;
    }
  }
};
</script>
