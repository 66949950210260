<template>
    <vue-modal name="preview-advisor-email" :width="668" height="auto" @before-open="beforeOpen" :scrollable="true">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Preview Advisor Email</h5>
        </div>
        <div v-show="!getDisplayView()" class="text-success advisor-preview-email modal-body">  
            Email is being rendered please hold......
        </div>      
        <div v-show="getDisplayView()" class="advisor-preview-email modal-body">        
            <div>
                <div>From: {{ emailFromAddress }}</div>
                <div>Subject: {{ emailSubject }}</div>
                
            </div>
            <div class="email-body" v-html="emailBody"></div>
        </div>            
        <div class="form-group form-row d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="$modal.hide('preview-advisor-email');"
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
</template>

<script>
import ContentMixins from "../mixins/ContentMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";

import AdminService from "../services/admin.service";
import AdvisorService from "../services/advisor.service";

export default {
  name: "AdvisorEmailPreview",
  components: {},
  mixins: [ContentMixins, ViewMixins],

  methods: {
    beforeOpen(){
      this.setDisplayView(false);
      AdvisorService.previewAdvisorEmail(this.advisorId, this.previewAdvisorEmailCallback, this);
    },
    previewAdvisorEmailCallback(response){
      this.$root.processServerResponse();
      this.setDisplayView(true);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.emailFromAddress = response.data.data.from; 
        this.emailSubject = response.data.data.subject; 
        this.emailBody = response.data.data.body; 
        AdminService.displayInfoMessage({ text: response.data.message, duration: 1000 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }      
    }
  },
  props: {
    advisorId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      emailBody: "",
      emailFromAddress: "",
      emailSubject: "",
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
};
</script>
