import Vue from "vue";

export default async function authHeader() {
  if (typeof Vue.prototype.$auth != "undefined"){
    let accessToken = await Vue.prototype.$auth.getAccessToken();
    if (accessToken) {
      return { Authorization: "Bearer " + accessToken };
    }
  }
}
