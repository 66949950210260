<template>
  <div></div>
</template>
<script>
import SmartCenterLink from "../components/SmartCenterLink.vue";

import ContentMixins from "./ContentMixins.vue";
import AdminService from "../services/admin.service";
import SmartCenterService from "../services/smart.center.service";
import UserService from "../services/user.service";
import GoogleService from "../services/google.service";
export default {
  name: "ViewMixins",
  mixins: [ContentMixins],
  components: {
    /* eslint-disable vue/no-unused-components */
    SmartCenterLink
  },
  data() {
    return {
      error: false,
      displayView: false,
      message: "",
      modalLoading: false
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  },
  created() {
    this.getPageContent(this.title);
    //this.getAllSmartCenterPages();
  },
  methods: {
    setDisplayView(setting) {
      this.displayView = setting;
    },
    getDisplayView(setting) {
      return this.displayView;
    },
    setModalLoading(setting) {
      this.modalLoading = setting;
    },
    getModalLoading() {
      return this.modalLoading;
    },
    postLoadData() {
      this.setDisplayView(true);
    },
    toggleHeaderMenu: function() {
      let label = this.$root.getShowHeaderMenu() ? "close" : "open";
      GoogleService.logClickEvent("Header Menu Toggle", label, 0, this);         

      this.$root.toggleHeaderMenu();
    },    
    buildSmartCenterLink(location, page) {
      return (
        "  <a data-html2canvas-ignore='true' href='" +
        SmartCenterService.buildSmartCenterLink(location, page) +
        "' target='_blank'><i class='fa-duotone fa-lightbulb' aria-hidden='true' alt='Smart Center'></i></a>"
      );
    }
  },
  watch: {},
  mounted() {
    if (UserService.getDisplayAgreement() == "Y"){
      this.$modal.show('user-agreement-modal');
    }
  },
  computed: {}
};
</script>
