<template>
    <div id="respondents-numbers">
        <div class="col text-center">
            <div class="header">Rate</div>
            <div class="count">{{ rate }}</div>
        </div>
        <div class="col text-center">
            <div class="header">Completes</div>
            <div class="count">{{ respondentCounts.completes }}</div>
        </div>
        <div class="col text-center">
            <div class="header">Partials</div>
            <div class="count">{{ respondentCounts.partials }}</div>
        </div>
        <div class="col text-center">
            <div class="header">Clicks</div>
            <div class="count">{{ respondentCounts.total }}</div>
        </div>
    </div>
</template>
<script>

export default {
  name: "RespondentCounts",
  components: {},
  computed: {
    rate: function() {
      if (this.respondentCounts.completionRate == 0) {
        return "--";
      }
      return this.respondentCounts.completionRate + " % ";
    },    
  },
  props: {
    respondentCounts: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data() {
    return {

    };
  },
  watch: {},
  methods: {
  },
  mounted() {
  }
};
</script>
