import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";

const API_CLIENT_SETTINGS_URL = AdminService.getBaseUrl() + "/api/v1/client-settings";

class ClientSettingsService {
  async resetClientSettings(clientCode, settings, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("settings", JSON.stringify(settings));
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_SETTINGS_URL + "/reset-client-settings", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientSettingsService.resetClientSettings", error, "admin", chartApp);
      });
  }
  async getClientSettings(clientCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_SETTINGS_URL + "/get-client-settings", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientSettingsService.getClientSettings", error, "admin", chartApp);
      });
  }
  async saveClientSetting(clientCode, type, value, sequenceNumber, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    params.append("type", type);
    params.append("value", value);
    params.append("sequenceNumber", sequenceNumber);
    await axios
      .post(API_CLIENT_SETTINGS_URL + "/save-client-setting", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientSettingsService.saveClientSetting", error, "admin", chartApp);
      });
  }

  mapClientSetting(value, typeName) {
    let clientSetting = {};
    clientSetting.clientSettingValue = value;
    clientSetting.clientSettingTypeName = typeName;
    return clientSetting;
  }
  parseClientSettings(inputClientSettings) {
    let clientSettings = {};
    let numberOfPeriods = inputClientSettings.find(x => x.clientSettingTypeName == "number-of-periods");
    if (typeof numberOfPeriods != "undefined" && Object.prototype.hasOwnProperty.call(numberOfPeriods, "clientSettingValue")) {
      clientSettings.numberOfPeriods = numberOfPeriods.clientSettingValue;
    } else {
      clientSettings.numberOfPeriods = 4;
    }
    let startPeriod = inputClientSettings.find(x => x.clientSettingTypeName == "start-period");
    if (typeof startPeriod != "undefined" && Object.prototype.hasOwnProperty.call(startPeriod, "clientSettingValue")) {
      clientSettings.startPeriod = startPeriod.clientSettingValue;
    } else {
      clientSettings.startPeriod = 1;
    }
    return clientSettings;
  }
}

export default new ClientSettingsService();
