<template>
  <div class="labels">
    <div v-if="$parent.isValue" class="value-drivers">
      <div class="column-header">Value Drivers</div>
      <div></div>
      <div></div>
    </div>
    <div v-if="$parent.isNeed" class="value-drivers">
      <div class="column-header">Business Areas</div>
    </div>
    <div class="experience-components">
      <div class="column-header">Experience Components</div>
      <div class="experience-components-column-header">
        <div><span>Importance Rank</span></div>
        <div v-if="$parent.isValue"><span>Intensity Rank</span></div>
        <div v-if="$parent.isNeed"><span>Gap Rank</span></div>
        <div v-else><span>Dissonance Rank</span></div>
        <div v-if="$parent.isNeed"><span>Pricing Power Rank</span></div>
      </div>
    </div>
    <div class="performance-assessment">
      <div class="column-header">Performance Assessment</div>
      <div class="performance-assessment-column-header row no-gutters">
        <div class="col-6 pl-1 pr-1">
          <div class="performance-assessment-column-section-header">GAP Group Sizes</div>
          <div>
            <span :class="getGapGroupClass(0)">Smallest 1/3<sup>rd</sup></span>
          </div>
          <div>
            <span :class="getGapGroupClass(1)">Next Tier</span>
          </div>
          <div>
            <span :class="getGapGroupClass(2)">Bottom Tier</span>
          </div>
        </div>
        <div class="col-6">
          <div class="performance-assessment-column-section-header">Pricing Power</div>
          <div>
            <span :class="getPricingPowerClass(0)">Highest 1/3<sup>rd</sup></span>
          </div>
          <div><span :class="getPricingPowerClass(1)">Next Tier</span></div>
          <div><span :class="getPricingPowerClass(2)">Bottom Tier</span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ValueMapLabels",
  mixins: [],
  components: {},
  data() {
    return {};
  },
  props: {
    useAltColors: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    getPricingPowerClass: function(index) {
      let pricingPowerClass = "color-pricing-power-tier-" + (index + 1);
      return pricingPowerClass;
    },
    getGapGroupClass: function(index) {
      let gapGroupClass = this.useAltColors ? "alt-" : "";
      gapGroupClass += "gap-group-tier-" + (index + 1);
      return gapGroupClass;
    }
  },
  watch: {}
};
</script>
