<template>
  <div class="benchmark-exclusion mx-auto">
        <button type="button" class="btn btn-primary mr-2" :disabled="this.inputClientCode == ''" @click="openBenchmarkExclusionList">Manage Benchmark Exclusion List</button>
        <button type="button" class="btn btn-primary mr-2" :disabled="this.inputClientCode == ''" @click="openApplyBenchmarkExclusionList">Apply to Client Hierarchy</button>
        <manage-benchmark-exclusion-list ref="manageExclusionList" 
            :input-client-code="inputClientCode"    
        />
        <modal
            :modal-id="'Apply to Client Hierarchy'"
            :modal-title="'Apply to Client Hierarchy'"
            :modal-content="'Are you sure you want apply the exlusion list to all organizations in this hierarchy ?'"
            :include-confirmation="true"
            @confirm="applyBenchmarkExclusionList"
          />
  </div>
</template>

<script>
import ViewMixins from "../mixins/ViewMixins.vue";
import ManageBenchmarkExclusionList from "../modals/ManageBenchmarkExclusionList.vue";
import BenchmarkCompanyService  from "../services/benchmark.company.service";
import AdminService  from "../services/admin.service";

export default {
  name: "BenchmarkExclusion",
  mixins: [ViewMixins],
  components: {ManageBenchmarkExclusionList},
  data() {
    return {
    };
  },
  props: {
    inputClientCode: {
        type: String,
        default: ""
    },     
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    openBenchmarkExclusionList: function(){
        this.$modal.show("manage-benchmark-exclusion-list");
    },
    openApplyBenchmarkExclusionList: function(){
        this.$modal.show("Apply to Client Hierarchy");
    },
    applyBenchmarkExclusionList: function(){
        BenchmarkCompanyService.applyBenchmarkExclusionList(this.inputClientCode, this.applyBenchmarkExclusionListCallback, this);
    },
    applyBenchmarkExclusionListCallback: function(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  watch: {},
};
</script>
