<template>
    <div id="moat" class="rank-body">
        <div class="col-1"></div>
        <div class="tier-labels">
            <div>Most Competitive</div>
            <div>Least Competitive</div>
        </div>
        <div :id="moatRankChartDisplayIdName" class="rank-chart-display">
          <div class="moat-score-rank-dashboard-chart" :style="dashboardChartStyle">
            <chart :options="options"></chart>      
          </div>         
        </div>
        <div class="rank-display ">
            <div class="rank-display-title">{{ titleHtml }}</div>
            <div class="score">{{ score }} %</div>
        </div>
</div>
</template>

<script>
import RankBodyMixins from "../../../mixins/RankBodyMixins.vue";
import DashboardMixins from "../../../mixins/DashboardMixins.vue";
import DashboardService from "../../../services/dashboard.service.js";
import ReportService from "../../../services/report.service";

import $ from "jquery";
import echarts from "echarts";

export default {
  name: "MoatScoreRankBody",
  mixins: [RankBodyMixins],
  components: {},
  data() {
    return {
      title: "Performance Trends",
      chart: {
        title: ""
      },      
      options: {},
    };
  },
  props: {
    moatRankChartDisplayIdName: {
      type: String,
      default: "moat-rank-chart-display"
    },
  },
  computed: {
    dashboardChartStyle: function() {
      return  "height: " + this.baseHeight + "px";
    },
  },
  mounted() {
    //console.log("MoatScoreRankBody mounted");
    this.resetChart();
  },
  created() {},
  methods: {
    resetChart: function(){
      // eslint-disable-next-line no-console
      //console.log("DashboardMixins::resetChart");
      if (this.chart && typeof this.chart.setOption === "function") {
        this.chart.setOption({option: {} }, true);
      }
      this.initChart({});
    },    
    initChart: function (dynamicOptions) {
      //console.log("MoatScoreRankBody initChart");
      // https://echarts.apache.org/examples/en/editor.html?c=scatter-punchCard
      let chartDiv = $("#"+this.moatRankChartDisplayIdName)[0];
      this.initBaseChart(dynamicOptions, chartDiv);
    },
    initBaseChart: function (dynamicOptions, chartDiv) {
      //console.log("MoatScoreRankBody initBaseChart");
      this.chart = echarts.init(chartDiv);
      let vm = this;
      this.chart.on('finished', () => {
        vm.saveImage();
      });
      this.options = DashboardService.getMoatScoreRankOptions(echarts);
      let moatScoreData = [];
	    moatScoreData.push("Moat Score");
      moatScoreData.push(this.score);
    	this.options.series[0].data.push(moatScoreData);      
      $.extend(true, this.options, dynamicOptions);
      //this.printOptions();
      this.chart.setOption(this.options, true);
    },
    printOptions(){
       // eslint-disable-next-line no-console
       console.log(this.dashboardId + " options: " + JSON.stringify(this.options));
    },
    saveImage(){
      if (this.isGenerateReportRunning()){
        let vm = this;
        setTimeout(function() {
          vm.imageUrl =  vm.chart.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff'
          });      
        }, 500);  
      }
    },
    isGenerateReportRunning(){
      return ReportService.getReportAutoChartGenerateStatus() == "generate";
    },     
  },
  watch: {}
};
</script>
