<template>
    <div class="contribution-container">
        <div v-if="!isDashboardCard" class="chart-header-container d-flex">
            <h4 class="text-primary pl-2">{{ contributionChartTitle }}</h4>
        </div>      
        <div class="title-and-chart">
            <div class="title-and-chart-left">
              <div v-if="isDashboardCard" class="chart-title-container">
                    <h4 class="text-primary">{{ contributionChartTitle }}</h4>
                </div>      
                <div class="contribution-button-container">      
                    <chart-buttons
                        :display="true"
                        :tooltip-configuration="snapChartTooltipConfig"
                        :snap-container-id="contributionSnapContainerId"
                        :include-advisor-button="false"
                        :include-remove-box="false"
                        :snap-label="'Snap'">
                    </chart-buttons>
                    <div v-show="!$parent.isInsiderUser && isSavedCard" class="chart-button-container">
                        <div class="chart-button-column">
                        <button class="btn ml-4" type="button" @click="displayInsights(contributionInsightModalId, contributionReportPageTypeName)">
                            <i class='fa-duotone fa-thought-bubble fa-lg'></i>                                                                            
                        </button>
                        </div>
                    </div>
                </div>      
            </div>
            <div :id="contributionSnapContainerId" class="contribution-wrapper">
              <div v-for="(chart, idxCharts) in contributionChart.charts" :key="idxCharts" :class="getClass(idxCharts)" >
                  <div v-show="chart.title != ''" class="contribution-chart-title">{{ chart.title }}</div>
                  <div class="contribution-chart-sub-title">{{ chart.subTitle }}</div>
                  <div class="d-flex">
                    <div class="contribution-chart-header">
                      <div class="value">
                        <div class="text-center w-100" v-html="getHeader(idxCharts)" style="word-wrap: break-word;"></div>
                      </div>
                      <div class="period-name-display"> {{contributionPeriodName}} </div>
                    </div>
                    <div class="contribution-chart">
                        <div class="chart">
                            <div v-if="displaySubHeader(idxCharts)" class="row no-gutters">
                              <div class="period-name-display"> &nbsp; </div>
                              <div class="value">
                                  <div class="text-center w-100">Future {{ contributionChartTitle }}</div>
                              </div>
                            </div>
                            <!--
                            <div v-if="displayChart(idxCharts)">
                              <div :id="contributionChart.snapContainerId+'-'+idxCharts" class="future-contribution-chart">
                                <chart :options="chart.options"></chart>
                              </div>
                              <div
                                v-for="(text, idxText) in chart.text"
                                v-bind:key="idxText"
                                class="attribute-list">
                                {{  text }}
                              </div>
                            </div>
                            -->
                            <div class="contribution-chart">
                              <div :id="prefix+'-'+contributionChart.snapContainerId+'-'+chart.id" class="future-contribution-chart no-data-for-chart-container">
                                <div v-if="chart.title=='Pricing Power'" class="no-data-for-chart">
                                  Not enough respondent data exists to display Pricing Power opportunities.
                                </div>
                                <div v-else class="no-data-for-chart">
                                  No performance areas <br /> are moving in sync <br /> with this growth metric.
                                  <br/><br/>
                                  Try using another <br /> growth metric.
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="period-name-display">  {{getPeriodName(idxCharts)}} </div>
                    </div>
                  </div>
              </div> <!-- End of For loop-->
              <div v-if="includeCoorelationMap" class="coorelation-map order-2">
                <div class="contribution-chart-title"></div>
                <div class="contribution-chart-sub-title"></div>
                <div class="dashboard-predictive-analytics">
                  <div>
                      <div class="d-flex pb-1">
                        <div class="very-strong-correlation-square border"></div>
                        <div class="pl-2 align-self-center">Very Strong</div>
                      </div>
                      <div class="d-flex pb-1">
                        <div class="strong-correlation-square border"></div>
                        <div class="pl-2 align-self-center">Strong</div>
                      </div>
                      <div class="d-flex">
                        <div class="mild-correlation-square border"></div>
                        <div class="pl-2 align-self-center">Mild</div>
                      </div>
                  </div>
                </div>
              </div>
            </div>              
        </div>
        <insights
            ref="insightsContributionModal"
            :modal-name="contributionInsightModalId"
            :report-page-type-name="contributionReportPageTypeName"
            :entry-page-content="this.$parent.pageContentForReport"
            @saveReportPage="updateReportPages"
            @saveAllReportPages="saveAllReportPages"
            @saveClientChart="saveClientChart"
        />    
    </div>
</template>

<script>
import ChartButtons from "../../components/chart/ChartButtons.vue";
import Insights from "../../modals/Insights.vue";
import ReportService from "../../services/report.service";

import Vue from "vue"; 
import echarts from "echarts";
import $ from "jquery";

export default {
  name: "PredictiveAnalyticsContributionChart",
  mixins: [],
  components: {ChartButtons,Insights},
  data() {
    return {
        chart: {
            title: ""
        }, 
        snapChartTooltipConfig: {
            content: "Click to take snapshot of the chart. ",
            trigger: "hover",
            delay: { show: 500, hide: 500 }
        },      
        
    };
  },
  props: {
    prefix: {
      type: String,
      default: "p"
    },
    includeCoorelationMap: {
      type: Boolean,
      default: true
    },
    isSavedCard: {
        type: Boolean,
        default: false
    },    
    isDashboardCard:{
      type: Boolean,
      default: false
    },
    contributionChart:  {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  computed: {
    displayContributionHeader: function(){
        return this.contributionHeader != "";
    },
    contributionChartTitle: function(){
        return this.contributionChart.title;
    },
    contributionSnapContainerId: function(){
        return this.prefix + "-" + this.contributionChart.snapContainerId;  
    },
    contributionReportPageTypeName: function(){
      return this.contributionChart.reportPageTypeName;
    },     
    contributionInsightModalId: function(){
      return this.contributionChart.insightModalId;
    },       
    contributionPeriodName: function(){
      return this.contributionChart.periodName;
    },       
    
  },
  mounted() {
    let vm = this;
    $(document).ready(function () {
        vm.initChart();
    });    
  },
  created() {},
  methods: {
    getClass: function(index) {
      let className = "individual-contribution-chart";
      if (index == 0){
        className += " order-3"
      } else {
        className += " order-1"
      }
      return className
    },
    displaySubHeader: function(index) {
        return index == 1 && this.contributionChart.snapContainerId.includes("future-contribution-contribution");
    },
    getHeader: function(index){
        return this.contributionChart.charts[index].header;
    },
    getPeriodName: function(index) {
        return this.contributionChart.charts[index].periodName;
    },
    displayChart: function(index) {
        return this.contributionChart.charts[index].display == true;
    },
    initChart: function() {
          for (let individualChartIndex = 0; individualChartIndex < this.contributionChart.charts.length; individualChartIndex++) {
            if (this.displayChart(individualChartIndex) == true){
              let chartId = this.contributionChart.charts[individualChartIndex].id;
              let divName = "#" + this.prefix + "-" + this.contributionChart.snapContainerId+"-"+chartId;
              let chartDiv = $("#" + this.prefix + "-" + this.contributionChart.snapContainerId+"-"+chartId)[0];
//              console.log("divName: " + divName);
              this.chart = echarts.init(chartDiv);
              let chartOptions = this.contributionChart.charts[individualChartIndex].options;
//              console.log("Chart Options: " + JSON.stringify(chartOptions));
              this.chart.setOption(chartOptions, true);
/*              
              let imageUrl =  this.chart.getDataURL({
                pixelRatio: 2,
                backgroundColor: '#fff'
              });     
              this.$emit('save-image',imageUrl);              
*/              
            }
          }
//          this.contributionChart.charts.reverse();
    },   
    displayInsights(modalId, reportPageTypeName) {
      if (reportPageTypeName == this.contributionReportPageTypeName) {
        this.$refs.insightsContributionModal.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsContributionModal.initializeInsights(this.$parent.clientChart);
        this.$modal.show(modalId);
      } 
    },  
    saveAllReportPages() {
      this.$parent.saveReportPage(this.modalId,this.$parent.reportTypeName);
    },    
    updateReportPages(value) {
      this.$parent.reportPagesList.push(value);
    },    
    saveClientChart(value, message) {
      this.$parent.saveClientChart(value, message);
    },
  },
  watch: {},
};
</script>
