import $ from "jquery";
import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";

const API_ADMIN_URL = AdminService.getBaseUrl() + "/api/v1/admin"

class BenchmarkCompanyService {
  async buildBenchmarkMapping  (callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADMIN_URL + "/build-benchmark-company-mapping", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("BenchmarkCompanyService.getBenchmarkExclusionList", error, "admin", chartApp);
      });
  }
  async getBenchmarkExclusionList(clientCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADMIN_URL + "/get-benchmark-exclusion-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("BenchmarkCompanyService.getBenchmarkExclusionList", error, "admin", chartApp);
      });
  }
  async saveBenchmarkExclusionList(clientCode, list, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    params.append("list", list);
    await axios
      .post(API_ADMIN_URL + "/save-benchmark-exclusion-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("BenchmarkCompanyService.saveBenchmarkExclusionList", error, "admin", chartApp);
      });
  }
  async applyBenchmarkExclusionList(clientCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCode", clientCode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADMIN_URL + "/apply-benchmark-exclusion-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("BenchmarkCompanyService.applyBenchmarkExclusionList", error, "admin", chartApp);
      });
  }
  

  async importBenchmarkSummaryData(file, callback, chartApp) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", UserService.getUserId());

    let headers = { "Content-Type": "multipart/form-data" };
    $.extend(headers, await authHeader());

    await axios
    .post(API_ADMIN_URL + "/import-benchmark-company-data", formData, { headers: headers })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      ErrorService.processError("BenchmarkCompanyService.importBenchmarkSummaryData", error, "admin", chartApp);
    });  

  }
  async getBenchmarkSummaryDataList(callback, chartApp) {
    await axios
    .get(API_ADMIN_URL + "/get-benchmark-summary-data", { headers: await authHeader() })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      ErrorService.processError("BenchmarkCompanyList.getTableData", error, "admin", chartApp);
    });    
  }
  getTableColumnData() {
    return [
      {
        title: "",
        data: "id",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-left");
        },
        className: "",
        name: "id",
        width: "0rem",
        bVisible: false
      },
      { title: "Quarter", data: "quarter", name: "quarter", class: "text-left", width: "10rem" },
      { title: "Year", data: "year", name: "year", class: "text-left", width: "10rem" },
      { title: "Active", data: "active", name: "active", width: "5rem" },
      { title: "Number of Records", data: "recordCount", name: "recordCount", class: "text-left", width: "10rem" }
    ];
  }
}

export default new BenchmarkCompanyService();
