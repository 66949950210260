import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";

const API_URL = process.env.VUE_APP_BASE_URL + "/api/auth/";
//const USER_MAX_TIME = 60000;
const USER_MAX_TIME = 7200000;
const ADMIN_USER_MAX_TIME = 7200000;

class AuthService {
  sendForgotPasswordEmail(params, callback) {
    return axios.post(API_URL + "send-forgot-password", params).then(response => {
      callback(response);
    });
  }
  resetPassword(password, token, callback) {
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("password", password);

    return axios.post(API_URL + "set-new-password", params).then(response => {
      callback(response);
    });
  }
  verifyToken(token, callback) {
    const params = new URLSearchParams();
    params.append("token", token);

    return axios.post(API_URL + "verify-token", params).then(response => {
      callback(response);
    });
  }
  login(user) {
    return axios
      .post(API_URL + "signin", {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("client", JSON.stringify(response.data.client));
        }
        return response.data;
      });
  }
  loadUser(user) {
    const params = new URLSearchParams();
    params.append("email", user.email);
    return axios.post(API_URL + "load-user", params).then(response => {
      let statusCode = response.data.statusCode;
      if (statusCode == 400){
        let message = response.data.message;
        return Promise.reject(message);
      }
      let user = response.data.data.user;
      if (typeof user != "undefined" && user.active == "Y"){
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("client", JSON.stringify(response.data.data.client));
        localStorage.setItem("user-settings", JSON.stringify(response.data.data.userSettings));
        localStorage.setItem("use-w30-as-default-client", JSON.stringify(response.data.data.useW30AsDefaultClient));
        return user;
      } else {
        return Promise.reject("Your account is not active at the moment. Please check with your administrator");
      }
    });
  }
  logout() {
    localStorage.clear();
  }
  hasSessionExpired(currentUser) {
    if (currentUser) {
      let ttl = (currentUser.userTypeName && currentUser.userTypeName == "Admin") ? ADMIN_USER_MAX_TIME : USER_MAX_TIME;
      let options = { timeZone: 'America/New_York' };
      let currentDate = new Date();
      let lastActivityDate = currentUser.lastActivityDate ? new Date(currentUser.lastActivityDate) : currentDate;

      let currentTime = currentDate.getTime();
      let lastActivityTime = lastActivityDate.getTime();
      let expirationTime = lastActivityTime + ttl;

    if (currentTime > expirationTime) {
        let message = 
        "Session Expired - currentDate: " +  currentDate.toLocaleTimeString("en-US", options) + 
        " lastActivityDate: " + lastActivityDate.toLocaleTimeString("en-US", options) + 
        " currentTime: " + currentTime + 
        " lastActivityTime: " + lastActivityTime +
        " expirationTime: " + expirationTime;
  
        ErrorService.logException("AuthService.hasSessionExpired", message, "auth", this);
        return true;
      } else {
        // extend the time. This will reset the 30 minutes of inactivity
        currentUser.lastActivityDate = new Date();
        localStorage.setItem("user", JSON.stringify(currentUser));
      }
    }
    return false;
  }
}

export default new AuthService();
