<template>
  <div class="card shadow chart-type-card" :class="{ 'chart-type-selected': this.currentDisplayType == this.chartType }" @click="setDisplayType">
    <div v-if="isCompetitiveProfileByNeed">
      <i class="fa-duotone fa-arrow-down-wide-short" aria-hidden="true" style="color: #379660; font-size: 23px;"></i>
    </div>
    <div v-if="isCompetitiveProfileByMoat">
      <i class="fa-duotone fa-castle" style="color: #e6af00; font-size: 23px;"></i>
    </div>
    <div v-if="isCompetitiveProfileByValue">
      <i class="fa-duotone fa-scale-unbalanced" style="color: #68afff; font-size:23px"></i>
    </div>
    <div v-if="isCompetitiveProfileByWinners">
      <i class="fa-duotone fa-road" style="color: #2f5eb3; font-size: 23px;"></i>
    </div>
    <div v-if="isMoatRank">
      <i class="fa-duotone fa-fort" style="color: #b48d00; font-size: 23px;"></i>
    </div>
    <div v-if="isWScoresRank">
      <img src="/img/wRatings-2021-W-support.png" class="w-support-card-type" alt="wRatings" />
    </div>
    <div v-if="isEmotionsRank">
      <i class="fa-duotone fa-heart-pulse" style="color: #960000; font-size: 23px;"></i>
    </div>
    <div v-if="isSatisfactionRank">
      <i class="fa-duotone fa-arrow-up-9-1" style="color: #C76829; font-size: 23px;"></i>
    </div>
    <div v-if="isCompetitiveProfileByNps">
      <i class="fa-duotone fa-face-smile" aria-hidden="true"  style="font-size:23px;color: #379660"></i>
    </div>
    <div v-if="isNPSRank">
      <i class="fa-duotone fa-face-viewfinder" aria-hidden="true"  style="font-size:23px;color: #379660"></i>
    </div>
    <div v-if="isMoatTrends">
      <font-awesome-icon :icon="['fas', 'fighter-jet']" style="color: #6c5400" size="2x" />
    </div>
    <div :class="getChartCardClass()">{{ displayName }} <span v-if="isWScoresRank">&#174;</span></div>
  </div>
</template>
<script>
export default {
  name: "ChartTypeCard",
  mixins: [],
  components: {},
  data() {
    return {};
  },
  props: {
    currentDisplayType: {
      type: String,
      default: ""
    },
    chartType: {
      type: String,
      default: ""
    },
    displayName: {
      type: String,
      default: ""
    }
  },
  computed: {
    isCompetitiveProfileByNeed() {
      return this.chartType == "need";
    },
    isCompetitiveProfileByMoat() {
      return this.chartType == "moat";
    },
    isCompetitiveProfileByWinners() {
      return this.chartType == "winners";
    },
    isCompetitiveProfileByNps() {
      return this.chartType == "nps";
    },
    isCompetitiveProfileByValue() {
      return this.chartType == "value";
    },
    isMoatRank() {
      return this.chartType == "moat-rank";
    },
    isWScoresRank() {
      return this.chartType == "w-scores-rank";
    },
    isEmotionsRank() {
      return this.chartType == "emotions-rank";
    },
    isSatisfactionRank() {
      return this.chartType == "satisfaction-rank";
    },
    isNPSRank() {
      return this.chartType == "nps-rank";
    },
    isMoatTrends() {
      return this.chartType == "trends";
    },    
    isResponseRates() {
      return this.chartType == "response-rates";
    },    
  },
  mounted() {},
  created() {},
  methods: {
    setDisplayType() {
      this.$emit("select", this.chartType);
    },
    getChartCardClass() {
      let returnClassName = "chart-card-row chart-card-name ";
      if (this.isResponseRates){
        returnClassName += "response-rates-card";
      }else if (this.isCompetitiveProfileByWinners) {
        returnClassName += "competitive-profile-by-winners-card";
      } else if (this.isCompetitiveProfileByNeed) {
        returnClassName += "competitive-profile-by-need-card";
      } else if (this.isCompetitiveProfileByNPS) {
        returnClassName += "competitive-profile-by-need-card";
      } else if (this.isCompetitiveProfileByMoat) {
        returnClassName += "competitive-profile-by-moat-card";
      } else if (this.isCompetitiveProfileByValue) {
        returnClassName += "competitive-profile-by-value-card";
      } else if (this.isMoatRank) {
        returnClassName += "moat-rank";
      } else if (this.isMoatTrends) {
        returnClassName += "moat-trends-card";
      } else if (this.isWScoresRank) {
        returnClassName += "w-scores-rank-card";
      } else if (this.isEmotionsRank) {
        returnClassName += "emotions-rank-card";
      } else if (this.isSatisfactionRank) {
        returnClassName += "satisfaction-rank-card";
      } else if (this.isNPSRank) {
        returnClassName += "nps-rank-card";
      } else if (this.isCompetitiveProfileByNps) {
        returnClassName += "competitive-profile-by-word-cloud-card";
      }
      return returnClassName;
    }
  },
  watch: {}
};
</script>
