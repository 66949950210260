<template>
  <div class="panel-admin-body dashboard-layout">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use"></modal-link>{{ title }}</div>
      <smart-center-link page="Dashboard Layout" location="Header" />
      <div class="ml-auto">
        <span class="wizard-icon">
          <!--
          <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <company-menu></company-menu>    
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">1</div>
          <div class="wizard-text">
            To adjust the default menu appearance when the wRatings System opens, click to show the Side Menu Titles or Top Navigation Menu.
          </div>
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">2</div>
          <div class="wizard-text">
            Click Save Wave Periods and Card Menus to auto-save your Dashboard selections. If not selected, the system defaults to your current Wave Period and the first drop-down menu option.
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">3</div>  
          <div class="wizard-text">
            To change your Dashboard appearance, Select which Card appears in each Position from the drop-down menus.  The Dashboard shows one or two rows of Cards, and each row shows one, two or three Cards.
          </div>      
        </div>
        <div class="w-100 d-flex pt-3">
          <div class="circle">4</div>  
          <div class="wizard-text pt-2">
            Click Save Options.
          </div>      
        </div>
      </div>
    </div>
    <user-settings />
    <div class="dashboard-layout-border"></div>
    <dashboard-settings  :initial-user-id="initialUserId"/>
    <div class="dashboard-layout-border"></div>
  </div>
</template>
<script>
import ContentService from "../../services/content.service";
import ClientService from "../../services/client.service";
import UserService from "../../services/user.service";

import CompanyMenu from "../../views/menu/CompanyMenu.vue";
import UserSettings from "../../components/UserSettings.vue";
import DashboardSettings from "../../components/DashboardSettings.vue";
import CompanyMixins from "../../mixins/CompanyMixins.vue";
import ContentMixins from "../../mixins/ContentMixins.vue";

export default {
  name: "DashboardLayout",
  mixins: [
    CompanyMixins, ContentMixins
  ],
  components: {
    UserSettings,
    CompanyMenu,
    DashboardSettings
  },
  props: {},
  data() {
    return {
      title: "Dashboard Layout",
      pageNumber: 3,
      settings: [],
      initialUserId:  UserService.getUserId(),
      tooltipConfigShow: ContentService.getWizardTooltip(),     
    };
  },
  methods: {
    loadData: function(){}
  },
  created() {},
  mounted() {
    this.$root.setShowMenuIcon(true);    
  },
  computed: {},
  watch: {},
};
</script>
