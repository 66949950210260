<template>
  <div class="form-inline w-100 mt-2">
    <label for="contentId" class="mr-1">Choose from existing content section</label>
    <select id="contentId" name="contentId" class="custom-select w-25" v-model="contentId" @change="selectContent($event)">
      <option value="">Select</option>
      <option v-for="(option, idxOptions) in this.contentSections" v-bind:value="option.contentId" v-bind:key="idxOptions">
        {{ option.title }}
      </option>
    </select>
    <button type="button" class="btn btn-primary ml-2" id="EditExistingContentButton" @click="displayContentSection('edit')" v-show="showEditContentButton">
      Edit Existing Content
      <span class="ml-auto">
        <font-awesome-icon :icon="['fa', 'edit']" />
      </span>
    </button>
    <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="displayContentSection('add')">
      Add New Content
      <span class="ml-auto">
        <font-awesome-icon :icon="['fa', 'plus-circle']" />
      </span>
    </button>
    <div v-if="editExistingContent" class="w-100">
      <edit-content
        source="survey-designer"
        initial-mode="edit"
        :initial-content-id="contentId"
        :display-summary-field="true"
        :display-page-field="false"
        :display-title-field="true"
        @save="updateContent"
        @close="
          editExistingContent = false;
          $parent.enableButtons();
        "
      ></edit-content>
    </div>
    <div v-show="addNewContent" class="w-100">
      <edit-content
        source="survey-designer"
        :initial-page="surveyName"
        :display-summary-field="true"
        :display-page-field="false"
        :display-title-field="true"
        @save="updateContent"
        @close="
          addNewContent = false;
          $parent.enableButtons();
        "
      ></edit-content>
    </div>
  </div>
</template>
<script>
import EditContent from "../views/admin/EditContent.vue";

export default {
  name: "QuestionContent",
  components: {
    EditContent
  },
  computed: {
    showEditContentButton: function() {
      return typeof this.contentId != "undefined" && this.contentId != 0;
    }
  },
  watch: {},
  data() {
    return {
      addNewContent: false,
      editExistingContent: false,
      contentId: 0
    };
  },
  props: {
    initialContentId: {
      type: Number,
      default: 0
    },
    surveyName: {
      type: String,
      default: ""
    },
    contentSections: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    displayContentSection: function(mode) {
      this.$parent.disableButtons();
      if (mode == "edit") {
        this.editExistingContent = true;
        this.addNewContent = false;
      } else {
        this.editExistingContent = false;
        this.addNewContent = true;
      }
    },
    getContentName: function() {
      let name = "";
      let contentSection = this.contentSections.find(x => Number(x.contentId) === Number(this.contentId));
      if (typeof contentSection != "undefined") {
        name = contentSection.title;
      }
      return name;
    },
    selectContent: function(event) {
      let content = {
        contentId: this.contentId,
        name: this.getContentName()
      };
      this.updateContent(content);
    },
    updateContent: function(result) {
      this.$emit("reload");
      this.contentId = result.contentId;

      this.$emit("save", result);

      this.addNewContent = false;
      this.editExistingContent = false;
      this.$parent.enableButtons();
    }
  },
  mounted() {
    this.contentId = this.initialContentId;
  },
  umnounted() {}
};
</script>
