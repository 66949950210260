<template>
  <div class="forgot-password">
    <div class="auth-header">
        <img id="profile-img" src="../assets/img/main-wratings-logo.png" class="profile-img-card" />
    </div>
    <div class="auth-content">
      <forgot-password />
    </div>
  </div>
</template>
<script>
import ForgotPassword from "../components/ForgotPassword.vue";

export default {
  name: "ForgotPasswordWrapper",
  mixins: [],
  components: {
    ForgotPassword
  },  
  data() {
    return {
      showModal: false
    };
  },
  methods: {},
  umnounted() {},
  computed: {},
  mounted() {},
  watch: {},
  props: {}
};
</script>
