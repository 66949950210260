<template>
    <div class="container chart-wrapper">
        <vue-skeleton-loader
            type="rec"
            :width="1118"
            :height="1140"
            animation="fade"
        />
    </div>
</template>
<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: "ChartDisplaySkeleton",
  mixins: [],
  components: {VueSkeletonLoader},
  data() {
    return {};
  },    
  props: {},
  methods: {},
  computed: {}, 
  created() {},
  mounted() {},
};

</script>