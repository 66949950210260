<template>
  <div>
    <div v-if="displayDropdown" class="form-group form-inline no-gutters">
      <div class="col-6 pr-3">
        <span class="float-right">
          <modal-link modal-id="Fill using Industry Fields"  :content-list="$parent.pageContent"></modal-link>
          <span class="section-label">Fill using Industry Fields</span>
        </span>
      </div>
      <div class="col-6">
        <select id="industry" name="industry" class="custom-select" @change="populateQuestions($event)">
          <option value="">Select</option>
          <option v-for="(option, idxOptions) in this.industryFieldOptions" v-bind:value="option.value" v-bind:key="idxOptions">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="question" v-for="(question, idxQuestion) in this.questions" v-bind:key="idxQuestion">
      <question
        v-if="displayQuestions"
        :ref="idxQuestion"
        :question-index="idxQuestion"
        :question-object="question"
        :is-question-in-emotional-section="sectionTypeName == 'Emotional Needs'"
        :next-sequence-number="questions.length"
        :section-object="sectionObject"
        @save="setSurveyToBuildRequired"
      ></question>
    </div>
    <div v-if="sectionTypeName == 'Functional Needs'" class="pt-5">
      <div class="form-row form-group form-inline">
        <modal-link modal-id="Enable Description Pop-up"  :content-list="$parent.pageContent"></modal-link>
        <survey-field
          :survey-id="this.$parent.survey.surveyId"
          :survey-field-object="this.$parent.getSurveyField('enable-description-popup')"
          field-type="checkbox"
          field-class="form-group form-inline"
          field-label="Enable Description Pop-up"
          :build-survey="false"
          default-field-name="enable-description-popup"
          default-field-value="N"
          @save="this.$parent.saveSurveyField"
        ></survey-field>
      </div>
      <div v-show="enableDescriptionPopup == true" class="question-description-container">
        <div class="form-group form-inline no-gutters">
          <div class="question-description-header col-4">Need</div>
          <div class="question-description-header col-8">Description</div>
        </div>
        <div class="question-description" v-for="(question, idxQuestion) in this.questions" v-bind:key="idxQuestion">
          <question-description
              v-if="displayQuestions"
              :question-object="question"
              @save="setSurveyToBuildRequired"
          ></question-description>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Question from "../components/Question.vue";
import QuestionDescription from "../components/QuestionDescription.vue";
import SurveyField from "../components/SurveyField.vue";
import SectionMixins from "../mixins/SectionMixins.vue";
import ContentMixins from "../mixins/ContentMixins.vue";

import jQuery from "jquery";
import axios from "axios";
import authHeader from "../services/auth-header";
import NeedsService from "../services/needs.service";
export default {
  name: "NeedsSection",
  mixins: [SectionMixins, ContentMixins],
  components: {
    Question, QuestionDescription, SurveyField
  },
  data() {
    return {
      displayQuestions: false,
      driverOptions: [
        { value: 1, name: "Trust" },
        { value: 2, name: "Strictness" },
        { value: 3, name: "Activity" },
        { value: 4, name: "Curiosity" },
        { value: 5, name: "Assurance" }
      ],
      industryFieldOptions: [
        { value: 0, name: "Automotive" },
        { value: 13, name: "Concepts" },
        { value: 1, name: "Consumer Goods" },
        { value: 2, name: "Electronics" },
        { value: 3, name: "Energy" },
        { value: 4, name: "Finance" },
        { value: 12, name: "Generic" },
        { value: 5, name: "Health Care" },
        { value: 6, name: "Industrials & Materials" },
        { value: 7, name: "Media" },
        { value: 14, name: "Restaurants" },
        { value: 8, name: "Retail" },
        { value: 9, name: "Technology" },
        { value: 10, name: "Telecom" },
        { value: 11, name: "Travel & Transport" }
      ]
    };
  },
  props: {
    surveyId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    enableDescriptionPopup: function() {
      if (typeof this.$parent.getSurveyField("enable-description-popup") == "undefined") {
        return false;
      }
      return this.$parent.getSurveyField("enable-description-popup").surveyFieldValue == "Y";
    },
    displayDropdown: function() {
      return this.sectionName == "Actual Functional Needs";
    }
  },
  methods: {
    setSurveyToBuildRequired() {
      this.$parent.saveBuildRequired("Y");
    },
    async populateQuestions(event) {
      const params = new URLSearchParams();
      params.append("sectionCode", event.target.value);
      let functionalNames;

      await axios
        .post(this.baseUrl + "/api/v1/survey-design/get-functional-needs-default", params, { headers: await authHeader() })
        .then(response => (functionalNames = response.data))
        //.catch(error => this.$log.info(error));
        .catch(error => {
              // eslint-disable-next-line no-console
              console.log(error) 
        });
      for (var index = 0; index < functionalNames.length; index++) {
        let question = functionalNames[index];
        if (!jQuery.isEmptyObject(question)) {
          this.$refs[index][0].questionLabel = question;
          this.$refs[index][0].saveQuestionLabel(question);
        }
      }
    }
  },
  created() {},
  mounted() {
    if (this.sectionObject.sectionType.name == "Functional Needs"){
      let vm = this;
      this.questions.sort(function(a, b) {
        let positionA = a.designViewSequenceNumber;
        let positionB = b.designViewSequenceNumber;
        return positionA - positionB;
      });
    }
    this.displayQuestions = true;
  }
};
</script>
