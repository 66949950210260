import $ from "jquery";
import axios from "axios";
import authHeader from "../auth-header";
import ErrorService from "../error.service";
import AdminService from "../admin.service";
import UserService from "../user.service";
import TableService from "../table.service";
import NeedsService from "../needs.service";
import ChartService from "../chart.service";

const BASE_URL = AdminService.getBaseUrl();
const API_URL = BASE_URL + "/api/v1/chart";

class Winners {
  async saveWinnersMap(surveyCode, conserveEfforts, coreEfforts, divergeEfforts, callback, panelApp) {
    const params = new URLSearchParams();
    params.append("surveyCode", surveyCode);
    params.append("conserveEfforts", JSON.stringify(conserveEfforts));
    params.append("coreEfforts", JSON.stringify(coreEfforts));
    params.append("divergeEfforts", JSON.stringify(divergeEfforts));
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/save-winners-map", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("Winners.saveWinnersMap", error, "survey-designer", panelApp);
      });
  }
  async getWinnersMapBySurveyCode(surveyCode, clientChartId, callback, panelApp) {
    const params = new URLSearchParams();
    params.append("surveyCode", surveyCode);
    params.append("clientChartId", clientChartId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/get-winners-map-by-survey-code", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("Winners.getWinnersMapBySurveyCode", error, "survey-designer", panelApp);
      });
  }
  async processDefaultWinnerMaps(callback, panelApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/process-default-winner-maps", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        if (error.response.status == 504) {
          let timeoutError = "This process takes more than 5 minutes. Please check your email. We will send a response once the process completes";
          AdminService.displayInfoMessage({ text: timeoutError, duration: -1 });
        }  else {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("Winners.processDefaultWinnerMaps", error, "admin", this);
        } 
        });


  }
  getTableColumnAttributes() {
    return {
      columnDefs: [
        TableService.getOrderSequence(),
        {
          targets: [0],
          className: "dt-left",
          width: 120,
          render: function(data, type, row, meta) {
            var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
            if (type === "display") {
              let originalData = data;
              let headerForTableStakes = "Table Stakes - Header";
              let headerForCore = "Core - Header";
              let headerForDiverge = "Diverge - Header";
              let firstInstanceOfTableStakes = true;
              let firstInstanceOfCore = true;
              let firstInstanceOfDiverge = true;
              if (originalData != "--" && headerForTableStakes == data && firstInstanceOfTableStakes) {
                data = "Table Stakes";
                data = '<span class="popUp" data-modal-name="' + data + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
                firstInstanceOfTableStakes = false;
              } else if (originalData != "--" && headerForCore == data && firstInstanceOfCore) {
                data = "Core";
                data = '<span class="popUp" data-modal-name="' + data + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
                firstInstanceOfCore = false;
              } else if (originalData != "--" && headerForDiverge == data && firstInstanceOfDiverge) {
                data = "Diverge";
                data = '<span class="popUp" data-modal-name="' + data + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
                firstInstanceOfDiverge = false;
              }
              return data;
            }
            return data;
          }          
        },
        {
          targets: [1],
          className: "dt-left",
          width: 190,
          render: function(data, type, row, meta) {
            var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
            let originalData = data;
            data = data.length > 40 ? data.substr(0, 40) + "…" : data;
            let displayData = data;
            let includeToolTip = data.length > 40;

            if (type === "display") {
              let isEmotional = data.includes(",");
              let needType = isEmotional ? "emotional" : "functional";
              let fieldNumber = NeedsService.getNeedNumber(data, needType);
              if (fieldNumber == 0) {
                fieldNumber = meta.row + 1;
              } else if (fieldNumber > 12) {
                return data;
              }
              let needName = isEmotional ? "Emotional Need " : "Functional Need ";
              needName += fieldNumber;
              data = '<span class="popUp"  data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
              if (includeToolTip) {
                data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
              } else {
                data += displayData;
              }
            }
            return data;
          }
        },
        TableService.getNumericTableAttribute([2], "dt-right", 50),
        TableService.getNumericTableAttribute([3], "dt-right", 50),
        TableService.getNumericTableAttribute([4], "dt-right ", 50),
        TableService.getPercentTableAttribute([-2], "dt-right", 50, 2),
        TableService.getNumericTableAttribute(["_all"], "dt-right", 50)
      ]
    };
  }
}
export default new Winners();
