import $, { data, type } from "jquery";
import CommonService from "./common.service";
import ScatterService from "./scatter.service";

class PredictiveAnalyticsService extends CommonService{
    /***  Series Functions ***/
    getSeries(name,gridIndex,xAxisIndex,yAxisIndex,config = {},isEmotionalNeeds = false){
        let baseSeries = {
            name: name,
            type: 'line',
            gridIndex: gridIndex,
            xAxisIndex: xAxisIndex,
            yAxisIndex: yAxisIndex,
            data: []
        };    
        $.extend(true, baseSeries, config);
        return baseSeries;
    }    
    /***  Parse Functions ****/
    parseLegendAndColorData(inputData, outputData, moveFirstColorToEnd = true){
        outputData.colors = [];
        outputData.colors.push('#808080');
        outputData.colors.push('#006600');
        outputData.legend = {
            normal: [],
        };        
        if (typeof inputData !== 'undefined' && inputData !== null) {      
            if (typeof inputData.data !== 'undefined' && inputData.data !== null) {
                if (typeof inputData.data.metricDisplayTypeNameForSecondary !== 'undefined' && inputData.data.metricDisplayTypeNameForSecondary !== null) {
                    outputData.metricDisplayTypeNameForSecondary = inputData.data.metricDisplayTypeNameForSecondary;
                }                
                if (typeof inputData.data.metricDisplayTypeName !== 'undefined' && inputData.data.metricDisplayTypeName !== null) {
                    outputData.metricDisplayTypeName = inputData.data.metricDisplayTypeName;
                }                
                if (typeof inputData.data.axis1Name !== 'undefined' && inputData.data.axis1Name !== null) {
                    outputData.legend.normal.push({name: inputData.data.axis1Name});
                }                
                if (typeof inputData.data.axis2Name !== 'undefined' && inputData.data.axis2Name !== null) {
                    outputData.legend.normal.push({name: inputData.data.axis2Name});
                }                
                if (typeof inputData.data.metricDisplayTypeName !== 'undefined' && inputData.data.metricDisplayTypeName !== null) {
                    outputData.metricDisplayTypeName = inputData.data.metricDisplayTypeName;
                }                

                if (typeof inputData.data.useMetricGrowth !== 'undefined' && inputData.data.useMetricGrowth !== null) {
                    outputData.useMetricGrowth = inputData.data.useMetricGrowth;
                }      
                if (typeof inputData.data.useMetricGrowthForSecondary !== 'undefined' && inputData.data.useMetricGrowthForSecondary !== null) {
                    outputData.useMetricGrowthForSecondary = inputData.data.useMetricGrowthForSecondary;
                }      
                if (typeof inputData.data.useSecondaryMetric !== 'undefined' && inputData.data.useSecondaryMetric !== null) {
                    outputData.useSecondaryMetric = inputData.data.useSecondaryMetric;
                }          
                if (typeof inputData.data.chartTitle !== 'undefined' && inputData.data.chartTitle !== null) {
                    outputData.chartTitle = inputData.data.chartTitle;
                }          
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    if (typeof inputData.data.metricsData.correlationData !== 'undefined' && inputData.data.metricsData.correlationData !== null && inputData.data.metricsData.correlationData.length > 0) {
                        outputData.correlation = inputData.data.metricsData.correlationData[0];
                    }                    
                }
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    if (typeof inputData.data.metricsData.correlationClass !== 'undefined' && inputData.data.metricsData.correlationClass !== null && inputData.data.metricsData.correlationClass.length > 0) {
                        outputData.correlationClass = inputData.data.metricsData.correlationClass[0];
                    }                    
                }
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    if (typeof inputData.data.metricsData.correlationMessage !== 'undefined' && inputData.data.metricsData.correlationMessage !== null && inputData.data.metricsData.correlationMessage.length > 0) {
                        outputData.correlationMessage = inputData.data.metricsData.correlationMessage[0];
                    }                    
                }
            }
        }
        return outputData;
    }

    parseData(chartNumber, inputData, outputData, itemsToRemove,useMetricGrowth = false,useSecondaryMetric = false){
        if (chartNumber == 1) {
          outputData.chart1 = {
            data: [],
            containerId: "predictive-analytics"
          };
        }      
        if (typeof inputData !== 'undefined' && inputData !== null) {      
            if (typeof inputData.data !== 'undefined' && inputData.data !== null) {
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    let rowData = [];
                    let primaryRows = [];
                    let secondaryRows = [];
                    if (useMetricGrowth && typeof inputData.data.metricsData.metricGrowthRow !== 'undefined' && inputData.data.metricsData.metricGrowthRow !== null) {      
                        primaryRows = inputData.data.metricsData.metricGrowthRow;
                    }                    
                    if (!useMetricGrowth && typeof inputData.data.metricsData.metricRow !== 'undefined' && inputData.data.metricsData.metricRow !== null) {      
                        primaryRows = inputData.data.metricsData.metricRow;
                    }                        
                    if (useSecondaryMetric && typeof inputData.data.metricsData.secondaryMetricGrowthRow !== 'undefined' && inputData.data.metricsData.secondaryMetricGrowthRow !== null) {      
                        secondaryRows = inputData.data.metricsData.secondaryMetricGrowthRow;
                    }                    
                    if (!useSecondaryMetric && typeof inputData.data.metricsData.secondaryMetricRow !== 'undefined' && inputData.data.metricsData.secondaryMetricRow !== null) {      
                        secondaryRows = inputData.data.metricsData.secondaryMetricRow;
                    }                        

                    let removeFirstRowCount = 0;
                    while (itemsToRemove.firstRows > removeFirstRowCount) {
                        let indexToRemove = 0;
                        primaryRows.splice(indexToRemove, 1);
                        secondaryRows.splice(indexToRemove, 1);
                        removeFirstRowCount++;
                    }

                    let removeRowCount = 0;
                    while (itemsToRemove.lastRows > removeRowCount) {
                        let indexToRemove = primaryRows.length - 1;
                        primaryRows.splice(indexToRemove, 1);
                        secondaryRows.splice(indexToRemove, 1);
                        removeRowCount++;            
                    }
                    let primaryRowData = {
                        name: "primary",
                        data: []
                    };
                    let first = []; 
                    for (let i = 0; i < primaryRows.length; i++) {
                        let primaryRow = primaryRows[i];
                        if (typeof primaryRow.chartData !== 'undefined' && primaryRow.chartData !== null) {
                            first.push(primaryRow.chartData);
                        }
                    }
                    primaryRowData.data = this.removeColumns(first, itemsToRemove);
                    rowData.push(primaryRowData);
                    let secondaryRowData = {
                        name: "secondary",
                        data: []
                    };          
                    let nd = [];          
                    for (let i = 0; i < secondaryRows.length; i++) {
                        let secondaryRow = secondaryRows[i];
                        if (typeof secondaryRow.chartData !== 'undefined' && secondaryRow.chartData !== null) {
                            nd.push(secondaryRow.chartData);
                        }
                    }
                    secondaryRowData.data = this.removeColumns(nd, itemsToRemove);
                    rowData.push(secondaryRowData);
                    outputData.chart1.data.push(rowData);
                }
            }
        }
        return outputData;
    }
    getData(metricProperties,  wave, useMetricGrowth, metricDisplayTypeName){
        let value = useMetricGrowth == true ? metricProperties.metricGrowth : metricProperties.metric;
        if (value == 0){
            value = '--'
        } else if (metricDisplayTypeName == "Percentage" || metricDisplayTypeName == "Percent") {
            value = parseFloat(value).toFixed(1);
        } else if (metricDisplayTypeName == "Currency") {
            value = parseFloat(value).toFixed(2);
        } else if (metricDisplayTypeName == "Currency (tenths)") {
            value = parseFloat(value).toFixed(1);
        } else if (metricDisplayTypeName == "Currency (hundredths)") {
            value = parseFloat(value).toFixed(0);
        }
        let propertyData = {name: wave, value: value, displayType: metricDisplayTypeName};
        return propertyData;
    }
    setDataLabels(yAxis,useMetricGrowth,metricDisplayTypeName){
        /*let label = {
            formatter: "{value}"
        }*/
        if (useMetricGrowth == true) {
            $.extend(true, yAxis.axisLabel,{formatter: "{value} %"});            
        } else if (metricDisplayTypeName.includes("Currency")) {
            $.extend(true, yAxis.axisLabel,{formatter: "$ {value}"});
        } else if (metricDisplayTypeName.includes("Percentage") || metricDisplayTypeName.includes("Percent")) {
            $.extend(true, yAxis.axisLabel,{formatter: "{value} %"});
        } else {
            $.extend(true, yAxis.axisLabel,{formatter: "{value}"});
        }        
      //  $.extend(true, yAxis,{axisLabel: label});
        return yAxis;

    }
    /***  Series Functions ***/
    getSeriesForPredictiveAnalyticsChart(name,gridIndex,xAxisIndex,yAxisIndex,useMetricGrowth = false,metricDisplayTypeName = "Currency",symbol = "circle"){

        let baseSeriesConfig = {label: {show: true,color: '#000'},symbolSize: 9};
        if (useMetricGrowth == true) {
            $.extend(true, baseSeriesConfig.label,{formatter: "{c} %"});
        } else if (metricDisplayTypeName.includes("Currency")) {
            $.extend(true, baseSeriesConfig.label,{formatter: "$ {c}"});
        } else if (metricDisplayTypeName.includes("Percentage") || metricDisplayTypeName.includes("Percent")) {
            $.extend(true, baseSeriesConfig.label,{formatter: "{c} %"});
        } else {
            $.extend(true, baseSeriesConfig.label,{formatter: "{c}"});
        }        
        $.extend(true, baseSeriesConfig, this.getCompanyConfig(symbol));
        return this.getSeries(name,gridIndex,xAxisIndex,yAxisIndex,baseSeriesConfig)
    }
    /*** yAxis Functions ***/

    /*Tooltip Functions */
    toolTipForPredictiveAnalyticsChart = (params) => {
        let returnValue = "";
        for (let i = 0; i < params.length; ++i) {
            let valueToDisplay = params[i].value;
            let seriesName = params[i].name;        
            let data = params[i].data;
            returnValue += params[i].marker += " ";        
            returnValue += seriesName += " : ";
            returnValue += "<span style='text-align:right'><b>" 
            if (!isNaN(valueToDisplay)){
                let displayType = typeof data.displayType !== 'undefined' || data.displayType != null ? data.displayType : "Currency";
                if (displayType.includes("Currency") ) {
                    returnValue +=  "$" + parseFloat(valueToDisplay).toLocaleString();
                } else if (displayType == "Percentage" || displayType == "Percent") {
                    returnValue += parseFloat(valueToDisplay).toFixed(1) + "%";
                } else {
                    returnValue += valueToDisplay;
                }
            } else {
                returnValue += "--";
            }
            returnValue += "</b></span>";
            returnValue += "<br>";
        }
        return returnValue;
    }      
}    

export default new PredictiveAnalyticsService();             

