import axios from "axios";
import $ from "jquery";

import authHeader from "./auth-header";
import ClientService from "./client.service";
import TableService from "./table.service";
import UserService from "./user.service";
const API_URL = "/api/v1/chart";

class ChartService {
  CHART_TYPES = [
    { name: "Profile", shortName: "competitive-profile", chartTypeId: 1, type: "analytics" },              
    { name: "Trends", shortName: "competitive-trends", chartTypeId: 2, type: "analytics" },
    { name: "Ranks", shortName: "ranks", chartTypeId: 3, type: "analytics" },
    { name: "Match", shortName: "profile-match", chartTypeId: 5, type: "analytics" },
    { name: "Predictive", shortName: "predictive-analytics", chartTypeId: 6, type: "analytics" },
    { name: "Dashboard Respondent Map", shortName: "respondent-map", chartTypeId: 9, type: "dashboard" },
    { name: "Dashboard NPS Trends", shortName: "nps-trends", chartTypeId: 10, type: "dashboard" },
    { name: "Dashboard Performance Trends", shortName: "performance-trends", chartTypeId: 11, type: "dashboard" },
    { name: "Dashboard Needs Profile", shortName: "needs-profile", chartTypeId: 12, type: "dashboard" },
    { name: "Dashboard Winners Map", shortName: "winners-map", chartTypeId: 13, type: "dashboard" },
    { name: "Dashboard Growth Sequence", shortName: "growth-sequence", chartTypeId: 14, type: "dashboard" },
    { name: "Dashboard Competitive Rank", shortName: "competitive-rank", chartTypeId: 15, type: "dashboard" },
    { name: "Dashboard Competitive Trends", shortName: "dashboard-competitive-trends", chartTypeId: 16, type: "dashboard" },
    { name: "Dashboard Contribution", shortName: "dashboard-predictive-analytics", chartTypeId: 17, type: "dashboard" },
    { name: "Dashboard Metric Contributions", shortName: "dashboard-metric-contributions", chartTypeId: 18, type: "dashboard" },

    { name: "NPS", shortName: "nps", chartTypeId: 18, type: "analytics" },
    { name: "Dashboard - NPS Rank", shortName: "dashboard-nps-rank", chartTypeId: 20, type: "dashboard" },
    { name: "Dashboard - W-Score Rank", shortName: "dashboard-w-score-rank", chartTypeId: 21, type: "dashboard" },
    { name: "Dashboard - Moat Score Rank", shortName: "dashboard-moat-score-rank", chartTypeId: 22, type: "dashboard" },            
    { name: "Dashboard - Moat Comps", shortName: "dashboard-moat-comps", chartTypeId: 23, type: "dashboard" },
    { name: "Response Rates", shortName: "response-rates", chartTypeId: 24, type: "analytics" },
    { name: "Impact", shortName: "impact", chartTypeId: 25, type: "analytics" },
  ];
  breakPointList = [];
  setVerticalLineBreakPoints(breakpoints) {
    this.breakPointList = breakpoints;
  }
  getVerticalLineBreakPoints() {
    return this.breakPointList;
  }
  getDashboardChartTypes(){
    return this.CHART_TYPES.filter(x => x.type == "dashboard" );
  }
  findChartTypeByName(chartTypeName) {
    return this.CHART_TYPES.find(x => x.shortName == chartTypeName);
  }
  findChartTypeById(chartTypeId) {
    return this.CHART_TYPES.find(x => x.chartTypeId == chartTypeId);
  }
  createDefaultSaveChartName(chartType, displayType) {
    if (chartType == "Profile" && (displayType == "need" || displayType == "")) {
      return "CP Needs";
    } else if (chartType == "Profile" && displayType == "moat") {
      return "CP Moats";
    } else if (chartType == "Profile" && displayType == "value") {
      return "CP Value";
    } else if (chartType == "Profile" && displayType == "winners") {
      return "CP Winners";
    } else if (chartType == "Profile" && displayType == "nps") {
      return "NPS";
    } else if (chartType == "Moat Profile" && displayType == "trends") {
      return "Moat Trends";
    } else if (chartType == "Match") {
      return "Match";
    } else if (chartType == "Predictive") {
      return "Predictive";
    } else if (chartType == "Ranks" && displayType == "w-scores-rank") {
      return "W Score Rank";
    } else if (chartType == "Ranks" && displayType == "moat-rank") {
      return "Moat Rank";
    } else if (chartType == "Ranks" && displayType == "nps-rank") {
      return "NPS Ranks";
    } else if (chartType == "Ranks" && displayType == "satisfaction-rank") {
      return "Satisfaction Rank";
    } else if (chartType == "Ranks" && displayType == "emotions-rank") {
      return "Emotions Rank";
    } else if (chartType == "Respondent Map") {
      return "Respondent Map";
    }
    return chartType;
  }
  isCompetitiveProfileByNeed(shortName, displayType) {
    return shortName == "competitive-profile" && (displayType == "need" || displayType == "");
  }
  isCompetitiveProfileByValue(shortName, displayType) {
    return shortName == "competitive-profile" && displayType == "value";
  }
  isCompetitiveProfileByWinners(shortName, displayType) {
    return shortName == "competitive-profile" && displayType == "winners";
  }
  isCompetitiveProfileByMoat(shortName, displayType) {
    return shortName == "competitive-profile" && displayType == "moat";
  }
  isCompetitiveProfileByNPS(shortName, displayType) {
    return shortName == "competitive-profile" && displayType == "nps";
  }
  isResponseRates(shortName) {
    return shortName == "response-rates";
  }
  isCompetitiveTrends(shortName) {
    return shortName == "competitive-trends";
  }
  isProfileMatch(shortName) {
    return shortName == "profile-match";
  }
  isPredictive(shortName) {
    return shortName == "predictive-analytics";
  }
  isMoatRank(shortName, displayType) {
    return shortName == "ranks" && displayType == "moat-rank";
  }
  isMoatTrends(shortName, displayType) {
    return shortName == "moat-profile" && displayType == "trends";
  }
  isWScoresRank(shortName, displayType) {
    return shortName == "ranks" && displayType == "w-scores-rank";
  }
  isSatisfactionRank(shortName, displayType) {
    return shortName == "ranks" && displayType == "satisfaction-rank";
  }
  isEmotionsRank(shortName, displayType) {
    return shortName == "ranks" && displayType == "emotions-rank";
  }
  isNPSRank(shortName, displayType) {
    return shortName == "ranks" && displayType == "nps-rank";
  }
  isNPS(shortName, displayType) {
    return shortName == "competitive-profile" && displayType == "nps";
  }
  getSegments(chartCriteriaList) {
    return chartCriteriaList.filter(
      x =>
        x.name != "Include Partial Responses" &&
        x.name != "Text Direction" &&
        x.name != "Show Public Groups" &&
        x.name != "Calculate Surveys Equal-Weighted" &&
        x.name != "Custom Time Period" &&
        x.name != "Custom End Date" &&
        x.name != "Custom Start Date" &&
        x.name != "Period" &&
        x.name != "Display Type" &&
        x.name != "Time Period" &&
        x.name != "Survey" &&
        x.name != "Metric" &&
        x.name != "Start Period" &&
        x.name != "End Period" &&
        x.name != "# of Ranking Tiers" &&
        x.name != "Show Needs" &&
        x.name != "Show Metrics" &&
        x.name != "Use Rank Calculations" &&
        x.name != "Survey Group" &&
        x.name != "Show Competitor Expectations" &&
        x.name != "Show Pricing Power" &&
        x.name != "Match Type"
    );
  }
  async getAllSectors(baseUrl, callback) {
    if (localStorage.getItem("get-all-sectors") != null) {
      callback(JSON.parse(localStorage.getItem("get-all-sectors")));
    } else {
      await axios
        .get(baseUrl + API_URL + "/get-all-sectors", { headers: await authHeader() })
        .then(response => {
          localStorage.setItem("get-all-sectors", JSON.stringify(response.data));
          callback(response.data);
        })
        .catch(error => {
          throw error;
        });
    }
  }
  async getTopCompanies(baseUrl, callback) {
      let clientCode =  ClientService.getClientCode();
      const params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      await axios
        .post(baseUrl + API_URL + "/get-top-companies", params, { headers: await authHeader() })
        .then(response => {
          localStorage.setItem("get-top-companies", JSON.stringify(response.data));
          callback(response.data);
        })
        .catch(error => {
          throw error;
        });
  }
  async getCountsBySurveyLocations(baseUrl, surveyId, period, callback) {
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("clientCode", ClientService.getClientCode());
    params.append("userId", UserService.getUserId());
    params.append("period", period);
    await axios
      .post(baseUrl + API_URL + "/get-counts-by-survey-locations", params, { headers: await authHeader() })
      .then(response => {
        callback(response.data);
      })
      .catch(error => {
        throw error;
      });
  }
  async getSurveyLocations(baseUrl, surveyId, period, includeCounts, callback) {
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("userId", UserService.getUserId());
    params.append("period", period);
    params.append("includeCounts", includeCounts);
    params.append("clientCode", ClientService.getClientCode());

    await axios
      .post(baseUrl + API_URL + "/get-survey-locations", params, { headers: await authHeader() })
      .then(response => {
        callback(response.data);
      })
      .catch(error => {
        throw error;
      });
  }
  async getYearOptions(baseUrl, callback) {
    if (localStorage.getItem("get-year-options") != null) {
      callback(JSON.parse(localStorage.getItem("get-year-options")));
    } else {
      const params = new URLSearchParams();
      await axios
        .post(baseUrl + API_URL + "/get-year-options", params, { headers: await authHeader() })
        .then(response => {
          localStorage.setItem("get-year-options", JSON.stringify(response.data));
          callback(response.data);
        })
        .catch(error => {
          throw error;
        });
    }
  }
  getDefaultFunctionalDrivers(){
    return [
      { name: "Top Quartile", },
      { name: "Second Quartile", },
      { name: "Third Quartile", },
      { name: "Bottom Quartile", },
    ];
  }
  getDefaultEmotionalDrivers(){
    return [
      { name: "<div>Assurance</div><div>Level</div>", },
      { name: "<div>Curiosity</div><div>Level</div>", },
      { name: "<div>Strictness</div><div>Level</div>", },
      { name: "<div>Trust</div> <div>Level</div>", },
      { name: "<div>Activity</div><div>Level</div>", },
    ];
  }  
  scrollToTop() {
    $("html, body").animate(
      {
        scrollTop: $(".chart-app").offset().top
      },
      500
    );
  }
  getMobileBreakpoint() {
    return 500;
  }
  getChartAttributes(source) {
    let chartAttributes = {};
    if (source == "Profile By Value") {
      chartAttributes = {
        title: "Profile By Value",
        dataSourceUrl: "/api/v1/chart/performance",
        typeName: "competitive-profile",
        maximumCompetitors: 5
      };
    } else if (source == "Profile By Moat" || source == "Profile By Winners" || source == "Profile By Word Cloud") {
      chartAttributes = {
        title: source,
        dataSourceUrl: "/api/v1/chart/performance",
        typeName: "competitive-profile",
        maximumCompetitors: 5
      };
    } else if (source == "Profile") {
      chartAttributes = {
        title: "Profile",
        dataSourceUrl: "/api/v1/chart/performance",
        typeName: "competitive-profile",
        maximumCompetitors: 5
      };
    } else if (source == "Trends") {
      chartAttributes = {
        title: "Trends",
        typeName: "competitive-trends",
        maximumCompetitors: 5,
        dataSourceUrl: "/api/v1/chart/competitive-trends"
      };
    } else if (source == "Ranks") {
      chartAttributes = {
        typeName: "ranks",
        maximumCompetitors: 5,
        title: "Ranks",
        dataSourceUrl: "/api/v1/chart/ranks"
      };
    } else if (source == "Moat Trends") {
      chartAttributes = {
        typeName: "moat-profile",
        maximumCompetitors: 5,
        title: "Moat Profile",
        dataSourceUrl: "/api/v1/chart/moats"
      };
    } else if (source == "Match") {
      chartAttributes = {
        title: "Match",
        typeName: "profile-match",
        maximumCompetitors: 5,
        dataSourceUrl: "/api/v1/chart/profile-match"
      };
    } else if (source == "Match By Value") {
      chartAttributes = {
        title: "Match",
        typeName: "profile-match",
        maximumCompetitors: 5,
        dataSourceUrl: "/api/v1/chart/profile-match"
      };
    } else if (source == "Match By Moat" || source == "Match By Winners") {
      chartAttributes = {
        title: "Match",
        typeName: "profile-match",
        maximumCompetitors: 5,
        dataSourceUrl: "/api/v1/chart/profile-match"
      };
    }  else if (source == "Net Promoter Score") {
      chartAttributes = {
        title: "Net Promoter Score",
        typeName: "nps",
        maximumCompetitors: 5,
        dataSourceUrl: "/api/v1/chart/net-promoter-score"
      };
    }
    $.extend(chartAttributes, TableService.getTableColumnAttributes(source));
    return chartAttributes;
  }
}

export default new ChartService();
