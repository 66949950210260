<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container advisor-list">
      <div class="filter-container border-bottom pt-3 pb-3">      
        <div class="row">
          <div class="form-group form-inline ml-2 col">
            <label for="response_message_user" class="mr-1">User</label>
            <select id="response_message_user" name="response_message_user" class="custom-select required" v-model="response_message_user_id">
              <option
                v-for="(response_message_user_option, idxresponse_message_userOptions) in this.response_message_user_options"
                v-bind:value="response_message_user_option.value"
                v-bind:key="idxresponse_message_userOptions"
              >
                {{ response_message_user_option.name }}
              </option>
            </select>
          </div>
          <div class="form-group form-inline ml-2 col">
            <label for="application_error_client" class="mr-1">Client</label>
            <select id="application_error_client" name="application_error_client" class="custom-select required" v-model="application_error_client_id">
              <option
                v-for="(application_error_client_option, idxapplication_error_clientOptions) in this.application_error_client_options"
                v-bind:value="application_error_client_option.value"
                v-bind:key="idxapplication_error_clientOptions"
              >
                {{ application_error_client_option.name }}
              </option>
            </select>
          </div>
          <div class="form-group form-inline ml-2 col">
            <label for="status" class="mr-1">Status</label>
            <select id="status" name="status" class="custom-select required" v-model="status_selected">
              <option v-for="(status_option, idxStatusOptions) in this.status_options" v-bind:value="status_option.value" v-bind:key="idxStatusOptions">
                {{ status_option.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row pt-1">        
          <div class="form-group form-inline col">
            <div class="pr-2">
              <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="loadData()">Filter</button>
            </div>
            <div class="pr-2">
              <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="reset()">Reset</button>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container pt-3">      
        <div class="button-left">
          <div class="pr-2">
            <button type="button" class="btn btn-primary" :disabled="disableButton" @click="$modal.show('preview-advisor-email')">
              Preview Advisor Email
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'eye']" />
              </span>
            </button>
          </div>
          <div v-show="viewSendTestEmail" class="pr-2">
            <button type="button" class="btn btn-primary" :disabled="disableButton" @click="$modal.show('send-test-email')">
              Send Test Email
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'envelope']" />
              </span>
            </button>
          </div>
          <div class="pr-2">
            <router-link :to="{ name: 'edit-advisor', params: { initialMode: 'admin-edit', initialClientCode: this.getClientCode(), initialClientName: this.getClientName(),  initialAdvisorConfigurationId: this.getAdvisorConfigurationId()} }">
              <button type="button" class="btn btn-primary" id="EditAdvisorButton" :disabled="!enableEditButton">
                Edit Advisor
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">
            <button type="button" class="btn btn-primary" id="DuplicateAdvisorButton" :disabled="!enableEditButton" @click="$modal.show('assign-advisor')">
              Assign Advisor
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'copy']" />
              </span>
            </button>
          </div>
          <div class="pr-2">
            <a href="#" @click="displayDeleteConfirmation('Confirmation Advisor')">
              <button type="button" class="btn btn-primary" :disabled="disableButton" >
                Delete Advisor
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
    <advisor-email-test :advisor-id="this.table.rowSelectedId" :initial-email-address="emailAddress"/>
    <advisor-email-preview :advisor-id="this.table.rowSelectedId" />
    <assign-advisor :advisor-id="table.rowSelectedId" :currentUserEmail="getCurrentUserEmail()" :clientName="getClientName()" @reload="reloadTable"/>
    <modal
      :modal-id="'Confirmation Advisor'"
      :modal-title="'Delete Confirmation'"
      :modal-content="'Are you sure you want delete the survey ?'"
      :modal-result-id="table.rowSelectedId"
      :include-confirmation="true"
      @confirm="deleteAdvisor"
    />
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import AdminService from "../../services/admin.service";
import AdvisorService from "../../services/advisor.service";
import AdvisorEmailTest from "../../modals/AdvisorEmailTest.vue";
import AdvisorEmailPreview from "../../modals/AdvisorEmailPreview.vue";
import AssignAdvisor from "../../modals/AssignAdvisor.vue";
export default {
  name: "AdvisorList",
  mixins: [AdminMixins, DataTableMixins],
  components: {AdvisorEmailTest, AdvisorEmailPreview, AssignAdvisor},
  data() {
    return {
//      viewSendTestEmail: process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "staging",
      viewSendTestEmail: false,
      title: "Advisor List",
      table: {
        id: "advisor-list-table",
        columns: AdvisorService.getAdvisorTableColumnAttributes()
      },
      response_message_user_id: 0,
      response_message_user_options: [
        { name: "Select User", value: 0 },
      ],
      application_error_client_id: 0,
      application_error_client_options: [
        { name: "Select Client", value: 0 },
      ],
      status_selected: "",
      status_options: [
        { name: "Select Status", value: "" },
        { name: "Ready", value: "R" },
        { name: "Complete", value: "C" }
      ],

    };
  },
  methods: {
    reset() {
      this.application_error_client_id = 0;
      this.response_message_user_id = 0;
      this.status_selected = "";
      this.loadData();
    },    
    reloadTable(){
      this.loadData();
      this.table.dataTable.draw(true);
    },
    deleteAdvisor() {
      this.$root.processServerRequest("Deleting advisor event");
      AdvisorService.deleteAdvisor(this.table.rowSelectedId, this.deleteAdvisorCallback, this);
    },
    deleteAdvisorCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.loadData();
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      await AdvisorService.getAdvisorList(this.response_message_user_id, this.application_error_client_id, this.status_selected, this.getAdvisorTableDataCallback, this);
    },
    loadData() {
      if (this.isAdmin) {
        this.getTableData();
      }
      
    },
    getAdvisorTableDataCallback(response){
      this.response_message_user_options = response.data.data.advisorListUserOptions;
      this.application_error_client_options = response.data.data.advisorListClientOptions;
      let data = response.data.data.advisorList;
      this.getTableDataWithDataCallback(response, data);
    },
    getCurrentUserEmail() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.userEmail;
      }
      return "";
    },

    getClientCode() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.clientCode;
      }
      return "";
    },
    getAdvisorConfigurationId() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.advisorConfigurationId;
      }
      return "";
    },
    getClientName() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.clientName;
      }
      return "";
    },
    getStatus() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.status;
      }
      return "";
    },
  },
  computed: {
    enableEditButton: function(){
      return this.table.rowSelected && this.getStatus() != "C";
    },    
    disableButton: function(){
      return this.table.rowSelectedId == 0;
    },
    emailAddress: function(){
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.userEmail;
      }
      return "";
    }
},
  created() {
    this.loadData();
  },
  props: {},
  mounted() {
          this.table.configuration.order = [[12, "desc"], [14, "desc"]];
  }
};
</script>
