<template>
  <div class="w-100">
    <!-- By Rival -->
    <div v-show="displayBenchmark" class="pt-3" id="competitorCard">
      <!-- By Sector -->
      <chart-company-option
        v-if="displayBenchmarkCompaniesBySection"
        ref="sectorBasedCompetitors"
        option-title="Benchmark Companies by Sector"
        option-type="sector"
        :source-id="clientId"
      ></chart-company-option>
      <!-- By Top 75 -->
      <chart-company-option :source-id="clientId" v-if="displayTop75CompetitorsBenchmarkCompanies" option-title="Benchmark Companies" option-type="top-75"></chart-company-option>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";

import ContentMixins from "../../mixins/ContentMixins.vue";
import ChartCompanyOption from "../../components/chart/ChartCompanyOption.vue";

import Vue from "vue";
import ChartService from "../../services/chart.service";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import ClientService from "../../services/client.service";

export default {
  name: "ChartCompany",
  mixins: [ContentMixins],
  components: {
    ChartCompanyOption
  },
  data() {
    return {
      displayBenchmark: false
    };
  },
  props: {
    clientId: {
      type: Number,
      default: ClientService.getClientId()
    },
    displayBenchmarkCompaniesBySection: {
      type: Boolean,
      default: false
    },
    displayTop75CompetitorsBenchmarkCompanies: {
      type: Boolean,
      default: true
    },
    pageContent: {
      type: Array,
      default: function() {
        return [];
      }
    },      
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  },
  created() {},
  mounted() {},
  methods: {
    setSector() {
      if (this.$parent.$data.displayCompetitors) {
        //this.sectorId = this.$parent.$data.clientOptions.find(x => x.companyId === this.companyId).sectorId;
        //this.$refs.sectorBasedCompetitors.$data.sectorId = newSectorId;
      }
    },
    resetCompanySelections() {
      this.$parent.$data.competitors = [];
      $(".competitor_option").prop("checked", false);
    },
    displayChartWithCompanies(competitor, event) {
      if (event.target.checked) {
        this.addCompany(competitor);
      } else {
        this.removeCompany(competitor);
      }
    },
    removeCompany(competitor) {
      var competitorToDelete = $("div[data-company-id='" + competitor.companyId + "']");
      if (typeof competitorToDelete !== "undefined" && competitorToDelete.length > 0) {
        competitorToDelete.html("");
      }
      var match = this.$parent.$data.competitors.find(x => Number(x.companyId) === Number(competitor.companyId));
      if (typeof match !== "undefined") {
        this.$parent.$data.competitors.splice($.inArray(match, this.$parent.$data.competitors), 1);
        let company = this.$parent.$refs.competitorsSelect.options.find(x => Number(x.companyId) === Number(competitor.companyId));
        if (typeof company !== "undefined") {
          company.checked = false;
        }
        let companyValue = this.$parent.$data.values.find(x => Number(x.companyId) === Number(competitor.companyId));
        if (typeof companyValue !== "undefined") {
          this.$parent.$data.values.splice($.inArray(companyValue, this.$parent.$data.values), 1);
        }
      }
    },
    addCompany(competitor) {
      if (this.$parent.$data.competitors.length >= this.$parent.maximumCompetitors) {
        event.preventDefault();
        let message = "Only " + this.$parent.maximumCompetitors + " competitors are allowed to be included in the chart";
        AdminService.displayErrorMessage({ text: message, duration: -1 });
        return false;
      }
      // Only add if the company doesn't already exist
      var match = this.$parent.$data.competitors.find(x => Number(x.companyId) === Number(competitor.companyId));
      if (typeof match == "undefined") {
        this.$parent.$data.competitors.push(competitor);
      }
    }
  },
  watch: {}
};
</script>
