import AdminService from "./admin.service";

const BASE_URL = AdminService.getBaseUrl();

class DraggableService {
    setListOrder(list, event, app) {
      let oldIndex = event.oldIndex;
      let newIndex = event.newIndex;
      app.dragging = false;
      if (typeof list[oldIndex] != "undefined" &&
          typeof list[newIndex] != "undefined" ){
          list[newIndex].sequenceNumber = newIndex;
          list[oldIndex].sequenceNumber = oldIndex;
          for (let index = 0; index < list.length; index++) {
            list[index].sequenceNumber = index;
          }
      }
      return list;
    }
    buildResponse(choices,numberOfChoicesToRank){
      let response = "";
      for (let i = 0; i < numberOfChoicesToRank; i++) {
        let choice = choices[i];
        let orderNumber = choice.sequenceNumber + 1;
        response += "(" + orderNumber + ") - " + choice.name ;
        if(i != (numberOfChoicesToRank-1)){
          response += ", ";
        }  
      }
      return response
    }
}
export default new DraggableService();
