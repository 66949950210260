<template>
  <vue-modal v-show="displayModal" :name="modalName" :scrollable="true" :max-width="700" :width="'60%'" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="reset">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <survey-taker
          v-if="showSurvey"
          :view-only="true"
          :read-only="true"
          :entry-responses="responses"
          :entry-client-id="this.$parent.clientId.toString()"
          :entry-survey-url="this.$parent.survey.url"
          :entry-survey-code="this.$parent.surveyCode"
        >
        </survey-taker>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-2" @click="reset">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import SurveyTaker from "../interview-application/views/SurveyTaker.vue";
import ResponseService from "../services/response.service";
import AdminService from "../services/admin.service";

export default {
  name: "SurveyResponses",
  components: {
    SurveyTaker
  },
  methods: {
    reset() {
      this.responses = [];
      this.showSurvey = false;
      //this.$modal.hide(this.modalName);
      this.$parent.displayModal = false;
    },
    getSurveyResponsesCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.responses = response.data.data;
        this.$modal.show(this.modalName);
        this.showSurvey = true;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  props: {
    modalName: {
      type: String,
      default: "survey-responses"
    },
    modalTitle: {
      type: String,
      default: "Form Responses"
    },
    surveyTakerId: {
      type: String,
      default: ""
    },
    surveyId: {
      type: Number,
      default: 0
    },
    displayModal: {
      type: Boolean,
      default: true,
    } 
  },
  data() {
    return {
      responses: [],
      showSurvey: false
    };
  },
  computed: {},
  mounted() {},
  watch: {
    surveyTakerId: function() {
      if (this.surveyId != 0) {
        this.$root.processServerRequest();
        ResponseService.getSurveyResponses(this.surveyId, this.surveyTakerId, this.getSurveyResponsesCallback, this);
      }
    }
  }
};
</script>
