<template>
    <div class="form-group form-inline no-gutters">
       <div class="question-description-label form-control">{{ questionObject.label }}</div> 
       <input :value="questionObject.description" :id="questionId" :name="questionId" type="text" class="form-control col-8 p-1" @blur="saveQuestionDescriptionEvent($event)" />
    </div>
</template>
<script>
import $ from "jquery";
import QuestionService from "../services/question.service";

export default {
    name: "QuestionDescription",
    mixins: [],
    props: {
        questionObject: {
            type: Object,
            default: function() {
                return {};
            }
        },        
    },
    components: {
    },
    data() {
        return {
          question: {},
          description: "",
          localQuestionObject: this.questionObject,
        };
    },
    methods: {
        saveQuestionDescriptionEvent: function(event) {
            this.saveQuestionDescription(event.target.value);
        },
        async saveQuestionDescription(newValue) {
            if (this.description != newValue) {
                this.description = newValue;
                //this.questionObject.description = newValue;
                var result = {
                    questionId: this.questionObject.questionId,
                    description: this.description
                };
                this.$parent.setSurveyToBuildRequired();
                if (!this.initialLoad) {
                    QuestionService.saveQuestionDescription(result, this);
                }
            }
        }
    },
    created() {
        $.extend(this.question, this.questionObject);
    },
    mounted() {
        this.initialLoad = false;
        this.description = this.questionObject.description;
    },
    watch: {},
    computed: {
        questionId: function() {
            return "question_" + this.questionObject.questionId;
        },        
    }
}
</script>
