<template>
  <div class="section-order">
    <div class="message pl-2">Drag and Drop a sections’s name to set the order of how they will always appear in the survey.</div>
        <VueDraggable
            v-model="sections"
            :disabled="!enabled"
            class="list-group draggable-container"
            ghost-class="draggable-ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="endOfDragSections"
            options="people"
        >
            <div v-show="section.name != ''" class="list-group-item draggable-card" v-for="section in this.sections" 
                :key="section.sectionId" 
                :index="section.sectionId">
                <i class="fa fa-caret-right" aria-hidden="true"></i>
                <span class="pl-1">{{ section.name }}</span>
<!--                <span class="pl-1">{{ "  SN: " + question.sequenceNumber }}</span> -->
            </div>
        </VueDraggable>      
  </div>
</template>

<script>
import { VueDraggable } from 'vue-draggable-plus';

export default {
  name: "SectionOrder",
  components: {
    VueDraggable
  },
  data() {
    return {
      enabled: true,
      sections: []
    };
  },
  methods: {
    checkMove: function(e) {},
    endOfDragSections: function(event) {
      let oldIndex = event.oldIndex;
      let newIndex = event.newIndex;
      this.dragging = false;
      if (typeof this.sections[oldIndex] != "undefined" &&
          typeof this.sections[newIndex] != "undefined" ){
          this.sections[newIndex].sequenceNumber = newIndex;
          this.sections[oldIndex].sequenceNumber = oldIndex;
          for (let index = 0; index < this.sections.length; index++) {
            this.sections[index].sequenceNumber = index;
          }
          this.$emit("save", this.sections);
          this.$emit("enable-save-order-button");
      }
    },			    
  },
  computed: {},
  mounted(){
    this.sections = JSON.parse(JSON.stringify(this.inputSections));
  },
  props: {
    inputSections: {
      type: Array,
      default: () => [
        { name: "", sectionId: 0},
        { name: "", sectionId: 0},
        { name: "", sectionId: 0},
        { name: "", sectionId: 0},
        { name: "", sectionId: 0}
      ]
    },
  }
};
</script>
