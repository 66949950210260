import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import SurveyService from "./survey.service";
import GoogleService from "./google.service";

const API_CLIENT_COMPETITOR_URL = AdminService.getBaseUrl() + "/api/v1/client-competitor";

class ClientCompetitorService {
  async getCompetitors(clientId, period, callback, includeCounts = true) {
    let surveyId = SurveyService.getSurveyId();
    if (surveyId != 0) {    
      const params = new URLSearchParams();
      params.append("client_id", clientId);
      params.append("period", period);
      params.append("survey_id", surveyId);
      params.append("userId", UserService.getUserId());
      params.append("includeCounts", includeCounts);
      await axios
        .post(API_CLIENT_COMPETITOR_URL + "/get-my-competitors", params, { headers: await authHeader() })
        .then(response => {
          callback(response.data);
        })
        .catch(error => {
          throw error;
        });
    }
  }
  async deleteClientCompetitor(clientCompetitorsId, name, surveyId, callback, chartApp) {
    GoogleService.logBaseEvent("competitor deleted", SurveyService.getSurveyName(), name, 0, chartApp);

    const params = new URLSearchParams();
    params.append("clientCompetitorsId", clientCompetitorsId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_CLIENT_COMPETITOR_URL + "/delete-competitor", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientCompetitorService.deleteClientCompetitor", error, "survey-designer", chartApp);
      });
  }
  async updateCompetitorDisplay(clientCompetitorsId, display, surveyId, callback, chartApp) {
    GoogleService.logBaseEvent("competitor saved", SurveyService.getSurveyName(), "Display in Survey/Charts", 0, chartApp);
    const params = new URLSearchParams();
    params.append("clientCompetitorsId", clientCompetitorsId);
    params.append("userId", UserService.getUserId());
    params.append("surveyId", surveyId);
    params.append("display", display);
    await axios
      .post(API_CLIENT_COMPETITOR_URL + "/update-competitor-display", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientCompetitorService.updateCompetitorDisplay", error, "survey-designer", chartApp);
      });
  }
  async updateCompetitorColor(clientCompetitorsId, color, surveyId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCompetitorsId", clientCompetitorsId);
    params.append("userId", UserService.getUserId());
    params.append("surveyId", surveyId);
    params.append("color", color);
    await axios
      .post(API_CLIENT_COMPETITOR_URL + "/update-competitor-color", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientCompetitorService.updateCompetitorColor", error, "survey-designer", chartApp);
      });
  }
  async saveClientCompetitorOrder(competitors, surveyId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("clientCompetitors", JSON.stringify(competitors));
    params.append("userId", UserService.getUserId());
    params.append("surveyId", surveyId);
    await axios
      .post(API_CLIENT_COMPETITOR_URL + "/save-competitor-order", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientCompetitorService.saveClientCompetitorOrder", error, "survey-designer", chartApp);
      });
  }
  async saveClientCompetitor(competitor, surveyId, callback, chartApp) {
    GoogleService.logBaseEvent("competitor saved", SurveyService.getSurveyName(), "Name", 0, chartApp);

    const params = new URLSearchParams();
    params.append("clientCompetitor", JSON.stringify(competitor));
    params.append("userId", UserService.getUserId());
    params.append("surveyId", surveyId);
    await axios
      .post(API_CLIENT_COMPETITOR_URL + "/save-competitor", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("ClientCompetitorService.saveClientCompetitor", error, "survey-designer", chartApp);
      });
  }
}

export default new ClientCompetitorService();
