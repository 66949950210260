<template>
  <div class="drag-and-drop">
    <div class="choices-options">
        <div class="form-group form-inline pt-1 pb-1 no-gutters">
            <label for="numberOfChoices">Number of choices</label>
            <div class="numberOfChoicesLeft">
                <a href="#!" @click="addChoice" class="btn-lg">
                    <font-awesome-icon :icon="['fa', 'plus-circle']" />
                </a>
            </div>
            <input v-model="numberOfChoices" id="numberOfChoices" name="numberOfChoices" 
                step="1" min="min" max="max" type="text" class="form-control required text-center" />
            <div class="numberOfChoicesRight">
                <a href="#!" @click="removeChoice" class="btn-lg">
                    <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </a>
            </div>
        </div>
        <div v-show="displayNumberOfChoicesToRank == 'Y'" class="form-group form-inline pt-1 pb-1 no-gutters">
            <label for="numberOfChoicesToRank">Number of choices to rank</label>
            <div class="numberOfChoicesLeft">
                <a href="#!" @click="addChoiceToRank" class="btn-lg">
                    <font-awesome-icon :icon="['fa', 'plus-circle']" />
                </a>
            </div>
            <input v-model="numberOfChoicesToRank" id="numberOfChoicesToRank" name="numberOfChoicesToRank" 
                step="1" min="min" max="max" type="text" class="form-control required  text-center"/>
            <div class="numberOfChoicesRight">
                <a href="#!" @click="removeChoiceToRank" class="btn-lg">
                    <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </a>
            </div>
        </div>
        <div class="form-group form-inline pt-1 pb-1 no-gutters">
            <label for="segment" class="control-label pr-1">"Only rank a subset of the choices"....?</label>
            <input type="checkbox" id="displayNumberOfChoicesToRank" name="displayNumberOfChoicesToRank" 
                v-model="displayNumberOfChoicesToRank" true-value="Y" false-value="N" 
                @click="checkDisplayNumberOfChoicesToRank($event)" />
        </div>
        <div class="form-group form-inline pt-1 pb-1 no-gutters">
            <label for="orderSet" >Order</label>
            <div class="form-check form-check-inline">
                <input type="radio" id="orderRandom" name="orderRandom" class="form-check-input" value="random"  v-model="choiceOrder" @click="saveOrderEvent($event)">Random 
            </div>
            <div class="form-check form-check-inline">
                <input type="radio" id="orderSet" name="orderSet" class="form-check-input" value="set" v-model="choiceOrder" @click="saveOrderEvent($event)">Set (below) 
            </div>
        </div>        
        <div v-show="draggableEnabled" class="question-order">
            <div class="message pl-2">Drag and Drop a choice's name to set the order of how they will always appear in the survey.</div>
            <VueDraggable
                v-model="choices"
                :disabled="!draggableEnabled"
                class="list-group draggable-container"
                ghost-class="draggable-ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="endOfDragChoices"
                options="people"
            >
                <div v-show="choice.name != ''" class="list-group-item draggable-card" v-for="choice in this.choices" 
                    :key="choice.questionId" 
                    :index="choice.questionId">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>
                    <span class="pl-1">{{ choice.name }}</span>
                </div>
            </VueDraggable>      
        </div>
    </div>        
    <div class="choices-set-up">
        <div v-for="(choice, idxChoices) in choices" :key="idxChoices" class="form-group form-inline no-gutters">
            <input 
                v-model="choices[idxChoices].name"
                :id="'choice' + (idxChoices + 1)"
                :name="'choice' + (idxChoices + 1)"
                class="form-control col-9 mb-1 p-1 mt-1" type="text"
                @change="saveChoice($event, idxChoices)" />
        </div>
    </div>
  </div>
</template>
<script>
import { VueDraggable } from 'vue-draggable-plus';
import DraggableService from "../services/draggable.service";
import DragAndDropMixins from "../mixins/DragAndDropMixins.vue";

export default {
  name: "QuestionDragDrop",
  mixins: [DragAndDropMixins],
  components: {
    VueDraggable
  },
  computed: {},
  watch: {
    numberOfChoicesToRank: function(){
        if (this.checkNumberOfChoicesToRank()){
            this.numberOfChoicesToRank = this.numberOfChoices;
        }
    }
  },
  data() {
    return {
        numberOfChoicesToRank: 2,
        numberOfChoices: 2,
        displayNumberOfChoicesToRank: "N",
        min: 1,
        max: 100
    };
  },
  props: {  
    initialNumberOfChoicesToRank: {
        type: Number,
        value: 2
    },
  },
  methods: {
    checkNumberOfChoicesToRank(){
        return this.numberOfChoicesToRank > this.numberOfChoices || this.numberOfChoicesToRank < this.min;
    },
    saveDragDrop: function(){
        if (this.checkNumberOfChoicesToRank()){
            this.numberOfChoicesToRank = this.numberOfChoices;
        }
        this.$emit("save",this.choices, this.choiceOrder, this.numberOfChoicesToRank);
    },
    checkDisplayNumberOfChoicesToRank: function(event){
      /* */
      this.numberOfChoicesToRank = this.numberOfChoices;
      if (event.target.checked) {
        this.displayNumberOfChoicesToRank = "Y";
      } else {
        this.displayNumberOfChoicesToRank = "N";
      }
    },
    endOfDragChoices: function(event) {
        this.choices = DraggableService.setListOrder(this.choices, event, this);
        this.saveDragDrop();
    },			    
    saveOrderEvent: function(event){
        let value = event.target.value;
        this.choiceOrder = value;
        this.saveDragDrop();
    },
    addChoice: function(result) {
        if (this.numberOfChoices < this.max){
            this.numberOfChoices++;
            if (this.displayNumberOfChoicesToRank == "N"){
                this.addChoiceToRank();
            }
            let orderNumber = this.choices.length + 1;
            let choice = { name: " ",  sequenceNumber: orderNumber};
            this.choices.push(choice);
            this.saveDragDrop();
        }
    },
    removeChoice: function() {
        if (this.numberOfChoices > this.min){
            this.choices.splice(-1);
            this.numberOfChoices--;
            if (this.displayNumberOfChoicesToRank == "N"){
                this.removeChoiceToRank();
            }
            this.saveDragDrop();
        }
    },  
    addChoiceToRank: function(result) {
        if (this.numberOfChoicesToRank < this.max && this.numberOfChoicesToRank < this.numberOfChoices){
            this.numberOfChoicesToRank++;
            this.saveDragDrop();
        }
    },
    removeChoiceToRank: function() {
        if (this.numberOfChoicesToRank > this.min && this.numberOfChoicesToRank > 0){
            this.numberOfChoicesToRank--;
            this.saveDragDrop();
        }
    },  
    saveChoice: async function (event, index) {
        let value = event.target.value;
        this.choices.name = value;
        this.saveDragDrop();
    }
  },
  mounted() {
    this.numberOfChoices = this.choices.length;
    if (!isNaN(this.initialNumberOfChoicesToRank)){
        this.numberOfChoicesToRank = this.initialNumberOfChoicesToRank;
    } else {
        this.numberOfChoicesToRank = this.numberOfChoices;
    }
    if (this.numberOfChoicesToRank != this.numberOfChoices){
        this.displayNumberOfChoicesToRank = "Y";
    }
  },  
  unmounted() {}
};
</script>
