<template>
    <div class="filter-buttons">
        <div class="pr-2">
            <button type="button" class="btn btn-primary ml-2" id="FilterButton" @click="filterData()">
              <span>Filter</span>
              <span v-show="isDataFiltering == true" class="spinner-border spinner-border-sm"></span>
            </button>
        </div>
        <div class="pr-2">
            <button type="button" class="btn btn-primary ml-2" id="ResetButton" @click="reset()">
              <span>Reset</span>
              <span v-show="isFilterResetting == true" class="spinner-border spinner-border-sm"></span>
            </button>
        </div>
    </div>
</template>
<script>
export default {
  name: "FilterButtons",
  mixins: [],
  components: {},
  data() {
    return {};
  },    
  props: {
    isDataFiltering: {
      type: Boolean,
      default: false
    },
    isFilterResetting: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    filterData: function(){
      this.$emit("filterData");
    },
    reset: function(){
      this.$emit("reset");
    },
  },
  computed: {}, 
  created() {},
  mounted() {},
};

</script>