<template>
  <div class="panel-admin-body">
    <div class="title">
      <div class="d-flex">
        {{ title }}
      </div>
    </div>
    <div class="new-competitive-dashboard">
      <saved-dashboard-cards 
        :client-object="$parent.$data.clientObject" 
        :save-client-chart-object="clientChart"/>
    </div>
  </div>
</template>
<script>
import SavedDashboardCards from "../components/SavedDashboardCards.vue";
import ChartService from "../services/chart.service";
import ReportService from "../services/report.service";
import ClientService from "../services/client.service";

export default {
  name: "ViewSavedDashboardCards",
  components: {
    SavedDashboardCards,
  },
  mixins: [],
  props: {
    saveClientChartObject: {
      type: Object,
      default: function() {
        return {
          clientChartId: 0
        };
      }
    },
  },
  data() {
    return {
      title: "View Saved Dashboard Cards",
      clientId: ClientService.getClientId(),
      clientChart: {
        clientChartId: 0
      },      
    };
  },
  methods: {
    loadData: function() {
      this.clientChart = JSON.parse(JSON.stringify(this.saveClientChartObject));
      let reportAutoGenerateStatus = ReportService.getReportAutoChartGenerateStatus();

      if ((reportAutoGenerateStatus == "preview" || reportAutoGenerateStatus == "generate")) {
        let chartTypes = ChartService.getDashboardChartTypes();

        for (let index = 0; index < chartTypes.length; index++) {
          let chartType = chartTypes[index];
          let reportClientChart = ReportService.getReportClientChartByChartType(chartType);
          if (typeof reportClientChart != "undefined") {
            this.autoGenerateChartRun = true;
            this.clientChart = JSON.parse(JSON.stringify(reportClientChart));
            ReportService.removeReportClientChart(this.clientChart);
            break;
          }
        }
      } else {
        this.autoGenerateChartRun = this.autoGenerateChart;
      }
    }
  },
  watch: {
    saveClientChartObject: function() {
      this.loadData();
    }
  },
  created() {},
  mounted() {
    this.loadData();
  },
  umnounted() {},
  computed: {}
};
</script>
