import axios from "axios";
import authHeader from "./auth-header";
import jQuery from "jquery";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";

const API_USER_URL = AdminService.getBaseUrl() + "/api/v1/user";

class UserSettingsService {
  getUserSetting(userSettingTypeName) {
    var setting = this.getUserSettings().find(x => x.userSettingTypeName == userSettingTypeName);
    if (typeof setting != "undefined"){
      return setting.userSettingValue;
    }
    return "";
  }
  getUserSettings() {
    if (!jQuery.isEmptyObject(localStorage.getItem("user-settings"))) {
      return JSON.parse(localStorage.getItem("user-settings"));
    }
    return [];
  }
  getShowMainMenu() {
    let showHeaderMenu = this.getUserSetting("Show Main Menu");
    if (typeof showHeaderMenu != "undefined"){
      return showHeaderMenu;
    }  
    return true;
  }
  getShowHeaderMenu() {
    let showHeaderMenu = this.getUserSetting("Show Header Menu");
    if (typeof showHeaderMenu != "undefined"){
      return showHeaderMenu;
    }  
    return false;
  }
  getSavedSurveyCode() {
    let savedSurveyCode = this.getUserSetting("survey-code");
    if (typeof savedSurveyCode != "undefined"){
      return savedSurveyCode;
    }  
    return "";
  }
  getSortByForSavedCards() {
    let sortByForSavedCards = this.getUserSetting("Sort By - Saved Cards");
    if (typeof sortByForSavedCards != "undefined"){
      return sortByForSavedCards;
    }  
    return "date-added-newest";
  }
  getDetailedViewForSavedCards(displayedSavedCards) {
    let detailedViewForSavedCards = this.getUserSetting("Detailed View - Saved Cards");
    if (typeof detailedViewForSavedCards != "undefined" && detailedViewForSavedCards != ""){
      return detailedViewForSavedCards == 'Y';
    } else if (typeof displayedSavedCards != "undefined") {
      return displayedSavedCards.length >= 20;
    }  
    return false;
  }
  getSavedClientId() {
    let savedClientId = this.getUserSetting("client-id");
    if (typeof savedClientId != "undefined"){
      return savedClientId;
    }  
    return -1;
  }
  saveAllUserSettings(settings){
    localStorage.setItem("user-settings", JSON.stringify(settings));
  }
  async saveUserSetting(userSettingTypeName, value, sequenceNumber, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("value", value);
    params.append("sequenceNumber", sequenceNumber);
    params.append("userSettingTypeName", userSettingTypeName);
    params.append("userId", UserService.getUserId());
    params.append("userCode", UserService.getUserCode());
    await axios
      .post(API_USER_URL + "/save-user-setting", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserSettingsService.saveUserSetting", error, "admin", chartApp);
      });
  }
  async saveUserSettingByUserId(userId, userCode, userSettingTypeName, value, sequenceNumber, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("value", value);
    params.append("sequenceNumber", sequenceNumber);
    params.append("userSettingTypeName", userSettingTypeName);
    params.append("userId", userId);
    params.append("userCode", userCode);
    await axios
      .post(API_USER_URL + "/save-user-setting", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserSettingsService.saveUserSetting", error, "admin", chartApp);
      });
  }
  async resetUserDashboardSetings(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("userCode", UserService.getUserCode());
    await axios
      .post(API_USER_URL + "/reset-user-dashboard-settings", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserSettingsService.resetUserDashboardSetings", error, "admin", chartApp);
      });
  }	  
  async getUserSettingsFromDatabase(userId, userCode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", userId);
    params.append("userCode", userCode);    
    await axios
      .post(API_USER_URL + "/get-user-settings", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("UserSettingsService.getClientSettings", error, "admin", chartApp);
      });
  }	  
}

export default new UserSettingsService();

