<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/profiles" @click="$parent.logViewDetailsEvent(title)">
            Winners <br />
            Map
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Winners Map" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('winners-map-save-chart-confirmation')"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-competitive-rank">
        <dashboard-card-dropdown label="View" :read-only="readOnly" :options="view_options" :default-value="view" @change="saveView" />
        <dashboard-card-dropdown v-if="showCompetitorsDropdown" label="Competitors" :read-only="readOnly" :options="competitor_options" :default-value="competitor"
          @change="saveCompetitor" />
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <div class="dashboard-card-body">
      <vue-skeleton-loader
            v-if="!displayDashboard" 
            type="rec"
            width="330"
            height="230"
            animation="fade"
        />
      <div class="winners-map-dashboard-chart" :style="dashboardChartStyle">
        <chart :options="options"></chart>
      </div>
      <div class="dashboard-x-axis-container" v-show="displayDashboard && showSummaryImage">
          <img src="../../assets/img/dashboard-card-components/Card-Winners.png" alt="xAxis"/>
      </div>
      <div id="winners-map-mini">
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
      </div> 
    </div>
    <!-- :updatedModalChartOptions.sync="options.series[0].data" -->
    <dashboard-modal :modal-id="dashboardId" :chart="this.chart" :client-chart="this.clientChart" :dashboard-card-report-page-type-name="reportTypeName" :modal-title="title" 
      :modal-chart-options="options" :show-winners-summary-image="showSummaryImage" :displayModal="this.displayModal" :key="$displayModal"/>
    <vue-modal :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardSummaryMixins from "../../mixins/DashboardSummaryMixins.vue";
import DashboardService from "../../services/dashboard.service";
import ClientCompetitorService from "../../services/client.competitor.service";
import DashboardCardDropdown from "./DashboardCardDropdown";
import SaveChart from "../chart/SaveChart.vue";
import VueSkeletonLoader from 'skeleton-loader-vue';

import Vue from "vue";
import $ from "jquery";

export default {
  name: "DashboardWinnersMap",
  mixins: [DashboardSummaryMixins],
  components: { DashboardCardDropdown,SaveChart,VueSkeletonLoader },
  data() {
    return {
      title: "Winners Map",
      modalName: "winners-map-save-chart-confirmation",
      view_options: [
        { name: "Map Summary", value: "map-summary" },
        { name: "Table Stakes", value: "table-stakes" },
        { name: "Core", value: "core-strengths" },
        { name: "Diverge", value: "diverge-dominate" }
      ],
    };
  },
  props: {},
  computed: {
    imgPath: function(){
      return require(`../../assets/img/dashboard-card-components/Card-Winners.png`);
    },    
    imgPathHiRes: function(){
      return require(`../../assets/img/dashboard-card-components/Card-Winners-HiRes.png`);
    }    

  },
  mounted() { },
  created() {
    // eslint-disable-next-line no-console
    //console.log("DashboardWinnersMap::created");
    this.view = "map-summary";
    this.dashboardViewSettingType = DashboardService.DASHBOARD_WINNERS_MAP_VIEW_SETTING_TYPE;
    this.dashboardCompetitorSettingType = DashboardService.DASHBOARD_WINNERS_MAP_COMPETITOR_SETTING_TYPE;

    this.chartTitle = "Winners Map";
    this.chartTypeName = "winners-map";
    this.containerId = "winners-map-mini"
	  this.reportTypeName = "Dashboard - Winners Map"
  },
  methods: {
    initChart: function (dynamicOptions) {
      // https://echarts.apache.org/examples/en/editor.html?c=scatter-punchCard
      let chartDiv = $(".winners-map-dashboard-chart")[0];
      this.initBaseChart(dynamicOptions, chartDiv);
    },

    loadData: async function () {
      // eslint-disable-next-line no-console
      //console.log("DashboardWinnersMap::loadData");
      if (this.loadCompetitorData == true){
        await ClientCompetitorService.getCompetitors(this.$parent.$parent.clientId, this.timePeriodId, this.loadCompetitors);
      } 
      this.setUpCard();
      DashboardService.getWinnersMap(this.timePeriodId, this.competitor, this.view, this.getSummaryCallback, this);
    },
    updateCard: function () {
      // eslint-disable-next-line no-console
      //console.log("DashboardWinnersMap::updateCard");
      this.setUpCard();
      DashboardService.getWinnersMap(this.timePeriodId, this.competitor, this.view, this.getSummaryCallback, this);
    }
  },
};
</script>
