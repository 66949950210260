<template>
  <div id="nps" class="rank-body pl-2">
      <div class="tier-labels">
          <div>Better</div>
          <div>Worse</div>
      </div>
      <div class="tier-bar">
            <div v-for="(tierInstance, idxTiers) in tiers" :key="idxTiers" :class="getTierClass(tierInstance)">
                <span>{{ tierInstance.name }}</span>
            </div>
      </div>            
      <div class="rank-display">
          <div class="rank-display-title">{{ titleHtml }}</div>
          <div v-if="score != 0" class="score">{{ score }}</div>
          <div v-if="message != ''" class="rank-position">{{ message }}</div>
      </div>
      <div :id="rankChartDisplayIdName" style="display: flex; width: 110px;">
        <div class="chart-display">
          <div class="promoters-rank-nps value">{{ getPromotersDisplay() }}</div>
          <div class="passives-rank-nps value">{{ getPassiveDisplay() }}</div>
          <div class="detractors-rank-nps value">{{ getDetractorsDisplay() }}</div>
        </div>
        <div class="nps-labels">
          <div class="promoters-rank-nps label"></div>
          <div class="passives-rank-nps label"></div>
          <div class="detractors-rank-nps label"></div>
        </div>
      </div> 
  </div>
</template>

<script>
import RankBodyMixins from "../../../mixins/RankBodyMixins.vue";
import $ from "jquery";

export default {
name: "NpsScoreRankBody",
mixins: [RankBodyMixins],
components: {},
data() {
  return {
    title: "Performance Trends",
  };
},
props: {
  netPromoterScore: {
    type: Array,
    default: function() {
      return [];
    }
  },    

},
computed: {},
mounted() {
  this.calculateDetractorsHeight();
  this.calculatePassivesHeight();
  this.calculatePromotorsHeight();
},
created() {},
methods: {
  getTierClass: function(tierInstance) {
      let className = "tier-block";
      if (tierInstance.name == this.tier){
        className += " selected"
      } 
      return className;
    },
  getPassive: function(){
//    console.log("getPassive " + JSON.stringify(this.netPromoterScore[1]));
    return this.netPromoterScore[1].Passives;
  },
  getPassiveDisplay: function(){
    let passive = this.getPassive();
    if (passive <= 0){
      return "";
    } else {
      return passive + "%";
    }
  },
  calculatePassivesHeight: function(){
    let height = 0;
    let passive = this.getPassive();
    let rankChartLine = this.rankChartDisplayContainer.find(".passives-rank-nps.value");
    let rankChartLabel = this.rankChartDisplayContainer.find(".passives-rank-nps.label");

    if (passive > 0){
      let heightPercent = passive / 100;
      height = this.baseHeight * heightPercent;
      rankChartLabel.html("Passives");
    }
    rankChartLine.css("height", height + "px");
    rankChartLabel.css("height", height + "px");
  },
  getDetractors: function(){
 //   console.log("getDetractors " + JSON.stringify(this.netPromoterScore[0]));
    return this.netPromoterScore[0].Detractors;
  },
  getDetractorsDisplay: function(){
    let detractors = this.getDetractors();
    if (detractors <= 0){
      return "";
    } else {
      return detractors + "%";
    }
  },
  calculateDetractorsHeight: function() {
      let height = 0;
      let detractors = this.getDetractors();
      let rankChartLine = this.rankChartDisplayContainer.find(".detractors-rank-nps.value");
      let rankChartLabel = this.rankChartDisplayContainer.find(".detractors-rank-nps.label");

      if (detractors > 0){
        let heightPercent = detractors / 100;
        height = this.baseHeight * heightPercent;
        rankChartLabel.html("Detractors");
      }
      rankChartLine.css("height", height + "px");
      rankChartLabel.css("height", height + "px");
    },

  getPromoters: function(){
//    console.log("getPromoters " + JSON.stringify(this.netPromoterScore[2]));
    return this.netPromoterScore[2].Promoters;
  },
  getPromotersDisplay: function(){
    let promoters = this.getPromoters();
    if (promoters <= 0){
      return "";
    } else {
      return promoters + "%";
    }
  },
  calculatePromotorsHeight: function(){
    let height = 0;
    let promoters = this.getPromoters();
    let rankChartLine = this.rankChartDisplayContainer.find(".promoters-rank-nps.value");
    let rankChartLabel = this.rankChartDisplayContainer.find(".promoters-rank-nps.label");
    if (promoters > 0){
      let heightPercent = promoters / 100;
      height = this.baseHeight * heightPercent;
      rankChartLabel.html("Promoters");
    }
    rankChartLine.css("height", height + "px");
    rankChartLabel.css("height", height + "px");

  },
},
watch: {}
};
</script>
