class NeedsService {
  emotionalNeedsList = [];
  functionalNeedsList = [];
  moatsList = [];
  setFunctionalNeedsList(list) {
    this.functionalNeedsList = list;
  }
  setEmotionalNeedsList(list) {
    this.emotionalNeedsList = list;
  }
  setMoatsList(list) {
    this.moatsList = list;
  }
  getFunctionalNeedsPosition(need, maxPosition) {
    if (need.name == "Functional 1") {
      //Quality/Performance [Design]
      return 3;
    } else if (need.name == "Functional 2") {
      // Innovation/Customization [Innovation]	5
      return 5;
    } else if (need.name == "Functional 3") {
      //Value/Usefulness [Value]	2
      return 2;
    } else if (need.name == "Functional 4") {
      // Pricing/Pricing Practices [Value]	1
      return 1;
    } else if (need.name == "Functional 5") {
      // Safety/Standards/Exclusive/Rare [Lock-Out]	4
      return 4;
    } else if (need.name == "Functional 6") {
      // Availability/Breadth/Choices [Scale]	7
      return 7;
    } else if (need.name == "Functional 7") {
      // Brand Reputation/Leadership [Brand]	12
      return 12; 
    } else if (need.name == "Functional 8") {
      // Culture/People/Competence [Skill]	11
      return 11;
    } else if (need.name == "Functional 9") {
      // Community/Knowledge [Network]	9
      return 9;
    } else if (need.name == "Functional 10") {
      // Relationship/Consistency/Tools [Lock-In]	8
      return 8;
    } else if (need.name == "Functional 11") {
      // Ease of Doing Business/Simplicity [Routine]	6
      return 6;
    } else if (need.name == "Functional 12") {
      // Speed of Response/Time-Sensitivity [Time]	10
      return 10;
    }
    return maxPosition;
  }
  getFunctionalNeedsDisplayName(need) {
      if (need.designViewSequenceNumber == null){
        return need.name;
      }
      if (need.designViewSequenceNumber == 3) {
        return "Quality/Performance [Design]";
      } else if (need.designViewSequenceNumber == 5) {
        // Innovation/Customization [Innovation]	5
        return "Innovation/Customization [Innovation]";
      } else if (need.designViewSequenceNumber == 2) {
        //Value/Usefulness [Value]	2
        return "Value/Usefulness [Value]";
      } else if (need.designViewSequenceNumber == 1) {
        // Pricing/Pricing Practices [Value]	1
        return "Pricing/Pricing Practices [Value]";
      } else if (need.designViewSequenceNumber == 4) {
        // Safety/Standards/Exclusive/Rare [Lock-Out]	4
        return "Safety/Standards/Exclusive/Rare [Lock-Out]";
      } else if (need.designViewSequenceNumber == 7) {
        // Availability/Breadth/Choices [Scale]	7
        return "Availability/Breadth/Choices [Scale]";
      } else if (need.designViewSequenceNumber == 12) {
        // Brand Reputation/Leadership [Brand]	12
        return "Brand Reputation/Leadership [Brand]";
      } else if (need.designViewSequenceNumber == 11) {
        // Culture/People/Competence [Skill]	11
        return "Culture/People/Competence [Skill]";
      } else if (need.designViewSequenceNumber == 9) {
        // Community/Knowledge [Network]	9
        return "Community/Knowledge [Network]";
      } else if (need.designViewSequenceNumber == 8) {
        // Relationship/Consistency/Tools [Lock-In]	8
        return "Relationship/Consistency/Tools [Lock-In]";
      } else if (need.designViewSequenceNumber == 6) {
        // Ease of Doing Business/Simplicity [Routine]	6
        return "Ease of Doing Business/Simplicity [Routine]";
      } else if (need.designViewSequenceNumber == 10) {
        // Speed of Response/Time-Sensitivity [Time]	10
        return "Speed of Response/Time-Sensitivity [Time]";
      }
      return need.name;
    }
  rearrangeNeedsList(list) {
    let newList = [];
    list.forEach(function(need) {
      need = this.setNameAndPosition(need);
      newList.push(need);
    });
    newList.sort(function(a, b) {
      return a.newOrderPosition - b.newOrderPosition;
    });
    newList.forEach(function(need) {
      delete need.newOrderPosition;
    });
    return newList;   
  }
  getMoatNumber(name) {
    let searchableMoat = this.moatsList.find(x => x.name == name);
    if (typeof searchableMoat != "undefined") {
      return searchableMoat.number;
    } else {
      return 0;
    }
  }
  getNeedNumber(needName, needType) {
    let searchableNeed = needType == "emotional" ? this.emotionalNeedsList.find(x => x.needName == needName) : this.functionalNeedsList.find(x => x.needName == needName);
    if (typeof searchableNeed != "undefined") {
      return searchableNeed.needNumber;
    } else {
      return 0;
    }
  }
  replaceNeedName(originalName, newName, needType) {
    let searchableNeed = needType == "emotional" ? this.emotionalNeedsList.find(x => x.needName == originalName) : this.functionalNeedsList.find(x => x.needName == originalName);
    if (typeof searchableNeed != "undefined") {
      searchableNeed.needName = newName;
    }
  }
}
export default new NeedsService();
