import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import ClientService from "./client.service";
import UserService from "./user.service";
import AdminService from "./admin.service";
import GoogleService from "./google.service";
import UserSettingsService from "./user.settings.service";
import $ from "jquery";
import jQuery from "jquery";
import Vue from "vue";

import moment from "moment-timezone";
Vue.prototype.moment = moment;

const BASE_URL = AdminService.getBaseUrl();
const API_SURVEY_URL = BASE_URL + "/api/v1/survey";

class SurveyScoreService {
  
  async saveSurveyGradeThresholds(surveyCode,surveyGradeThresholdList,rejectGrade, rejectPoints,callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyCode", surveyCode);
    params.append("rejectGrade", rejectGrade);
    params.append("rejectPoints", rejectPoints);
    params.append("surveyGradeThresholdList", JSON.stringify(surveyGradeThresholdList));
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_URL + "/save-survey-grade-thresholds", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyScoreService.saveSurveyGradeThresholds", error, "survey-designer", chartApp);
      });
  }

  async getSurveyGradeThresholds(surveyCode,callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyCode", surveyCode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_URL + "/get-survey-grade-thresholds", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyScoreService.getSurveyGradeThresholds", error, "survey-designer", chartApp);
      });
  }

  async getSurveyQualityScore(surveyTakerList, surveyCode,callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyTakerList", surveyTakerList);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_URL + "/get-survey-quality-score", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyScoreService.getSurveyQualityScore", error, "survey-designer", chartApp);
      });
  }
  getTableColumnData() {
    return [
      {
        data: "summaryRecord.surveySummaryId",
        name: "surveySummaryId",
        width: "5rem"
      },
      { data: "summaryRecord.surveyTakerId", name: "surveyTakerId", width: "6rem" },
      { data: "kScore1", name: "kS1", width: "2rem" },
      { data: "kScore2", name: "kS2", width: "2rem" },
      { data: "kScore3", name: "kS3", width: "2rem" },
      { data: "kScore4", name: "kS4", width: "2rem" },
      { data: "kScore5", name: "kS5", width: "2rem" },
      { data: "kScore6", name: "kS6", width: "2rem" },
      { data: "kScore7", name: "kS7", width: "2rem" },
      { data: "kScore8", name: "kS8", width: "2rem" },
      { data: "kScore9", name: "kS9", width: "2rem" },
      { data: "kScore10", name: "kS10", width: "2rem" },
      { data: "kScore11", name: "kS11", width: "2rem" },
      { data: "kScore12", name: "kS12", width: "2rem" },
      { data: "kScore13", name: "kS13", width: "2rem" },
      { data: "kScore14", name: "kS14", width: "2rem" },
      { data: "kScore15", name: "kS15", width: "2rem" },
      { data: "kScore16", name: "kS16", width: "2rem" },
      { data: "qualityScore", name: "score", width: "2rem" },
      { data: "grade", name: "grade", width: "2rem" },
/*      
      {
        data: "url",
        name: "url",
        width: "15rem",
        render: function(data, type, row) {
          if (type === "display") {
            if (typeof data != "undefined" && data != null && data.length > 0) {
              let url = "/" + row.clientId + "/s/" + data;
              return url;
            }
          } else {
            return data;
          }
        }
      },
      {
        data: "clientName",
        name: "clientName",
        width: "7rem"
      },
      {
        data: "parentClientName",
        name: "parentClientName",
        width: "7rem"
      },
      {
        data: "buildRequired",
        name: "buildRequired",
        className: "text-center",
        width: "5rem"
      },
      {
        data: "active",
        className: "text-center",
        name: "status",
        width: "4rem",
        render: function(data, type, row) {
          var statusMap = { N: { label: "Deactive", style: "warning" }, Y: { label: "Active", style: "success" } };
          if (type === "display") {
            return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
          } else {
            return data;
          }
        }
      },
      {
        data: "dateCreated",
        type: "date",
        name: "createDate",
        width: "6rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      },
      {
        data: "dateModified",
        type: "date",
        name: "updateDate",
        width: "6rem",
        render: function(data, type, row) {
          return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
        }
      }
*/      
    ];
  }
}

export default new SurveyScoreService();
