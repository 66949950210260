import Vue from "vue";
import { useRouter } from "vue-router";
Vue.use(useRouter);

import SurveyTaker from "../../interview-application/views/SurveyTaker.vue";

const interviewAppRoutes = [
  {
    name: "survey",
    path: "/survey-code/:surveyCode",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Interview"
    }
  },
  {
    name: "survey with unique code",
    path: "/survey-code/:surveyCode/:uniqueCode",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Interview"
    }
  },
  {
    name: "survey by url",
    path: "/:entryClientId/s/:entrySurveyUrl",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Interview"
    }
  },
  {
    name: "survey by url with unique code",
    path: "/:entryClientId/s/:entrySurveyUrl/:uniqueCode",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Interview"
    },
  },
  {
    name: "survey by url with default language",
    path: "/:entryLanguageCode/:entryClientId/s/:entrySurveyUrl",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Interview"
    }
  },
  {
    name: "survey by url with unique code and default language",
    path: "/:entryLanguageCode/:entryClientId/s/:entrySurveyUrl/:uniqueCode",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Interview"
    },
  },  
  { path: '/:pathMatch(.*)*',  name: 'not-found', component: Error },  
  { path: '/:pathMatch(.*)',  name: 'bad-not-found', component: Error }
];

export default interviewAppRoutes;
