<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use"></modal-link>Cards<span class="trademark">™</span></div>
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <chart-menu></chart-menu>
    <div class="pl-3">Saved views of your Profile and Trend charts</div>
    <saved-cards :entry-survey-id="survey.surveyId" :entry-survey-name="survey.name" :entry-client-object="clientObject" />
  </div>
</template>
<script>
import ContentMixins from "../../mixins/ContentMixins.vue";
import ChartCompanyMixins from "../../mixins/ChartCompanyMixins.vue";

import SavedCards from "../../components/SavedCards.vue";
import SavedReports from "../../components/SavedReports.vue";

import ChartMenu from "../../views/menu/ChartMenu.vue";
import AdminService from "../../services/admin.service";
import ReportService from "../../services/report.service";
import ClientService from "../../services/client.service";
import AlertService from "../../services/alert.service";

export default {
  name: "Report",
  mixins: [ContentMixins, ChartCompanyMixins],
  components: {
    ChartMenu,
    SavedCards,
  },
  props: {},
  data() {
    return {
      pageNumber: 1,
      title: "Report"
    };
  },
  methods: {
    getReportsByClientIdCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let value = response.data.data;
        this.clientObject.clientReports = [...value];
        ClientService.saveClient(this.clientObject);
        this.$root.setClientObject(this.clientObject);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      AlertService.closeAlert();
    }
  },
  created() {},
  mounted() {
    ReportService.getReportsByClientId(ClientService.getClientId(), this.getReportsByClientIdCallback, this);
  },
  computed: {}
};
</script>
