<template>
    <div class="dashboard-card-body d-flex" style="min-height: 250px;">
        <div class="mx-auto">
        <vue-skeleton-loader
            type="rec"
            :width="width"
            :height="height"
            animation="fade"
        />
        </div>
    </div>
</template>
<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: "DashboardCardSkeleton",
  mixins: [],
  components: {VueSkeletonLoader},
  data() {
    return {};
  },    
  props: {
    width: {
      type: Number,
      default: 330
    },
    height: {
      type: Number,
      default: 230
    }
  },
  methods: {},
  computed: {}, 
  created() {},
  mounted() {},
};

</script>