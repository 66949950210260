<template>
    <div class="advisor-start-period">
        <label for="advisorStartPeriodTypeId" class="pb-2 w-100 text-center">{{label}}</label>
        <div class="form-group form-inline">
            <div class="control-label pt-1 my-auto">Period</div>
            <div class="pt-1 pl-3">
                <select id="clientTimePeriodId" 
                        name="clientTimePeriodId" 
                        class="custom-select required" 
                        :disabled="this.$root.getLoading() || displayCustomDateFields" 
                        v-model="clientTimePeriodId" 
                        @change="setStartAndEndDate()">
                    <option value="-2">Choose A Time Period</option>
                    <option v-for="(option, idxPeriodOptions) in clientTimePeriods" v-bind:value="String(option.clientTimePeriodId)" v-bind:key="idxPeriodOptions">
                        {{ option.chartDisplayName }}
                    </option>
                </select>
            </div>
         </div>        
        <div class="form-group pt-2">
            <input id="customTimePeriodEnabled" name="customTimePeriodEnabled" type="checkbox" true-value="Y" false-value="N" v-model="customTimePeriodEnabled" @click="saveCustomTimePeriodEnabled($event)" />
            <span class="ml-1 mr-1">Custom Time Period</span>
            <div v-show="displayCustomDateFields" class="pt-2 d-flex">
                <div v-show="displayCustomDateFields" class="form-group form-inline">
                    <label for="customStartDate" class="mr-1">Start Date</label>
                    <datepicker 
                        format="MMM dd yyyy" 
                        v-model="customStartDate"
                        @selected="saveDateValue($event, true)" 
                        :typeable="true"
                        wrapper-class="" 
                        input-class="form-control required w-100 bg-white" 
                        name="customStartDate">
                    </datepicker>
                </div>
                <div v-show="displayCustomDateFields" class="form-group form-inline ml-2">
                    <label for="customEndDate" class="mr-1">End Date</label>
                    <datepicker 
                        format="MMM dd yyyy" 
                        v-model="customEndDate"
                        @selected="saveDateValue($event, false)" 
                        :typeable="true"
                        wrapper-class="" 
                        input-class="form-control required w-100 bg-white" 
                        name="customEndDate">
                    </datepicker>
                </div>
            </div>
        </div>
        <div v-show="displayDateRange" class="form-group form-inline">
            <label for="dateRange" class="pr-2">Date Range: </label>
            <div>
            {{ moment(customStartDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY") }} - 
                            {{ moment(customEndDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/DD/YYYY") }}
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";

export default {
    name: "CustomTimePeriod",
    components: {
        Datepicker,
    },
    mixins: [],    
    data() {
        return {
            clientTimePeriodId: 0,
            customTimePeriodEnabled: "N",
            customStartDate: new Date(),
            customEndDate: new Date(),
            clientTimePeriods: [],
            label: "Time Period"
        };
    },
    umnounted() { },
    computed: {
        displayCustomDateFields: function() {
            return this.customTimePeriodEnabled == "Y" || this.customTimePeriodEnabled == "true" || this.customTimePeriodEnabled == true;
        },     
        displayDateRange: function(){
            return this.customStartDate != null && this.customEndDate != null;
        }    
    },
    mounted() { 
        if (this.type == "current"){
            this.processCurrentTimePeriod();
        } else {
            this.processPreviousTimePeriod();
            this.label = "Previous Time Period";
        }
    },
    methods: {
        processCurrentTimePeriod: function() {
            this.clientTimePeriodId = this.advisorTimePeriod.clientTimePeriodId;
            if (typeof this.advisorTimePeriod.startDate !== "undefined") {
                this.customStartDate = this.advisorTimePeriod.startDate;
            } 
            if (typeof this.advisorTimePeriod.endDate !== "undefined") {
                this.customEndDate = this.advisorTimePeriod.endDate;
            }
            this.customTimePeriodEnabled = this.advisorTimePeriod.customTimePeriodEnabled;
            this.clientTimePeriods.push({
                clientTimePeriodId: 0,
                chartDisplayName: "Current Wave"
            });
            this.clientTimePeriods.push({
                clientTimePeriodId: -1,
                chartDisplayName: "Custom"
            });
            this.clientTimePeriods.push(...this.inputClientTimePeriods);
            if (this.customTimePeriodEnabled == "Y"){
                this.clientTimePeriodId = -1;
            }            
        },
        processPreviousTimePeriod: function() {
            this.clientTimePeriodId = this.advisorTimePeriod.previousClientTimePeriodId;
            if (typeof this.advisorTimePeriod.previousStartDate !== "undefined") {
                this.customStartDate = this.advisorTimePeriod.previousStartDate;
            } 
            if (typeof this.advisorTimePeriod.previousEndDate !== "undefined") {
                this.customEndDate = this.advisorTimePeriod.previousEndDate;
            }
            this.customTimePeriodEnabled = this.advisorTimePeriod.previousCustomTimePeriodEnabled;
            this.clientTimePeriods.push({
                clientTimePeriodId: 0,
                chartDisplayName: "Previous Wave"
            })
            this.clientTimePeriods.push({
                clientTimePeriodId: -1,
                chartDisplayName: "Custom"
            });
            this.clientTimePeriods.push(...this.inputClientTimePeriods);
            if (this.customTimePeriodEnabled == "Y"){
                this.clientTimePeriodId = -1;
            }            
        },
        setStartAndEndDate: function(event) {
          let selectedItem = this.clientTimePeriods.find(x => Number(x.clientTimePeriodId) == Number(this.clientTimePeriodId));
          if (typeof selectedItem !== "undefined") {
            if (typeof selectedItem.startDate !== "undefined") {
                this.customStartDate = selectedItem.startDate;
            }
            if (typeof selectedItem.endDate !== "undefined") {
                this.customEndDate = selectedItem.endDate;
            }
          }   
          if (Number(this.clientTimePeriodId) == 0) {
            this.customStartDate = null;
            this.customEndDate = null;
          } else if ( Number(this.clientTimePeriodId) == -1){
            this.customTimePeriodEnabled = "Y";
          }
          let timePeriod = {
            clientTimePeriodId: this.clientTimePeriodId,
            startDate: this.customStartDate,
            endDate: this.customEndDate,
            customTimePeriodEnabled: this.customTimePeriodEnabled,
            type: this.type
          }
          this.$emit("save", timePeriod);         
        },    
        saveCustomTimePeriodEnabled: function(event) {
            this.customTimePeriodEnabled = event.target.checked ? "Y" : "N";
            if (this.customTimePeriodEnabled == "Y"){
                this.clientTimePeriodId = -1;
                if (this.customStartDate == null){
                    this.customStartDate = new Date();
                }
                if (this.customEndDate == null){
                    this.customEndDate = new Date();
                }
            } else {
                this.clientTimePeriodId = 0;
                this.customStartDate = null;
                this.customEndDate = null;
            }
        },    
        saveDateValue: function(event, isStartDate) {
            let dateValue = event;
            if (isStartDate == true){
                this.customStartDate = dateValue;
            } else {
                this.customEndDate = dateValue;
            }
            this.setStartAndEndDate();
        },            
    },
    watch: {},
    props: {
        type: {
            type: String,
            default: "current"
        },
        advisorTimePeriod: {
            type: Object,
            default: function() {
                return {};
            }        
        },
        inputClientTimePeriods: {
            type: Array,
            default: function() {
                return [];
            }
        },
    }
};
</script>
