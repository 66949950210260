<template>
  <vue-modal :name="modalId" :clickToClose="false" :draggable="true" :adaptive="true" @opened="loadData" :resizable="true" :width="modalWidth" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="dashboard-modal-card modal-body mx-auto d-flex">
        <dashboard-predictive-analytics-contributions ref="predictiveAnalytics" 
            v-if="saveClientChartObject.displayType == 'dashboard-predictive-analytics'"  
            :entry-client-chart="saveClientChartObject" 
            dashboard-id="predictiveAnalytics" 
            :input-metric-options="metric_options"
            :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
            :input-is-pricing-power="true" 
            :read-only="true"
            :initial-metric-id="String(getCriteriaParameterForDashboardCard(saveClientChartObject,'metricGroupId'))" 
            :entry-survey-code="entrySurveyCode" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'predictive-analytics',false)"/>

        <dashboard-predictive-analytics-contributions ref="metricContributions" 
            v-if="saveClientChartObject.displayType == 'dashboard-metric-contributions'"  
            :entry-client-chart="saveClientChartObject" 
            dashboard-id="metricContributions" 
            :input-metric-options="metric_options"
            :time-period-id="String(saveClientChartObject.clientTimePeriodId)" 
            :input-is-pricing-power="false" 
            :read-only="true"
            :initial-metric-id="String(getCriteriaParameterForDashboardCard(saveClientChartObject,'metricGroupId'))" 
            :entry-survey-code="entrySurveyCode" 
          :dashboardLayoutCard="getDashboardLayoutCard(dashboardLayoutCards,'metric-contributions',false)"/>
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>
<script>
import DashboardPredictiveAnalyticsContributions from "../components/dashboard/DashboardPredictiveAnalyticsContributions.vue";
import DashboardCardMixins from "../mixins/DashboardCardMixins"
import MetricsService from "../services/metrics.service";
import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";

export default {
  name: "DashboardSavedCardPredictiveAnalyticsContributionsModal",
  mixins: [DashboardCardMixins],
  components: {DashboardPredictiveAnalyticsContributions},
  props: {},
  data() {
    return {
      title: "Predictive Analytics",
      entrySurveyCode: "",
    };
  },
  methods: {
    loadData: async function(){
      if (this.triggerLoadData == true){
        await this.setupDashboardCard(); 
        this.entrySurveyCode = SurveyService.getSurveyCodeBySurveyId(this.saveClientChartObject.surveyId);
        await MetricsService.getMetricGroupBySurveyCode(this.entrySurveyCode, this.getMetricGroupByClientIdCallback, this);
      }
    },
    getMetricGroupByClientIdCallback(response) {
      this.metric_options = [];
      this.metricId = "";
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        MetricsService.setMetricDropdownOptions(response.data.data,this);
      } else if (statusCode == 201) {
        //        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }    
  },
  watch: {},
  created() {},
  mounted() {},
  umnounted() {},
  computed: {
    triggerLoadData: function() {
      return this.saveClientChartObject != null && (this.saveClientChartObject.displayType == "dashboard-metric-contributions" ||
            this.saveClientChartObject.displayType == "dashboard-predictive-analytics");
    }    
  }
};
</script>
