<template>
  <div v-if="this.printReportEnabled == true" class="panel-admin-body">
    <saved-reports v-show="!isInsiderUser" ref="savedReportsView" :entry-survey-id="survey.surveyId" :entry-survey-name="survey.name" :entry-client-object="clientObject" @edit-report="initializeEditReport" @sort-reports="sortReports"/>
    <div  class="d-flex title">
      <modal-link modal-id="How to Use" :content-list="pageContentForReport"></modal-link>
      Print Report
      <div class="ml-auto">
        <span class="wizard-icon">
          <!--
          <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
      </div>
    </div>
    <div v-show="$root.getShowWizardContent()" class="wizard-content pb-2">
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">1</div>
          <div class="wizard-text">
            Enter a title and subtitle that will appear on the 
            cover page of your report. Leave blank if you don’t 
            want any titles.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">2</div>
          <div class="wizard-text">
            Click Cover Page to print your W Report™ with 
            titles and subtitles. Click Contents Page to print a 
            page by page listing that appears after the Cover 
            Page. Click End Page to print a wRatings logo as 
            the last page.       
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            Saved Cards that are Checked from your 
            Competitive Dashboard appear in your Page Order 
            column. To remove a Saved Card, click the X at the 
            top right or drag & drop the card to out of the 
            Page Order into the Card Options area.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">4</div>
          <div class="wizard-text">
            To add a Saved Card, drag & drop the Card from 
            the Card Options area to the Page Order area.
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex pt-2">
          <div class="circle">5</div>  
          <div class="wizard-text">
            Click each chart or table of a Saved Card to include 
            in your report. Each chart or table appears on its 
            own page.
          </div>      
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">6</div>  
          <div class="wizard-text">
            Click Insights to edit the text that appears in the left 
            margin of the page for a chart or table. Leaving 
            blank eliminates that area from your page.
          </div>      
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">7</div>  
          <div class="wizard-text">
            Click Print Report and wait for the full report to be 
            generated before leaving the page.
          </div>      
        </div>
      </div>
    </div>
    <div id="print-report-header" class="print-report-body" v-show="!isInsiderUser" >
      <div class="container w-100 no-gutters">
        <div class="form-row form-group">
          <label for="title" class="col-2">
            <span v-if="errorMissingTitle" style="color: red">Report Title</span>
            <span v-if="!errorMissingTitle">Report Title</span>
          </label>
          <input v-model="clientReport.title" name="title" type="text" class="form-control col-10" />
        </div>
        <div class="form-row form-group">
          <label for="subTitle" class="col-2">Report Subtitle</label>
          <input v-model="clientReport.subTitle" name="subTitle" type="text" class="form-control col-10" />
        </div>
        <div class="form-row border-top pt-4">
          <div class="report-include-options chart-criteria-border pr-2">
            <h4 class="card-title text-primary">Included Options</h4>
            <div class="container">
              <div class="row">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :id="'include-cover-page'"
                  :name="'include-cover-page'"
                  v-model="clientReport.includeCoverPage"
                  true-value="Y"
                  false-value="N"
                />
                <label class="custom-checkout" :for="'include-cover-page'">Cover Page </label>
              </div>
              <div class="row">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :id="'include-content-page'"
                  :name="'include-content-page'"
                  v-model="clientReport.includeContentPage"
                  true-value="Y"
                  false-value="N"
                />
                <label class="custom-checkout" :for="'include-content-page'">Contents Page </label>
              </div>
              <div class="row">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :id="'include-end-page'"
                  :name="'include-end-page'"
                  v-model="clientReport.includeEndPage"
                  true-value="Y"
                  false-value="N"
                />
                <label class="custom-checkout" :for="'include-end-page'">End Page </label>
              </div>
              <div class="row mt-3 mb-3 align-items-center">
                <button type="button" class="btn btn-primary mx-auto" :disabled="disablePrintReportButton" @click="initializePreviewReport">
                  <span class="align-text-bottom"><font-awesome-icon :icon="['fa', 'images']" /><span style="padding-left: 5px">Print Report</span></span>
                </button>
<!--                
                <button type="button" class="btn btn-primary mx-auto" @click="initializePreviewReport">
                  <span class="align-text-bottom"><font-awesome-icon :icon="['fa', 'images']" /><span style="padding-left: 5px">Preview Report</span></span>
                </button>
-->                
                <button v-show="displaySaveButton" :disabled="disableSaveReportButton" type="button" class="btn btn-primary mx-auto" @click="initializeSaveReport">
                  <span class="align-text-bottom"><font-awesome-icon :icon="['fa', 'floppy-disk']" /><span style="padding-left: 5px">Save Report</span></span>
                </button>
              </div>
            </div>
          </div>
          <div class="report-card-options d-flex pl-2">
            <div class="col-5">
              <h4 class="card-title text-primary">Page Order</h4>

              <div class="draggable-group-header">Current Saved Cards</div>
              <VueDraggable
                v-model="clientChartsIncludedInReport"
                :disabled="!enabled"
                id="current-saved-cards"
                class="list-group draggable-container"
                ghost-class="draggable-ghost"
                :move="checkMove"
                :sort="true"
                @start="dragging = true"
                @end="endOfDragRemoveReportPages"
                options="people"
              >
                <div class="list-group-item draggable-card m-2" v-for="element in clientChartsIncludedInReport" :key="element.clientChartId" :index="element.clientChartId">
                  <font-awesome-icon :icon="['fas', 'grip-vertical']" />
                  <span class="pl-1">{{ element.name }}</span>
                  <div class="float-right">
                    <a load-on-starup="true" href="javascript:;" class="reload" @click="$modal.show('Client Chart - ' + element.clientChartId)">
                      <font-awesome-icon :icon="['fas', 'window-close']" />
                    </a>
                  </div>
                  <a class="ml-2" style="cursor: pointer; color: #2f5eb3 !important" @click="previewClientChartWindow(element)"> View Card </a>
                  <div class="row pt-3 pl-3 border-top">
                    <div
                      class="col-12"
                      v-for="clientChartReportPage in getClientChartReportPages(element)"
                      :key="clientChartReportPage.clientChartId + '_' + clientChartReportPage.name"
                      :index="clientChartReportPage.clientChartId + '_' + clientChartReportPage.name"
                    >
                      <ClientChartToReportPageType :client-chart-object="element" :client-chart-to-report-page-type-object="clientChartReportPage" @update="updateClientChartToReportPageTypeObject"/>
                    </div>
                  </div>
                  <modal
                    :modal-id="'Client Chart - ' + element.clientChartId"
                    :modal-title="'Remove From Report'"
                    :modal-content="'Are you sure you remove ' + element.name + ' from the report?'"
                    :modal-result-id="element.clientChartId"
                    :include-confirmation="true"
                    @confirm="removeFromReport(element)"
                  />
                </div>
              </VueDraggable>
              <div class="text-center" v-show="emptyClientChartsIncludedInReport">Drag & Drop here to add card to the report</div>
            </div>
            <div class="col d-flex flex-column align-items-center pt-5">
              <div class="mx-auto mb-3" style="color: #2f5eb3">
                <font-awesome-icon :icon="['fas', 'arrow-alt-circle-right']" size="3x" />
              </div>
              <label>Drag & Drop saved cards to add or remove them from the report</label>
              <div class="mx-auto" style="color: #2f5eb3">
                <font-awesome-icon :icon="['fas', 'arrow-alt-circle-left']" size="3x" />
              </div>
            </div>
            <div class="col-5">
              <h4 class="card-title text-primary">Card Options</h4>
              <label>&nbsp;</label>
              <div class="draggable-group-header">Available Saved Cards</div>
              <VueDraggable
                v-model="availableClientCharts"
                :disabled="!enabled"
                id="available-saved-cards"
                class="list-group draggable-container"
                ghost-class="draggable-ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="endOfDragAddReportPages"
                options="people"
              >
                <div class="list-group-item draggable-card m-2" v-for="element in availableClientCharts" :key="element.clientChartId" :index="element.clientChartId">
                  <font-awesome-icon :icon="['fas', 'grip-vertical']" />
                  <span class="pl-1">{{ element.name }}</span>
                  <a class="float-right ml-2" style="cursor: pointer; color: #2f5eb3 !important" @click="previewClientChartWindow(element)"> View Card </a>
                </div>
              </VueDraggable>
              <div class="text-center" v-show="availableClientCharts.length <= 0">Drag & Drop here to remove card from the report</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isInsiderUser" class="overlay">
      <img class="overlay-inner" v-show="isInsiderUser" src="../assets/img/overlap.png"  style="width: 50%"/>
    </div>
    <div class="w-100" v-show="printReportUrl != ''">
      <iframe class="hide-print-report" width="100%" height="100%" :src="printReportUrl"></iframe>
    </div>
    <dashboard-moat-comps-modal :modal-id="'dashboard-moat-comps'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-needs-profile-modal :modal-id="'needs-profile'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-growth-sequence-modal :modal-id="'growth-sequence'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-winners-map-modal :modal-id="'winners-map'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-nps-trends-modal :modal-id="'nps-trends'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-competitive-trends-modal :modal-id="'dashboard-competitive-trends'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-competitive-rank-modal :modal-id="'competitive-rank'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-respondent-map-modal :modal-id="'respondent-map'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-performance-trends-modal :modal-id="'performance-trends'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-saved-card-predictive-analytics-contributions-modal :modal-id="'dashboard-predictive-analytics'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>
    <dashboard-saved-card-predictive-analytics-contributions-modal :modal-id="'dashboard-metric-contributions'" :modal-title="dashboardCardModalTitle" :client-object="dashboardClientObject" :save-client-chart-object="dashboardSavedClientChart"/>

    <PreviewReport :modal-name="'preview-report'" :display-save-button="displaySaveButton" 
    :display-print-button="displayPrintButton" :preview-report-html="previewReportHTML" 
     :key="displayPreviewModal"
      @print-to-pdf="executePrintReportToPDF" @save-to-pdf="executeSaveReportToPDF"
      @print-to-pptx="executePrintReportToPPTX" @save-to-pptx="executeSaveReportToPPTX"/>
    <RenderClientChart ref="renderClientChart" :client-charts-included-in-report="clientChartsIncludedInReport" :modal-name="'render-client-chart'" />
    <insights
      ref="insightsChart"
      :modal-name="'insightsChart'"
      :report-page-type-name="insightReportPageName"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />
  </div>
  <div v-else class="print-report-body panel-admin-body no-permission-list" style="font-size: 14px; color: red">
    Print Report is not supported on the device you are using. Please use a desktop device
  </div>
</template>
<script>
import $ from "jquery";
import { isMobile } from "mobile-device-detect";
import ChartMenu from "./menu/ChartMenu.vue";
import AdminMixins from "../mixins/AdminMixins.vue";

import DataTableMixins from "../mixins/DataTableMixins.vue";
import AdminService from "../services/admin.service";
import ChartService from "../services/chart.service";
import ReportService from "../services/report.service";
import ClientService from "../services/client.service";
import ContentService from "../services/content.service";
import AlertService from "../services/alert.service";
import SurveyService from "../services/survey.service";
import ClientChartToReportPageType from "./print-report/ClientChartToReportPageType.vue";
import SavedReports from "../components/SavedReports.vue";
import RenderClientChart from "../modals/RenderClientChart.vue";
import PreviewReport from "../modals/PreviewReport.vue";
import Insights from "../modals/Insights.vue";
import DashboardNeedsProfileModal from "../modals/DashboardNeedsProfileModal.vue";
import DashboardGrowthSequenceModal from "../modals/DashboardGrowthSequenceModal.vue";
import DashboardWinnersMapModal from "../modals/DashboardWinnersMapModal.vue";
import DashboardNpsTrendsModal from "../modals/DashboardNpsTrendsModal.vue";
import DashboardCompetitiveTrendsModal from "../modals/DashboardCompetitiveTrendsModal.vue";
import DashboardCompetitiveRankModal from "../modals/DashboardCompetitiveRankModal.vue";
import DashboardRespondentMapModal from "../modals/DashboardRespondentMapModal.vue";
import DashboardPerformanceTrendsModal from "../modals/DashboardPerformanceTrendsModal.vue";
import DashboardSavedCardPredictiveAnalyticsContributionsModal from "../modals/DashboardSavedCardPredictiveAnalyticsContributionsModal.vue";
import DashboardMoatCompsModal from "../modals/DashboardMoatCompsModal.vue";
import { reactive, toRaw } from 'vue'

import { VueDraggable } from 'vue-draggable-plus';
export default {
  name: "PrintReport",
  mixins: [AdminMixins,DataTableMixins],
  components: {
    VueDraggable,
    PreviewReport,
    SavedReports,
    RenderClientChart,
    ClientChartToReportPageType,
    Insights,
    DashboardNeedsProfileModal,DashboardGrowthSequenceModal,DashboardWinnersMapModal,
    DashboardNpsTrendsModal,DashboardCompetitiveTrendsModal,DashboardCompetitiveRankModal,
    DashboardRespondentMapModal,DashboardPerformanceTrendsModal,
    DashboardSavedCardPredictiveAnalyticsContributionsModal,
    DashboardMoatCompsModal
  },
  props: {
    initialClientReportId: {
      type: Number,
      default: 0
    },
    initialMode: {
      type: String,
      default: "view"
    },
    clientChartIdsInput: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  data() {
    return {
      survey: {},
      mode: "download",
      printReportExtension: ".pdf",
      dashboardCardModalTitle: "",
      dashboardSavedClientChart: {},
      dashboardClientObject: {},
      reportGenerationModalContent: ReportService.getReportGenerationMessage(),
      saveReport: false,
      previewReport: true,
      displaySaveButton: true,
      displayPrintButton: true,
      previewReportHTML: "",
      showTemplate: false,
      printReportEnabled: !isMobile,
      pdfGenerationInProgress: false,
      pollingMax: 300,
      pageNumber: 7,
      numberOfPollingIteration: 0,
      clientChartsIncludedInReport: [],
      availableClientCharts: [],
      enabled: true,
      errorMissingTitle: false,
      dragging: false,
      reportPageTypes: [],
      clientReport: ClientService.getDefaultClientReport(),
      printReportUrl: "",
      insightReportPageName: "",
      checkReportStatusTimeout: null,
      pageContentForReport: [],
      displayPreviewModal: true,
      disablePrintReportButton: true,
      disableSaveReportButton: true,
    };
  },
  methods: {
    updateClientChartToReportPageTypeObject(index, name, object) {
      const clientChartReportPageIndex = this.clientReport.clientChartReportPages.findIndex(x => x.clientChartId == index && x.name == name);
      if (clientChartReportPageIndex !== -1) {
        let updateClientChartReportPageObject = toRaw(object);
        this.$set(this.clientReport.clientChartReportPages, clientChartReportPageIndex, updateClientChartReportPageObject);
      } 
    },    
    displayInsights(reportPageInput, clientChart, reportPageName) {
      this.insightReportPageName = reportPageName;
      this.$refs.insightsChart.reportPageInput = reportPageInput;
      this.$refs.insightsChart.initializeInsights(clientChart);
      this.$modal.show("insightsChart");
    },
    saveClientChart(value, message) {},
    saveAllReportPages() {},
    updateReportPages(value) {},
    checkMove: function(e) {},
    endOfDragAddReportPages: function(event) {
      let newClientChartReportPageObject = toRaw(event.clonedData);      
      this.clientChartsIncludedInReport.push(newClientChartReportPageObject); 
      this.addClientCardToReportPages(newClientChartReportPageObject);
      var match = this.availableClientCharts.find(x => Number(x.clientChartId) === Number(newClientChartReportPageObject.clientChartId));
      if (typeof match !== "undefined") {
        this.availableClientCharts.splice($.inArray(match, this.availableClientCharts), 1);
      }      
      this.dragging = false;
    },
    endOfDragRemoveReportPages(event) {
      let element = toRaw(event.clonedData);      
      this.removeClientCardToReportPages(element);
      this.availableClientCharts.push(element);
      this.availableClientCharts.sort(ReportService.compareValues("clientChartId", "asc"));      
      this.clientChartsIncludedInReport.splice($.inArray(element, this.clientChartsIncludedInReport), 1);      
      this.dragging = true;
    },
    removeFromReport(element) {
      this.clientChartsIncludedInReport.splice($.inArray(element, this.clientChartsIncludedInReport), 1);
      this.removeClientCardToReportPages(element);
      this.availableClientCharts.push(element);
    },
    reset() {
      ReportService.clearReportAutoChartGenerate();
      this.pdfGenerationInProgress = false;
      this.numberOfPollingIteration = 0;
      this.reportGenerationModalContent = ReportService.getReportGenerationMessage();
      this.errorMissingTitle = false;
    },
    async reOrderPages() {
      if (this.clientChartsIncludedInReport.length > 0) {
        let sortedReportPages = [];
        let vm = this;
        this.clientChartsIncludedInReport.forEach(function(clientChart) {
          let reportPagesByClientChartId = vm.clientReport.clientChartReportPages.filter(
            reportClientChart => Number(reportClientChart.clientChartId) == Number(clientChart.clientChartId)
          );
          sortedReportPages.push(...reportPagesByClientChartId);
        });
        this.clientReport.clientChartReportPages = [...sortedReportPages];
      }
    },
    async checkReportName() {
      if (this.clientReport.title == "") {
        AdminService.displayErrorMessage({ text: "A title is required for the report. Please enter a title." });
        this.errorMissingTitle = true;
        return false;
      } else {
        this.errorMissingTitle = false;
      }
      ReportService.checkReportName(this.clientReport, this.checkReportNameCallback, this);
    },
    async checkReportNameCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.executePreviewReport();
        this.errorMissingTitle = false;
        AlertService.closeAlert();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
        this.errorMissingTitle = true;
      }
    },
    async generateReport() {
      this.reset();
      AlertService.showInProgressMessage(this.reportGenerationModalContent,"Report generation in progress....");
      this.reOrderPages();
      this.clientChartsIncludedInReport.forEach(function(clientChart) {
        ReportService.addReportClientChart(clientChart);
      });
      if (this.clientChartsIncludedInReport.length > 0) {
       ReportService.setReportAutoChartGenerateStatus("generate");
        if (!(await this.$auth.isAuthenticated())) {
          this.$router.push({ path: "/login" });
          return;
        }
        this.printReportUrl = ReportService.getClientChartLink(this.clientChartsIncludedInReport[0], this).href;
        let vm = this;
        this.checkReportStatusTimeout = function() {
          if (vm.pdfGenerationInProgress == false || vm.pdfGenerationInProgress == "false") {
            vm.checkReportGenerateStatus();
            setTimeout(vm.checkReportStatusTimeout, 1000);
          }
        };
        setTimeout(vm.checkReportStatusTimeout, 1000);
      }
    },
    executePrintReportToPDF(){
      this.printReportExtension = ".pdf"
      this.previewReport = false;
      this.saveReport = false;
      this.clientReport.clientReportId = 0;
      ReportService.generateReportPDF(this.clientReport, this.saveReport, this.downloadItemCallback, this);
    },
    executePrintReportToPPTX: function(){
      this.printReportExtension = ".pptx"
      this.previewReport = false;
      this.saveReport = false;
      this.clientReport.clientReportId = 0;
      ReportService.generatePowerPointReport(this.clientReport, this.saveReport, this.downloadItemCallback, this);
    },
    executePreviewReport(){
      this.previewReport = true;
      this.generateReport();
    },
    initializePreviewReport(){
      this.previewReport = true;
      if (this.mode == 'edit'){
        this.mode = 'download';
        this.displayPreviewModal=false;
        this.executePreviewReport();
      } else {
        this.checkReportName();
        this.displayPreviewModal=true;
      }
    },
    initializeSaveReport(){
      this.saveReport = true;
      this.displayPreviewModal=true;
      this.executePreviewReport();
      this.generateReport();
    },
    executeSaveReportToPDF(){
      this.previewReport = false;
      this.saveReport = true;
      ReportService.generateReportPDF(this.clientReport, this.saveReport, this.downloadItemCallback, this);
    },
    executeSaveReportToPPTX(){
      this.previewReport = false;
      this.saveReport = true;
      ReportService.generatePowerPointReport(this.clientReport, this.saveReport, this.downloadItemCallback, this);
    },
    initializeEditReport(result){
      this.mode = 'edit';
      this.displaySaveButton = true;
      this.clientReport = JSON.parse(JSON.stringify(result));
      this.clientChartsIncludedInReport = [];
      this.loadSavedReport();
      this.availableClientCharts = this.getClientChartsBySurvey().filter(o1 => !this.clientChartsIncludedInReport.some(o2 => o1.clientChartId === o2.clientChartId));
      $("html, body").animate({scrollTop: $("#print-report-header").offset().top},500);      
    },
    sortReports: function(sortBy) {
      if (sortBy == "title-ascending") {
        this.clientObject.clientReports.sort(ReportService.compareValues("title", "asc"));
      } else if (sortBy == "title-descending") {
        this.clientObject.clientReports.sort(ReportService.compareValues("title", "desc"));
      } else if (sortBy == "organization-ascending") {
        this.clientObject.clientReports.sort(ReportService.compareValues("clientName", "asc"));
      } else if (sortBy == "organization-descending") {
        this.clientObject.clientReports.sort(ReportService.compareValues("clientName", "asc"));
      } else if (sortBy == "date-added-newest") {
        this.clientObject.clientReports.sort(ReportService.compareValues("dateCreated", "desc"));
      } else if (sortBy == "date-added-oldest") {
        this.clientObject.clientReports.sort(ReportService.compareValues("dateCreated", "asc"));
      }      
    },    
    previewReportCallback(response){
      this.$root.processServerResponse();
      AlertService.closeAlert();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.previewReportHTML = response.data.data.previewReportHTML;
        this.previewReport = false;
        this.$modal.show("preview-report");
        AdminService.displaySuccessMessage({ text: response.data.message, duration: 2000  });
        this.printReportUrl = "";
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
        this.errorMissingTitle = true;
      }
    },
    checkReportGenerateStatus: function() {
      if (this.pdfGenerationInProgress == true || this.pdfGenerationInProgress == "true") {
        return;
      }
      if (ReportService.getReportAutoChartGenerateStatus() == "" || this.numberOfPollingIteration > this.pollingMax) {
        this.pdfGenerationInProgress = true;
        clearTimeout(this.checkReportStatusTimeout);
        if (this.previewReport){
          ReportService.previewReport(this.clientReport, this.previewReportCallback, this);
        } else {
          ReportService.generateReportPDF(this.clientReport, this.saveReport, this.downloadItemCallback, this);
        }
      
      } else {
        this.numberOfPollingIteration = this.numberOfPollingIteration + 1;
      }
    },
    getDownloadFilename() {
      let filename = this.clientReport.title;
      if (this.clientReport.subTitle != "") {
        filename += "-" + this.clientReport.subTitle;
      }
      filename += this.printReportExtension;
      return filename;
    },
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      if (!this.saveReport){
        const blob = new Blob([response.data], { type: "application/pdf" });
        this.clickLink(blob);
        this.setModalLoading(false);
        AdminService.displaySuccessMessage({ text: "Download Complete", duration: 2000 });
        this.reportGenerationModalContent = ReportService.getReportDownloadCompleteMessage();
      } else {
        AdminService.displaySuccessMessage({ text: "Report saved. Go to The W Report page to download", duration: 2000 });
        this.reportGenerationModalContent = ReportService.getReportSavedCompleteMessage();
      }
      this.saveReport = false;
      this.printReportUrl = "";
      this.$root.processServerRequest("Reloading generated reports and resetting for a new report");      
      ReportService.getReportsByClientId(ClientService.getClientId(), this.getReportsByClientIdCallback, this);
      clearTimeout(this.checkReportStatusTimeout);
    },
    getReportsByClientIdCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        let value = response.data.data;
        this.clientObject.clientReports = [...value];
        ClientService.saveClient(this.clientObject);
        this.$root.setClientObject(this.clientObject);
        this.loadData();
        // Rebuild Generated Reports
        this.$refs.savedReportsView.buildSavedReportTable();
        // Reset to latest
        this.clientReport = ClientService.getDefaultClientReport();
        this.reset();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getChartType(clientChart) {
      return ChartService.findChartTypeById(clientChart.chartTypeId);
    },
    renderClientChart(clientChart) {},
    openClientChartWindow(clientChart) {
      ReportService.openClientChartWindow(clientChart, true, this);
    },
    previewClientChartWindow(clientChart) {
      this.reset();
      if (ReportService.isSavedDashboardCard(clientChart.chartTypeId)){
        this.dashboardSavedClientChart = JSON.parse(JSON.stringify(clientChart));
        this.dashboardCardModalTitle = clientChart.name;
        this.dashboardClientObject = {};
        this.$modal.show(clientChart.displayType);
      } else {
        ReportService.setReportAutoChartGenerateStatus("preview");
        ReportService.addReportClientChart(clientChart);
        ReportService.openClientChartWindow(clientChart, false, this);
      }
    },
    getClientChartReportPages(element) {
      let clientChartReportPages = this.clientReport.clientChartReportPages.filter(clientChartReportPage => Number(clientChartReportPage.clientChartId) == Number(element.clientChartId));
      return clientChartReportPages.sort(ReportService.compareValues("sequenceNumber", "asc"));
    },
    getReportPageTypesCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.reportPageTypes = response.data.data;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    addClientCardToReportPages(clientChart) {
      let reportTypesByChartId = this.reportPageTypes
        .filter(reportPageType => Number(reportPageType.chartType.chartTypeId) == Number(clientChart.chartTypeId))
        .filter(reportPageType => reportPageType.displayType == clientChart.displayType);
      let vm = this;
      reportTypesByChartId = toRaw(reportTypesByChartId);
      reportTypesByChartId.forEach(function(reportType, index) {
        let clientChartToReportPageType = {
          clientChartId: clientChart.clientChartId,
          name: reportType.name,
          sequenceNumber: reportType.sequenceNumber,
          include: true
        };
        vm.clientReport.clientChartReportPages.push(clientChartToReportPageType);
      });
    },
    removeClientCardToReportPages(clientChart) {
      let clientCardsToRemove = this.clientReport.clientChartReportPages.filter(x => x.clientChartId == clientChart.clientChartId);
      clientCardsToRemove= toRaw(clientCardsToRemove);
      let vm = this;
      clientCardsToRemove.forEach(function(clientCardToRemove, index) {
        vm.clientReport.clientChartReportPages.splice($.inArray(clientCardToRemove, vm.clientReport.clientChartReportPages), 1);
      });
    },
    loadData() {
      this.survey = SurveyService.getSurvey();
      this.reset();
      this.clientReport.clientId = ClientService.getClientId();
      this.clientReport.clientChartReportPages = [];
      this.clientChartsIncludedInReport = [];
      this.availableClientCharts = this.getClientChartsBySurvey().filter(o1 => !this.clientChartsIncludedInReport.some(o2 => o1.clientChartId === o2.clientChartId));
    },
    getClientChartsBySurvey: function() {
      let originalClientCharts = this.clientObject.clientCharts;
      let surveyIdToCompare = SurveyService.getSurveyId();
      if (typeof originalClientCharts != "undefined") {
        return originalClientCharts.filter(clientChartInput => Number(clientChartInput.surveyId) == Number(surveyIdToCompare));
      } else {
        return {};
      }
    },
    getPageContentByReportCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForReport = response.data.data;
      }
    },
    loadSavedReport(){
      let processedClientChartIds = [];
      let processesReportPageTypesId = [];
      for (let i = 0; i < this.clientReport.savedClientChartReportPages.length; i++) {
          let clientChartId = this.clientReport.savedClientChartReportPages[i].clientChartId;
          let clientChart = {};
          clientChart.clientChartId =clientChartId
          clientChart.name = this.clientReport.savedClientChartReportPages[i].name;
          clientChart.include = this.clientReport.savedClientChartReportPages[i].include;
          this.clientReport.clientChartReportPages.push(clientChart);
          processesReportPageTypesId.push(this.clientReport.savedClientChartReportPages[i].reportPageTypeId);
          if (!processedClientChartIds.includes(clientChartId)){
            processedClientChartIds.push(clientChartId);
          }
      }
      for (let i = 0; i < processedClientChartIds.length; i++) {
        // Loop through all saved cards and create remaining report page type records for that client chart with the include value of false
        let clientChartId = processedClientChartIds[i];
        var clientChart = this.clientObject.clientCharts.find(x => x.clientChartId === clientChartId);
        if (typeof clientChart !== "undefined") {
          this.clientChartsIncludedInReport.push(clientChart);
          let reportTypesByChartId = this.reportPageTypes
          .filter(reportPageType => Number(reportPageType.chartType.chartTypeId) == Number(clientChart.chartTypeId))
          .filter(reportPageType => reportPageType.displayType == clientChart.displayType);

          let vm = this;
          reportTypesByChartId.forEach(function(reportType, index) {
            if (!processesReportPageTypesId.includes(reportType.reportPageTypeId)){
              let clientChartToReportPageType = {
                clientChartId: clientChart.clientChartId,
                name: reportType.name,
                sequenceNumber: reportType.sequenceNumber,
                include: false
              };
              vm.clientReport.clientChartReportPages.push(clientChartToReportPageType);
            }
          });        
        }
      }
    }
  },
  created() {},
  async mounted() {
    await ContentService.getPageContent("Report", this.getPageContentByReportCallBack, this);
    await ReportService.getReportPageTypes(this.getReportPageTypesCallback, this);
    this.mode = this.initialMode;
    let clientCharts = this.clientObject.clientCharts;
    if (this.mode == 'edit'){
      if (this.initialClientReportId != 0) {
        let match = this.clientObject.clientReports.find(x => x.clientReportId === this.initialClientReportId);
        this.clientReport = JSON.parse(JSON.stringify(match));
      }
      this.loadSavedReport();
    } else {
      this.clientChartsIncludedInReport = [];
      for (let i = 0; i < this.clientChartIdsInput.length; i++) {
        let clientChartId = this.clientChartIdsInput[i];
        let clientChart = clientCharts.find(x => x.clientChartId === clientChartId);
        if (typeof clientChart !== "undefined") {
          this.clientChartsIncludedInReport.push(clientChart);
        }
      }
    }
    this.availableClientCharts = clientCharts.filter(o1 => !this.clientChartsIncludedInReport.some(o2 => o1.clientChartId === o2.clientChartId));
    if (this.availableClientCharts.length == 0) {
      this.availableClientCharts = this.getClientChartsBySurvey().filter(o1 => !this.clientChartsIncludedInReport.some(o2 => o1.clientChartId === o2.clientChartId));
    }
    if (this.mode != 'edit'){
      let vm = this;
      this.clientChartsIncludedInReport.forEach(function(clientChart, index) {
        vm.addClientCardToReportPages(clientChart);
      });
    }
    this.$root.setShowMenuIcon(true);
  },
  watch: {
    surveyCode: function() {
      this.loadData();
    },
    clientChartsIncludedInReport: function() {
      if(this.emptyClientChartsIncludedInReport) {
        this.disablePrintReportButton = true;
        this.disableSaveReportButton = true;
      } else {
        this.disablePrintReportButton = false;
        this.disableSaveReportButton = false;
      }
    }
  },
  computed: {
    emptyClientChartsIncludedInReport: function() {
      return this.clientChartsIncludedInReport.length <= 0;
    }, 
  },
  beforeRouteLeave: function(to, from, next) {
    ReportService.clearReportAutoChartGenerate();
    next();
  }
};
</script>
