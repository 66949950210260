import $ from "jquery";
import jQuery from "jquery";

import ValidatorService from "./validator.service";

class FormService {
  setupForm(formElement) {
    jQuery.validator.setDefaults({
      debug: true,
      success: "valid"
    });
    jQuery.validator.addMethod(
      "notEqual",
      function(value, element, param) {
        return this.optional(element) || value != param;
      },
      "Please choose a value!"
    );
    jQuery.validator.addMethod("emailCheck", ValidatorService.emailCheck, ValidatorService.emailErrorMessage);
  }
  getBaseValidator() {
    return {
      highlight: function(element) {
        $(element)
          .closest(".form-group")
          .removeClass("has-success")
          .addClass("has-error");
      },
      unhighlight: function(element) {
        $(element)
          .closest(".form-group")
          .removeClass("has-error")
          .addClass("has-success");
      },
      errorElement: "small",
      errorClass: "form-text text-danger",
      errorPlacement: function(error, element) {
        if (element.length) {
          var targetParent = $(element).parent();
          if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
            targetParent = targetParent.parent();
          }
          targetParent.append(error);
        }
      }
    };
  }
}

export default new FormService();
