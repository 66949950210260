<template>
  <div></div>
</template>
<script>
import CompanyMenu from "../views/menu/CompanyMenu.vue";
import ContentMixins from "./ContentMixins.vue";
import ViewMixins from "./ViewMixins.vue";
import GlobalMixins from "./GlobalMixins.vue";
import ChartService from "../services/chart.service";
import AdminService from "../services/admin.service";

export default {
  name: "CompanyMixins",
  mixins: [ContentMixins, ViewMixins, GlobalMixins],
  components: {
    /* eslint-disable vue/no-unused-components */
    CompanyMenu
  },
  data() {
    return {};
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  },
  created() {},
  methods: {
    async loadYearOptions() {
      await ChartService.getYearOptions(this.baseUrl, this.loadYearOptionsCallback);
      this.display = true;
    },

    loadYearOptionsCallback: function(data) {
      this.year_options = data;
    }
  },
  watch: {
    clientId: function() {
      this.loadData();
    }
  },
  mounted() {
    if (this.clientId != 0) {
      this.loadData();
    }
    this.$root.setShowMenuIcon(true);  
  },
  computed: {
    permissionToView: function() {
      return (this.isAdvancedUser || this.isAdmin) && !this.isW5OUser;
    },    
  }
};
</script>
