<template>
  <div v-if="isAdmin" class="playground panel-admin-body">
    <div class="title">
      <div class="d-flex">
        {{ title }}
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> <h4>Info Message</h4> </div>
      <div class="col-4"> <h4>Success Message</h4> </div>
      <div class="col-4"> <h4>Error Message</h4> </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> 
         <div class="d-flex">
          <button type="button" class="btn btn-primary ml-2" @click="displayInfoMessage">Current Info Message</button>
          <button type="button" class="btn btn-primary ml-2" @click="displayNewInfoMessage">New Info Message</button>
         </div>  
      </div>
      <div class="col-4"> 
         <div class="d-flex">
          <button type="button" class="btn btn-primary ml-2" @click="displaySuccessMessage">Current Success Message</button>
          <button type="button" class="btn btn-primary ml-2" @click="displayNewSuccessMessage">New Success Message</button>
         </div>  
      </div>
      <div class="col-4"> 
         <div class="d-flex">
          <button type="button" class="btn btn-primary ml-2" @click="displayErrorMessage">Current Error Message</button>
          <button type="button" class="btn btn-primary ml-2" @click="displayNewErrorMessage">New Error Message</button>
         </div>  
      </div>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>
<script>
import ViewMixins from "../mixins/ViewMixins.vue";
import AdminMixins from "../mixins/AdminMixins.vue";
import AdminService from "../services/admin.service";
import Swal from 'sweetalert2'


export default {
  name: "Playground",
  mixins: [AdminMixins, ViewMixins],
 components: {},
  props: {},
  data() {
    return {
      title: "At the Playground",
      baseToastConfiguration: {
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      }
    };
  },
  methods: {
    displayNewInfoMessage: function() {
      const Toast = Swal.mixin(this.baseToastConfiguration);
      Toast.fire({
        icon: "info",
        title: "Refreshing page please stand by......"
      });      
    },
    displayInfoMessage: function() {
      AdminService.displayInfoMessage({ text: "Refreshing page please stand by......" });      
    },
    displayNewSuccessMessage: function() {
      const Toast = Swal.mixin(this.baseToastConfiguration);
      Toast.fire({
        icon: "success",
        title: "Report saved. Go to The W Report page to download"
      });      
    },    
    displaySuccessMessage: function() {
      AdminService.displaySuccessMessage({ text: "Report saved. Go to The W Report page to download", duration: -1 },true);
    },
    displayNewErrorMessage: function() {
      const Toast = Swal.mixin(this.baseToastConfiguration);
      Toast.fire({
        icon: "error",
        title: "Error saving report. Please try again"
      });            
    },    
    displayErrorMessage: function() {
      AdminService.displayErrorMessage({ text: "Error saving report. Please try again" });
    },
  },
  created() {},
  mounted() {}
};
</script>
<style lang="scss">
</style>
