<template>
  <div class="w-100">
    <div :class="containerId">
      <table id="dataTable" :class="tableClass" cellspacing="0" width="100%"></table>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import TableService from "../../services/table.service";
import AlertService from "../../services/alert.service";
import PrintAndCopyService from "../../services/print.and.copy.service";

export default {
  name: "ChartDataTable",
  components: {},  
  data() {
    return {
      configuration: {
        searching: false,
        paging: false,
        responsive: true,
        fixedColumns: true,
        autoWidth: false,
        info: false,
        order: [],
        colReorder: true,
        dom: '<"w-100"<"chart-header-container d-flex"><B>>frtip'
      },
      columns: [],
      rows: [],
      tableId: "dataTableVertical",
      dataTable: null
    };
  },
  props: {
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isSavedCard: {
        type: Boolean,
        default: false
    },    
    tableWidth: {
      type: String,
      default: "auto"
    },
    tableTitle: {
      type: String,
      default: ""
    },
    displayDirection: {
      type: String,
      default: "vertical"
    },
    rowStartPosition: {
      type: Number,
      default: 1
    },
    tableClass: {
      type: String,
      default: "table display wrap row-border table-hover w-100"
    },
    containerId: {
      type: String,
      default: ""
    },
    chartData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    dataContainerId: {
      type: String,
      default: ""
    },
    insightModalId: {
      type: String,
      default: ""
    },
    reportPageTypeName: {
      type: String,
      default: ""
    },
    columnDefs: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isInsiderUser: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async snapChart(displayLegend, snapContainerId) {
      PrintAndCopyService.initializeSnapChart(displayLegend, snapContainerId, this); 
    },
    clearTable() {
      var parent = $("body").find("#" + this.dataContainerId);

      if (parent.length >= 1) {
        this.dataTable = parent.find("." + this.containerId);
        this.dataTable.html("");
        var newTable = $("<table/>")
          .attr("id", this.tableId)
          .addClass(this.tableClass)
          .attr("cellspacing", "0")
          .attr("width", this.tableWidth);
        this.dataTable.append(newTable);
        this.columns = [];
        this.rows = [];
      }
    },
    displayTableHorizontal() {
      this.clearTable();

      for (var companyInstance = 1; companyInstance < this.chartData.cols.length; companyInstance++) {
        var id = this.chartData.cols[companyInstance].id;
        if (id !== "cb1") {
          var companyName = this.chartData.cols[companyInstance].label;
          this.rows[companyInstance] = [companyName];
        }
      }

      this.columns.push({ title: "Company" });
      for (var i = 0; i < this.chartData.rows.length; i++) {
        var dataRow = this.chartData.rows[i].c;
        var moat = dataRow[0].v;
        var include = dataRow[0].include;
        if (include) {
          this.columns.push({ title: moat });
        }
      }
      var rowTotal = this.rows.length;

      for (var indexRows = 0; indexRows < this.chartData.rows.length; indexRows++) {
        var chartDataRow = this.chartData.rows[indexRows].c;
        for (var t = 1; t < this.rows.length; t++) {
          if (typeof chartDataRow[t] !== "undefined") {
            var value = chartDataRow[t].v;
            var includeData = chartDataRow[t].include;
            if (includeData) {
              this.rows[t].push([value]);
            }
          }
        }
      }
      // Create the table
      $.extend(this.configuration, { columns: this.columns });
      if (this.columnDefs.length > 0) {
        $.extend(this.configuration, { columnDefs: this.columnDefs });
      }
      var table = this.dataTable.find("#" + this.tableId);
      var dataTable = table.DataTable(this.configuration);
      for (var position = this.rowStartPosition; position < this.rows.length; position++) {
        dataTable.row.add(this.rows[position]).draw(true);
      }
    },
    displayTableVertical() {
      this.clearTable();
      for (var i = 0; i < this.chartData.cols.length; i++) {
        var need = this.chartData.cols[i].label;
        var id = this.chartData.cols[i].id;
        if (id !== "cb1") {
          this.columns.push({ title: need });
        }
      }
      $.extend(this.configuration, { columns: this.columns });
      if (this.columnDefs.length > 0) {
        $.extend(this.configuration, { columnDefs: this.columnDefs });
      }
      var table = this.dataTable.find("#" + this.tableId);
      var dataTable = table.DataTable(this.configuration);
      for (var dataIndexRows = 0; dataIndexRows < this.chartData.rows.length; dataIndexRows++) {
        var dataRow = this.chartData.rows[dataIndexRows].c;
        for (var t = 0; t < dataRow.length; t++) {
          var include = dataRow[t].include;
          if (include) {
            this.rows.push(dataRow[t].v);
          }
        }
        if (this.rows.length > 0) {
          dataTable.row.add(this.rows).draw(true);
        }
        this.rows = [];
      }
    },
    displayTable() {
      this.setButtons();
      this.setHeaderConfiguration();
      this.setRowConfiguration();
      if (this.displayDirection == "horizontal") {
        this.tableId = "dataTableHorizontal" + this.containerId;
        this.displayTableHorizontal();
      } else {
        this.tableId = "dataTableVertical_" + this.containerId;
        this.displayTableVertical();
      }
      const vm = this;
      $(".popUp").click(function() {
        let needName = $(this).data("modal-name");
        AlertService.showModalMessage(needName, vm.contentList);
      });
      $(".chart-data").on("draw.dt", function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
      if (this.tableTitle.length > 0) {
        var table = this.dataTable.find("#" + this.tableId);
        table
          .parent()
          .find(".chart-header-container")
          .html(this.tableTitle);
      }
      this.buildResponsiveTable();
    },
    getTableData(){
      var table = this.dataTable.find("#" + this.tableId).DataTable();
      return TableService.getTableData(table);
    },
    buildResponsiveTable() {
      if (this.configuration.responsive) {
        const vm = this;
        setTimeout(function() {
          var table = vm.dataTable.find("#" + vm.tableId);
          var responsiveTable = table.DataTable();
          if (typeof responsiveTable != "undefined" && typeof responsiveTable.responsive != "undefined"){
            responsiveTable.responsive.rebuild();
            responsiveTable.responsive.recalc();
        }
        }, 500);
      }
    },
    setRowConfiguration() {
      if (this.dataContainerId == "nps_data_container" || 
          this.dataContainerId == "by_value_nps_data_container" || 
            this.dataContainerId == "nps_data_container") {
        $.extend(this.configuration, {
          rowCallback: function(row, data, displayNum, displayIndex, dataIndex) {
            if (displayIndex == 2) {
              TableService.setRowColor($(row), "#008000");
            } else if (displayIndex == 3) {
              TableService.setRowColor($(row), "#B08E00");
            } else if (displayIndex == 4) {
              TableService.setRowColor($(row), "#C00000");
            }
          }
        });
      } else if( this.dataContainerId == "base-rank-data-container"){
        $.extend(this.configuration, {
          rowCallback: function(row, data, displayNum, displayIndex, dataIndex) {
            let skipRow = false;
            for (let indexColumns = 0; indexColumns < data.length; indexColumns++) {
              if (data[indexColumns] == " "){
                skipRow = true;
                break;
              }
            }         
            if (skipRow){
                $(row).addClass("blank-row");
            }
          }
        });       
      }
    },
    setButtons() {
      if (this.isInsiderUser || !this.isSavedCard) {
        let buttonContainer = TableService.getTableButtons(this, this.dataContainerId);
        if (typeof buttonContainer != "undefined") {
          let match = buttonContainer.buttons.find(x => x.titleAttr == "Insights");
          if (typeof match != "undefined") {
            buttonContainer.buttons.splice($.inArray(match, buttonContainer.buttons), 1);
          }
          $.extend(this.configuration, buttonContainer);
        }
      } else {
        $.extend(this.configuration, TableService.getTableButtons(this, this.dataContainerId));
      } 
    },
    setHeaderConfiguration() {
      if (this.dataContainerId == "moat_needs_data_container" || this.dataContainerId == "winner_needs_data_container") {
        $.extend(this.configuration, {
          headerCallback: function(thead, data, start, end, display) {
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(3)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(4)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(5)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(6)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(7)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(8)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(9)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(10)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(11)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(12)
            );
          }
        });
      } else if (
        this.dataContainerId == "value_functional_needs_data_container" ||
        this.dataContainerId == "value_emotional_needs_data_container" ||
        this.dataContainerId == "functional_needs_data_container" ||
        this.dataContainerId == "emotional_needs_data_container" ||
        this.dataContainerId == "competitive-trends-data-container"
      ) {
        $.extend(this.configuration, {
          headerCallback: function(thead, data, start, end, display) {
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(2)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(3)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(4)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(5)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(6)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(7)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(8)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(9)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(10)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(11)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(12)
            );
          }
        });
      } else if (
        this.dataContainerId == "moats-rank-data-container" ||
        this.dataContainerId == "by_value_nps_data_container" ||
        this.dataContainerId == "nps_data_container"
      ) {
        $.extend(this.configuration, {
          headerCallback: function(thead, data, start, end, display) {
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(1)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(2)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(3)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(4)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(5)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(6)
            );
          }
        });
      }  else if (
        this.dataContainerId == "moat-trends-data-container"
      ) {    
        $.extend(this.configuration, {
          headerCallback: function(thead, data, start, end, display) {
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(2)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(3)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(4)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(5)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(6)
            );
            TableService.setHeaderColor(
              $(thead)
                .find("th")
                .eq(7)
            );
          }
        });
      }
    }
  },
  watch: {
    chartData: function() {
      if (!$.isEmptyObject(this.chartData)) {
        this.displayTable();
      }
    }
  },
  mounted() {},
  created() {}
};
</script>
