<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container client-list">
      <div class="button-container">
        <div class="button-left">
          <div class="pr-2">
            <router-link :to="{ name: 'add-client', params: { initialMode: 'add' } }">
              <button type="button" class="btn btn-success" id="AddNewClientButton">
                Add Client
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'plus-circle']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">
            <router-link :to="{ name: 'edit-client', params: { initialMode: 'edit', initialClientId: this.table.rowSelectedId, initialClientCode: this.getClientCode() } }">
              <button type="button" class="btn btn-primary" id="EditClientButton" :disabled="!table.rowSelected">
                Edit Client
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </span>
              </button>
            </router-link>
          </div>
          <div class="pr-2">
            <button type="button" class="btn btn-primary" id="DuplicateClientButton" :disabled="!table.rowSelected || disableCopyClientButton" @click="$modal.show('copy-client')">
              Copy Client
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'copy']" />
              </span>
            </button>
          </div>
          <div class="pr-2">
            <a class="pr-2" href="#" @click="displayDeleteConfirmation('Confirmation Client')">
              <button type="button" class="btn btn-primary" id="DeleteSurveyButton" :disabled="!table.rowSelected">
                Delete Client
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'minus-circle']" />
                </span>
              </button>
            </a>
          </div>
        </div>
        <div class="button-right">
          <a class="pr-2" href="#" @click="$modal.show('import-client-file')">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Import Clients <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
          </a>
          <a href="/api/v1/client/download-client-import-template" @click.prevent="downloadItem()" id="DownloadUserImportTemplate">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Download Client Import Template <font-awesome-icon :icon="['fa', 'download']"/></span>
            </button>
          </a>
        </div>
      </div>
      <div class="data-container">
        <div class="col-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th>Client Id</th>
                <th>Name</th>
                <th>Parent Name</th>
                <th>Is Parent</th>
                <th>Show Benchmark as Rank Group</th>
                <th>Active</th>
                <th>Last Wave End Date</th>
                <th>Subscription End Date</th>
                <th>Date Created</th>
                <th>Date Updated</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
    <vue-modal name="import-client-file" :width="368" :height="250">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Import Client Data</h5>
        </div>
        <div class="form-group form-row p-2 text-success">
          {{ modalReturnMessage }}
        </div>
        <div class="form-group form-row">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importClientUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="
              $modal.hide('import-client-file');
              $router.go(0);
            "
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
    <vue-modal name="copy-client" :width="368" :height="250">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Copy Client</h5>
        </div>
        <div class="form-group form-row p-2 text-success">
          {{ modalReturnMessage }}
        </div>
        <div class="form-group form-row p-2">
          Please select the number of copies you want to make
          <select id="numberOfCopies" name="numberOfCopies" class="custom-select required" v-model="numberOfCopies">
            <option v-for="(copy_option, idxcopyOptions) in copyOptions" v-bind:value="copy_option" v-bind:key="idxcopyOptions">{{ copy_option }}</option>
          </select>
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.copyClient">
            <span>Copy Client</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="
              $modal.hide('copy-client');
              $router.go(0);
            "
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
    <modal
      :modal-id="'Confirmation Client'"
      :modal-title="'Delete Confirmation'"
      :modal-content="'Are you sure you want delete the client ?'"
      :modal-result-id="table.rowSelectedId"
      :include-confirmation="true"
      @confirm="deleteClient"
    />
  </div>
  <div v-else class="panel-admin-body nps-email-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import axios from "axios";

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import ClientService from "../../services/client.service";

export default {
  name: "ClientList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      importClientImportFile: "",
      title: "Client List",
      table: {
        id: "client-list-table",
        columns: ClientService.getTableColumnData()
      },
      copyOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      numberOfCopies: 1,
      disableCopyClientButton: false
    };
  },
  methods: {
    copyClient() {
      this.$root.processServerRequest();
      this.modalReturnMessage = "Making copies of the client please stand by....";
      ClientService.copyClient(this.getClientCode(), this.numberOfCopies, this.copyClientCallback, this);
    },
    copyClientCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        ClientService.clearCache();
        ClientService.getAllClientsFromDatabase(this.$parent.$parent.getAllClientsCallback);

        this.table.dataTable.draw(true);
        this.modalReturnMessage = response.data.message;
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    deleteClient() {
      this.$root.processServerRequest("Deleting client");
      ClientService.deleteClient(this.getClientCode(), this.deleteClientCallback, this);
    },
    getClientCode() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.clientCode;
      }
      return "";
    },
    deleteClientCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        ClientService.clearCache();
        ClientService.getAllClientsFromDatabase(this.$parent.$parent.getAllClientsCallback);

        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.getTableData();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async getTableData() {
      this.$root.processServerRequest("Retrieving client");
      await ClientService.getClientList(this.getTableDataCallback, this);
      const vm = this;
      this.table.dataTable.on('dblclick', 'tr', function () {
          vm.$router.push({ name: 'edit-client', params: { initialMode: 'edit', initialClientId: vm.table.rowSelectedId, initialClientCode: vm.getClientCode() } });
      });
    },
    postRowSelect(rowData, disable) {
      if (typeof rowData != "undefined") {
        if (rowData.isParent == "Y") {
          this.disableCopyClientButton = disable;
          return;
        }
      }
      this.disableCopyClientButton = false;
    },
    getDownloadFilename() {
      return "Client Import Template";
    },
    onFileChange: function() {
      this.importClientImportFile = this.$refs.importClientUpload.files[0];
    },
    async importFile() {
      this.$root.processServerRequest();
      this.setModalLoading(true);
      this.modalReturnMessage = "Importing client data please stand by.....";
      let formData = new FormData();
      formData.append("file", this.importClientImportFile);

      let headers = { "Content-Type": "multipart/form-data" };
      $.extend(headers, await authHeader());

      await axios
        .post(this.baseUrl + "/api/v1/client/import-clients", formData, { headers: headers })
        .then(response => this.importFileCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientList.importFile", error, "admin", this);
        });
    },
    importFileCallback: function(response) {
      this.setModalLoading(false);
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        this.table.dataTable.draw(true);
        ClientService.clearCache();
        ClientService.getAllClientsFromDatabase(this.$parent.$parent.getAllClientsCallback);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  computed: {
    downloadItemUrl: function() {
      return this.baseUrl + "/api/v1/client/download-client-import-template";
    }
  },
  created() {
    if (this.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [[4, "desc"]];
    }
  },
  props: {},
  mounted() {}
};
</script>
