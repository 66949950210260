<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/profiles" @click="$parent.logViewDetailsEvent(title)">                      
            NPS <br>
            Trends
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Respondents" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('nps-trends-save-chart-confirmation')"> 
          Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-nps-trends">
        <div>
          <div>
            <div class="detractors-square dashboard-nps-trends-square"></div>
            <div class="pl-2 align-self-center">Detractors</div>
          </div>
          <div>
            <div class="passives-square dashboard-nps-trends-square"></div>
            <div class="pl-2 align-self-center">Passives</div>
          </div>
        </div>
        <div>
          <div>
            <div class="promoters-square dashboard-nps-trends-square"></div>
            <div class="pl-2 align-self-center">Promoters</div>
          </div>
        </div>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div class="dashboard-card-body">
      <div class="nps-trends-chart" :style="dashboardChartStyle">
        <chart :options="options"></chart>
      </div>
      <div id="nps-trends-mini">
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
      </div> 
    </div>
<!--    :updatedModalChartOptions.sync="options.series[0].data" -->    
    <dashboard-modal :modal-id="dashboardId" :chart="this.chart" :client-chart="this.clientChart" :dashboard-card-report-page-type-name="reportTypeName" :modal-title="title" :modal-chart-options="options" />
    <vue-modal :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts"></save-chart>
    </vue-modal>

  </div>
</template>

<script>
import Vue from "vue";
import echarts from "echarts";
import $ from "jquery";

import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";


export default {
  name: "DashboardNpsTrends",
  mixins: [DashboardMixins],
  components: {SaveChart,DashboardCardSkeleton},
  data() {
    return {
      title: "NPS Trends",
      modalName: "nps-trends-save-chart-confirmation",     
      npsTrendsMapOptions: {},
      npsTrendsData: [],
      showYAxis: {
          boundaryGap: true,
          type: "value",
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 10,
            fontFamily: "Franklin Gothic Demi Cond",
            color: "#1663E0"
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff"
            }
          },
          min: 0,
          max: 100,
          interval: 10,
          splitNumber: 100,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#000"
            }
          }
        },  
        noShowYAxis:  {
        type: 'value',
        show: false,
      },
      baseSeries:{
        yAxisIndex: 0,
          stack: "total",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            fontSize: 10,
            rotate: 0,
            formatter: "{c}%",
            fontWeight: 'normal'
          },
          data: []
      },
      detractorsSeries: {
          name: 'Detractors',
        },
        passivesSeries: {
          name: 'Passives',
        },
        promotersSeries: {
          name: 'Promoters',
        }        
    };
  },
  props: {},
  computed: {},
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardNpsTrends::mounted");
    this.chartTitle = "NPS Trends";
    this.chartTypeName = "nps-trends";
    this.containerId = "nps-trends-mini"
	  this.reportTypeName = "Dashboard - NPS Trends"
    this.resetChart();
  },
  created() {},
  methods: {
    processSavedChartCriteria(criteria) {},
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      return params;
    },      
    initChart: function(dynamicOptions) {
      // https://echarts.apache.org/examples/en/editor.html?c=bar-brush
      let chartDiv = $(".nps-trends-chart")[0];
      this.chart = echarts.init(chartDiv);
      let vm = this;
      this.chart.on('finished', () => {
        vm.saveImage();
      })
      this.options = DashboardService.getNPSTrendsOptions();
      this.options.xAxis.data = dynamicOptions.xAxis.data;
      this.options.series = DashboardService.setSeries(this.baseSeries, this.options.series, dynamicOptions);

      DashboardService.setIndividualSeries(this.detractorsSeries, 0, dynamicOptions);
      DashboardService.setIndividualSeries(this.passivesSeries, 1, dynamicOptions);
      DashboardService.setIndividualSeries(this.promotersSeries, 2, dynamicOptions);
      $.extend(true, this.options, dynamicOptions);
        
      this.options.yAxis =  this.noShowYAxis; 
      let showLabel = this.options.series[0].data.length < 10;
      if (!showLabel){
          this.options.yAxis =  this.showYAxis;
      }      
      this.options.series[0].label.show = showLabel;
      this.options.series[1].label.show = showLabel;
      this.options.series[2].label.show = showLabel;

      this.chart.setOption(this.options, true);
      this.options.yAxis = this.noShowYAxis; 
      this.options.series[0].label.rotate = 90;
      this.options.series[1].label.rotate = 90;
      this.options.series[2].label.rotate = 90;
      this.options.series[0].label.show = true;
      this.options.series[1].label.show = true;
      this.options.series[2].label.show = true;
      if (this.autoGenerateChartRun == true){
        this.options.legend.show = true;
      }
      //this.printOptions();
    },    
    loadData: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardNpsTrends::loadData");
      this.displayDashboard = false;
      if (this.autoGenerateChartRun){
        this.processAutoGenerateChart();
      } else {
        this.setupClientChart();
      }      
      DashboardService.getNPSTrends(this.timePeriodId, this.getNPSTrendsCallback, this);
    },
    getNPSTrendsCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.npsTrendsData = response.data.data.npsTrendsData;  
        this.displayDashboard = true;
        this.npsTrendsMapOptions = DashboardService.getNPSTrendsOptions();    
        let dynamicOptions = response.data.data.options;
        this.initChart(dynamicOptions);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }  
  },
  watch: {
    entrySurveyCode: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardNpsTrends::entrySurveyCode");
      this.resetChart();
    },
  }
};
</script>
