<template>
  <div>
    
  </div>
</template>
<script>
export default {
  name: "LogoutRedirect",
  beforeMount() {
  },
  mounted() {
    try {
      this.$parent.dataLoaded = false;
      this.$store.dispatch("auth/logout");
      this.$router.replace({ path: "/login" }).catch(failure => {});
    } catch (e) {
//      this.error = e.toString();
    }
  },
  methods: {
  },
};
</script>
