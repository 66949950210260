<template>
    <div class="user-settings-system">
      <div class="row no-gutters pb-2">
        <div class="application-settings">Application Settings</div>
      </div>
      <div class="row no-gutters">
        <div class="col-3 form-group form-inline pt-1">
          <input
            id="showAllRanks"
            name="showAllRanks"
            type="checkbox"
            true-value="Y"
            false-value="N"
            :disabled="isNewUser"
            v-model="showAllRanks"
            @click="saveCheckboxValue($event, 'show-all-rankings')"
          />
          <label class="pl-2">Show All Ranks</label>
        </div>
        <div class="col-3 form-group form-inline pt-1">
          <input
            id="showAllRanksByClientOnly"
            name="showAllRanksByClientOnly"
            type="checkbox"
            true-value="Y"
            false-value="N"
            :disabled="isNewUser"
            v-model="showAllRanksByClientOnly"
            @click="saveCheckboxValueByClientOnly($event, 'show-all-rankings-by-client-only')"
          />
          <label class="pl-2">Show All Ranks By Client Only</label>
        </div>
      </div>
      <div class="row no-gutters dashboard-settings-header ">
        <div class="application-settings">Dashboard Settings</div>
      </div>
      <div class="row no-gutters">
        <div class="col-3 form-group form-inline pt-1">
            <input
              id="showDashboardSettings"
              name="showDashboardSettings"
              type="checkbox"
              true-value="Y"
              false-value="N"
              :disabled="isNewUser"
              v-model="showDashboardSettings"
            />
            <label class="pl-2">Create Custom Dashboard Settings</label>
        </div>
      </div>
      <dashboard-settings v-if="showDashboardSettings == 'Y'" :initial-user-id="initialUserId"/>
    </div>
</template>

<script>
import DashboardMixins from "../mixins/DashboardMixins.vue";
import UserSettingsService from "../services/user.settings.service";
import AdminService from "../services/admin.service";
import DashboardSettings from "../components/DashboardSettings.vue";

export default {
  name: "UserSettingsSystem",
  mixins: [DashboardMixins],
  components: {DashboardSettings},
  data() {
    return {
      title: "User Settings System",
      showAllRanks: "N",
      showAllRanksByClientOnly: "N",
      showDashboardSettings: "N"
    };
  },
  props: {
    initialUserTypeId: {
      type: Number,
      default: 0
    },
    initialUserId: {
      type: Number,
      default: 0
    },
    initialUserCode: {
      type: String,
      default: "0"
    },
    initialMode: {
      type: String,
      default: ""
    },
  },
  computed: {
    checkShowAllRanks: function(){
      return this.initialUserTypeId == 1 || this.initialUserTypeId == 3 || this.initialUserTypeId == 4;
    },
    isNewUser: function(){
      return this.initialMode == "add-user" || this.initialMode == "";
    }
  },
  mounted() {
    if (this.initialMode != "add-user" && this.initialMode != ""){
      UserSettingsService.getUserSettingsFromDatabase(this.initialUserId, this.initialUserCode, this.processUserSettings, this);
    }
    this.setDefaultValues();
  },
  created() {},
  methods: {
    setDefaultValues: function(){
      if (this.initialMode == "add-user"){
        if (this.checkShowAllRanks == true){
          this.showAllRanks = "Y";
          this.showAllRanksByClientOnly = "Y";
        } else {
          this.showAllRanks = "N";
          this.showAllRanksByClientOnly = "N";
        }
      }
    },
    saveCheckboxValue: async function (event, userSettingTypeName) {
      this.showAllRanks = event.target.checked ? "Y" : "N";
      this.$root.processServerRequest("Saving application settings");
      await UserSettingsService.saveUserSettingByUserId(this.initialUserId, this.initialUserCode, userSettingTypeName, this.showAllRanks, 0, this.saveUserSettingCallback, this);
    },
    saveCheckboxValueByClientOnly: async function (event, userSettingTypeName) {
      this.showAllRanksByClientOnly = event.target.checked ? "Y" : "N";
      this.$root.processServerRequest("Saving application settings");
      await UserSettingsService.saveUserSettingByUserId(this.initialUserId, this.initialUserCode, userSettingTypeName, this.showAllRanksByClientOnly, 0, this.saveUserSettingCallback, this);
    },

    saveUserSettingCallback: function (response) {
      this.$root.processServerResponse();
      AdminService.displaySuccessMessage({ text: response.data.message, duration: 2000 });
    },
    processUserSettings: function (response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.settings = response.data.data;
        var showAllRanks = this.settings.find((x) => x.userSettingTypeName == "show-all-rankings");
        if (typeof showAllRanks != "undefined") {
          this.showAllRanks = showAllRanks.userSettingValue;
        }
        var showAllRanksByClientOnly = this.settings.find((x) => x.userSettingTypeName == "show-all-rankings-by-client-only");
        if (typeof showAllRanksByClientOnly != "undefined") {
          this.showAllRanksByClientOnly = showAllRanksByClientOnly.userSettingValue;
        }
      }
    }
  },
  watch: {
    initialUserTypeId: function(){
      this.setDefaultValues();
    },
    initialUserCode: async function(){
      if (this.initialUserCode != "0"){
        await UserSettingsService.saveUserSettingByUserId(this.initialUserId, this.initialUserCode, 'show-all-rankings', this.showAllRanks, 0, this.saveUserSettingCallback, this);
      }
    },
  },
};
</script>
