<template>
  <a v-show="showLink" :href="link" @click="goToSmartCenter" target="_blank" data-html2canvas-ignore="true">
    <!--
    <img class="lightbulb" src="../assets/img/Bulb-Yellow.png" :alt="smartCenterAlt" />
    <i class="fa-duotone fa-lightbulb" aria-hidden="true" :alt="smartCenterAlt"></i>
    -->
    <i class="fa-sharp fa-solid fa-lightbulb" aria-hidden="true" :alt="smartCenterAlt"></i>
  </a>
</template>
<script>
import SmartCenterService from "../services/smart.center.service";
import GoogleService from "../services/google.service";

export default {
  name: "SmartCenterLink",
  data() {
    return {
      link: "",
      showLink: false
    };
  },
  umnounted() {},
  computed: {},
  created() {},
  mounted() {
    this.buildLink();
  },
  methods: {
    goToSmartCenter: function() {
      this.buildLink();
      let label = this.page + " - " + this.location;
      GoogleService.logClickEvent("Smart Center Link", label, 0, this);

    },
    buildLink: function() {
      this.link = SmartCenterService.buildSmartCenterLink(this.location, this.page);
      if (this.link != "") {
        this.showLink = true;
      }
    }
  },
  watch: {},
  props: {
    smartCenterId: {
      type: String,
      default: ""
    },
    page: {
      type: String,
      default: ""
    },
    location: {
      type: String,
      default: ""
    },
    smartCenterAlt: {
      type: String,
      default: "SmartCenter"
    }
  }
};
</script>
