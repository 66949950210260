<template>
  <div class="panel-admin-body">
    <survey-status :input-survey="survey" :display-survey-status="!isSurveyEmpty" :url="liveSurveyUrl" :does-survey-url-work="this.doesSurveyUrlWork"></survey-status>
    <div class="d-flex title">
      {{ title }}
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <design-menu></design-menu>
    <div v-if="permissionToView">
      <div v-if="getDisplayView()" class="ideal">
        <div class="row no-gutters justify-content-center">
          <div class="design-column col-lg-6">
              <needs-section :section-object="this.getSection('Actual Functional Needs')" :survey-id="this.survey.surveyId"></needs-section>
          </div>
          <div class="design-column">
              <needs-section :section-object="this.getSection('Actual Emotional Needs')" :survey-id="this.survey.surveyId" @save="this.saveSurveyField"></needs-section>
            <div class="dashboard-divider pt-3"></div>
            <survey-drivers 
              :input-survey-drivers="surveyDrivers" 
              :survey-id="this.survey.surveyId" 
              @save="updateSurveyDrivers"
              @enable-save-order-button="enableSaveOrderButton">
            </survey-drivers>
          </div>
        </div>
        <div class="row no-gutters pt-2">
          <div class="col" role="group">
            <button type="submit" class="btn btn-primary float-right" :disabled="this.error || this.disableButton" @click="saveSurvey()">
              <span>{{ buttonText }} </span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
            <button type="submit" class="btn btn-primary float-right mr-4" :disabled="this.error || this.disableSaveOrderButton" v-on:click="this.saveSurveyDriverOrder">
              <span>Save Order</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
        <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
          <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
        </div>
      </div>
    </div>
    <div v-else class="ideal text-center">You do not have permission to view this page</div>
  </div>
</template>
<script>
import AdminService from "../../services/admin.service";
import SurveyService from "../../services/survey.service";
import GoogleService from "../../services/google.service";
import ErrorService from "../../services/error.service";

import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import NeedsSection from "../../components/NeedsSection.vue";
import SurveyStatus from "../../components/SurveyStatus.vue";
import SurveyDrivers from "../../components/SurveyDrivers.vue";

export default {
  name: "Needs",
  mixins: [StudyDesignMixins],
  components: {
    NeedsSection,
    SurveyDrivers,
    SurveyStatus
  },
  props: {},
  data() {
    return {
      surveyName: "",
      pageNumber: 3,
      title: "Needs",
      surveyDrivers: ["", "", "", "", ""],
      disableSaveOrderButton: true,
      buildSurvey: true
    };
  },
  methods: {
    enableSaveOrderButton(){
      this.disableSaveOrderButton = false;
      this.disableButton = true;
    },
    postLoadData: function() {
      this.setDisplayView(true);
    },
    updateSurveyDrivers: function(data){
      this.surveyDrivers =  JSON.parse(JSON.stringify(data));
    },
    saveSurveyDriverOrder(){
      this.$root.processServerRequest("Saving driver order");
      GoogleService.logClickEvent(SurveyService.getSurveyName(), "Save Order - Driver", 0, this);

      this.disableSaveOrderButton = true;
      SurveyService.saveSurveyDriverOrder(this.surveyDrivers, this.survey.surveyId, this.saveSurveyDriverOrderCallback, this);
    },    
    saveSurveyDriverOrderCallback(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.updateSurveyDrivers(response.data.data);
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.disableButton = false;
      } else {
        ErrorService.processError("Needs.saveSurveyDriverOrderCallback", response.data.message, "survery-designer", this);
      }
    },    
  },
  created() {},
  mounted() {},
  computed: {
    buttonText: function() {
      return this.survey.buildRequired == "N" ? "Save" : "Build Survey";
    }
  }
};
</script>
