<template>
  <div class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="quick-guide">
      <embed src="./guides/quickguide.pdf" type="application/pdf" width="100%" height="800px" />
    </div>
  </div>
</template>
<script>
import AdminMixins from "../mixins/AdminMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";

export default {
  name: "QuickGuide",
  mixins: [AdminMixins, ViewMixins],
  components: {},
  props: {},
  data() {
    return {
      title: "Quick Guide"
    };
  },
  methods: {
    async submitContactUsForm() {},
    submitContactUsFormCallback: function(response) {}
  },
  created() {},
  mounted() {},
  computed: {}
};
</script>
