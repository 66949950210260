import $ from "jquery";
import pptxgen from "pptxgenjs";

import html2canvas from "html2canvas";
import AdminService from "./admin.service";
import GoogleService from "./google.service";

class PowerPointService {
    getModalMessage(){
        return "Please stand by as your power point is on its way. <br> <span class='company-data-column'>Do not navigate away from this screen.....</span>";
    }
   createChart(imageData, imageWidth){
    let pptx = new pptxgen();

    let slide = pptx.addSlide();
    slide.addText("wRatings Demo!", { x: 1, y: 1, w: 10, fontSize: 36, fill: { color: "F1F1F1" }, align: "center" });
    
    // Image by local URL
    //slide.addImage({ path: "images/chart_world_peace_near.png" });
    //slide.addImage({ path: "/assets/img/print-report/wRatings-2021-GoForTheW-v2.png" });
    slide.addImage({ data: imageData, w: '80%', h: '80%' });

    pptx.writeFile({ fileName: "demo.pptx" });    

   }
   runDemo(){
    let pptx = new pptxgen();

    let slide = pptx.addSlide();
    slide.addText("wRatings Demo!", { x: 1, y: 1, w: 10, fontSize: 36, fill: { color: "F1F1F1" }, align: "center" });
    
    // Image by local URL
    //slide.addImage({ path: "images/chart_world_peace_near.png" });
    slide.addImage({ path: "/assets/img/print-report/wRatings-2021-GoForTheW-v2.png" });

    pptx.writeFile({ fileName: "demo.pptx" });    
   } 
}

export default new PowerPointService();
