import Swal from 'sweetalert2'

const BASE_TOAST_CONFIGURATION = {
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
    }
}; 
//https://sweetalert2.github.io/
class AlertService {
    closeAlert(){
        Swal.close();
    }
    showDisplayInfoMessage(message){
        const Toast = Swal.mixin(BASE_TOAST_CONFIGURATION);
        Toast.fire({
          icon: "info",
          title: message
        });         
    }
    showDisplaySuccessMessage(message) {
        const Toast = Swal.mixin(BASE_TOAST_CONFIGURATION);
        Toast.fire({
          icon: "success",
          title: message
        });      
    }      
    showDisplayErrorMessage(message) {
        let config = BASE_TOAST_CONFIGURATION;
        config.timer = 15000;
        const Toast = Swal.mixin(config);
        Toast.fire({
          icon: "error",
          title: message
        });   
    }        
    showInProgressMessage(content, title = 'In progress....'){
        Swal.fire({
            title: '<h5 class="modal-title">'+title+'</h5>',
            html: content,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true
        })
    }
    showModalMessage(title, contentList){
        let content = contentList.find((content) => content.title === title);
        if (typeof content !== 'undefined') {        
            Swal.fire({
                title: '<h5 class="modal-title">'+title+'</h5>',
                html: content.contentText,
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true
            })
        }
    }
    showConfirmationMessage(callback, title="Cancel Confirmation", content="Are you sure you want revert your changes?"){ 
        Swal.fire({
            title: '<h5 class="modal-title">'+title+'</h5>',
            text: content,
            showCancelButton: true,
            confirmButtonColor: "#2f5eb3",
            cancelButtonColor: "#2f5eb3",
            confirmButtonText: "Confirm"
        }).then((result) => {
            if (result.isConfirmed) {
                callback();
            }
        });       
    }
}
export default new AlertService();
