import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";

import moment from "moment-timezone";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;

const API_CLIENT_URL = AdminService.getBaseUrl() + "/api/v1/client";

class ClientDocumentService {
    async getClientDocuments(clientCode, callback, chartApp) {
        let userId = UserService.getUserId();
        if (typeof clientCode != "undefined" && typeof userId != "undefined") {
          const params = new URLSearchParams();
          params.append("clientCode", clientCode);
          params.append("userId", UserService.getUserId());
          await axios
            .post(API_CLIENT_URL + "/get-client-documents", params, { headers: await authHeader() })
            .then(response => callback(response))
            .catch(error => {
              error.message += ErrorService.mergeError(error.response);
              ErrorService.processError("ClientDocumentService.getClientDocuments", error, "admin", chartApp);
            });
        }
    }
    async importFile(importsDocumentFile, note, clientCode, callback, chartApp) {
      let formData = new FormData();
      formData.append("file", importsDocumentFile);
      formData.append("note", note);
      formData.append("userId", UserService.getUserId());
      formData.append("clientCode", clientCode);
  
      let headers = { "Content-Type": "multipart/form-data" };
      $.extend(headers, await authHeader());
  
      await axios
        .post(API_CLIENT_URL + "/import-document", formData, { headers: headers })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientDocumentService.importFile", error, "admin", chartApp);
        });
    }
    async deleteClientDocument(clientDocumentId, callback, chatApp) {
      const params = new URLSearchParams();
      params.append("clientDocumentId", clientDocumentId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_URL + "/delete-document", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientDocumentService.deleteClientDocument", error, "admin", chatApp);
        });
    }   
    async downloadItem(clientDocumentId, callback, chatApp){
      const params = new URLSearchParams();
      params.append("clientDocumentId", clientDocumentId);
      params.append("userId", UserService.getUserId());

      await axios({
        url: API_CLIENT_URL + "/download-document",
        method: "GET",
        responseType: "blob",
        params: params
      })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientDocumentService.downloadItem", error, "admin", chatApp);
        });
    }   
    getClientDocumentTableColumnData() {
        return  [
            {
              title: "Id",
              data: "id",
              createdCell: function(td, cellData, rowData, row, col) {
                $(td).addClass("text-center");
              },
              className: "",
              name: "id",
              width: "2rem"
            },
            { title: "Document Name", data: "document", name: "document", width: "15rem" },
            { title: "Note", data: "note", name: "note", width: "25rem" },
            { title: "Uploaded By", data: "userName", name: "userName", width: "10rem" },
            {
              title: "Date Created",
              data: "dateCreated",
              type: "date",
              name: "dateCreated",
              width: "5rem",
              render: function(data, type, row) {
                return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
              }
            },
            {
              title: "Date Modified",
              data: "dateModified",
              type: "date",
              name: "dateModified",
              width: "5rem",
              render: function(data, type, row) {
                return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
              }
            }
          ]
    ;
    }
      
}

export default new ClientDocumentService();
