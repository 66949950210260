<template>
  <div class="dashboard-welcome-slider mx-auto">
    <div ref="swiper" class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide">
        <div class="main-fast-start-slide">
          <div class="fast-start-slide-header w-100">
            <div class="logo">
                <img src="../../assets/img/wRatings-2021-GoForTheW-v2.png" alt="wRatings Admin" />
            </div>
            <div class="text">
              <div>Fast Start</div>
              <div style="color: #2A3A66; font-size: 15px">Your Roadmap to Sales Growth</div>
            </div>
          </div>
          <div class="fast-start-slide">
            <div>
              <a :href="keyConceptsLink" target="_blank" @click="goToSmartCenter('Key Concept Training')">
                <img id="graphs-img" src="../../assets/img/graphs.png" />
              </a>
              <div class="fast-start-slide-sub-header">
                <a :href="keyConceptsLink" target="_blank" @click="goToSmartCenter('Key Concept Training')">
                  Key Concept Training
                </a>
              </div>
            </div>
            <div @click="goToDashboardLayout">
              <i class="fa-duotone fa-gear-complex fast-start-slide-gear"></i>
              <div class="fast-start-slide-sub-header">Hide / Change Dashboard Card</div>
            </div>
          </div>
        </div>          
      </div>
      <div class="swiper-slide">
        <div class="base-slide image-only-slide">
          <div class="base-slide-header">
            <div class="main-header">Why wRatings?</div>
            <div class="sub-header">We provide executives with a roadmap to sales growth</div>
          </div>    
          <div class="base-slider-section w-100">
            <div class="icon-image">
              <img  class="mx-auto" src="../../assets/img/dashboard-welcome-slides/fs2.png"/>
            </div>            
          </div>      
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide image-only-slide">
          <div class="base-slide-header">
            <div class="main-header">Fundamental Shift to Winning</div>
            <div class="sub-header">Use the predictive power of customer expectations</div>
          </div>    
          <div class="base-slider-section w-100">
            <div class="icon-image">
              <img  class="mx-auto" src="../../assets/img/dashboard-welcome-slides/fs3.png"/>
            </div>            
          </div>      
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide image-only-slide">
          <div class="base-slide-header">
            <div class="main-header">Rule-Maker or Rule-Taker</div>
            <div class="sub-header">You either make the rules . . . or follow them</div>
          </div>    
          <div class="base-slider-section w-100">
            <div class="icon-image">
              <img  class="mx-auto" src="../../assets/img/dashboard-welcome-slides/fs4.png"/>
            </div>            
          </div>      
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide image-only-slide">
          <div class="base-slide-header">
            <div class="main-header">It’s a New Way of Thinking</div>
            <div class="sub-header">Your #1 competitor is customers expectations</div>
          </div>    
          <div class="base-slider-section w-100">
            <div class="icon-image">
              <img  class="mx-auto" src="../../assets/img/dashboard-welcome-slides/fs5.png"/>
            </div>            
          </div>      
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide image-only-slide">
          <div class="base-slide-header">
            <div class="main-header">Our Platform</div>
            <div class="sub-header">Unites real-time analytics/metrics into a single pane decision view</div>
          </div>    
          <div class="base-slider-section w-100">
            <div class="icon-image">
              <img  class="mx-auto" src="../../assets/img/dashboard-welcome-slides/fs6.png"/>
            </div>            
          </div>      
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide">
          <div class="base-slide-header">
            <div class="main-header">Access Levels</div>
            <div class="sub-header">We streamline our menus to match each user’s needs</div>
          </div>          
          <div class="base-slider-divider"></div>
          <div class="base-slider-section w-100">
            <div class="base-slider-section">
                <div class="icon-image w-25">
                  <img  src="../../assets/img/dashboard-welcome-slides/access-levels-executives.png"/>
                </div>
                <div class="w-75 pl-2">
                  <div class="main-header">Executives</div>
                  <div class="sub-header">Time is valuable; We use artificial intelligence to auto-analyze data that drives sales growth decisions</div>
                </div>
            </div>
          </div>            
          <div class="base-slider-divider"></div>
          <div class="base-slider-section w-100">
            <div class="base-slider-section">
                <div class="icon-image w-25">
                  <img  src="../../assets/img/dashboard-welcome-slides/access-levels-operations.png"/>
                </div>
                <div class="w-75 pl-2">
                  <div class="main-header">Operations, Sales, Marketing</div>
                  <div class="sub-header">Eliminate waste & maximize actions; We connect customer performance to finance & operating metrics</div>
                </div>
            </div>
          </div>      
          <div class="base-slider-divider"></div>      
          <div class="base-slider-section w-100">
            <div class="base-slider-section">
                <div class="icon-image w-25">
                  <img  src="../../assets/img/dashboard-welcome-slides/access-levels-research.png"/>
                </div>
                <div class="w-75  pl-2">
                  <div class="main-header">Research, Strategy</div>
                  <div class="sub-header">Standardize on a common language around growth; We use real-time analytics for insights into your business framework and competitive moats</div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide setup-for-you-slide">
          <div class="base-slide-header pb-2">
            <div class="main-header">Setup for You</div>
            <div class="sub-header">Our system adapts to your way of doing business</div>
          </div>      
          <div class="base-slider-divider"></div>
          <div class="base-slider-section w-100">
            <div class="left">
                <div class="main-header">Your Organization</div>
              </div>
              <div class="right">
                <img src="../../assets/img/dashboard-welcome-slides/setup-your-organization.png"/>
              </div>
          </div>            
          <div class="base-slider-divider"></div>
          <div class="base-slider-section your-business w-100">
            <div class="left">
                <div class="main-header">Your Business</div>
            </div>
            <div class="d-flex right">
                <div class="pr-2"><img src="../../assets/img/dashboard-welcome-slides/your-business-satisfaction.png"/></div>
                <div class="sub-header">We modify core functional needs to your business</div>
                <div class="pr-2"><img src="../../assets/img/dashboard-welcome-slides/your-business-emotions.png"/></div>
                <div class="sub-header">We track how you make customers feel when you do business with them</div>
            </div>
          </div>      
          <div class="base-slider-divider"></div>      
          <div class="base-slider-section w-100">
            <div class="left">
                <div class="main-header">Your Segments</div>
            </div>
            <div class="d-flex right">
                <div class="sub-header w-50">You can filter expectations & performance by Size, Type, Spend, Wallet Share, etc.</div>
                <div class="w-50"><img src="../../assets/img/dashboard-welcome-slides/your-segments.png"/></div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide image-only-slide">
          <div class="base-slide-header">
            <div class="main-header">Competitive Dashboard</div>
            <div class="sub-header">Your single pane decision view</div>
          </div>    
          <div class="base-slider-section w-100">
            <div class="icon-image">
              <img  class="mx-auto" src="../../assets/img/dashboard-welcome-slides/fs9.png"/>
            </div>            
          </div>      
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide image-only-slide">
          <div class="base-slide-header">
            <div class="main-header">Power of Analytics</div>
            <div class="sub-header">Dig into and organize data to grow your sales</div>
          </div>    
          <div class="base-slider-section w-100">
            <div class="icon-image">
              <img  class="mx-auto" src="../../assets/img/dashboard-welcome-slides/fs10.png"/>
            </div>            
          </div>      
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide image-only-slide">
          <div class="base-slide-header">
            <div class="main-header">Tailoring to Your Preferences</div>
            <div class="sub-header">Control your dashboard, chart colors and setup advisor emails</div>
          </div>    
          <div class="base-slider-section w-100">
            <div class="icon-image">
              <img  class="mx-auto" src="../../assets/img/dashboard-welcome-slides/fs11.png"/>
            </div>            
          </div>      
        </div>
      </div>
      <div class="swiper-slide">
        <div class="base-slide">
          <div class="base-slide-header">
            <div class="main-header">More Info</div>
            <div class="sub-header">At any time, engage your assigned Research Advisor</div>
          </div>          
          <div class="base-slider-section">
            <div class="base-slider-sub-section d-flex">
              <div class="icon-image">
                <img  src="../../assets/img/dashboard-welcome-slides/wizard-hat.png"/>
              </div>
              <div>
                <div class="main-header">Wizards</div>
                <div class="sub-header">Shows you step-by-step sequence to complete a task</div>
              </div>
            </div>
            <div class="base-slider-left-divider"></div>
            <div class="base-slider-sub-section d-flex">
              <div class="icon-image">
                <img  src="../../assets/img/dashboard-welcome-slides/lightbulb.png"/>
              </div>
              <div>
                <div class="main-header">Help Text</div>
                <div class="sub-header">Descriptions of menus, charts, tables and data</div>
              </div>
            </div>
          </div>
          <div class="base-slider-divider"></div>
          <div class="base-slider-section pb-2">
            <div class="base-slider-sub-section">
              <a :href="keyConceptsLink" target="_blank" @click="goToSmartCenter('Key Concept Training')">
                <img src="../../assets/img/dashboard-welcome-slides/key-concepts.png" />
              </a>
            </div>
            <div class="base-slider-sub-section">
              <div class="main-header" style="padding: 5px 0px;">
                <a :href="keyConceptsLink" target="_blank" @click="goToSmartCenter('Key Concept Training')">
                  Key Concept Training
                </a>                
              </div>
              <div class="sub-header">How to build sales growth, block by block with examples</div>
              <div class="duration">Duration ~55 Minutes</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>

    <!-- If we need navigation buttons -->
    <div class="swiper-button-prev">
      <font-awesome-icon :icon="['fas', 'arrow-left']" size="xl" />
    </div>
    <div class="swiper-button-next">
      <font-awesome-icon :icon="['fas', 'arrow-right']" size="xl" />
    </div>
    <!-- If we need scrollbar -->
    <div class="d-none swiper-scrollbar"></div>
  </div>
    <!-- 
    -->    

  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper Vue.js components
Swiper.use([ Navigation, Pagination, Scrollbar, A11y ]);

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import GoogleService from "../../services/google.service";
import SmartCenterService from "../../services/smart.center.service";

export default {
  name: "DashboardWelcomeSlider",
  mixins: [],
  components: {
 },
  data() {
    return {
        title: "Welcome Slider",
        keyConceptsLink: "",
    };
  },
  
  props: {},
  computed: {},
  mounted() {
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination, Scrollbar, A11y],
      // Optional parameters
      loop: true,

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
/*
      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
*/      
    })
  },
  created() {},
  methods: {
    goToDashboardLayout: function() {
      GoogleService.logClickEvent("Dashboard", "Go to Dashboard Layout", 0, this);
      this.$router.replace({ name: "dashboard-layout" });
    },    
    goToSmartCenter: function (location) {
      let label = "Dashboard - " + location;
      GoogleService.logClickEvent("Smart Center Link", label, 0, this);
      let link = SmartCenterService.buildSmartCenterLink(location, "Dashboard");
      if (location == "Fast Start") {
        this.fastStartLink = link;
      } else if (location == "Key Concept Training") {
        this.keyConceptsLink = link;
      }
    },    
  },
  watch: {},
};
</script>
