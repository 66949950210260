<template>
    <div class="survey-drivers">
        <div class="message pl-5">When displaying Value Profiles, display the names below.</div>
        <div v-for="(surveyDriver, idxSurveyDrivers) in surveyDrivers" :key="idxSurveyDrivers"
            class="form-group form-inline no-gutters">
            <label :for="'survey_driver' + (idxSurveyDrivers + 1)" class="col-3">
                <span class="section-label pr-3">
                    {{ surveyDrivers[idxSurveyDrivers].label }}
                </span>
            </label>
            <input 
                v-model="surveyDrivers[idxSurveyDrivers].name"
                :id="'survey_driver' + (idxSurveyDrivers + 1)"
                :name="'survey_driver' + (idxSurveyDrivers + 1)" :disabled="disableWidgets"
                class="form-control col-9 mb-1 p-1 mt-1" type="text"
                @change="saveSurveyDriver($event, idxSurveyDrivers)" @input="$emit('update:surveyDrivers[idxSurveyDrivers].name', $event.target.value)"/>
        </div>
        <div class="dashboard-divider pt-3"></div>        
        <div class="survey-driver-order">
            <div class="message pl-5">To allow respondent data to order the drivers, select Weighted.</div>
            <div class="message pl-5">To fix the driver order, select Fixed and then drag & drop the name in the order you want.</div>
            <survey-field
            ref="surveyDriverDisplayOption"
            :survey-id="this.surveyId"
            :survey-field-object="this.$parent.getSurveyField('survey-driver-display-option')"
            :display-modal="false"
            field-class="form-group form-inline no-gutters mt-2 mb-2"
            field-type="radio"
            field-label="Driver Order"
            label-class="col-3 pr-2 form-check-label"
            :field-options="driver_order_options"            
            default-field-name="survey-driver-display-option"
            default-field-value="weighted"
            @save="this.$parent.saveSurveyField"
            ></survey-field>
            <VueDraggable
                v-model="surveyDrivers"
                :disabled="!enabled"
                class="list-group draggable-container"
                ghost-class="draggable-ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="endOfDragSurveyDrivers"
                options="people"
            >
                <div v-show="driver.name != ''" class="list-group-item draggable-card" v-for="driver in this.surveyDrivers" 
                    :key="driver.surveyDriverId" 
                    :index="driver.surveyDriverId">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>
                    <span class="pl-1">{{ driver.name }}</span>
                </div>
            </VueDraggable>      
        </div>

    </div>
</template>
<script>
import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";
import ErrorService from "../services/error.service";
import { VueDraggable } from 'vue-draggable-plus';
import SurveyField from "./SurveyField.vue";

export default {
    name: "SurveyDrivers",
    components: {
		SurveyField,
        VueDraggable
    },
    data() {
        return {
            enabled: true,
            driverOption: 'weighted',
            surveyDrivers: [],
            driver_order_options:  [
                { value: "weighted", name: "Weighted" },
                { value: "set", name: "Set (below)" }
            ]
        };
    },
    props: {
        inputSurveyDrivers: {
            type: Array,
            default: () => [
                { surveyDriverId: 0, name: "Driver 1", surveyDriverNumber: 1, sequenceNumber: 1, surveyId: 0 },
                { surveyDriverId: 0, name: "Driver 2", surveyDriverNumber: 2, sequenceNumber: 2, surveyId: 0 },
                { surveyDriverId: 0, name: "Driver 3", surveyDriverNumber: 3, sequenceNumber: 3, surveyId: 0 },
                { surveyDriverId: 0, name: "Driver 4", surveyDriverNumber: 4, sequenceNumber: 4, surveyId: 0 },
                { surveyDriverId: 0, name: "Driver 5", surveyDriverNumber: 5, sequenceNumber: 5, surveyId: 0 }
            ]

        },
        disableWidgets: {
            type: Boolean,
            default: false
        },
        surveyId: {
            type: Number,
            default: 0
        },
    },
    computed: {},
    methods: {
        saveSurveyDriver: async function (event, index) {
            let value = event.target.value;
            let localSurveyDriversObject = JSON.parse(JSON.stringify(this.surveyDrivers[index]));
            localSurveyDriversObject.surveyId = this.surveyId;
            localSurveyDriversObject.name = value;
            this.$root.processServerRequest("Saving Driver");
            this.surveyDrivers[index].surveyId = this.surveyId;
            
            await SurveyService.saveSurveyDriver(localSurveyDriversObject, this.surveyId, this.saveSurveyDriverCallback, this);
        },
        saveSurveyDriverCallback(response) {
            this.$root.processServerResponse();
            this.$parent.disableButton = false;
            let statusCode = response.data.statusCode;
            if (statusCode == 200) {
                this.$emit("save", response.data.data);
                AdminService.displaySuccessMessage({ text: response.data.message });
            } else {
                ErrorService.processError("ClientCompetitors.saveSurveyDriverCallback", response.data.message, "survery-designer", this);
            }
        },
        checkMove: function(e) {},
        endOfDragSurveyDrivers: async function(event) {
            let oldIndex = event.oldIndex;
            let newIndex = event.newIndex;
            this.dragging = false;
            if (typeof this.surveyDrivers[oldIndex] != "undefined" &&
                typeof this.surveyDrivers[newIndex] != "undefined" ){
                this.surveyDrivers[newIndex].sequenceNumber = newIndex;
                this.surveyDrivers[oldIndex].sequenceNumber = oldIndex;
                for (let index = 0; index < this.surveyDrivers.length; index++) {
                    this.surveyDrivers[index].sequenceNumber = index + 1;
                }
                this.$emit("save",this.surveyDrivers);
                this.$emit("enable-save-order-button");
                this.$parent.disableSaveOrderButton;
                await this.$refs.surveyDriverDisplayOption.saveSurveyField('set');
            }
        },		
    },
    created() { },
    mounted(){
        this.surveyDrivers = JSON.parse(JSON.stringify(this.inputSurveyDrivers));
    },
};
</script>
