<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container partial-response-list">
      <div class="col d-flex justify-content-end pr-2">
        <a class="pr-2" href="#" @click="$modal.show('process-partial-responses')">
          <button type="button" class="btn btn-primary">
            <span class="align-text-bottom">Process Partials <font-awesome-icon :icon="['fa', 'database']"/></span>
          </button>
        </a>
      </div>
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
    <vue-modal name="process-partial-responses" :width="368" :height="300">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Process Partial Responses</h5>
        </div>
        <div class="form-group form-row p-2 pb-2">
          This process will pull a set number of surveys (currently: {{ maxNumberOfPartialRecords }} ) that were not completed and map that data for analysis. Click the 'Continue'
          button to proceed.
        </div>
        <div class="form-group form-row p-2 text-success">
          {{ modalReturnMessage }}
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.processPartialResponses">
            <span>Continue</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="
              $modal.hide('process-partial-responses');
              $router.go(0);
            "
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
  </div>
  <div v-else class="panel-admin-body partial-response-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment-timezone";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import SurveySummaryService from "../../services/survey.summary.service";

export default {
  name: "PartialList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      title: "Partial Survey Responses",
      maxNumberOfPartialRecords: 50,
      table: {
        id: "partial-response-list",
        columns: [
          {
            title: "Id",
            data: "id",
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "id",
            width: "2rem"
          },
          {
            title: "Survey Id",
            data: "surveyId",
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "surveyId",
            width: "2rem"
          },
          {
            title: "Survey Name",
            data: "name",
            name: "name",
            width: "10rem",
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: ""
          },
          {
            title: "Survey Taker Id",
            data: "surveyTakerId",
            name: "surveyTakerId",
            width: "5rem",
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: ""
          },
          {
            title: "# of Responses",
            data: "numberOfResponses",
            name: "numberOfResponses",
            width: "4rem",
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            }
          },
          {
            title: "Date Started",
            data: "startDate",
            type: "date",
            name: "startDate",
            width: "7rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
            },
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            }
          }
        ]
      }
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerResponse("Loading table data");
      await axios
        .get(this.baseUrl + "/api/v1/admin/get-partial-response-list", { headers: await authHeader() })
        .then(response => this.getTableDataCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("PartialList.getTableData", error, "admin", this);
        });
    },
    async processPartialResponses() {
      this.$root.processServerResponse();
      this.setModalLoading(true);
      this.modalReturnMessage = "Processing a maximum of " + this.maxNumberOfPartialRecords + " partial responses please stand by.....";
      SurveySummaryService.processPartialResponses(this.maxNumberOfPartialRecords, this.processPartialResponsesCallback, this);
    },

    processPartialResponsesCallback: function(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [[5, "asc"]];
    }
  },
  props: {},
  mounted() {}
};
</script>
