<template>
  <div class="survey-taker-post-message">
    <div class="logo">
        <img src="../../assets/img/wRatings-2021-GoForTheW-v2.png" alt="wRatings Admin" />
    </div>
    <div>
        <h4 class="text-primary text-center">{{ $t('signUpHeader') }}</h4>
        <div class="button-container justify-content-center">
            <button type="button" class="btn btn-primary mr-2" @click="openGetDataWindow">{{ $t('toGetDataButton') }}</button>
            <button type="button" class="btn btn-primary mr-2" @click="openForOurPanelWindow">{{ $t('forOurPanelButton') }}</button>
        </div>
    </div>
  </div>
</template>

<script>
import ViewMixins from "../../mixins/ViewMixins.vue";

export default {
  name: "SurveyTakerPostMessage",
  mixins: [ViewMixins],
  components: {},
  data() {
    return {
    };
  },
  props: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    openGetDataWindow: function(){
        let panelLink = "https://www.wratings.com/get-data";
        var imgWindow = window.open(panelLink, "imgWindow", "width=400,height=500");
    },
    openForOurPanelWindow: function(){
        let panelLink = "https://www.wratings.com/our-panel";
        window.open(panelLink, "_blank");
    },
  },
  watch: {},
};
</script>
