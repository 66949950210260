<template>
  <div>
    <div id="chart-container" class="moats">
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="moat-rank-title-and-chart" class="title-and-chart">
        <profile-chart-header
            :title="'Moat Rank'"
            :display="false"
            :input-display-number-of-responses="eChartCriteria.displayNumberOfResponses"
            @set-display-number-of-responses="setDisplayNumberOfResponses"
          />
          <ranks-chart ref="eChart"
            :e-chart-mini-id="'moat-ranks-e-chart-mini'"
            :e-chart-id="'moat-ranks-e-chart'"
            :input-report-type-name="'Chart - Moat Rank'"
            :modal-id="'moats-rank'"
            :is-saved-card="this.$parent.clientChart.clientChartId != 0" 
            :input-client-chart="this.$parent.clientChart"
            @save-table="saveProfileDataTable"
            @save-criteria="buildClientChart"/>                       
      </div>
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="moats-rank-data-container" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
        <div v-show="isInsiderUser" class="overlay">
          <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png"  style="width: 50%"/>
          </div>
        <chart-data-table
          ref="moatsRankDataTable"
          :data-container-id="'moats-rank-data-container'"
          :container-id="'moats-rank'"
          :is-saved-card="this.$parent.clientChart.clientChartId != 0" 
          :report-page-type-name="'Table - Moat Rank'"
          :insight-modal-id="'moats-rank-table'"
          :chart-data="this.table.data"
          v-bind:row-start-position="6"
          :column-defs="chart.columnDefs"
          :table-class="'table display row-border table-hover'"
          :table-width="'auto'"
          :content-list="this.pageContentForMoats"          
          :table-title="this.table.title"
        ></chart-data-table>
      </div>
    </div>
    <insights
      ref="insightsChart"
      :modal-name="'moats-rank'"
      :report-page-type-name="'Chart - Moat Rank'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsTable"
      :modal-name="'moats-rank-table'"
      :report-page-type-name="'Table - Moat Rank'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div>
</template>
<script>
import $ from "jquery";
import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";

import RanksChart from "../../../views/echarts/RanksChart.vue";
import ProfileChartHeader from "../../../components/echarts/ProfileChartHeader.vue";

import NeedsService  from "../../../services/needs.service";
import ReportService from "../../../services/report.service";
import ChartService from "../../../services/chart.service";
import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import TableService from "../../../services/table.service";
import RanksService from "../../../services/echarts/ranks.service";
import ContentService from "../../../services/content.service";
export default {
  name: "MoatsRank",
  mixins: [ChartCompanyMixins],
  components: {RanksChart,ProfileChartHeader},
  data() {
    return {
      title: "Moat Ranks",
      table: {
        id: "",
        data: {},
        title: "<div class='title'><h4 class='text-primary'>Moat Rank</h4></div>"
      },
      pageContentForMoats: [],
      isMobile: false,
    };
  },
  props: {
    inputData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    getPageContentByMoatsCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForMoats = response.data.data;
      }
    },    
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Moat Rank") {
        this.$refs.insightsChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Moat Rank") {
        this.$refs.insightsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    parseForRanks:function(inputData){
      let itemsToRemove = {
          lastColumns: 0,
          firstRows: 1,
          firstColumns: 0,
          lastRows:1
        };
      let data = {};
      let chart1Data = inputData ;
      data = RanksService.parseData(1,chart1Data,data,itemsToRemove,false);
      data = RanksService.parseLegendAndColorData(chart1Data,data,false);
      this.eChartData = JSON.parse(JSON.stringify(data));
    },
    saveAllReportPages() {
      this.saveReportPage("moats-rank");
    },
    async saveProfileDataTable(containerId) {
      if (this.clientChart.clientChartId != 0  && this.isGenerateReportRunning()) {
        await ReportService.saveReportTableFile(
          this.$refs.moatsRankDataTable.getTableData(),
          "Table - Moat Rank", 
          this.$parent.clientChart, 
          this.saveReportTableFileCallback, 
          this
        );
        await ReportService.saveReportPage(
          this.getReportTableContent(containerId), 
          "Table - Moat Rank", 
          this.$parent.clientChart, 
          this.saveReportPageCallback, 
          this
        );        
      }
    },             
    async processChartData() {
      try {
        this.chart = ChartService.getChartAttributes("Moats");
        this.pageContentForMoats.push(...this.contentList);
        let moatData = JSON.parse(JSON.stringify(this.inputData));
        let otherData = moatData["otherData"];
        
        let companyColorList = otherData["companyColorList"];
        TableService.setCompanyColorList(companyColorList);

        let containerId = moatData["containerId"];

        let chartContainerWidth = $("#chart-container").width();
        if (chartContainerWidth < 500) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
        this.$parent.setChartResults(moatData.companyList);
        this.$parent.$data.criteriaResultsList = AdminService.removeByName(moatData.criteriaList, "Competitor");
        this.table.id = containerId;
        let labelIndex = 1;
        let copyOfChartData = JSON.parse(JSON.stringify(moatData));
        for (let indexRows = 0; indexRows < moatData.rows.length; indexRows++) {
          let copyOfChartDataRow = copyOfChartData.rows[indexRows].c;
          let copyValue = copyOfChartDataRow[labelIndex].v;
          if (typeof copyValue != "undefined") {
            copyOfChartDataRow[labelIndex].v = TableService.getMoatLongName(copyValue);
          }
        }
        this.table.data = copyOfChartData;

        this.$root.$emit("check-number-of-responses");
        this.setDisplayView(true);
        this.$parent.setDisplayView(true);
        this.$root.processServerResponse();
        this.setEChartCriteria(this.$parent.$refs.chartCriteria.eChartCriteria,true);
        this.parseForRanks(moatData);
        this.$refs.eChart.processChart(true,'moat-ranks',this.eChartData,this.eChartCriteria);
        $("#moat-rank-title-and-chart").addClass("chart-wrapper");

      } catch (err) {
        ErrorService.processException("MoatsRank.displayChartCallback", err, "chart", this);
      }
    },
  },
  umnounted() {},
  computed: {},
  mounted() {},
  created() {
    ContentService.getPageContent("Moats", this.getPageContentByMoatsCallBack, this);
  },
  watch: {
    inputData: function() {
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try{this.$refs.eChart.clear();} catch(e){}
      if (this.$parent.$refs.chartCriteria.$data.displayType == "moat-rank") {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    }
  }
};
</script>
