import BaseChartService from "./base.chart.service";

import $ from "jquery";

class BarHorizontalService extends BaseChartService {
    barWidth = 80;
    setBarWidth(barWidth) {
        this.barWidth = barWidth;
    }
    getBarWidth() {
        return this.barWidth;
    }
    constructor() {
        super();
    }
    setDataByWaves(inputTimePeriods,selectedQuestion) {
        let seriesData = [];        
        let legendData = [];
        let axisData = {
            name: "",
            data: []
        };         
        for (let j = 0; j < inputTimePeriods.length; j++) {
            let selectedTimePeriod = inputTimePeriods[j];
            axisData = {
                name: selectedTimePeriod,
                data: []
            };  
            legendData.push(selectedTimePeriod);
            for (let i = 0; i < selectedQuestion.options.length; i++) {
                let option = selectedQuestion.options[i];
                let countItem = option.counts.find(item => item.timePeriod === selectedTimePeriod);
                if (typeof countItem != 'undefined' && countItem.count != null) {
                    axisData.data.push(countItem.count);
                } 
            }
            seriesData.push(axisData);
        }    
        this.setSeriesData(seriesData);        
        this.setLegendData(legendData);       
    }
    setDataByResponses(inputTimePeriods,selectedQuestion) {
        let seriesData = [];
        let legendData = [];
        let axisData = {
            name: "",
            data: []
        };         
        for (let i = 0; i < selectedQuestion.options.length; i++) {
            let option = selectedQuestion.options[i];
            axisData = {
                name: option.label,
                data: []
            };  
            legendData.push(option.label);
            for (let j = 0; j < inputTimePeriods.length; j++) {
                let selectedTimePeriod = inputTimePeriods[j];
                let countItem = option.counts.find(item => item.timePeriod === selectedTimePeriod);
                if (typeof countItem != 'undefined' && countItem.count != null) {
                    axisData.data.push(countItem.count);
                }                 
            }
            seriesData.push(axisData);
        }    
        this.setSeriesData(seriesData);       
        this.setLegendData(legendData);       
    }
    getSeries(isStacked = false,numberOfWaves) {
        return this.getSeriesData().map((seriesData, sid) => {
            let series = {
                name: seriesData.name,
                data: seriesData.data
            };
            $.extend(true, series, this.getBaseSeries(isStacked,numberOfWaves));
            return series;
        });          
    }    
    getBaseSeries(isStacked = false,numberOfWaves) {
        let stackConfig = {
            stack: 'total',
        }        
        let label = {
            formatter: function(param) {
                return param.value == 0 ? '' : param.value + '%'
            },
            show: this.getShowLabel(),
        };
        if (isStacked == false){
            $.extend(true, label, {position: "right"}); 
        } 
        let labelTextStyle = this.getDefaultLabelTextStyle();
        
        if (isStacked == true && this.useBlackTextForStackedBar() == false){
            $.extend(true, labelTextStyle, { color: "#fff" });             
        }
        
        $.extend(true, label, labelTextStyle);                 
        let series =  {
            type: 'bar',
            label: label,
            emphasis: {
                focus: 'series'
            },
        };
        if (isStacked == true){
            $.extend(true, series, stackConfig);
            $.extend(true, series, this.stackedBarConfiguration(numberOfWaves, this.getBarWidth()));
        } else {    
            $.extend(true, series, this.barConfiguration(this.getChartType(), this.getisMultiWave()));
        }          
        return series;
    }
    getOptions(disableSelectedMode = false,isStacked = false,app,modalId,reportPageTypeName,numberOfWaves) {
        let legend;
        if (disableSelectedMode == true){
            legend = {selectedMode: false};
            $.extend(true, legend, this.getDefaultLegendResponseRatesChart());        
        } else {    
            legend = this.getDefaultLegendResponseRatesChart();
        }      
        let data = {
            data: this.getLegendData()
        }
        $.extend(true, legend, data);            
        let axisLabel = {
            formatter: '{value}%'
        }   
        $.extend(true, axisLabel, this.getDefaultAxisTextStyle());            
        let grid = this.getShowLegend() ? this.getGrid() : this.getGridBackground();
        let gridForNoStacked = {
            "left": 200
        };
        if (isStacked == false){
            $.extend(true, grid, gridForNoStacked);                
        }
        let tooltip = {
            axisPointer: {
                type: 'shadow' 
            },
            formatter: this.toolTipForStackedAreaChart,
            textStyle: this.getDefaultLabelTextStyle()
        };        
        $.extend(true, tooltip, this.getDefaultToolTip('axis'));           
        let xAxis = {
            type: 'value',
            axisLabel: axisLabel,
            splitLine: {
                lineStyle: {
                  color: "#fff",
                }
            },                                             
        };
        if (isStacked == false || disableSelectedMode == true){
            $.extend(true, xAxis, {min: 0, max: 100});
        }        
        let option = {
            toolbox: this.getDefaultToolBox(app,modalId,reportPageTypeName),
            tooltip: tooltip,
            legend: legend,
            grid: grid,
            xAxis: xAxis,
            yAxis: {
                type: 'category',
                data: this.getYAxisData(),
                axisLabel: this.getDefaultAxisTextStyle(),
                axisTick:{
                    show: false,
                },                                                         
            },
            series: this.getSeries(isStacked,numberOfWaves),
            color: this.getColorPalette()
        };
        return option;
    }
}
export default new BarHorizontalService();
