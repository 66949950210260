<template>
  <div v-if="getDisplayView()" class="advisor-unsubscribe panel-admin-body">
    <div class="auth-header">
        <img id="profile-img" src="../../assets/img/main-wratings-logo.png" class="profile-img-card" />
    </div>
    <div class="auth-content" v-if="displayResponseMessage && isAdvisorOwner">
        <div class="advisor-unsubscribe-header">{{ title }}</div>      
        <div class="pt-1">
          <div v-if="noneSelected" >
            <div>You will no longer receive notifications from the wRatings Email Advisor.</div>
            <div class="pt-2">Did you unsubscribe by accident? </div>
            <div class="pt-2">
                <a href="/advisor">Resubscribe to notifications</a>
            </div>
          </div>
          <div v-if="!noneSelected">
            <div>Your preferences on when to receive notifications from the wRatings Email Advisor has been updated.</div>
            <div class="pt-2">Did you want to stop Advisor notifications?</div>
            <div class="pt-2">
                <a :href="'/advisor/unsubscribe/'+this.advisorConfigurationCode">Unsubscribe</a>
            </div>
          </div>
        </div>      
    </div>
    <div class="auth-content" v-if="displayResponseMessage && !isAdvisorOwner">
      <div class="advisor-unsubscribe-header">{{ title }}</div>      
        <div class="pt-1">
          <div v-if="noneSelected" >
            <div>You will no longer receive notifications from the wRatings Email Advisor.</div>
            <div class="pt-2">Did you unsubscribe by accident? </div>
            <div class="pt-2">
                <a href="/advisor">Resubscribe to notifications</a>
            </div>
          </div>
        </div>      
    </div>
    <div class="auth-content" v-if="!displayResponseMessage && !isAdvisorOwner">
      To stop receiving notifications, click Unsubscribe
      <div class="d-flex justify-content-between pt-2">
          <div class="mt-1"></div>
          <button type="submit" class="btn btn-primary mt-2 ml-2 "  @click="unsubscribeAdditionalUser()">
            <span>Unsubscribe</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
    </div>
    <div class="auth-content" v-if="!displayResponseMessage && isAdvisorOwner">
        <div class="advisor-unsubscribe-header">{{ title }}</div>      
        To stop receiving notifications, select None and click Update. <br>
        To adjust your notification frequency, select the new delivery times and click Update.
        <div class="row pt-3">
          <label for="advisorNotificationFrequencyTypeId" class="advisor-label">Frequency Of Notifications</label>
          <div class="col-8">
            <div class="row no-gutters">
              <div class="col-6" v-for="(notification_frequency_option, idxFrequencyOfNotifications) in notification_frequency_types" v-bind:key="idxFrequencyOfNotifications">
                <input
                  type="radio"
                  id="advisorNotificationFrequencyTypeId"
                  name="advisorNotificationFrequencyTypeId"
                  v-model="advisorNotificationFrequencyTypeId"
                  :value="notification_frequency_option.advisorNotificationFrequencyTypeId"
                />
                <label class="pl-1">{{ notification_frequency_option.name }}</label>
              </div>
            </div>      
          </div>
        </div>
        <div class="d-flex justify-content-between pt-2">
          <div class="mt-1">
              <a href="/advisor">View All Preferences</a>
          </div>
          <button type="submit" class="btn btn-primary ml-2 float-right"  @click="unsubscribePrimaryUser()">
            <span>Update</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
    </div>      
  </div>
</template>
<script>

import AdminMixins from "../../mixins/AdminMixins.vue";
import AdminService from "../../services/admin.service";
import AdvisorService from "../../services/advisor.service";
import ViewMixins from "../../mixins/ViewMixins.vue";

export default {
  name: "AdvisorUnsubscribe",
  mixins: [AdminMixins],
  components: {},
  props: {
    advisorConfigurationCode: {
      type: String,
      default: ""
    },    
    emailAddress: {
      type: String,
      default: ""
    },    
    isPrimary: {
      type: Boolean,
      default: true
    },    
  },
  data() {
    return {
      title: "Unsubscribe",
//      advisorConfigurationCode: "PhjejXIFyEWJmb8H84ODgrRVRUbkgu4F",
      advisorNotificationFrequencyTypeId: 0,
      defaultAdvisorNotificationFrequencyTypeId: 0,
      returnMessage: "",
      notification_frequency_types: [],
      displayResponseMessage: false
    };
  },
  computed: {
    isAdvisorOwner: function (){
      return this.isPrimary == "true";
    },
    noneSelected: function (){
      return this.displayResponseMessage && this.advisorNotificationFrequencyTypeId == this.defaultAdvisorNotificationFrequencyTypeId;
    }
  },
  methods: {
    unsubscribeAdditionalUser(){
      AdvisorService.unsubscribeAdditionalUser(this.advisorConfigurationCode, this.emailAddress, this.unsubscribeAdditionalUserCallback,this);
    },    
    unsubscribePrimaryUser(){
      AdvisorService.unsubscribeAdvisorConfiguration(this.advisorConfigurationCode, this.advisorNotificationFrequencyTypeId, this.unsubscribeUserCallback,this);
    },    
    unsubscribeAdditionalUserCallback(response) {
      this.$root.processServerResponse();
      this.returnMessage = response.data.message;
      this.displayResponseMessage = true;
      if (!this.noneSelected){
        this.title = "Updated";
      }
    },
    unsubscribeUserCallback(response) {
      this.$root.processServerResponse();
      this.returnMessage = response.data.message;
      this.displayResponseMessage = true;
      if (!this.noneSelected){
        this.title = "Updated";
      }
    },
    loadData: async function () {
      this.setDisplayView(false);
      this.$root.processServerRequest();
      await AdvisorService.getOptionsForAdvisorUnsubsubscribe(this.getOptionsForAdvisorUnsubsubscribeCallback, this);
      this.setDisplayView(true);
    },    
    getOptionsForAdvisorUnsubsubscribeCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.notification_frequency_types = response.data.data.notification_frequency_types;
        this.advisorNotificationFrequencyTypeId = response.data.data.defaultAdvisorNotificationFrequencyTypeId;
        this.defaultAdvisorNotificationFrequencyTypeId= response.data.data.defaultAdvisorNotificationFrequencyTypeId;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },

  },
  created() {},
  mounted() {
    this.loadData();
  }
};
</script>
