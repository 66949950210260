<template>
  <div class="w-100">
    <div v-show="optionType === 'sector'" class="form-row">
      <div class="col-lg-1">
        <div class="form-group form-row">
          <label class="text-sm-right control-label col-sm-5 col-form-label">Sector</label>
          <div class="col-sm-7">
            <select id="section_options" @change="populateCompaniesBySector(value)">
              <option v-for="(sector_option, idxSectorOptions) in sector_options" v-bind:value="sector_option.sectorId" v-bind:key="idxSectorOptions">
                <span>{{ sector_option.name }}</span>
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-divider  w-100"></div>
    <div class="form-row w-100" :class="{ 'section-based-competitor': optionType === 'sector' }">
      <div class="col-lg-2 col-md-2 col-sm-2" v-for="(chart_company_option, idxChartCompanyOptions) in chart_company_options" v-bind:key="idxChartCompanyOptions">
        <input
          class="competitor_option mr-1"
          type="checkbox"
          v-bind:id="chart_company_option.companyId"
          v-bind:value="chart_company_option.companyId"
          v-bind:name="chart_company_option.companyId"
          @click="updateInput(chart_company_option, $event)"
        />
        <label class="form-check-label" v-bind:for="chart_company_option.companyId">{{ chart_company_option.name }}</label>
      </div>
    </div>
    <div class="dashboard-divider w-100" style="margin-top: 10px;"></div>
  </div>
</template>
<script>
import axios from "axios";

import ChartService from "../../services/chart.service";
import AdminService from "../../services/admin.service";
import ErrorService from "../../services/error.service";
import GoogleService from "../../services/google.service";
import SurveyService from "../../services/survey.service";

export default {
  name: "ChartCompanyOption",
  data() {
    return {
      sector_options: [],
      chart_company_options: []
    };
  },
  computed: {},
  props: {
    optionTitle: {
      type: String,
      default: ""
    },
    optionType: {
      type: String,
      default: ""
    },
    sourceId: {
      type: Number,
      default: 0
    },
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  },
  mounted() {},
  created() {
    if (this.optionType === "top-75") {
      ChartService.getTopCompanies(this.baseUrl, this.loadTopCompanies);
    } else if (this.optionType === "sector") {
      ChartService.getAllSectors(this.baseUrl, this.loadAllSectors);
    }
  },
  methods: {
    updateInput(competitor, event) {
      let action = event.target.checked ? "benchmark company selected" : "benchmark company unselected"
      GoogleService.logBaseEvent(action, SurveyService.getSurveyName(), competitor.name, 0, this);
      this.$parent.displayChartWithCompanies(competitor, event);
    },
    loadAllSectors(response) {
      this.sector_options = response;
    },
    loadTopCompanies(data) {
      this.chart_company_options = data;
    },
    loadCompaniesBySector(response) {
      this.chart_company_options = response.data;
    },
    async populateCompaniesBySector(id) {
      const params = new URLSearchParams();
      params.append("client_id", id);
      await axios
        .post(this.baseUrl + "/api/v1/chart/get-companies-by-sector", params)
        .then(response => this.loadCompaniesBySector(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ChartCompanyOption.populateCompaniesBySector", error, "chart", this);
        });
    }
  },
  watch: {
    sourceId: function() {
      if (this.optionType === "sector") {
        //var newSectorId = this.$parent.$data.clientOptions.find(x => x.companyId === this.companyId).sectorId;
        this.populateCompaniesBySector(this.sourceId);
      } else if (this.optionType === "top-75") {
        ChartService.getTopCompanies(this.baseUrl, this.loadTopCompanies);
      } 
    }
  }
};
</script>
