<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/profiles" @click="$parent.logViewDetailsEvent(title)">
            Growth <br />
            Sequence
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Growth Sequence" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('growth-sequence-save-chart-confirmation')"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-competitive-rank">
        <dashboard-card-dropdown label="View" :read-only="readOnly" :options="view_options" :default-value="view" @change="saveView">
        </dashboard-card-dropdown>
        <dashboard-card-dropdown v-if="showCompetitorsDropdown" label="Competitors" :read-only="readOnly" :options="competitor_options" :default-value="competitor"
          @change="saveCompetitor"></dashboard-card-dropdown>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div class="dashboard-card-body">
      <div class="growth-sequence-dashboard-chart" :style="dashboardChartStyle">
        <chart :options="options"></chart>
      </div>
      <div class="dashboard-x-axis-container" v-show="showSummaryImage">
          <img src="../../assets/img/dashboard-card-components/Card-Moats2.png" alt="xAxis"/>
      </div>
      <div id="growth-sequence-mini">
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
      </div> 
    </div>
    <!-- :updatedModalChartOptions.sync="options.series[0].data" -->
    <dashboard-modal :modal-id="dashboardId" :chart="this.chart" :client-chart="this.clientChart" :dashboard-card-report-page-type-name="reportTypeName" :modal-title="title" 
    :modal-chart-options="options" :show-moat-summary-image="showSummaryImage" :displayModal="displayModal" :key="$displayModal"/>
    <vue-modal :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardSummaryMixins from "../../mixins/DashboardSummaryMixins.vue";
import DashboardService from "../../services/dashboard.service";
import ClientCompetitorService from "../../services/client.competitor.service";
import DashboardCardDropdown from "./DashboardCardDropdown";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";

import Vue from "vue";
import $ from "jquery";

export default {
  name: "DashboardGrowthSequence",
  mixins: [DashboardSummaryMixins],
  components: { DashboardCardDropdown, SaveChart, DashboardCardSkeleton },
  data() {
    return {
      title: "Growth Sequence",
      modalName: "growth-sequence-save-chart-confirmation",
      view_options: [
        { name: "Moat Summary", value: "moat-summary" },
        { name: "Core Moats", value: "breakaway-moats" },
        { name: "Early Mover Moats", value: "early-mover-moats" },
        { name: "Promise Moats", value: "promise-moats" },
        { name: "Emotions", value: "emotions" }
      ],
    };
  },
  props: {},
  computed: {
    imgPath: function(){
      return require(`../../assets/img/dashboard-card-components/Card-Moats2.png`);
    },    
    imgPathHiRes: function(){
      return require(`../../assets/img/dashboard-card-components/Card-Moats-HiRes2.png`);
    }        
  },
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardGrowthSequence::mounted");
    this.view = "moat-summary";
    this.dashboardViewSettingType = DashboardService.DASHBOARD_GROWTH_SEQUENCE_VIEW_SETTING_TYPE;
    this.dashboardCompetitorSettingType = DashboardService.DASHBOARD_GROWTH_SEQUENCE_COMPETITOR_SETTING_TYPE;

    this.chartTitle = "Growth Sequence";
    this.chartTypeName = "growth-sequence";
    this.containerId = "growth-sequence-mini"
	  this.reportTypeName = "Dashboard - Growth Sequence"

  },
  created() { },
  methods: {
    initChart: function (dynamicOptions) {
      // https://echarts.apache.org/examples/en/editor.html?c=scatter-punchCard
      let chartDiv = $(".growth-sequence-dashboard-chart")[0];
      this.initBaseChart(dynamicOptions, chartDiv);
    },

    loadData: async function () {
      if (this.loadCompetitorData == true){
        await ClientCompetitorService.getCompetitors(this.$parent.$parent.clientId, this.timePeriodId, this.loadCompetitors);
      } 
      this.setUpCard();
      DashboardService.getGrowthSequence(this.timePeriodId, this.competitor, this.view, this.getSummaryCallback, this);
    },
    updateCard: function () {
      this.setUpCard();
      DashboardService.getGrowthSequence(this.timePeriodId, this.competitor, this.view, this.getSummaryCallback, this);
    },
  },
};
</script>
