<template>
  <div></div>
</template>

<script>
import UserService from "../services/user.service";
import ClientService from "../services/client.service";
import jQuery from "jquery";

export default {
  name: "GlobalMixins",
  data() {
    return {
        isAdmin: false,
        isAdvancedUser: false,
        isInsiderUser: false,
        isW5OUser: false,
        isExecutiveUser: false,
        
    };
  },
  created() {
        this.setUserData();
  },
  methods: {
    setUserData() {
      if (this.isLoggedIn) {
            this.isAdmin = UserService.isAdmin(this.currentUser);
            this.isAdvancedUser = UserService.isAdvancedUser(this.currentUser);
            this.isInsiderUser = UserService.isInsiderUser(this.currentUser);
            this.isW5OUser = UserService.isW5OUser(this.clientObject, this.isAdmin);
            this.isExecutiveUser = UserService.isExecutiveUser(this.clientObject, this.isAdmin);
        }
    },
  },
  watch: {},
  mounted() {
        this.setUserData();
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.state.auth.status.loggedIn;
    },    
    currentUser: function() {
      if (this.isLoggedIn) {
        return this.$store.state.auth.user;        
      } else {
        return null;
      }
    },    
    clientObject: function() {
      return this.$root.getClientObject();
    },    
    clientId: function() {
      if (typeof this.clientObject.clientId != "undefined") {
        return this.clientObject.clientId;
      } else {
        return 0;
      }
    },
    clientCode: function() {
      if (typeof this.clientObject.clientCode != "undefined") {
        return this.clientObject.clientCode;
      } else {
        return "";
      }
    },
    clientName: function() {
      if (typeof this.clientObject.name != "undefined") {
        return this.clientObject.name;
      } else {
        return "";
      }
    },
    surveyCode: function() {
      return this.$root.getSurveyCode();
    },
    displaySurveyWidget: function() {
      if (!jQuery.isEmptyObject(this.clientObject.surveyCodes)) {
        return this.clientObject.surveyCodes.length > 1;
      }
      return false;
    },    
  }
};
</script>