<template>
  <div class="login col-md-12">
    <div class="card card-container">
      <img id="profile-img" src="../assets/img/main-wratings-logo.png" class="profile-img-card" />
      <div class="display-3 mt-5">Page not found.</div>
      <div class="paragraph-text mt-2">
        <br />
        <br />Try going back to home page and repeating your action. You can also try logging in or contact support. See links below.
      </div>
      <div class="d-flex flex-row pt-5">
        <router-link class="d-flex align-items-center dropdown-item" :to="{ name: 'competitive-dashboard' }">
          <font-awesome-icon :icon="['fa', 'flag-checkered']" />
          <span class="pl-2">Home</span>
        </router-link>
        <router-link class="dropdown-item" :to="{ name: 'contact-us' }">
          <img src="/img/favicon-wratings-logo.png" width="20" height="20" />
          <span class="pl-2">Support</span>
        </router-link>
        <router-link class="dropdown-item" :to="{ name: 'login' }">
          <font-awesome-icon :icon="['fa', 'sign-in']" />
          <font-awesome-icon :icon="['fas', 'cubes']" size="lg" />
          <span class="pl-2">Login</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Error",
  data() {
    return {};
  },
  created() {},
  components: {},
  computed: {},
  props: {},
  mounted() {},
  methods: {}
};
</script>
