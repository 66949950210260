<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="metric-group-list container">
      <div class="button-container">
        <div class="button-left"></div>
        <div class="button-right">
          <a class="pr-2" href="#" @click="showImportModal">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Import Metric Group Data <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
          </a>
          <a href="/api/v1/admin/download-metric-group-import-template" @click.prevent="downloadItem()" id="DownloadMetricGroupImportTemplate">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Download Template <font-awesome-icon :icon="['fa', 'download']"/></span>
            </button>
          </a>
        </div>
      </div>
      <div class="data-container">
        <div class="col-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th>Metric Group Id</th>
                <th>Name</th>
                <th>Metric Type</th>
                <th>Metric Display Type</th>
                <th>Growth Rate</th>
                <th>Client Id</th>
                <th>Client Name</th>
                <th>Active</th>
                <th>Date Created</th>
                <th>Date Updated</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
    <vue-modal name="import-all-metric-group-data-file" :width="368" :height="200">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Import Metric Group Data</h5>
        </div>
        <div class="form-group form-row p-2">
          {{ returnMessage }}
        </div>
        <div class="form-group form-row">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importMetricGroupUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="
              $modal.hide('import-all-metric-group-data-file');
              $router.go(0);
            "
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import MetricsService from "../../services/metrics.service";
import UserService from "../../services/user.service";

export default { 
  name: "AllMetricGroupList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      importMetricGroupImportFile: "",
      returnMessage: "",
      title: "Metric Group List",
      table: {
        id: "metric-list-table",
        columns: []
      },
    };
  },
  methods: {
    showImportModal(){
        this.returnMessage = "";
        this.$modal.show('import-all-metric-group-data-file');
    },
    async getTableData() {
      this.table.columns = MetricsService.getTableColumnData();
      this.getMetrics();
    },
    async getMetrics() {
      this.$root.processServerRequest("Loading all metric groups");
      MetricsService.getAllMetricGroupList(this.getTableDataCallback, this);
    },
    getDownloadFilename() {
      return "Metric Group Data Import Template";
    },
    onFileChange: function() {
      this.importMetricGroupImportFile = this.$refs.importMetricGroupUpload.files[0];
    },
    async importFile() {
      this.$root.processServerRequest("Importing metric group data");
      this.setModalLoading(true);
      let formData = new FormData();
      formData.append("file", this.importMetricGroupImportFile);
      formData.append("userId", UserService.getUserId());

      let headers = { "Content-Type": "multipart/form-data" };
      $.extend(headers, await authHeader());

      await axios
        .post(this.baseUrl + "/api/v1/admin/import-all-metric-group-data", formData, { headers: headers })
        .then(response => this.importFileCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("MetricGroupList.importFile", error, "admin", this);
        });
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);

      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.returnMessage = response.data.message;
        this.$modal.hide("import-all-metric-group-data-file");
        this.$router.go(0);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  computed: {
    downloadItemUrl: function() {
      return this.baseUrl + "/api/v1/admin/download-metric-group-import-template";
    }
  },
  created() {
    if (this.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [
        [6, "asc"]
      ];
    }
  },
  props: {},
  mounted() {}
};
</script>
