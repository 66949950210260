<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container response-message-list">
      <div>
        <div class="row">
          <div class="col-3">
            <div class="form-group form-inline">
              <label for="startDate" class="mr-1">Start Date</label>
              <datepicker
                format="MMM dd yyyy"
                v-model="startDate"
                :typeable="true"
                wrapper-class=""
                input-class="form-control required w-100 bg-white"
                name="startDate"
              ></datepicker>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group form-inline ml-2">
              <label for="endDate" class="mr-1">End Date</label>
              <datepicker format="MMM dd yyyy" v-model="endDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="endDate"></datepicker>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group form-inline">
              <label for="status" class="mr-1">Status</label>
              <select id="status" name="status" class="custom-select required" v-model="status">
                <option v-for="(status_option, idxStatusOptions) in this.status_options" v-bind:value="status_option.value" v-bind:key="idxStatusOptions">
                  {{ status_option.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group form-inline ml-2">
              <label for="duration" class="mr-1">Duration</label>
              <select id="duration" name="duration" class="custom-select required" v-model="duration">
                <option v-for="(duration_option, idxDurationOptions) in this.duration_options" v-bind:value="duration_option.value" v-bind:key="idxDurationOptions">
                  {{ duration_option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-group form-inline ml-2">
              <label for="response_message_title" class="mr-1">Title</label>
              <select id="response_message_title" name="response_message_title" class="custom-select required" v-model="response_message_title">
                <option
                  v-for="(response_message_title_option, idxresponse_message_titleOptions) in this.response_message_title_options"
                  v-bind:value="response_message_title_option.value"
                  v-bind:key="idxresponse_message_titleOptions"
                >
                  {{ response_message_title_option.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group form-inline ml-2">
              <label for="response_message_user" class="mr-1">User</label>
              <select id="response_message_user" name="response_message_user" class="custom-select required" v-model="response_message_user_id">
                <option
                  v-for="(response_message_user_option, idxresponse_message_userOptions) in this.response_message_user_options"
                  v-bind:value="response_message_user_option.value"
                  v-bind:key="idxresponse_message_userOptions"
                >
                  {{ response_message_user_option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="pr-2">
            <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="loadData()">Run</button>
          </div>
          <div class="pr-2">
            <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="reset()">Reset</button>
          </div>
        </div>
      </div>
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import moment from "moment";
Vue.prototype.moment = moment;
import Datepicker from "vuejs-datepicker";

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import AdminService from "../../services/admin.service";
import TableService from "../../services/table.service";
import AlertService from "../../services/alert.service";

export default {
  name: "ResponseMessageList",
  mixins: [AdminMixins, DataTableMixins],
  components: { Datepicker },
  data() {
    return {
      title: "Response Message List",
      table: TableService.getResponseMessageListTableAttributes(),
      startDate: new Date(2020, 0, 1),
      endDate: new Date(),
      status: 0,
      duration_options: [
        { name: "Select Duration", value: 0 },
        { name: "Less than 1 Second", value: 1 },
        { name: "More than 1 Second", value: 2 }
      ],
      duration: 0,
      status_options: [
        { name: "Select Status", value: 0 },
        { name: "Success", value: 200 },
        { name: "Success with Warnings", value: 201 },
        { name: "Failed", value: 400 },
        { name: "No Status", value: -1 }
      ],
      response_message_title: "",
      response_message_title_options: [{ name: "Select Title", value: "" }],
      response_message_user_id: 0,
      response_message_user_options: [
        { name: "Select User", value: 0 },
        { name: "No User", value: -1 }
      ]
    };
  },
  methods: {
    reset() {
      this.status = 0;
      this.duration = 0;
      this.response_message_title = "";
      this.response_message_user_id = 0;
      this.startDate = new Date(2020, 0, 1);
      this.endDate = new Date();
    },
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      AdminService.getResponseMessageList(
        this.startDate,
        this.endDate,
        this.status,
        this.duration,
        this.response_message_title,
        this.response_message_user_id,
        this.getResponseMessageListCallback,
        this
      );
    },
    getResponseMessageListCallback(response) {
      this.$root.processServerResponse();
      this.response_message_title_options = response.data.data.responeMessageTitleOptions;
      this.response_message_user_options = response.data.data.responeMessageUserOptions;
      this.table.data = response.data.data.messageList;
      $.extend(this.table.configuration, { columns: this.table.columns });
      $.extend(this.table.configuration, { data: this.table.data });
      this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
      const vm = this;
      this.table.dataTable
        .on("click", "tr", function(e, dt, type, indexes) {
          vm.table.rowSelected = true;
          var rowData = vm.table.dataTable
            .rows(this)
            .data()
            .toArray();
          $("#" + vm.table.id + " .selected").removeClass("selected");
          $(this).toggleClass("selected");
          if (!$.isEmptyObject(rowData)) {
            vm.table.rowSelectedId = rowData[0].id;
            vm.postRowSelect(rowData[0], true);
          }
        })
        .on("deselect", function(e, dt, type, indexes) {
          vm.table.rowSelected = false;
          vm.table.rowSelectedId = 0;
          if ($(this).hasClass("selected")) {
            $(this).removeClass("selected");
          }
          var rowData = vm.table.dataTable
            .rows(this)
            .data()
            .toArray();
          if (!$.isEmptyObject(rowData)) {
            vm.postRowSelect(rowData[0], false);
          }
        });
      AlertService.closeAlert();
      this.buildResponsiveTable();
    },
    loadData() {
      this.getTableData();
      this.table.configuration.responsive = false;
      this.table.configuration.order = [[9, "desc"]];
      this.table.configuration.dom = "frtpB";
    }
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.loadData();
    }
  },
  props: {},
  mounted() {}
};
</script>
