<template>
  <div>
    <div class="client-competitor-header">
      <div class="col-3"></div>
      <div class="col-3 text-center mt-3">Competitor Name</div>
      <div class="col-2 pl-3 mt-3">Color</div>
      <div class="col-2 pl-3 text-center">Display in Survey/Charts</div>
    </div>
    <div v-for="(competitor, idxCompetitors) in clientCompetitors" :key="idxCompetitors" class="form-group form-inline">
      <label :for="'comp' + (idxCompetitors + 1)" class="col-3">Competitor {{ idxCompetitors + 1 }}</label>
      <input
        v-model="clientCompetitors[idxCompetitors].name"
        :id="'competitor' + (idxCompetitors + 1)"
        :name="'competitor' + (idxCompetitors + 1)"
        :disabled="disableWidgets"
        class="form-control w-30"
        type="text"
        @change="saveClientCompetitorEvent($event, idxCompetitors)"
      />
      <div class="ml-1"></div>
      <button class="fg-color-sample" @click="pickColor(idxCompetitors)" :style="getStyle(idxCompetitors)"></button>
      <div class="client-competitor-add-remove">
        <a v-if="displayAddCompetitor(idxCompetitors)" href="#!" @click="addCompetitor" class="pl-2 btn-md">
          <font-awesome-icon :icon="['fa', 'plus-circle']" />
        </a>
        <a v-if="displayRemoveCompetitor(idxCompetitors)" href="#!" @click="deleteCompetitor(idxCompetitors)" class="pl-2 btn-md">
          <font-awesome-icon :icon="['fa', 'minus-circle']" />
        </a>
      </div>
      <input type="checkbox" 
        v-model="clientCompetitors[idxCompetitors].display"
        :id="'competitor' + (idxCompetitors + 1)"
        :name="'competitor' + (idxCompetitors + 1)"
        :disabled="disableWidgets"
        true-value="Y" 
        false-value="N"        
        @click="saveClientCompetitorDisplayEvent($event, idxCompetitors)" />
    </div>
    <div class="form-row float-right mr-5 mt-3">
      <label>Note: Click to set competitor's color</label>
    </div>
    <div class="client-competitor-order">
      <div class="message">Drag and Drop a competitor’s name to change the order of how they are displayed in the survey and chart criteria, then click Save Order to save & update the table above.</div>
      <VueDraggable
        v-model="clientCompetitors"
        :disabled="!enabled"
        class="list-group draggable-container w-100"
        ghost-class="draggable-ghost"
        @end="endOfDragClientCompetitors"
        options="people"
      >
        <div v-show="element.name != ''" class="list-group-item draggable-card" v-for="element in clientCompetitors" :key="element.clientCompetitorsId">
          <i class="fa fa-caret-right" aria-hidden="true"></i>
          <span class="pl-1">{{ element.name }}</span>
        </div>
      </VueDraggable>      
    </div>
    <color-picker ref="colorPicker" @save="updateColor" />
  </div>
</template>

<script>
import GoogleService from "../services/google.service";
import SurveyService from "../services/survey.service";
import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";
import ClientCompetitorService from "../services/client.competitor.service";
import ClientService from "../services/client.service";
import ColorPicker from "../modals/ColorPicker.vue";
import { VueDraggable } from 'vue-draggable-plus';

export default {
  name: "ClientCompetitors",
  components: {
    ColorPicker,
    VueDraggable
  },
  data() {
    return {
      numberOfBaseCompetitors: 5,
      enabled: true,
      currentIndex: 0,
      clientCompetitors: [],
    };
  },
  methods: {
	  checkMove: function(e) {},
    endOfDragClientCompetitors: function(event) {
      let oldIndex = event.oldIndex;
      let newIndex = event.newIndex;
      this.dragging = false;
      if (typeof this.clientCompetitors[oldIndex] != "undefined" &&
          typeof this.clientCompetitors[newIndex] != "undefined" ){
          this.clientCompetitors[newIndex].sequenceNumber = newIndex;
          this.clientCompetitors[oldIndex].sequenceNumber = oldIndex;
          for (let index = 0; index < this.clientCompetitors.length; index++) {
            this.clientCompetitors[index].sequenceNumber = index;
          }
          this.$emit("save", this.clientCompetitors);
          this.$emit("enable-save-order-button");
          this.$parent.disableSaveOrderButton;
      }
    },			    
    pickColor(index) {
      this.currentIndex = index;
      this.$refs.colorPicker.$data.currentColor.hex = this.clientCompetitors[this.currentIndex].competitorColor;
      this.$modal.show("color-picker");
    },
    getStyle(index) {
      if (this.clientCompetitors[index].competitorColor == ""){
          if (index < this.defaultColors.length - 1) {
            this.clientCompetitors[index].competitorColor = this.defaultColors[index].competitorColor
          } else {
            this.clientCompetitors[index].competitorColor = "#000";
          }
      } 
      return { background: this.clientCompetitors[index].competitorColor };
    },
    getNextCompetitorColor() {
      let currentCompetitorCount = this.clientCompetitors.length;
      if (currentCompetitorCount < this.defaultColors.length && typeof (this.defaultColors[currentCompetitorCount] != "undefined")) {
        return this.defaultColors[currentCompetitorCount].competitorColor;
      } else {
        return "#000";
      }
    },
    updateColor(result) {
      this.clientCompetitors[this.currentIndex].competitorColor = result;
      this.saveCompetitorColor(result);
    },
    saveCompetitorColor(competitorColor) {
      if (this.autoSave) {
        this.$root.processServerRequest("Saving competitor data");
        this.$parent.survey.buildRequired = "Y";
        this.$parent.disableButton = true;
        ClientService.clearCache();
        ClientCompetitorService.updateCompetitorColor(
          this.clientCompetitors[this.currentIndex].clientCompetitorsId,
          competitorColor,
          this.surveyId,
          this.saveClientCompetitorCallback,
          this
        );
      }
    },
    saveClientCompetitorCallback(response) {
      this.$root.processServerResponse();
      this.$parent.disableButton = false;
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$emit("save", response.data.data);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        ErrorService.processError("ClientCompetitors.saveClientCompetitorCallback", response.data.message, "survery-designer", this);
      }
    },
    saveClientCompetitorDisplayEvent: function(event, index) {
      let display = event.target.checked ? "Y" : "N";
      this.$root.processServerRequest("Saving Competitor data");
      this.$parent.survey.buildRequired = "Y";
      this.$parent.disableButton = true;
      ClientService.clearCache();
      ClientCompetitorService.updateCompetitorDisplay(
        this.clientCompetitors[index].clientCompetitorsId,
        display,
        this.surveyId,
        this.saveClientCompetitorCallback,
        this
      );
    },
    saveClientCompetitorEvent: function(event, index) {
      this.value = event.target.value;
      if (this.autoSave) {
        this.$root.processServerRequest("Saving Competitor");
        this.$parent.survey.buildRequired = "Y";
        this.$parent.disableButton = true;
        ClientService.clearCache();
        ClientCompetitorService.saveClientCompetitor(this.clientCompetitors[index], this.surveyId, this.saveClientCompetitorCallback, this);
      }
    },
    displayAddCompetitor: function(idxCompetitors) {
      if (this.disableWidgets == true) {
        return false;
      }
      let currentCompetitorSize = this.clientCompetitors.length;
      if (idxCompetitors == currentCompetitorSize - 1) {
        return true;
      } else {
        return false;
      }      
    },
    displayRemoveCompetitor: function(idxCompetitors) {
      if (this.disableWidgets == true) {
        return false;
      }
      return (idxCompetitors >= 1);
    },
    addCompetitor: function() {
       GoogleService.logBaseEvent("competitor added", SurveyService.getSurveyName(), "new competitor", 0, this);         
       this.clientCompetitors.push({
         name: "",
         clientCompetitorsId: 0,
         clientId: this.clientId,
         surveyId: this.surveyId,
         competitorColor: this.getNextCompetitorColor(),
         display: "Y",
         sequenceNumber: 100,
       });
    },
    deleteCompetitor: function(idxCompetitors) {
      let clientCompetitorsId = this.clientCompetitors[idxCompetitors].clientCompetitorsId;
      let name = this.clientCompetitors[idxCompetitors].name;
      this.clientCompetitors.splice(idxCompetitors, 1);
      if (this.surveyId != 0) {
        this.$parent.survey.buildRequired = "Y";
      }
      if (clientCompetitorsId != 0) {
        this.$parent.disableButton = true;
        this.$root.processServerRequest("Deleting competitor");
        ClientCompetitorService.deleteClientCompetitor(clientCompetitorsId, name, this.surveyId, this.deleteCompetitorCallback, this);
      }
    },
    deleteCompetitorCallback(response) {
      this.$root.processServerResponse();
      this.$parent.disableButton = false;
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        ErrorService.processError("ClientCompetitors.deleteCompetitorCallback", response.data.message, "survery-designer", this);
      }
    }
  },
  computed: {},
  mounted(){
    this.clientCompetitors = JSON.parse(JSON.stringify(this.inputClientCompetitors));
  },
  props: {
    disableWidgets: {
      type: Boolean,
      default: false
    },
    autoSave: {
      type: Boolean,
      default: false
    },
    clientId: {
      type: Number,
      default: ClientService.getClientId()
    },
    surveyId: {
      type: Number,
      default: 0
    },
    inputClientCompetitors: {
      type: Array,
      default: () => [
        { name: "", clientCompetitorsId: 0, competitorColor: "#A80000", sequenceNumber: 100, display: "Y" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#948A54", sequenceNumber: 100, display: "Y" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#F29700", sequenceNumber: 100, display: "Y" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#84E2E0", sequenceNumber: 100, display: "Y" },
        { name: "", clientCompetitorsId: 0, competitorColor: "#B3A2C7", sequenceNumber: 100, display: "Y" }
      ]
    },
    defaultColors: {
      type: Array,
      default: () => [
        { competitorColor: "#A80000" },
        { competitorColor: "#948A54" },
        { competitorColor: "#F29700" },
        { competitorColor: "#84E2E0" },
        { competitorColor: "#B3A2C7" },
        { competitorColor: "#753C2C" },
        { competitorColor: "#BCB48A" },
        { competitorColor: "#CC7E00" },
        { competitorColor: "#FDED86" },
        { competitorColor: "#433456" }
      ]
    }
  }
};
</script>
