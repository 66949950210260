import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import ClientService from "./client.service";
import TableService from "./table.service";

import moment from "moment";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;

const API_URL = AdminService.getBaseUrl() + "/api/v1/client";
const ADMIN_URL = AdminService.getBaseUrl() + "/api/v1/admin";

class MetricsService {
  setMetricDropdownOptions(inputData, vm, includeAll = false) {
    vm.metric_options = [];
    $.each(inputData, function(i, item) {
      let metricOption = includeAll ? JSON.parse(JSON.stringify(item)) : {};
      metricOption.name = item.name;
      metricOption.value = String(item.metricGroupId);

      vm.metric_options.push(metricOption);

      // Create a second entry for %
      let metricOptionPercent = includeAll ? JSON.parse(JSON.stringify(item)) : {};
      metricOptionPercent.name = metricOption.name + " Growth %";
      metricOptionPercent.value = String(item.metricGroupId) + "-growth";

      vm.metric_options.push(metricOptionPercent);

      if (i == 0) {
        vm.metricId = metricOption.value;
      }
    });
  }
  getPredictiveAnalyticsColumns(id) {
    if (id == "predictive-analytics-table") {
      return [
        TableService.getOrderSequence(),
        {
          targets: [0],
          visible: true,
          className: "predictive-text-column predictive-border-column",
          createdCell: function(td, cellData, rowData, row, col) {
            let baseClass = "predictive-text-column predictive-border-column";
            if (cellData == "Customer NPS") {
              $(td).addClass(baseClass + " customer-nps-column ");
            } else if (
              cellData == "Connected" ||
              cellData == "Precise" ||
              cellData == "Like-Minded" ||
              cellData == "Stable" ||
              cellData == "Imaginative" ||
              cellData == "Tough-Minded" ||
              cellData == "Flexible" ||
              cellData == "Detached" ||
              cellData == "Practical" ||
              cellData == "Assurance" ||
              cellData == "Curiosity" ||
              cellData == "Strictness" ||
              cellData == "Trust" ||
              cellData == "Activity" ||
              cellData == "Anxious"
            ) {
              $(td).addClass(baseClass + " emotional-needs-column ");
            }
          }
        },
        {
          targets: "_all",
          width: "50px"
        },
        {
          targets: [1, 2, 3, 4, 5, 6],
          createdCell: function(td, cellData, rowData, row, col) {
            if (cellData >= 0.8 && cellData < 1.0) {
              $(td).addClass("very-strong-correlation");
            } else if (cellData >= 0.5 && cellData < 0.8) {
              $(td).addClass("strong-correlation");
            } else if (cellData >= 0.3 && cellData < 0.5) {
              $(td).addClass("mild-correlation");
            }
          },
          render(v) {
            if (v != null && !isNaN(v) && v != 0) {
              return Number(v).toFixed(2);
            }
            return TableService.getEmptyCell();
          }
        },
        TableService.getPercentTableAttribute([7], "", 50, 2),
        TableService.getNumericTableAttribute([1, 2, 3, 4, 5, 6, 9], "", 50, 2),
        TableService.getNumericTableAttribute([5, 6, 8, 10, 11], "predictive-border-column", 50, 2)
      ];
    } else if (id == "needs-table") {
      return [TableService.getOrderSequence(), { targets: [0], visible: false, sortable: false }, { targets: "_all", width: "50px" }];
    } else {
      return [TableService.getOrderSequence(), { targets: [0], visible: true }, { targets: "_all", width: "50px" }];
    }
  }
  getPredictiveTableAttributes(id) {
    return {
      id: id,
      class: "table display wrap row-border table-hover",
      columns: [],
      configuration: {
        dom: '<"w-100"<"chart-header-container d-flex"><B>>frtip',
        searching: false,
        paging: false,
        select: true,
        processing: true,
        responsive: true,
        destroy: true,
        info: false,
        ordering: true,
        keys: true,
        bSort: true,
        columnDefs: this.getPredictiveAnalyticsColumns(id),
        language: {
          decimal: ",",
          thousands: "."
        },
        buttons: []
      }
    };
  }
  async getMetricGroupByClientId(callback, chartApp) {
    if (!UserService.isLoggedIn()) {
      return;
    }
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("clientCode", ClientService.getClientCode());

    await axios
      .post(API_URL + "/get-metric-groups-by-client-id", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricServices.getMetricGroup", error, "admin", chartApp);
      });
  }
  async getMetricGroupBySurveyCode(surveyCode, callback, chartApp) {
    if (!UserService.isLoggedIn() || surveyCode == "") {
      return;
    }
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("surveyCode", surveyCode);

    await axios
      .post(API_URL + "/get-metric-groups-by-survey-code", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricServices.getMetricGroup", error, "admin", chartApp);
      });
  }

  async getMetricGroup(metricGroupId, mode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("metricGroupId", metricGroupId);
    params.append("mode", mode);

    await axios
      .post(API_URL + "/get-metric-group", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricServices.getMetricGroup", error, "admin", chartApp);
      });
  }
  async getAllMetricGroupList(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    await axios
      .post(ADMIN_URL + "/get-all-metric-group-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricServices.getAllMetricGroupList", error, "admin", chartApp);
      });
  }
  async getAllMetricList(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    await axios
      .post(ADMIN_URL + "/get-all-metric-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricServices.getAllMetricGroupList", error, "admin", chartApp);
      });
  }
  async getMetricList(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("clientCode", ClientService.getClientCode());
    await axios
      .post(API_URL + "/get-metric-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricServices.getMetricList", error, "admin", chartApp);
      });
  }
  async getMetricTypes(callback, chartApp) {
    await axios
      .get(API_URL + "/get-metric-types", { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricServices.getMetricDisplayTypes", error, "admin", this);
      });
  }
  async getMetricDisplayTypes(callback, chartApp) {
    await axios
      .get(API_URL + "/get-metric-display-types", { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricServices.getMetricDisplayTypes", error, "admin", this);
      });
  }
  async saveMetricList(stringMetricList, metricGroupId, mode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("stringMetricList", stringMetricList);
    params.append("metricGroupId", metricGroupId);
    params.append("mode", mode);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/save-metrics", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricsService.saveMetricList", error, "admin", chartApp);
      });
  }
  async deleteMetricGroup(metricGroupId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("metricGroupId", metricGroupId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/delete-metric-group", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricsService.deleteMetricGroup", error, "admin", chartApp);
      });
  }
  async deleteMetric(metricId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("metricId", metricId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_URL + "/delete-metric", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricsService.deleteMetric", error, "admin", chartApp);
      });
  }
  async importFile(importClientImportFile, metricGroup, mode, callback, chartApp) {
    let formData = new FormData();
    formData.append("file", importClientImportFile);
    formData.append("mode", mode);
    formData.append("userId", UserService.getUserId());
    formData.append("clientCode", ClientService.getClientCode());
    formData.append("metricGroupId", metricGroup.metricGroupId);
    formData.append("metricDisplayTypeId", metricGroup.metricDisplayTypeId);
    formData.append("metricGroupName", metricGroup.name);
    formData.append("calculatedGrowthRate", metricGroup.calculatedGrowthRate);
    formData.append("metricTypeId", metricGroup.metricTypeId);
    formData.append("minimumOutliers", metricGroup.minimumOutliers); 
    formData.append("maximumOutliers", metricGroup.maximumOutliers);

    let headers = { "Content-Type": "multipart/form-data" };
    $.extend(headers, await authHeader());

    await axios
      .post(API_URL + "/import-metrics", formData, { headers: headers })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricsService.importFile", error, "admin", chartApp);
      });
  }

  async saveMetricGroup(mode, metricGroup, stringConfiguration, stringMetricList, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("mode", mode);
    params.append("metricGroupId", metricGroup.metricGroupId);
    params.append("metricDisplayTypeId", metricGroup.metricDisplayTypeId);
    params.append("metricGroupName", metricGroup.name);
    params.append("calculatedGrowthRate", metricGroup.calculatedGrowthRate);
    params.append("metricTypeId", metricGroup.metricTypeId);
    params.append("minimumOutliers", metricGroup.minimumOutliers); 
    params.append("maximumOutliers", metricGroup.maximumOutliers);
    params.append("clientCode", ClientService.getClientCode());
    params.append("userId", UserService.getUserId());
    params.append("stringMetricList", stringMetricList);
    params.append("stringConfiguration", stringConfiguration);

    await axios
      .post(API_URL + "/save-metric-group", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("MetricGroups.saveMetricGroup", error, "admin", chartApp);
      });
  }
  getDefaultMetricGroup() {
    return {
      metricGroupId: 0,
      metricTypeId: 0,
      metricDisplayTypeId: 0,
      name: "",
      calculatedGrowthRate: "Sequentially",
      clientId: ClientService.getClientId(),
      configuration: {},
      minimumOutliers: null, 
      maximumOutliers: null,
    };
  }
  getDefaultMetric() {
    return {
      metricGroupId: 0,
      metricDisplayTypeId: 0,
      metricGrowth: 0,
      metricId: 0,
      metric: 0,
      year: new Date().getFullYear(),
      period: 1
    };
  }
  getCurrentDateDefaultMetric() {
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1;
    let currentPeriod = Math.ceil(currentMonth / 3);
    return {
      metricGroupId: 0,
      metricDisplayTypeId: 0,
      metricGrowth: 0,
      metricId: 0,
      metric: 0,
      year: currentDate.getFullYear(),
      period: currentPeriod
    };
  }
  getTableColumnData() {
    return [
      {
        data: "metricGroupId",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "id",
        width: "8rem"
      },
      { data: "name", name: "name", width: "10rem" },
      { data: "metricTypeName", name: "metricTypeName", width: "10rem" },
      { data: "metricDisplayTypeName", name: "metricDisplayTypeName", width: "10rem" },
      { data: "calculatedGrowthRate", name: "calculatedGrowthRate", width: "10rem" },
      { data: "clientId", name: "clientId", class: "text-left", width: "5rem" },
      { data: "clientName", name: "clientName", width: "10rem" },
      TableService.getActiveColumn(),
      TableService.getDateColumn("dateCreated"),
      TableService.getDateColumn("dateModified")
    ];
  }
  getMetricTableColumnData() {
    return [
      {
        data: "metricId",
        createdCell: function (td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "id",
        width: "6rem"
      },
      { data: "metric", name: "metric", class: "text-left", width: "5rem" },
      { data: "metricGrowth", 
        name: "metricGrowth", 
        class: "text-left",
        width: "8rem",
        render: function(data, type, row) {
          let v = row.metricGrowth;
          let useValueToCalculateGrowth =  typeof row.calculatedGrowthRate != "undefined" && (row.calculatedGrowthRate == "YoY-Value"|| row.calculatedGrowthRate == "Sequentially-Value");    
          if (v != null && !isNaN(v)) {
            if (useValueToCalculateGrowth) {
              return Number(v).toFixed(2);
            }
            return Number(v).toFixed(2) + "%";
          } else {
            return "--";
          }
        }      
      },
      { data: "year", name: "year", class: "text-left", width: "5rem" },
      { data: "period", name: "period", class: "text-left", width: "5rem" },
      {
        data: "metricGroupId",
        createdCell: function (td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "id",
        width: "6rem"
      },
      { data: "name", name: "name", width: "10rem" },
      { data: "metricTypeName", name: "metricTypeName", width: "10rem" },
      { data: "metricDisplayTypeName", name: "metricDisplayTypeName", width: "10rem" },
      { data: "clientId", name: "clientId", class: "text-left", width: "5rem" },
      { data: "clientName", name: "clientName", width: "10rem" },
      TableService.getActiveColumn(),
      TableService.getDateColumn("dateCreated"),
      TableService.getDateColumn("dateModified")
    ];
  }
}

export default new MetricsService();
