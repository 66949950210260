<template>
    <div id="w-score-rank-snap-id" class="rank-body pl-4">
        <div class="tier-labels">
            <div>High Performance</div>
            <div>Low Performance</div>
        </div>
        <div class="tier-bar">
            <div v-for="(tierInstance, idxTiers) in tiers" :key="idxTiers" :class="getTierClass(tierInstance)">
                <span>{{ tierInstance.name }}</span>
            </div>
        </div>            
        <div :id="wScoreRankChartDisplayIdName" class="rank-chart-display">
            <div></div>
            <div class="rank-chart-line-container">
              <div id="rank-chart-x-container">
                <img src="../../../assets/img/wRatings-X.png" alt="wRatings Admin" width="20" height="20" />
              </div>
              <div class="rank-chart-line"></div>
            </div>
        </div>
        <div class="rank-display">
            <div class="rank-display-title">{{ titleHtml }}</div>
            <div v-if="score != 0" class="score">{{ score }}</div>
            <div v-if="message != ''" class="rank-position">{{ message }}</div>
        </div>
    </div>
</template>

<script>
import RankBodyMixins from "../../../mixins/RankBodyMixins.vue";

import $ from "jquery";
export default {
  name: "WScoreRankBody",
  mixins: [RankBodyMixins],
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {
    wScoreRankChartDisplayIdName: function(){
      if (this.isModal){
        return "w-score-rank-chart-display-container-modal";
      } else {
        return "w-score-rank-chart-display-container";
      }
    },
    wScoreRankChartDisplayContainer: function(){
      return $("#"+this.wScoreRankChartDisplayIdName);
    }
  },
  mounted() {
    this.calculateHeight();
  },
  created() {},
  methods: {
    //create a method that returns a height based on the score 
    calculateHeight: function() {
      //console.log("calculateHeight score: " + this.score + " baseHeight: " + this.baseHeight);
      let heightPercent = this.score / 100;
      let height = this.baseHeight * heightPercent;
      let rankLine = this.wScoreRankChartDisplayContainer.find(".rank-chart-line-container .rank-chart-line");
      rankLine.css("height", height + "px");
    },
    getTierClass: function(tierInstance) {
      let className = "tier-block";
      if (tierInstance.name == this.tier){
        className += " selected"
      } 
      return className;
    },
  },
  watch: {
    score: function() {
//      console.log("score " + this.score);
      this.calculateHeight();
    }
  }
};
</script>
