<template>
  <div v-show="showHeaderMenu">
    <div class="d-flex md-stepper-horizontal">
      <div v-bind:class="[this.isActivePage(1) ? activeClass : '', notActiveClass]" v-on:click="goToStudyDesign">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'wrench']" />
        </div>
        <div class="md-step-title">Design</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(2) ? activeClass : '', notActiveClass]" v-on:click="goToRespondentDownload">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'users']" />
        </div>
        <div class="md-step-title">Respondent</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(4) ? activeClass : '', notActiveClass]" v-on:click="goToGrowthAnalysis">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'bullseye']" />
        </div>
        <div class="md-step-title">Analytics</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(3) ? activeClass : '', notActiveClass]" v-on:click="goToUserSettings">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'address-card']" />
        </div>
        <div class="md-step-title">Preferences</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";

import Vue from "vue";
import MenuMixins from "../../mixins/MenuMixins.vue";
import GlobalMixins from "../../mixins/GlobalMixins.vue";
import {useRouter} from "vue-router";
Vue.use(useRouter);

export default {
  name: "DashboardMenu",
  components: {},
  mixins: [MenuMixins,GlobalMixins],
  router,
  props: {},
  data() {
    return {};
  },
  methods: {
    goToStudyDesign: function() {
      this.$router.replace({ path: "/design/sections" });
    },
    goToRespondentDownload: function() {
      this.$router.replace({ path: "/respondent/download" });
    },
    goToGrowthAnalysis: function() {
      this.$router.replace({ path: "/analytics/profiles" });
    },
    goToUserSettings: function() {
      let userId = Number(this.currentUser.id);
      this.$router.replace({ path: "/user-settings",params: { initialMode: 'edit-user', initialUserId: userId, initialUserCode: this.currentUser.userCode } });
    }
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {}
};
</script>
