<template>
  <div v-if="$parent.getDisplayView()">
    <div v-if="$parent.getDisplayView()" id="chart-criteria-results" class="shadow">
      <div class="ml-auto d-flex">
        <h4 class="card-title text-primary w-100">Chart Criteria Selections</h4>
        <span class="wizard-icon float-right">
          <!--
            <font-awesome-icon v-tooltip="tooltipConfigShow" size="2x" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="chart-criteria-label">Survey Name</div>
          <div>{{ surveyName }}</div>
        </div>
        <div class="col-lg-3 col-sm-6" v-for="(result, idxCriteriaResultsList) in criteriaResultsList" v-bind:key="idxCriteriaResultsList">
          <div v-if="result.displayValue.length > 0 && 'Competitor' != result.name">
            <div class="chart-criteria-label">{{ result.name }}</div>
            <div>{{ result.displayValue }}</div>
          </div>
        </div>
      </div>
      <div v-for="(companyList, idxCriteriaResultsCompanyList) in criteriaResultsCompanyList" v-bind:key="idxCriteriaResultsCompanyList" class="row">
        <div v-if="companyList.list.length > 0 && companyList.label !== 'Client'" class="col-sm-12 chart-criteria-label">{{ companyList.label }}</div>
        <div v-if="companyList.list.length > 0 && companyList.label !== 'Client'" class="col-sm-12 chart-criteria-companies">
          <div class="chart-criteria-choice btn-secondary p-1 mr-1" v-for="(company, idxCompanyList) in companyList.list" v-bind:key="idxCompanyList">
            <span>{{ company.name }}</span
            ><span v-if="company.companyType === 'competitor' && company.numberOfResults >= 0" style="font-size: 10px"> (n={{ company.numberOfResults }}) </span>
            <div style="cursor: pointer" class="ml-2 close" aria-label="Close" @click="removeCompany(company, $event)">
              <span aria-hidden="true">×</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isPredictiveAnalytics" v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">1</div>
        <div class="wizard-text">
          Click Save as Card to enter a name 
          for this Profile. Saved Cards appear 
          on your Competitive Dashboard in 
          The W Report™ area. Click the View 
          Needs Map for a text-based view of 
          your Profile.
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">2</div>
        <div class="wizard-text">
          Click Snap to capture an image of the 
          Chart/Table and then paste into your 
          own files. Click Insights to add a 
          description of the Profile; This 
          description appears in the left page 
          margin when you print The W Report™        
        </div>
      </div>
      <div class="wizard-content-column-two-entries col-4">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            To change the appearance of the Chart’s X-Axis text, click Angle or 
            Horizontal. To show/hide any data item on the Chart, click the data’s label 
            on the Chart Legend.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">4</div>  
          <div class="wizard-text">
            To save a Table to your local files, click Download. To copy a Table to your 
            clipboard, click Clip.
          </div>      
        </div>
      </div>
    </div>
    <div v-if="isPredictiveAnalytics" v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">1</div>
        <div class="wizard-text">
          Click Save as Card to enter a 
          name for this set of Analytics. 
          Saved Cards appear on your 
          Competitive Dashboard in 
          The W Report™ area.          
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">2</div>
        <div class="wizard-text">
          Click Snap to capture an image of the 
          Chart/Table and then paste into your 
          own files. Click Insights to add a 
          description of the Analytics; This 
          description appears in the left page 
          margin when you print The W Report™.        
        </div>
      </div>
      <div class="wizard-content-column-two-entries col-4">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            Future Period dates offset customer data to project future change impact. Correlation 
            strength indicates that performance improvement may positively impact that metric.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">4</div>  
          <div class="wizard-text">
            All pricing power, power ratio gap and customer data is from survey wave identified in 
            the Period End date.
          </div>      
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">4</div>  
          <div class="wizard-text">
            To save a Table to your local files, click Download. To copy a Table to your clipboard, 
            click Clip.
          </div>      
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContentService from "../../services/content.service";

export default {
  name: "ChartCriteriaResults",
  data() {
    return {
      tooltipConfigShow: ContentService.getWizardTooltip(),     
    };
  },
  computed: {
    surveyName: function() {
      let returnName = this.$parent.survey.name;
      if (this.criteriaResultsCompanyList.find(x => x.type === "client").list.length > 0) {
        if (this.criteriaResultsCompanyList.find(x => x.type === "client").list[0].numberOfResults >= 0) {
          returnName += " (n=" + this.criteriaResultsCompanyList.find(x => x.type === "client").list[0].numberOfResults + ")";
        }
      }
      return returnName;
    }
  },
  props: {
    criteriaResultsCompanyList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    criteriaResultsList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isPredictiveAnalytics: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeCompany: function(competitor, event) {
      this.$parent.$refs.chartCriteria.$refs.chartCompany.removeCompany(competitor);
    }
  },
  mounted() {},
  created() {},
  watch: {}
};
</script>
