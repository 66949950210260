<template>
  <div></div>
</template>
<script>
import AdminService from "../services/admin.service";
import DashboardService from "../services/dashboard.service";
import DashboardMixins from "./DashboardMixins.vue";

import $ from "jquery";
import echarts from "echarts";
export default {
  name: "DashboardSummaryMixins",
  mixins: [DashboardMixins],
  components: {
    /* eslint-disable vue/no-unused-components */
  },
  data() {
    return {
      title: "",
      includeCompetitor: false,
      dashboardViewSettingType: "",
      dashboardCompetitorSettingType: "",
      view: "needs-summary",
      competitor: "",
      competitor_options: [],
    //  minDashboardChartHeight: 225,
      showSummaryImage: false,
      baseSeries: {
        name: "",
        type: "scatter",
        symbolSize: 12,
        itemStyle: {
          color: "#0000ff"
        },
        label: {
          fontFamily: "Franklin Gothic Demi Cond",
          offset: [0, 1]
        },
        data: [],
        animationDelay: function (idx) {
          return idx * 5;
        }
      },
      expectationsSeries: {
        data: [],
        symbolKeepAspect: true,
        symbolSize: [30, 5],
      },
      needsSeries: {
        data: []
      },
      competitorSeries: {
        data: []
      },
      pricingPowerSeries: {
        barWidth: 35,
        yAxisIndex: 1,
        data: []
      }
    };
  },
  props: {
    dashboardLayoutCard: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  created() { },
  methods: {
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isClientCompetitorId = criteriaParameters[0] == "clientCompetitorId" ? true : false;
  		if (isClientCompetitorId){
        this.competitor = criteriaParameters[1];
  		}
      let isView = criteriaParameters[0] == "view" ? true : false;
  		if (isView){
        this.view = criteriaParameters[1];
  		}
    },
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("clientCompetitorId",this.competitor);
      params.append("view",this.view);
      return params;
    },
    initBaseChart: function (dynamicOptions, chartDiv) {
      this.chart = echarts.init(chartDiv);
      let vm = this;
      this.chart.on('finished', () => {
        vm.saveImage();
      });
      this.options = DashboardService.getNeedsProfileOptions(echarts);
      this.options.series = DashboardService.setSeries(this.baseSeries, this.options.series, dynamicOptions);

      DashboardService.setIndividualSeries(this.expectationsSeries, 0, dynamicOptions);
      DashboardService.setIndividualSeries(this.needsSeries, 1, dynamicOptions);
      if (this.includeCompetitor == true){
        DashboardService.setIndividualSeries(this.competitorSeries, 2, dynamicOptions);
        DashboardService.setIndividualSeries(this.pricingPowerSeries, 3, dynamicOptions);
      } else {
        DashboardService.setIndividualSeries(this.pricingPowerSeries, 2, dynamicOptions);
      }

      $.extend(true, this.options, dynamicOptions);
      this.options.legend.data = DashboardService.setLegend(this.options.legend.data, dynamicOptions);
      if (this.view == "needs-summary" ||
          this.view == "moat-summary" ||
          this.view == "map-summary" ){
        this.showSummaryImage = true;
        this.options.xAxis.show = false;
      } 
      //this.printOptions();
      this.chart.setOption(this.options, true);
    },
    getSummaryCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.displayDashboard = true;
        let dynamicOptions = response.data.data.options;
        this.includeCompetitor = response.data.data.includeCompetitor;
        this.initChart(dynamicOptions);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadCompetitors(data) {
      this.competitor_options = [];
      for (var i = 0; i < data.length; i++) {
        this.competitor_options.push({ value: data[i].companyId, name: data[i].name });
      }
    },
    saveView: function (value) {
      this.view = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardViewSettingType, this.view);
      this.updateCard();
    },
    saveCompetitor: function (value) {
      this.competitor = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardCompetitorSettingType, this.competitor);
      this.updateCard();
    },
    setUpCard: function () {
      this.displayDashboard = false;
      this.showSummaryImage = false;
      if (this.autoGenerateChartRun){
        this.processAutoGenerateChart();
      }  else {
        this.view = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardViewSettingType, this.view, this.view_options);
        this.competitor = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardCompetitorSettingType, this.competitor, this.competitor_options);
        this.setupClientChart();
      }    
      let competitorFound = this.competitor_options.find(x => x.value == Number(this.competitor));
      if (typeof competitorFound == "undefined") {
        this.competitor = "";
      }    
    }
  },
  watch: {
    entrySurveyCode: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardSummaryMixins::entrySurveyCode");
      this.resetChart();
      if (this.entrySurveyCode == ""){
        this.showSummaryImage = false;
      }
    },    
  },
  mounted() {
    this.showSummaryImage = false;
    this.resetChart();
  },
  computed: {
    showCompetitorsDropdown: function(){
      return this.competitor_options.length > 0;
    }
  }
};
</script>
