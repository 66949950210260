<template>
  <div v-show="showHeaderMenu">
    <div class="d-flex md-stepper-horizontal">
      <div v-bind:class="[this.isActivePage(1) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToIncludeSection">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'puzzle-piece']" />
        </div>
        <div class="md-step-title">Sections</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(2) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToTheCompetitors">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'chess']" />
        </div>
        <div class="md-step-title">Competitors</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(3) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToNeeds">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'street-view']" />
        </div>
        <div class="md-step-title">Needs</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(3) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToSurveyDesign">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'chart-pie']" />
        </div>
        <div class="md-step-title">Segments</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(4) ? activeClass : '', notActiveClass]" v-on:click="this.$parent.goToSurveyPreview">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'eye']" />
        </div>
        <div class="md-step-title">Preview</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import VueRouter from "vue-router";
import Vue from "vue";
import MenuMixins from "../../mixins/MenuMixins.vue";

export default {
  name: "DesignMenu",
  components: {},
  mixins: [MenuMixins],
  router,
  props: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
  watch: {},
  computed: {}
};
</script>
