<template>
  <div class="panel-admin-body">
    <div class="title">
      <div class="d-flex">
        {{ title }}
        <smart-center-link page="Competitive Dashboard" location="Single Pane Decision View" />
        <div class="small pt-1 ml-auto">
          <span class="wizard-icon">
            <!--
              <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
            -->
            <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
          </span>
          {{ new Date().toLocaleDateString("en-US", { weekday: "long", year: "numeric", month: "short", day: "numeric" }) }}
        </div>
        <div class="pl-3">
          <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
          <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 align">
        <dashboard-menu></dashboard-menu>
      </div>
    </div>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">1</div>
        <div class="wizard-text">Select the time period from the drop-down 
              menu to show in your Dashboard 
              Cards. All Cards driven by time period 
              update their views.
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-4">
        <div class="circle">2</div>
        <div class="wizard-text">
          Within each Card, select the items you 
          want to view from the drop-down 
          menu. Cards with connected views 
          automatically update.
        </div>
      </div>
      <div class="wizard-content-column col-4">
        <div class="circle">3</div>
        <div class="wizard-text">
          <span>To change which Cards appear on your 
          Dashboard, click the Settings icon (</span><i class="fa-duotone fa-gear-complex" style="color: #2f5eb3;"></i><span class="wizard-text" style="padding-left:0px">) 
          or go to Preferences->Dashboard on your sidebar menu.</span>
        </div>
      </div>
    </div>
    <div class="new-competitive-dashboard mb-5">
      <dashboard-cards :entry-survey-code="surveyCode" :client-object="clientObject"/>
      <div class="row justify-content-center">       
        <div class="col-xl-6 col-md-8">
          <saved-cards :view-type="'dashboard'" :enable-second-row-of-saved-charts="false" :display-dashboard-cards="false" :entry-survey-id="surveyId" :entry-survey-name="surveyName" :entry-client-object="clientObject" /> 
          <div class="show-more float-right">
            <a href="#" @click="goToCards">Show more <i class="fa fa-chevron-down ml-1"></i></a>
          </div>
        </div>
        <div class="col-xl-3 col-md-4">
          <saved-reports :view-type="'dashboard'" :entry-survey-id="surveyId" :entry-survey-name="surveyName" :entry-client-object="clientObject" @edit-report="initializeEditReport" @sort-reports="sortReports"/>
          <div class="show-more float-right">
            <a href="#" @click="goToReports">Show more <i class="fa fa-chevron-down ml-1"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardCards from "../components/DashboardCards.vue";
import SavedCards from "../components/SavedCards.vue";
import ViewMixins from "../mixins/ViewMixins.vue";
import DashboardMenu from "../views/menu/DashboardMenu.vue";
import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";
import ContentService from "../services/content.service";
import ReportService from "../services/report.service";
import DashboardMixins from "../mixins/DashboardMixins.vue";
import SavedReports from "../components/SavedReports.vue";
import GlobalMixins from "../mixins/GlobalMixins.vue";

export default {
  name: "Dashboard",
  components: {
    DashboardMenu,
    DashboardCards,
    SavedReports,
    SavedCards
  },
  mixins: [ViewMixins, DashboardMixins,GlobalMixins],
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  },
  data() {
    return {
      title: "Competitive Dashboard",
      tooltipConfigShow: ContentService.getWizardTooltip(),     
    };
  },
  methods: {
    goToCards: function(){
      this.$router.replace({ path: "/analytics/report" });
    },
    goToReports: function(){
      this.$router.replace({ path: "/print-report" });
    },
    sortReports: function(sortBy) {
      if (sortBy == "title-ascending") {
        this.clientObject.clientReports.sort(ReportService.compareValues("title", "asc"));
      } else if (sortBy == "title-descending") {
        this.clientObject.clientReports.sort(ReportService.compareValues("title", "desc"));
      } else if (sortBy == "organization-ascending") {
        this.clientObject.clientReports.sort(ReportService.compareValues("clientName", "asc"));
      } else if (sortBy == "organization-descending") {
        this.clientObject.clientReports.sort(ReportService.compareValues("clientName", "asc"));
      } else if (sortBy == "date-added-newest") {
        this.clientObject.clientReports.sort(ReportService.compareValues("dateCreated", "desc"));
      } else if (sortBy == "date-added-oldest") {
        this.clientObject.clientReports.sort(ReportService.compareValues("dateCreated", "asc"));
      }      
    },
    initializeEditReport: function(){

    },
    loadData: function() {
      this.survey = SurveyService.getSurvey();
      this.$root.processServerRequest();
      this.surveyId = SurveyService.getSurveyId();
      this.surveyName = SurveyService.getSurveyName();
    }
  },
  watch: {
    surveyCode: function() {
      this.loadData();
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$root.setShowMenuIcon(true);  
  },
  umnounted() {},
  computed: {}
};
</script>
