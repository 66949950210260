import BaseChartService from "./base.chart.service";
import $ from "jquery";

class BarVerticalService extends BaseChartService {
    barWidth = 80;
    setBarWidth(barWidth) {
        this.barWidth = barWidth;
    }
    getBarWidth() {
        return this.barWidth;
    }

    constructor() {
        super();
    }
    getSeries(isStacked,numberOfWaves,numberOfWavesMax) {
        let stackConfig = {
            stack: 'total',
            //barWidth: '90%',
        }          
        return this.getSeriesData().map((seriesData, sid) => {
            let label = {
                formatter: function(param) {
                    return param.value == 0 ? '' : param.value + '%'
                },
                show: this.getShowLabel()
            };
            if (isStacked == false){
                $.extend(true, label, {position: "top"}); 
            }            
            let labelTextStyle = this.getDefaultLabelTextStyle();
            
            if (isStacked == true && this.useBlackTextForStackedBar() == false){
                $.extend(true, labelTextStyle, { color: "#fff" });             
            }
            
            $.extend(true, label, labelTextStyle);                 
            let series = {
                name: seriesData.name,
                type: 'bar',
                label: label,
                avoidLabelOverlap: true,
                data: seriesData.data
            };
            if (isStacked == true){
                $.extend(true, series, stackConfig);
                $.extend(true, series, this.stackedBarConfiguration(numberOfWaves, this.getBarWidth(), numberOfWavesMax));
            } else {    
                $.extend(true, series, this.barConfiguration(this.getChartType(), this.getisMultiWave()));
            }            
            return series;
        });        
    }
    setData(inputTimePeriods,selectedQuestion) {
        let legendData = [];
        let seriesData = [];
        let axisData = {
            name: "",
            data: []
        };           
        for (let i = 0; i < selectedQuestion.options.length; i++) {
            let option = selectedQuestion.options[i];
            axisData = {
                name: option.label,
                data: []
            };          
            legendData.push(option.label);    
            for (let j = 0; j < inputTimePeriods.length; j++) {
                let selectedTimePeriod = inputTimePeriods[j];
                let countItem = option.counts.find(item => item.timePeriod === selectedTimePeriod);
                if (typeof countItem != 'undefined' && countItem.count != null) {
                    axisData.data.push(countItem.count);
                } 
            } 
            seriesData.push(axisData);      
        }
        this.setLegendData(legendData);
        this.setSeriesData(seriesData);
    }
    getOptions(disableSelectedMode = false, isStacked = false,app,modalId,reportPageTypeName,numberOfWaves,numberOfWavesMax) {
        let legend;
        if (disableSelectedMode == true){
            legend = {selectedMode: false};
            $.extend(true, legend, this.getDefaultLegendResponseRatesChart());        
        } else {    
            legend = this.getDefaultLegendResponseRatesChart();
        }
        let data = {
            data: this.getLegendData()
        }
        $.extend(true, legend, data);            
        let axisLabel = {
            formatter: '{value}%'
        }   
        $.extend(true, axisLabel, this.getDefaultAxisTextStyle());          
        let tooltip = {
            axisPointer: {
                type: 'shadow' 
            },
            formatter: this.toolTipForStackedAreaChart,
            textStyle: this.getDefaultLabelTextStyle()
        };        
        $.extend(true, tooltip, this.getDefaultToolTip('axis'));           
        let yAxis = {
            type: 'value',
            axisLabel: axisLabel ,
            splitLine: {
                lineStyle: {
                  color: "#fff",
                }
            },                                          
        };
        if (isStacked == false || disableSelectedMode == true){
            $.extend(true, yAxis, {min: 0, max: 100});
        }
        let option = {
            legend: legend,
            toolbox: this.getDefaultToolBox(app,modalId,reportPageTypeName),
            tooltip: tooltip,            
            grid: this.getShowLegend() ? this.getGrid() : this.getGridBackground(),
            yAxis: yAxis,
            xAxis: {
                type: 'category',
                data: this.getXAxisData(),              
                axisLabel: this.getDefaultAxisTextStyle(),
                axisTick:{
                    show: false,
                },                                                         
},
            series: this.getSeries(isStacked,numberOfWaves,numberOfWavesMax),
            color: this.getColorPalette()
        };
        return option;
    }
}
export default new BarVerticalService();
