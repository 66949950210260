

class ColorService {
  DEFAULT_CORE_COLORS = [
    /*{ clientSettingTypeName: "expectations-color", clientSettingValue: "#008000", displayName: "Expectations" },*/
    { clientSettingTypeName: "company-color", clientSettingValue: "#1C408E", displayName: "Company" },
    { clientSettingTypeName: "pricing-power-color", clientSettingValue: "#D7E4BD", displayName: "Pricing Power" },
    { clientSettingTypeName: "metrics-color", clientSettingValue: "#28945D", displayName: "Metrics" }
  ];
  DEFAULT_W50_COLORS = [
    { clientSettingTypeName: "benchmark-1-color", clientSettingValue: "#D9C62F", displayName: "Benchmark 1" },
    { clientSettingTypeName: "benchmark-2-color", clientSettingValue: "#5D562E", displayName: "Benchmark 2" },
    { clientSettingTypeName: "benchmark-3-color", clientSettingValue: "#3F97C2", displayName: "Benchmark 3" },
    { clientSettingTypeName: "benchmark-4-color", clientSettingValue: "#933291", displayName: "Benchmark 4" },
    { clientSettingTypeName: "benchmark-5-color", clientSettingValue: "#FD9B8F", displayName: "Benchmark 5" }
  ];
  DEFAULT_MATCH_BY_TIME_PERIOD_EXPECTATIONS_COLORS = [];
  DEFAULT_MATCH_BY_TIME_PERIOD_COMPANY_COLORS = [
    { clientSettingTypeName: "match-by-time-period-company-1-color", clientSettingValue: "#0E3F8C", displayName: "", sequenceNumber: 1  },
    { clientSettingTypeName: "match-by-time-period-company-2-color", clientSettingValue: "#1663E0", displayName: "", sequenceNumber: 2  },
    { clientSettingTypeName: "match-by-time-period-company-3-color", clientSettingValue: "#558ED5", displayName: "", sequenceNumber: 3  },
    { clientSettingTypeName: "match-by-time-period-company-4-color", clientSettingValue: "#95B3D7", displayName: "", sequenceNumber: 4  },
    { clientSettingTypeName: "match-by-time-period-company-5-color", clientSettingValue: "#B9CDE5", displayName: "", sequenceNumber: 5  }  
  ];
  DEFAULT_MATCH_BY_SAVED_CARD_EXPECTATIONS_COLORS = [];
  DEFAULT_MATCH_BY_SAVED_CARD_COMPANY_COLORS = [
    { clientSettingTypeName: "match-by-saved-card-company-1-color", clientSettingValue: "#0E3F8C", displayName: "" , sequenceNumber: 1 },
    { clientSettingTypeName: "match-by-saved-card-company-2-color", clientSettingValue: "#1663E0", displayName: "" , sequenceNumber: 2 },
    { clientSettingTypeName: "match-by-saved-card-company-3-color", clientSettingValue: "#558ED5", displayName: "" , sequenceNumber: 3 },
    { clientSettingTypeName: "match-by-saved-card-company-4-color", clientSettingValue: "#95B3D7", displayName: "" , sequenceNumber: 4 },
    { clientSettingTypeName: "match-by-saved-card-company-5-color", clientSettingValue: "#B9CDE5", displayName: "" , sequenceNumber: 5 }  
  ];
  DEFAULT_MATCH_BY_SURVEY_EXPECTATIONS_COLORS = [];
  DEFAULT_MATCH_BY_SURVEY_COMPANY_COLORS = [
    { clientSettingTypeName: "match-by-survey-company-1-color", clientSettingValue: "#0E3F8C", displayName: "", sequenceNumber: 1  },
    { clientSettingTypeName: "match-by-survey-company-2-color", clientSettingValue: "#1663E0", displayName: "", sequenceNumber: 2  },
    { clientSettingTypeName: "match-by-survey-company-3-color", clientSettingValue: "#558ED5", displayName: "", sequenceNumber: 3  },
    { clientSettingTypeName: "match-by-survey-company-4-color", clientSettingValue: "#95B3D7", displayName: "", sequenceNumber: 4  },
    { clientSettingTypeName: "match-by-survey-company-5-color", clientSettingValue: "#B9CDE5", displayName: "", sequenceNumber: 5  }  
  ];

  setValue(name, value, colorList) {
    let searchableColor = colorList.find(x => x.clientSettingTypeName == name);
    if (typeof searchableColor != "undefined") {
      searchableColor.clientSettingValue = value;
    } 
  }  
  getValue(name, colorList) {
    let searchableColor = colorList.find(x => x.clientSettingTypeName == name);
    if (typeof searchableColor != "undefined") {
      return searchableColor.clientSettingValue;
    } else {
      return "";
    }
  }  
  setColors(settingsList, defaultColorList) {  
    let colorList = JSON.parse(JSON.stringify(defaultColorList));
    for (var i = 0; i < colorList.length; i++) {
      let name = colorList[i].clientSettingTypeName;
      var coreSetting = settingsList.find(x => x.clientSettingTypeName == name);
      if (typeof coreSetting != "undefined") {
        colorList[i].clientSettingValue = coreSetting.clientSettingValue;
      }
    }
    return colorList;
  }
}
export default new ColorService();
