<template>
  <div class="row no-gutters">
    <div class="col">
      <form accept-charset="UTF-8" enctype="multipart/form-data" autocomplete="off" class="form" action="#" method="post" novalidate="novalidate" id="ChartGenerateFormId">
        <div class="my-3">
          <div v-if="!display" class="container pt-2">
            <chart-criteria-skeleton />
          </div>
          <div v-if="display" class="container pt-2">
            <div class="form-row">
              <div class="col-lg-3 col-md-4 col-sm-12 chart-criteria-border pr-2">
                <div v-show="showMetric && !showNpsTrends" class="form-group">
                  <label for="metric" class="control-label pt-1 my-auto">Metric</label>
                  <select
                    id="metric"
                    name="metric"
                    class="custom-select required shadow"
                    :disabled="disableChartCriteriaWidgets"
                    v-on:change="saveValue($event, 'Metric', 'metric', true)"
                    v-model="metric"
                  >
                    <option value="">Select</option>
                    <option v-for="(option, idxMetricOptions) in metric_options" v-bind:value="option.value" v-bind:key="idxMetricOptions">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <div v-show="showDisplayType" class="form-group">
                  <label for="display_type" class="control-label pt-1 pr-1 my-auto">{{ displayTypeLabel }}</label>
                  <modal-link :modal-id="displayTypeModalName" :content-list="pageContent"></modal-link>
                  <div v-if="useChartTypeCard" class="d-flex justify-content-left flex-wrap">
                    <chart-type-card
                      v-for="(option, idxChartTypeCardOptions) in display_type_options"
                      :chart-type="option.value"
                      :display-name="option.name"
                      :current-display-type="displayType"
                      @select="setDisplayType"
                      v-bind:key="idxChartTypeCardOptions"
                    ></chart-type-card>
                  </div>
                  <div v-else class="form-group">
                    <select
                      id="display_type"
                      name="display_type"
                      class="custom-select required shadow"
                      :disabled="disableChartCriteriaWidgets"
                      v-on:change="saveValue($event, 'Display Type', 'display_type', true)"
                      v-model="displayType"
                    >
                      <option value="">Select</option>
                      <option v-for="(option, idxDisplayTypeOptions) in display_type_options" v-bind:value="option.value" v-bind:key="idxDisplayTypeOptions">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-show="showMatchType" class="form-group">
                  <label for="match_type" class="control-label pt-1 my-auto">{{ matchTypeLabel }}</label>
                  <div class="">
                    <select
                      id="match_type"
                      name="match_type"
                      class="custom-select required shadow"
                      :disabled="disableChartCriteriaWidgets"
                      v-on:change="saveValue($event, 'Match Type', 'match_type', true)"
                      v-model="matchType"
                    >
                      <option value="">Select</option>
                      <option v-for="(option, idxMatchTypeOptions) in match_type_options" v-bind:value="option.value" v-bind:key="idxMatchTypeOptions">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-show="!displayComparativePeriod" class="form-group">
                  <label for="period" class="control-label pt-1 my-auto">{{ timePeriodLabel }}</label>
                  <div class="">
                    <select
                      id="period"
                      name="period"
                      class="custom-select required shadow"
                      :disabled="disableChartCriteriaWidgets"
                      v-on:change="saveTimePeriodEvent($event, 'Period', 'period', true)"
                      v-model="period"
                    >
                      <option value="">Select</option>
                      <option v-for="(option, idxPeriodOptions) in period_options" v-bind:value="option.value" v-bind:key="idxPeriodOptions">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-show="displaySingleSelectSurveyGroups" class="form-group">
                  <label for="surveyGroup" class="control-label pt-1 pr-1 my-auto">Group</label>
                  <modal-link modal-id="Survey Group" :content-list="pageContent"></modal-link>
                  <div class="">
                    <select
                      id="surveyGroup"
                      name="surveyGroup"
                      class="custom-select shadow"
                      :disabled="disableChartCriteriaWidgets"
                      v-on:change="saveValue($event, 'Survey Group', 'survey_group', true);setUseEqualWeight();"
                      v-model="surveyGroup"
                    >
                      <option value="">None</option>
                      <option v-for="(option, idxSurveyGroupOptions) in survey_group_options" v-bind:value="option.value" v-bind:key="idxSurveyGroupOptions">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group pt-3">
                    <input 
                      id="showPublicGroups" 
                      name="showPublicGroups" 
                      type="checkbox" 
                      true-value="Y" 
                      false-value="N" 
                      v-model="showPublicGroups" 
                      @click="saveCheckboxValue($event, 'Show Public Groups', 'show_public_groups', true)"
                      />
                    <span class="ml-1 mr-1">Show Public Groups</span>
                    <modal-link modal-id="Show Public Groups" :content-list="pageContent"></modal-link>
                  </div>                  
                  <div v-show="showEqualWeight" class="form-group">
                    <input 
                      id="useEqualWeight" 
                      name="useEqualWeight" 
                      type="checkbox" 
                      :disabled="disableEqualWeight"
                      true-value="Y" 
                      false-value="N" 
                      v-model="useEqualWeight" 
                      @click="saveCheckboxValue($event, 'Calculate Surveys Equal-Weighted', 'use_equal_weight', true)"
                      />
                    <span class="ml-1 mr-1">Calculate Surveys Equal-Weighted</span>
                    <modal-link modal-id="Calculate Surveys Equal-Weighted" :content-list="pageContent"></modal-link>
                  </div>                  
                </div>
                <div class="form-group">
                  <input
                    id="includePartialResponses"
                    name="includePartialResponses"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                    v-model="includePartialResponses"
                    @click="saveCheckboxValue($event, 'Include Partial Responses', 'include_partial_responses', true)"
                  />
                  <span class="ml-1 mr-1">Include Partial Responses</span>
                  <modal-link modal-id="Include Partial Responses" :content-list="pageContent"></modal-link>
                </div>
                <div v-show="!displayComparativePeriod && showCustomTimePeriod && displayCustomTimePeriod" class="form-group">
                  <input
                    id="customTimePeriod"
                    name="customTimePeriod"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                    v-model="customTimePeriod"
                    @click="saveCheckboxValue($event, 'Custom Time Period', 'custom_time_period', true)"
                  />
                  <span class="ml-1 mr-1">Custom Time Period</span>
                  <modal-link modal-id="Custom Time Period" :content-list="pageContent"></modal-link>
                  <div v-show="displayCustomDateFields" class="pt-2 d-flex">
                    <div v-show="displayCustomDateFields" class="form-group form-inline">
                      <label for="customStartDate" class="mr-1">Start Date</label>
                      <datepicker format="MMM dd yyyy" v-model="customStartDate" @selected="saveDateValue($event, 'Custom Start Date', 'custom_start_date', true)" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="customStartDate"></datepicker>
                    </div>
                    <div v-show="displayCustomDateFields" class="form-group form-inline ml-2">
                      <label for="customEndDate" class="mr-1">End Date</label>
                      <datepicker format="MMM dd yyyy" v-model="customEndDate" @selected="saveDateValue($event, 'Custom End Date', 'custom_end_date', true)" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="customEndDate"></datepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="isComparative" class="col-lg-3 col-md-4 col-sm-12 chart-criteria-border pl-2 pr-2">
                <div v-show="displaySavedCards" class="form-group">
                  <label for="savedCards" class="control-label pt-1 my-auto">Saved Card</label>
                  <div class="">
                    <multiselect
                      v-model="comparativeSavedCards"
                      :options="saved_card_options"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :max-height="250"
                      select-label=""
                      deselect-label=""
                      selected-label=""
                      placeholder="Select"
                      label="name"
                      name="comparative-saved-cards"
                      track-by="name"
                      :preselect-first="false"
                      :max="maximumComparative"
                      @input="onComparativeInput($event, 'saved-card')"
                      @remove="onComparativeRemove($event, 'saved-card')"
                    >
                      <template #option="props">
                        <span class="checkbox-label" @click.self="select(props.option)">
                          <input name="comparative-saved-cards" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="form-group pt-2">
                    <input id="showAllChartCards" name="showAllChartCards" type="checkbox" true-value="Y" false-value="N" v-model="showAllChartCards" />
                    <span class="ml-1 mr-1">Show All Chart Cards</span>
                    <modal-link modal-id="Show All Chart Cards" :content-list="pageContent"></modal-link>
                  </div>
                </div>
                <div v-show="displayComparativePeriod" class="form-group">
                  <label for="period" class="control-label pt-1 my-auto">{{ timePeriodLabel }}</label>
                  <div class="">
                    <multiselect
                      v-model="comparativePeriods"
                      :options="period_options"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :max-height="250"
                      select-label=""
                      deselect-label=""
                      selected-label=""
                      placeholder="Select"
                      label="name"
                      name="comparative-period"
                      track-by="name"
                      :preselect-first="false"
                      :max="maximumComparative"
                      @input="onComparativeInput($event, 'time-period')"
                      @remove="onComparativeRemove($event, 'time-period')"
                    >
                      <template #option="props">
                        <span class="checkbox-label" @click.self="select(props.option)">
                          <input name="comparative-period" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div v-show="displayMultiSelectSurveyGroups" class="form-group">
                  <label for="surveyGroup" class="control-label pt-1 my-auto">Groups to Match</label>
                  <div>
                    <multiselect
                      v-model="comparativeSurveyGroups"
                      :options="survey_group_options"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :max-height="250"
                      select-label=""
                      deselect-label=""
                      selected-label=""
                      placeholder="None"
                      label="name"
                      name="comparative-survey-group"
                      track-by="name"
                      :preselect-first="false"
                      :max="maximumComparative"
                      @input="onComparativeInput($event, 'survey-group')"
                      @remove="onComparativeRemove($event, 'survey-group')"
                    >
                      <template #option="props">
                        <span class="checkbox-label" @click.self="select(props.option)">
                          <input name="comparative-survey-group" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="form-group pt-2">
                    <input 
                      id="showPublicGroups" 
                      name="showPublicGroups" 
                      type="checkbox" 
                      true-value="Y" 
                      false-value="N" 
                      v-model="showPublicGroups" 
                      @click="saveCheckboxValue($event, 'Show Public Groups', 'show_public_groups', true)"
                    />
                    <span class="ml-1 mr-1">Show Public Groups</span>
                    <modal-link modal-id="Show Public Groups" :content-list="pageContent"></modal-link>
                  </div>                  
                  <div class="form-group pt-1">
                    <input 
                      id="useEqualWeightForComparativeSurveyGroups" 
                      name="useEqualWeightForComparativeSurveyGroups" 
                      type="checkbox" 
                      true-value="Y" 
                      false-value="N" 
                      :disabled="disableEqualWeightForComparativeSurveyGroups"
                      v-model="useEqualWeight" 
                      @click="saveCheckboxValue($event, 'Calculate Surveys Equal-Weighted', 'use_equal_weight', true)"
                      />
                    <span class="ml-1 mr-1">Calculate Surveys Equal-Weighted</span>
                    <modal-link modal-id="Calculate Surveys Equal-Weighted" :content-list="pageContent"></modal-link>
                  </div>                  
                </div>
                <div v-show="displayComparativeSurvey" class="form-group">
                  <label for="survey" class="control-label pt-1 my-auto">Surveys to Match</label>
                  <div>
                    <multiselect
                      v-model="comparativeSurveys"
                      :options="survey_options"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :max-height="250"
                      select-label=""
                      deselect-label=""
                      selected-label=""
                      placeholder="Select"
                      label="name"
                      name="comparative-survey"
                      track-by="name"
                      :preselect-first="false"
                      :max="maximumComparative"
                      @input="onComparativeInput($event, 'survey')"
                      @remove="onComparativeRemove($event, 'survey')"
                    >
                      <template #option="props">
                        <span class="checkbox-label" @click.self="select(props.option)">
                          <input name="comparative-survey" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div v-show="!isComparative && !showNpsTrends" class="col-lg-3 col-md-4 col-sm-12 chart-criteria-border pl-2 pr-2">
                <div v-show="showCompetitors && !hasCompetitors && displayType != 'moat-rank'" class="form-group mb-0">
                  <label class="control-label pt-1 pl-0 pr-0 my-auto">Options</label>
                </div>
                <div v-show="showCompetitors && hasCompetitors" class="form-group">
                  <label class="control-label pt-1 pl-0 pr-0 my-auto">Competitors</label>
                  <multiselect
                    ref="competitorsSelect"
                    v-model="values"
                    :disabled="disableChartCriteriaWidgets"
                    :options="competitor_options"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :max-height="250"
                    select-label=""
                    deselect-label=""
                    selected-label=""
                    placeholder="Select"
                    label="name"
                    :max="maximumCompetitors"
                    name="multi-competitor"
                    track-by="companyId"
                    :preselect-first="false"
                    @select="addCompany"
                    @remove="removeCompany"
                  >
                  <template slot="maxElements"
                      ><span style="color: #e54d42">{{ maximumCompetitorMessage }}</span></template
                    >
                    <template #option="props">
                      <span class="checkbox-label"  @click.self="select(props.option)">
                        <input name="multi-competitor" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                        {{ props.option.name }}
                      </span>
                    </template>
                  </multiselect>
                </div>
                <div v-show="isRanks" class="form-group">
                  <label class="control-label pt-1 pl-0 pr-0 my-auto"># of Ranking Tiers</label>
                  <select
                    id="ranking_tier"
                    name="ranking_tier"
                    class="custom-select shadow"
                    :disabled="disableChartCriteriaWidgets"
                    v-on:change="saveValue($event, '# of Ranking Tiers', 'ranking_tier', true)"
                    v-model="ranking_tier"
                  >
                    <option value="">Select</option>
                    <option v-for="(option, idxRankingTiersOptions) in number_of_ranking_tiers_options" v-bind:value="option.value" v-bind:key="idxRankingTiersOptions">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <div v-show="isRanks" class="form-group">
                  <label class="control-label pt-1 pl-0 pr-0 my-auto">Show Needs</label>
                  <div>
                    <multiselect
                      v-model="showNeeds"
                      :disabled="disableChartCriteriaWidgets"
                      :options="filteredShowNeedsOptions"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :max-height="250"
                      select-label=""
                      deselect-label=""
                      selected-label=""
                      placeholder="Select"
                      label="name"
                      name="show-needs"
                      track-by="name"
                      @select="addNeed"
                      @remove="removeNeed"
                      :preselect-first="false"
                    >
                      <template #option="props">
                        <span class="checkbox-label" @click.self="select(props.option)">
                          <input name="show-needs" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div v-show="isRanks" class="form-group">
                  <label class="control-label pt-1 pl-0 pr-0 my-auto">Show Metrics</label>
                  <div>
                    <multiselect
                      v-model="showMetrics"
                      :options="show_metrics_options"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :max-height="250"
                      select-label=""
                      deselect-label=""
                      selected-label=""
                      placeholder="Select"
                      label="name"
                      name="show-metrics"
                      track-by="name"
                      :disabled="disableChartCriteriaWidgets"
                      @select="addMetric"
                      @remove="removeMetric"
                    :preselect-first="false"
                    >
                      <template #option="props">
                        <span class="checkbox-label" @click.self="select(props.option)">
                          <input name="show-metrics" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div v-show="isRanks && displayUseRankCalculations" class="form-group">
                  <label class="control-label pt-1 pl-0 pr-0 my-auto">Use Rank Calculations</label>
                  <select
                    id="use_rank_calculations"
                    name="use_rank_calculations"
                    class="custom-select shadow"
                    :disabled="disableChartCriteriaWidgets"
                    v-on:change="saveValue($event, 'Use Rank Calculations', 'rank_calculation_type', true)"
                    v-model="rank_calculation_type"
                  >
                    <option value="">Select</option>
                    <option v-for="(option, idxUseRankCalculations) in use_rank_calculations_options" v-bind:value="option.value" v-bind:key="idxUseRankCalculations">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <div v-show="displayShowW30Benchmarks" class="form-group pt-2">
                  <input id="displayCompetitorsOption" name="displayCompetitorsOption" type="checkbox" v-model="displayBenchmark" @click="logChartClickEvent('Show Benchmarks',displayBenchmark)"/>
                  <span class="ml-1 mr-1">Show Benchmark Companies</span>
                  <modal-link modal-id="Show Benchmark Companies" :content-list="pageContent"></modal-link>
                </div>
                <div class="form-group pt-2" v-if="includeShowCompetitorExpectations">
                  <input
                    id="showCompetitorExpectations"
                    name="showCompetitorExpectations"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                    v-model="showCompetitorExpectations"
                    @click="saveCheckboxValue($event, 'Show Competitor Expectations', 'Show Competitor Expectations', true)"
                  />
                  <span class="ml-1 mr-1">Show Competitor Expectations</span>
                  <modal-link modal-id="Show Competitor Expectations" :content-list="pageContent"></modal-link>
                </div>
                <div class="form-group pt-2" v-if="includeShowPricingPower && !isNps">
                  <input
                    id="showPricingPower"
                    name="showPricingPower"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                    v-model="showPricingPower"
                    @click="saveCheckboxValue($event, 'Show Pricing Power', 'show_pricing_power', true)"
                  />
                  <span class="ml-1 mr-1">Show Pricing Power</span>
                  <modal-link modal-id="Show Pricing Power" :content-list="pageContent"></modal-link>
                </div>
              </div>
              <div class="col-lg-6 col-md-4 col-sm-12 pl-2">
                <div class="segments row">
                  <div v-for="(chartCriteria, idxSegments) in segments" v-bind:key="idxSegments" class="col-lg-4 col-sm-12">
                    <chart-criteria-option
                      placeholder="Select"
                      option-type="multiselect"
                      label-class="control-label pt-1 my-auto pr-1"
                      v-bind:option-object="chartCriteria"
                      :resetOption="resetOption"
                      :chartTitle="$parent.chart.title"
                      :surveyName="surveyName"
                      @add="saveResultValue"
                    ></chart-criteria-option>
                  </div>
                  <div class="d-none col-lg-4 col-sm-12">
                    <div class="form-group">
                      <label for="survey_location" class="control-label pt-1 pr-1 my-auto">Location</label>
                      <modal-link modal-id="Location" :content-list="pageContent"></modal-link>
                      <a class="mr-1" href="#" @click="showPopup">Click to View Counts</a>
<!-- vue3-migration ORIGINAL Treeselect - We can remove this once everything is working
                      <treeselect
                        v-model="surveyLocation"
                        :multiple="true"
                        :alwaysOpen="false"
                        :show-count="false"
                        :default-expand-level="1"
                        :options="survey_location_options"
                        :append-to-body="true"
                        :required="false"
                        :clearable="false"
                        :maxHeight="400"
                        placeholder="Select"
                      />
-->                           
                      <Treeselect
                        :model-value="surveyLocation"
                        :is-single-select="false"
                        :alwaysOpen="false"
                        :show-count="false"
                        :default-expand-level="1"
                        :options="survey_location_options"
                        :append-to-body="true"
                        :required="false"
                        :clearable="false"
                        :maxHeight="400"
                        placeholder="Select"
                      />                                       
                    </div>
                  </div>
                  <div v-show="!showExclusionList" class="col-lg-4 col-sm-12">
                    <div class="form-group">
                      <label for="word" class="control-label pt-1 pr-1 my-auto">Word</label>
<!-- vue3-migration ORIGINAL Treeselect - We can remove this once everything is working                      
                      <treeselect
                        v-model="words"
                        :multiple="true"
                        :alwaysOpen="false"
                        :show-count="false"
                        :default-expand-level="1"
                        :options="word_options"
                        :append-to-body="true"
                        :required="false"
                        :clearable="false"
                        :maxHeight="400"
                        placeholder="Select"
                      />
-->                           
                      <Treeselect
                        :model-value="words"
                        :is-single-select="false"
                        :alwaysOpen="false"
                        :show-count="false"
                        :default-expand-level="1"
                        :options="word_options"
                        :append-to-body="true"
                        :required="false"
                        :clearable="false"
                        :maxHeight="400"
                        placeholder="Select"
                      />

                    </div>
                  </div>                  
                </div>
              </div>
            </div>
            <chart-company ref="chartCompany" :client-id="clientId" :page-content="pageContent"></chart-company>
            <div v-show="displayWinnersMap" class="form-row">
              <winners ref="winnersRef" :survey-code="surveyCode" :client-chart-object="this.$parent.clientChart" :original-winner-type="winnerType"/>
            </div>
            <div class="btn-group-horizontal pb-3 pt-3" role="group">
              <button type="submit" class="btn btn-primary mr-2" v-on:click="$parent.displayMainChart">
                <span>{{ generateLabel }}</span>
                <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
              </button>
              <button v-show="showExclusionList" type="submit" class="btn btn-primary mr-2" v-on:click="showManageExclusionListModal">Manage Exclusive List</button>
              <button type="submit" class="btn btn-primary" v-on:click="resetChartCriteria">Reset</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <counts-by-location ref="countsByLocation" :period="period.toString()"/>
    <manage-exclusion-list ref="manageExclusionList" type="Client"/>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import ChartCriteriaSkeleton from "../../components/skeleton/ChartCriteriaSkeleton.vue";

import ChartCriteriaOption from "../../components/chart/ChartCriteriaOption.vue";
import ChartTypeCard from "../../components/chart/ChartTypeCard.vue";
import ChartCompany from "../../components/chart/ChartCompany.vue";
import Winners from "../../components/chart/Winners.vue";
import Multiselect from "vue-multiselect";
import ContentMixins from "../../mixins/ContentMixins.vue";
import ChartCriteriaMixins from "../../mixins/ChartCriteriaMixins.vue";
import CountsByLocation from "../../modals/CountsByLocation.vue";
import ManageExclusionList from "../../modals/ManageExclusionList.vue";
import ChartService from "../../services/chart.service";
import ClientService from "../../services/client.service";
import AdminService from "../../services/admin.service";
import ErrorService from "../../services/error.service";
import SurveyService from "../../services/survey.service";
import UserService from "../../services/user.service";
import GoogleService from "../../services/google.service";
import ClientCompetitorService from "../../services/client.competitor.service";
import WordCloudService  from "../../services/word.cloud.service";
import ReportService   from "../../services/report.service";

import authHeader from "../../services/auth-header";
import Treeselect, { DirectionType, IconsType, OptionType, TreeselectValue } from 'vue-treeselectjs'
import 'vue-treeselectjs/dist/vue-treeselectjs.css'

export default {
  name: "ChartCriteria",
  components: {
    ChartCriteriaOption,
    Datepicker,
    ChartCompany,
    Multiselect,
    ChartTypeCard,
    Winners,
    ManageExclusionList,
    CountsByLocation,
    ChartCriteriaSkeleton,
    Treeselect
  },
  mixins: [ContentMixins, ChartCriteriaMixins],

  data() {
    return {
      period: "",
      defaultPeriod: "",
      comparativePeriods: [],
      comparativeSurveys: [],
      showNeeds: [],
      showMetrics: [],
      comparativeSurveyGroups: [],
      comparativeSavedCards: [],
      survey_options: [],
      show_needs_options: [],
      show_metrics_options: [],
      use_rank_calculations_options: [],
      rank_calculation_type: "",
      number_of_ranking_tiers_options: [],
      ranking_tier: "", 
      textDirection: "", 
      metric: "-1",
      displayType: "need",
      matchType: "",
      validator: null,
      chartForm: null,
      competitors: [],
      values: [],
      periodPrefix: "",
      competitor_options: [],
      display: false,
      saved_card_options: [],
      period_options: [],
      match_type_options: [],
      display_type_options: [],
      metric_options: [],
      displayBenchmark: false,
      includePartialResponses: false,
      customTimePeriod: false,
      customStartDate: new Date(),
      customEndDate: new Date().toLocaleDateString("en-US"),
      showAllChartCards: "N",
      showCompetitorExpectations: false,
      disableEqualWeightForComparativeSurveyGroups: true,
      showPricingPower: "Y",
      maximumComparative: 4,
      winnerType: "",
      displayCustomTimePeriod: true,
      eChartCriteria: {},
      moatRankOptions : ["total-moat-score","all-moat-scores","functional-gap-size"],
      baseRankOptions : ["w-score","satisfaction","emotional-needs","functional-needs","ideal-functional-needs","ideal-emotional-needs"] ,
      adminOnlyOptions: ["functional-needs","emotional-needs","ideal-functional-needs","ideal-emotional-needs"],
    };
  },
  computed: {
    isAdmin: function() {
      return this.$parent.isAdmin;
    },
    displayCustomDateFields: function() {
      return this.customTimePeriod == "Y" || this.customTimePeriod == "true" || this.customTimePeriod == true;
    },    
    showExclusionList: function() {
      return this.displayType == "nps"
    },
    totalComparative: function() {
      let total = 0;
      if (this.displayMultiSelectSurveyGroups || this.displayComparativeSurvey) {
        total = this.comparativeSurveys.length + this.comparativeSurveyGroups.length;
      } else if (this.displayComparativePeriod) {
        total = this.comparativePeriods.length;
      } else if (this.displaySavedCards) {
        total = this.comparativeSavedCards.length;
      }
      return total;
    },
    maximumCompetitorMessage: function() {
      return "Maximum of " + this.maximumCompetitors + " competitors. First remove a selected competitor to select another.";
    },
    displayWinnersMap: function() {
      return this.displayType == "winners";
    },
    showNpsTrends: function() {
      return this.displayType == "nps-trends";
    },
    displayMultiSelectSurveyGroups: function() {
      return this.matchType == "survey";
    },
    displaySingleSelectSurveyGroups: function() {
      return (!this.displayMultiSelectSurveyGroups && this.displayComparativePeriod) || !this.showMatchType;
    },
    displaySavedCards: function() {
      return this.matchType == "saved-card";
    },
    displayComparativePeriod: function() {
      return this.matchType == "time-period";
    },
    displayComparativeSurvey: function() {
      return this.matchType == "survey";
    },
    generateLabel: function(){
      if (this.displayType == "nps"){
        return "Generate NPS";
      } else if ((this.displayType == "need" || this.displayType == "value" ||
                  this.displayType == "winner" || this.displayType == "moat") && 
          this.isComparative == false){
        return "Generate Profile";
      }
      return "Generate Chart";
    },
    isNps: function(){
      return this.displayType == "nps" ;
    },
    displayShowW30Benchmarks: function(){
      return !this.showNpsTrends && !(this.isRanks && this.displayType != "moat-rank")
      //return !this.showNpsTrends && !this.isRanks;
    },
    displayUseRankCalculations: function(){
      return this.displayType == "w-scores-rank" && this.use_rank_calculations_options.length > 0;
    },
    isRanks: function(){
      return this.displayType == "nps-rank"
      || this.displayType == "satisfaction-rank"
      || this.displayType == "emotions-rank"
      || this.displayType == "moat-rank"
      || this.displayType == "w-scores-rank";
    },
    filteredShowNeedsOptions: function(){
      if (this.displayType == "moat-rank"){
        
        return this.show_needs_options
          .filter(option => !this.baseRankOptions.includes(option.value));
      }
      let showNeedsOptions = this.show_needs_options.filter(option => !this.moatRankOptions.includes(option.value));
      if (!this.isAdmin){
        showNeedsOptions = showNeedsOptions.filter(option => !this.adminOnlyOptions.includes(option.value));
      }
      return showNeedsOptions;
    },
    clientId: function() {
      if (typeof this.$parent.clientId != "undefined") {
        return this.$parent.clientId;
      } else {
        return 0;
      }
    },
    clientCode: function() {
      if (typeof this.$parent.clientCode != "undefined") {
        return this.$parent.clientCode;
      } else {
        return "";
      }
    },
    surveyCode: function() {
      if (typeof this.$parent.surveyCode != "undefined") {
        return this.$parent.surveyCode;
      } else {
        return "";
      }
    },
    surveyName: function() {
      return this.$parent.survey.name;
    },
    showCompetitors: function() {
      return  this.displayType != "nps-rank" &&
      this.displayType != "satisfaction-rank" &&
      this.displayType != "emotions-rank" &&
      this.displayType != "w-scores-rank";
    },
    hasCompetitors: function(){
      return this.competitor_options.length > 0;
    }
  },
  props: {
    showEqualWeight: {
      type: Boolean,
      default: true
    },    
    showCustomTimePeriod: {
      type: Boolean,
      default: true
    },    
    isComparative: {
      type: Boolean,
      value: false
    },
    useChartTypeCard: {
      type: Boolean,
      value: false
    },
    showMetric: {
      type: Boolean,
      value: false
    },
    showDisplayType: {
      type: Boolean,
      value: false
    },
    includeShowCompetitorExpectations: {
      type: Boolean,
      value: false
    },
    includeShowPricingPower: {
      type: Boolean,
      value: false
    },
    showMatchType: {
      type: Boolean,
      value: false
    },
    displayTypeLabel: {
      type: String,
      default: "Chart Type"
    },
    displayTypeModalName: {
      type: String,
      default: "Chart Type"
    },
    matchTypeLabel: {
      type: String,
      default: "By"
    },
    maximumCompetitors: {
      type: Number,
      default: 5
    },
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    },
    timePeriodLabel: {
      type: String,
      default: "Period"
    }
  },
  methods: {
    showManageExclusionListModal(){
      GoogleService.logManageExclusionList(this.$parent.chart.title, this.displayType, this.surveyName, this);
      this.$refs.manageExclusionList.loadData();
      this.$modal.show("manage-exclusion-list");
    },
    setTextDirection(inputTextDirection) {
      this.saveResultValue(inputTextDirection, "Text Direction", "text_direction", true);
      this.textDirection = inputTextDirection;
      if (typeof this.$parent.clientChart.criteria != "undefined" &&
                 this.$parent.clientChart.criteria.includes("text_direction")) {
        this.$parent.clientChart.criteria = 
          this.$parent.clientChart.criteria.replace("&text_direction=angle","&text_direction="+this.textDirection);
        this.$parent.clientChart.criteria = 
          this.$parent.clientChart.criteria.replace("&text_direction=horizontal","&text_direction="+this.textDirection);

      } else {
        this.$parent.clientChart.criteria = this.$parent.clientChart.criteria + "&text_direction="+this.textDirection;
      }
    },    
    setDisplayType(inputDisplayType) {
      this.saveResultValue(inputDisplayType, "Display Type", "display_type", true);
      this.displayType = inputDisplayType;
      this.displayCustomTimePeriod =  this.displayType != 'trends';
      this.$emit("reset-client-chart","");
    },
    setUseEqualWeightForComparativeRemove: function() {
      if (this.isComparative == false){
        return;
      }
      if (this.comparativeSurveyGroups.length != 0){
        this.disableEqualWeightForComparativeSurveyGroups = false;
      } else {
        this.disableEqualWeightForComparativeSurveyGroups = true;
        this.useEqualWeight = "N";
      }
    },
    onComparativeInput(option, type) {
      let lastOption = option.slice(-1)[0];
      if (type == "survey-group") {
        this.setUseEqualWeightForComparativeRemove();
      }
      if (this.totalComparative > this.maximumComparative) {
        if (type == "survey") {
          this.comparativeSurveys.pop();
        } else if (type == "survey-group") {
          this.comparativeSurveyGroups.pop();
        } else if (type == "time-period") {
          this.comparativePeriods.pop();
        } else if (type == "saved-card") {
          this.comparativeSavedCards.pop();
        }
        if (lastOption.checked == true) {
          lastOption.checked = false;
          return;
        }
        let message = "Only " + this.maximumComparative + " comparisons are allowed to be included in the chart";
        AdminService.displayErrorMessage({ text: message, duration: -1 });
      } else {
        if (typeof lastOption != "undefined") {
          lastOption.checked = true;
        }
      }
    },
    onComparativeRemove(option, type) {
      option.checked = false;
      let index = -1;
      if (type == "survey") {
        index = this.comparativeSurveys.findIndex(item => item.name == option.name);
        if (index >= 0) {
          this.comparativeSurveys[index].checked = false;
        }
      } else if (type == "survey-group") {
        index = this.comparativeSurveyGroups.findIndex(item => item.name == option.name);
        if (index >= 0) {
          this.comparativeSurveyGroups[index].checked = false;
        }
        this.setUseEqualWeightForComparativeRemove();
      } else if (type == "time-period") {
        index = this.comparativePeriods.findIndex(item => item.name == option.name);
        if (index >= 0) {
          this.comparativePeriods[index].checked = false;
        }
      } else if (type == "saved-cards") {
        index = this.comparativeSavedCards.findIndex(item => item.name == option.name);
        if (index >= 0) {
          this.comparativeSavedCards[index].checked = false;
        }
      }
    },
    updateValue: function(value) {
      this.$emit("input", value);
    },
    logChartClickEvent(widgetName, value){
      GoogleService.logChartClickEvent(this.$parent.chart.title, this.displayType, this.surveyName, widgetName, this);
    },
    resetCompetitors(){
      for (let i = 0; i < this.competitor_options.length; i++) {
        this.competitor_options[i].checked = false;
      }
    },
    resetChartCriteriaForRanks() {
      for (let i = 0; i < this.show_needs_options.length; i++) {
        this.show_needs_options[i].checked = false;
      }
      for (let i = 0; i < this.show_metrics_options.length; i++) {
        this.show_metrics_options[i].checked = false;
      }
      for (let i = 0; i < this.use_rank_calculations_options.length; i++) {
        this.use_rank_calculations_options[i].checked = false;
      }
      for (let i = 0; i < this.number_of_ranking_tiers_options.length; i++) {
        this.number_of_ranking_tiers_options[i].checked = false;
      }

    },    
    resetChartCriteria(setDefaults = true) {
      this.$parent.$data.autoGenerateChartRun = false;
      this.$parent.setDisplayView(false);
      if (this.$refs.chartCompany !== undefined) {
        this.$refs.chartCompany.resetCompanySelections();
      }
      this.$parent.$refs.chartCriteriaResults.$data.criteriaResultsCompanyList = [];
      this.$parent.$refs.chartCriteriaResults.$data.criteriaResultsList = [];
      this.resetCriteriaResults();
      if (this.matchType != "survey"){
        this.setUseEqualWeight();
      }  else {
        this.setUseEqualWeightForComparativeRemove();
      }    
      if (this.isRanks){
        this.resetChartCriteriaForRanks();
      }
      this.resetCompetitors();
      if (setDefaults) {
        GoogleService.logResetCriteria(this.$parent.chart.title, this.displayType, this.surveyName, this);
        this.setDefaultValues();
      }
      // Reset Competitors
      this.values = [];

      // Reset all of the segments
      this.resetOption = !this.resetOption;

      $(".chart").html("");
      $(".chart-criteria-option").prop("checked", false);
    },
    getPeriodName(value) {
      if (this.period_options.length > 0) {
        let period = this.period_options.find(x => x.value == value);
        if (typeof period != "undefined") {
          return period.name;
        }
      }
      return "";
    },
    saveTimePeriod: function(value, group_label, parameterName, updateValue) {
      this.$parent.$data.clientChart.clientTimePeriodId = value;
      this.saveResultValue(value, group_label, parameterName, updateValue);
    },
    saveTimePeriodEvent: function(event, group_label, parameterName, updateValue) {
      this.$parent.$data.clientChart.clientTimePeriodId = event.target.value;
      this.saveResultValue(event.target.value, group_label, parameterName, updateValue);
    },
    buildUrlParams(client_id) {
      let params = new URLSearchParams();
      if (this.displayWinnersMap) {
        this.$refs.winnersRef.setupWinnersMapForSave();
        if (typeof this.$parent.clientChart != "undefined" && (this.$parent.clientChart.clientChartId == 0 || this.$parent.clientChart.displayType == "winners")) {
          params.append("clientChartId", this.$parent.clientChart.clientChartId);
        }
        params.append("conserveEfforts", JSON.stringify(this.$refs.winnersRef.conserveEfforts));
        params.append("coreEfforts", JSON.stringify(this.$refs.winnersRef.coreEfforts));
        params.append("divergeEfforts", JSON.stringify(this.$refs.winnersRef.divergeEfforts));
        params.append("winnerTypeSelected", this.$refs.winnersRef.winnerTypeSelected);
      }

      params.append("client_id", client_id);
      params.append("survey_id", SurveyService.getSurveyId());
      params.append("user_id", UserService.getUserId());
      var competitors = this.competitors;
      var competitorsLength = competitors.length;
      for (var i = 0; i < competitorsLength; i++) {
        if (competitors[i].companyType == "benchmark") {
          params.append("benchmark_company_id", competitors[i].companyId);
        } else {
          params.append("competitor_company_id", competitors[i].companyId);
        }
      }
      for (var savedCardsIndex = 0; savedCardsIndex < this.comparativeSavedCards.length; savedCardsIndex++) {
        params.append("comparative_saved_card", this.comparativeSavedCards[savedCardsIndex].clientChartId);
      }
      for (var t = 0; t < this.comparativePeriods.length; t++) {
        params.append("comparative_period", this.comparativePeriods[t].value);
      }
      for (var surveyIndex = 0; surveyIndex < this.comparativeSurveys.length; surveyIndex++) {
        params.append("comparative_survey", this.comparativeSurveys[surveyIndex].value);
      }
      params = this.buildCriteriaUrl(params);
      for (var surveyGroupIndex = 0; surveyGroupIndex < this.comparativeSurveyGroups.length; surveyGroupIndex++) {
        params.append("comparative_survey_group", this.comparativeSurveyGroups[surveyGroupIndex].value);
      }
      if (Object.prototype.hasOwnProperty.call(this.eChartCriteria, "eChartType")) {
        params.append("eChartType",this.eChartCriteria.eChartType);
	    }
      if (Object.prototype.hasOwnProperty.call(this.eChartCriteria, "displayNumberOfResponses")) {
        params.append("displayNumberOfResponses",this.eChartCriteria.displayNumberOfResponses);
      }	        
      if (Object.prototype.hasOwnProperty.call(this.eChartCriteria, "angleText")) {
        params.append("angleText",this.eChartCriteria.angleText);
      }	        
      if (Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showGoalLine")) {
        params.append("showGoalLine",this.eChartCriteria.showGoalLine);
      }	        
      if (Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showLegend")) {
        params.append("showLegend",this.eChartCriteria.showLegend);
	    }
      if (Object.prototype.hasOwnProperty.call(this.eChartCriteria, "showLabels")) {
        params.append("showLabels",this.eChartCriteria.showLabels);
      }	        
      if (Object.prototype.hasOwnProperty.call(this.eChartCriteria, "colorPaletteId")) {
        params.append("colorPaletteId",this.eChartCriteria.colorPaletteId);
      }	        
      return params;
    },
    loadClients(response) {
      this.clientOptions = response.data;
    },
    loadOptions(response) {
      this.$root.processServerResponse();
      this.defaultPeriod = response.data.defaultPeriod;
      let returnChartCriteriaList = response.data.returnChartCriteriaList
      for (var i in returnChartCriteriaList) {
        this.criteriaResults.push(returnChartCriteriaList[i]);
      }
      this.surveyGroupList = response.data.surveyGroupList;
      this.segments = ChartService.getSegments(this.criteriaResults);
      this.survey_group_options = this.getSurveyGroupOptions();
      this.period_options = this.findCriteria("Period").options;
      this.saved_card_options = ClientService.getSavedChartsByChartType(this.$parent.clientObject.clientCharts, this.$parent.survey.surveyId, 1);
      this.saved_card_options.sort(ReportService.compareValues("name", "asc"));
      this.display_type_options = this.findCriteria("Display Type").options;
      this.match_type_options = this.findCriteria("Match Type").options;
      if (this.$parent.chart.typeName == "competitive-trends") {
        this.displayType = "summary";
        this.metric_options = this.findCriteria("Metric").options;
        this.metric = "-1";
      } else if (this.$parent.chart.typeName == "ranks") {
        this.displayType = "moat-rank";
        this.displayCustomTimePeriod = true;
        this.show_needs_options = this.findCriteria("Show Needs").options;
        this.show_metrics_options = this.findCriteria("Show Metrics").options;
        this.use_rank_calculations_options = this.findCriteria("Use Rank Calculations").options;
        this.number_of_ranking_tiers_options= this.findCriteria("# of Ranking Tiers").options;
        this.ranking_tier = "";
        this.rank_calculation_type = "";
      } else if (this.$parent.chart.typeName == "profile-match") {
        this.matchType = "survey";
        this.survey_options = this.findCriteria("Survey").options;
      } else if (this.$parent.chart.typeName == "predictive-analytics") {
        this.displayType = "analytics";
      }
    },
    loadCompetitors(data) {
      this.competitor_options = data;
      for (var index = 0; index < this.competitor_options.length; index++) {
        var competitorOption = this.competitor_options[index];
        var competitorOptionMatch = this.values.find(x => Number(x.companyId) === Number(competitorOption.companyId));
        if (typeof competitorOptionMatch != "undefined") {
          competitorOptionMatch.name = this.competitor_options[index]["name"];
        }
        this.competitor_options[index]["checked"] = false;
      }
    },
    resetShowNeeds(){
      for (let optionIndex = 0; optionIndex < this.showNeeds.length; optionIndex++) {
        let found = false;
        let option = this.show_needs_options[optionIndex];
        for (let filteredOptionIndex = 0; filteredOptionIndex < this.filteredShowNeedsOptions.length; filteredOptionIndex++) {
          let filteredOption = this.show_needs_options[optionIndex];
          if (filteredOption.value == option.value){
            found = true;
            break;
          }
        }
        if (found == true){
          option.checked = false;
          var match = this.showNeeds.find(x => x.value === option.value);
          if (typeof match !== "undefined") {
            this.showNeeds.splice($.inArray(match, this.showNeeds), 1);
          }
        }
      }      
    },
    setDefaultValues() {
      this.period = this.defaultPeriod;
      this.customStartDate = new Date().toLocaleDateString("en-US");
      this.customEndDate = new Date().toLocaleDateString("en-US");
      this.saveTimePeriod(this.period, "Period", "period", true);
      this.saveResultValue(this.displayType, "Display Type", "display_type", true);
      this.saveResultValue(this.matchType, "Match Type", "match_type", true);
      
      this.customTimePeriod = false;
      this.useEqualWeight = "N";
      this.includePartialResponses = false;
      this.showCompetitorExpectations = false;
      this.showPricingPower = "Y";
      this.saveResultValue(this.showPricingPower, "Show Pricing Power", "show_pricing_power", true);
      this.surveyGroup = [];
      this.displayBenchmark = false;
      this.metric = -1;
      this.comparativeSurveyGroups = [];
      this.surveyLocation = [];
      this.words = [];
      this.showNeeds = [];
      this.showMetrics = [];
      this.ranking_tier = "";
      this.rank_calculation_type = "";
      this.textDirection = "";
      for (let optionIndex = 0; optionIndex < this.survey_group_options.length; optionIndex++) {
        let option = this.survey_group_options[optionIndex];
        option.checked = false;
      }

      this.comparativeSurveys = [];
      for (let optionIndex = 0; optionIndex < this.survey_options.length; optionIndex++) {
        let option = this.survey_options[optionIndex];
        option.checked = false;
      }
      this.resetShowNeeds();
      this.showMetrics = [];
      for (let optionIndex = 0; optionIndex < this.show_metrics_options.length; optionIndex++) {
        let option = this.show_metrics_options[optionIndex];
        option.checked = false;
      }
      this.comparativePeriods = [];
      for (let optionIndex = 0; optionIndex < this.period_options.length; optionIndex++) {
        let option = this.period_options[optionIndex];
        option.checked = false;
      }
      this.comparativeSavedCards = [];
      for (let optionIndex = 0; optionIndex < this.saved_card_options.length; optionIndex++) {
        let option = this.saved_card_options[optionIndex];
        option.checked = false;
      }
      this.displayCustomTimePeriod =  this.displayType != 'trends';
    },
    processPartialResponsesCallback: function(response) {},
    async loadData(chartTypeName) {
      let surveyId = SurveyService.getSurveyId();
      if (surveyId != 0) {        
        this.$root.processServerRequest("Loading data");
        const params = new URLSearchParams();
        params.append("client_id", this.clientId);
        params.append("survey_id", surveyId);
        params.append("user_id", UserService.getUserId());
        params.append("chart_type_name", chartTypeName);
        await axios
          .post(this.baseUrl + "/api/v1/chart/get-options", params, { headers: await authHeader() })
          .then(response => this.loadOptions(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("ChartCriteria.loadData.get-options", error, "chart", this);
          });
      }
      if (!this.$parent.autoGenerateChartRun) {
        this.setDefaultValues();
      }
      this.setupChart();
      this.$parent.processAutoGenerateChart();
      if (this.matchType != "survey"){
        this.setUseEqualWeight();
      }  else {
        this.setUseEqualWeightForComparativeRemove();
      }    
      this.display = true;
      this.$root.processServerResponse();
    },
    setupChart() {
      this.chartForm = $("#ChartGenerateFormId");

      jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
      });
      jQuery.validator.addMethod(
        "notEqual",
        function(value, element, param) {
          return this.optional(element) || value != param;
        },
        "Please choose a value!"
      );

      this.validator = this.chartForm.validate({
        rules: {},
        highlight: function(element) {
          $(element)
            .closest(".form-group")
            .removeClass("has-success")
            .addClass("has-error");
        },
        unhighlight: function(element) {
          $(element)
            .closest(".form-group")
            .removeClass("has-error")
            .addClass("has-success");
        },
        errorElement: "small",
        errorClass: "form-text text-danger",
        errorPlacement: function(error, element) {
          if (element.length) {
            var targetParent = $(element).parent();
            if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
              targetParent = targetParent.parent();
            }
            targetParent.append(error);
          }
        }
      });
    },
    removeCompany(competitor) {
      var competitorToDelete = $("div[data-company-id='" + competitor.companyId + "']");
      if (competitorToDelete !== "undefined" && competitorToDelete.length > 0) {
        competitorToDelete.html("");
      }
      var match = this.competitors.find(x => Number(x.companyId) === Number(competitor.companyId));
      if (typeof match !== "undefined") {
        this.competitors.splice($.inArray(match, this.competitors), 1);
        let company = this.$refs.competitorsSelect.options.find(x => Number(x.companyId) === Number(competitor.companyId));
        if (typeof company !== "undefined") {
          company.checked = false;
        }
      }
    },
    addCompany(competitor) {
      competitor.checked = true;
      // Only add if the company doesn't already exist
      var match = this.competitors.find(x => Number(x.companyId) === Number(competitor.companyId));
      if (typeof match == "undefined") {
        this.competitors.push(competitor);
      }
    },
    select(option) {
      option.selected = !option.selected;
    },
    addMetric(metric) {
      metric.checked = true;
    },
    removeMetric(metric) {
      metric.checked = false;
      let showMetricsCriteria = this.findCriteriaByParameter("show_metrics");
      if (typeof showMetricsCriteria != "undefined") {
        let valueToRemove = showMetricsCriteria.values.find(x => x === metric.value);
        if (typeof valueToRemove !== "undefined") {
          showMetricsCriteria.values.splice($.inArray(showMetricsCriteria, showMetricsCriteria.values), 1);
        }      
      }      
    },    
    addNeed(need) {
      need.checked = true;
    },
    removeNeed(need) {
      need.checked = false;
      let showNeedsCriteria = this.findCriteriaByParameter("show_needs");
      if (typeof showNeedsCriteria != "undefined") {
        let valueToRemove = showNeedsCriteria.values.find(x => x === need.value);
        if (typeof valueToRemove !== "undefined") {
          showNeedsCriteria.values.splice($.inArray(valueToRemove, showNeedsCriteria.values), 1);
        }      
      }
    }    
  },
  watch: {
    displayType: function(){
      this.resetShowNeeds();
    },
    customTimePeriod: function() {
      if (this.customTimePeriod == true || this.customTimePeriod == "Y"){
        this.saveResultValue(this.customStartDate, "Custom Start Date", "custom_start_date", true);
        this.saveResultValue(this.customEndDate, "Custom End Date", "custom_end_date", true);
      } else {
        this.resetCustomFields();
      }
    },
    showAllChartCards: function() {
      if (this.showAllChartCards == "N") {
        this.saved_card_options = ClientService.getSavedChartsByChartType(this.$parent.clientObject.clientCharts, this.$parent.survey.surveyId, 1);
        this.saved_card_options.sort(ReportService.compareValues("name", "asc"));
      } else {
        this.saved_card_options = ClientService.getAllSavedChartsByChartType(this.$parent.clientObject.clientCharts, 1);
        this.saved_card_options.sort(ReportService.compareValues("name", "asc"));
      }
    },
    displayBenchmark: function() {
      this.$refs.chartCompany.$data.displayBenchmark = this.displayBenchmark;
    },
    surveyCode: function() {
      if (this.$refs.chartCompany !== undefined) {
        this.$refs.chartCompany.resetCompanySelections();
      }
      if (!this.$parent.autoGenerateChartRun) {
        this.criteriaResults = [];
        this.resetChartCriteria(false);
        this.loadData(this.$parent.chart.typeName);
      }
    },
    comparativeSavedCards: function() {
      if (this.comparativeSavedCards.length > this.maximumCompetitors) {
        event.preventDefault();
        let message = "Only " + this.maximumCompetitors + " saved cards are allowed to be included in the chart";
        AdminService.displayErrorMessage({ text: message, duration: -1 });
      } 
    },
    comparativePeriods: function() {
      if (this.comparativePeriods.length > this.maximumCompetitors) {
        event.preventDefault();
        let message = "Only " + this.maximumCompetitors + " time periods are allowed to be included in the chart";
        AdminService.displayErrorMessage({ text: message, duration: -1 });
      } 
    },
    comparativeSurveys: function() {
      if (this.comparativeSurveys.length > this.maximumComparative) {
        event.preventDefault();
        let message = "Only " + this.maximumComparative + " surveys are allowed to be included in the chart";
        AdminService.displayErrorMessage({ text: message, duration: -1 });
      } 
    },
    comparativeSurveyGroups: function() {
      if (this.comparativeSurveyGroups.length > this.maximumComparative) {
        event.preventDefault();
        let message = "Only " + this.maximumComparative + " surveys are allowed to be included in the chart";
        AdminService.displayErrorMessage({ text: message, duration: -1 });
      } 
    },
    period: async function() {
      ClientCompetitorService.getCompetitors(this.clientId, this.period, this.loadCompetitors);
//      ChartService.getSurveyLocations(this.baseUrl, SurveyService.getSurveyId(), this.period, true, this.getSurveyLocationsCallback);
      WordCloudService.getWordCounts(SurveyService.getSurveyId(), this.period, true, this.getWordCountsCallback);
    },
    matchType: function() {},
    disableChartCriteriaWidgets: function() {
      $("#ChartGenerateFormId :input").prop("disabled", this.disableChartCriteriaWidgets);
      if (this.matchType != "survey"){
        this.setUseEqualWeight();
      }  else {
        this.setUseEqualWeightForComparativeRemove();
      }    
      $("#useEqualWeight").prop("disabled", this.disableEqualWeight);
      $("#useEqualWeightForComparativeSurveyGroups").prop("disabled", this.disableEqualWeightForComparativeSurveyGroups);
    }
  },
  mounted() {},
  created() {
    this.getPageContent("Chart");
    if (this.$parent.autoGenerateChart) {
      if (typeof this.$parent.saveClientChartObject != "undefined") {
        if (typeof this.$parent.saveClientChartObject.criteria != "undefined" && this.$parent.saveClientChartObject.criteria != "") {
          let criteriaParametersList = this.$parent.saveClientChartObject.criteria.split("&");
          // find survey id
          for (let criteriaIndex = 0; criteriaIndex < criteriaParametersList.length; criteriaIndex++) {
            let criteria = criteriaParametersList[criteriaIndex];
            let criteriaParameters = criteria.split("=");
            let defaultParameter = criteriaParameters[0];
            let defaultValue = criteriaParameters[1];
            if (defaultParameter == "survey_id") {
              if (this.$parent.survey.surveyId != defaultValue) {
                let newSurveyCode = SurveyService.getSurveyCodeBySurveyId(defaultValue);
                if (newSurveyCode != "") {
                  this.$parent.surveyCode = newSurveyCode;
                  SurveyService.setSurveyCode(this.surveyCode);
                  this.$root.setSurveyCode(this.surveyCode);
                  this.$parent.survey = SurveyService.getSurvey();
                }
              }
            }
          }
        }
      }
    }
    this.loadData(this.$parent.chart.typeName);
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
