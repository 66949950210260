<template>
  <div></div>
</template>
<script>
import AdminService from "../services/admin.service";
import AlertService from "../services/alert.service";

export default {
  name: "FilterMixins",
  mixins: [],
  components: {  },
  data() {
    return {
        originalList: [],
        filteredList: [],
        isDataFiltering: false,    
        executeReset: false,
        filterByCreatedDate: false,
        filterByCreatedStartDate: new Date(2020, 0, 1),
        filterByCreatedEndDate: new Date(),    
        filterByCompletedDate: false,
        filterByCompletedStartDate: new Date(2020, 0, 1),
        filterByCompletedEndDate: new Date(),    
        filterByStatusValue: "",    
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  },
  created() {
  },
  methods: {
    setFilterByStatusValue: function(value){
      //console.log("setFilterByStatusValue: " + value)
      this.filterByStatusValue = value;
    },
    setFilterByStartDateValue: function(value, type){
//      console.log("setFilterByStartDateValue: " + value)
      if (type == "completed"){
        this.filterByCompletedDate = true;
        this.filterByCompletedStartDate = value;
      } else {
        this.filterByCreatedDate = true;
        this.filterByCreatedStartDate = value;
      }
    },
    setFilterByEndDateValue: function(value, type){
//      console.log("setFilterByEndDateValue: " + value)
      if (type == "completed"){
        this.filterByCompletedDate = true;
        this.filterByCompletedEndDate = value;
      } else {
        this.filterByCreatedDate = true;
        this.filterByCreatedEndDate = value;
      }
    },
    preReset: async function(){
      this.isDataFiltering = false;
      this.executeReset = true;
      //console.log("preReset");
      AdminService.displayInfoMessage({text: "Resetting table data. Please wait..."});
      this.filterByStatusValue = "";
      this.filterByCreatedStartDate = new Date(2020, 0, 1);
      this.filterByCreatedEndDate = new Date();  
      this.filterByCompletedStartDate = new Date(2020, 0, 1);
      this.filterByCompletedEndDate = new Date();  
      this.filterByCreatedDate = false;
      this.filterByCompletedDate = false;
    },
    postReset: function(){
      //console.log("postReset");
      AlertService.closeAlert();
      this.executeReset = false;
    },
    preFilter: async function(){
      //console.log("beforeFilter");
      this.isDataFiltering = true;
      AdminService.displayInfoMessage({text: "Filtering table data. Please wait..."});
      this.filteredList =  [...this.originalList];
      //console.log("this.filteredList.length : " + this.filteredList.length);
    },
    postFilter: async function(){
      //console.log("postFilter");
      this.isDataFiltering = false;
      AlertService.closeAlert();
    },
    async filterByStatus() {
      //console.log("filterByStatus: " + this.filterByStatusValue + "   original size: " + this.filteredList.length);
      if (this.filterByStatusValue != "" && !this.isFilterListEmpty){
        this.filteredList = this.filteredList.filter(item => item.status == this.filterByStatusValue);
      }
    },
    async filterByCreatedDateRange() {
      //console.log("filterByCreatedDateRange : " + this.filterByCreatedDate + " Filter List size: " + this.filteredList.length);
      if (this.filterByCreatedDate && !this.isFilterListEmpty){
        let vm = this;
        this.filteredList = this.filteredList.filter(item => {
          let dateCreated = new Date(item.dateCreated);
          let filterByCreatedStartDate = new Date(vm.filterByCreatedStartDate);
          let filterByCreatedEndDate = new Date(vm.filterByCreatedEndDate);
          return dateCreated >= filterByCreatedStartDate && dateCreated <= filterByCreatedEndDate;
        });
      }
    },
    async filterByCompletedDateRange() {
      //console.log("filterByCompletedDateRange: " + this.filterByCompletedDate + " Filter List size: " + this.filteredList.length);
      if (this.filterByCompletedDate && !this.isFilterListEmpty){
        let vm = this;
        this.filteredList = this.filteredList.filter(item => {
          let dateCompleted = new Date(item.dateCompleted);
          let filterByCompletedStartDate = new Date(vm.filterByCompletedStartDate);
          let filterByCompletedEndDate = new Date(vm.filterByCompletedEndDate);
          return dateCompleted >= filterByCompletedStartDate && dateCompleted <= filterByCompletedEndDate;
        });
      }
    }

  },
  watch: {},
  mounted() {},
  computed: {
    isFilterListEmpty: function(){
      return this.filteredList.length == 0;
    }
  }
};
</script>
