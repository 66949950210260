<template>
  <div class="panel-admin-body">
    <survey-status :input-survey="survey" :display-survey-status="!isSurveyEmpty" :url="liveSurveyUrl" :does-survey-url-work="this.doesSurveyUrlWork"></survey-status>
    <div class="d-flex title">
      {{ title }}
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <design-menu></design-menu>
    <div v-if="permissionToView">
      <div v-if="getDisplayView()" class="ideal">
        <div class="row no-gutters justify-content-center">
          <div class="design-column">
            <div class="header">Survey Defaults</div>
            <div class="form-group form-inline no-gutters">
              <label for="surveyName" class="col-3 pr-3 p-1"> <modal-link modal-id="Survey Name" :content-list="pageContent"></modal-link>Survey Name </label>
              <input v-model="surveyName" id="surveyName" name="surveyName" type="text" class="form-control col-9 p-1" @blur="saveSurveyName()" />
            </div>
            <div class="form-group form-inline no-gutters">
              <label for="surveyTitle" class="col-3 pr-3 p-1"> <modal-link modal-id="Survey Title" :content-list="pageContent"></modal-link>Survey Title </label>
              <input v-model="surveyTitle" id="surveyTitle" name="surveyTitle" type="text" class="form-control col-9 p-1" @blur="saveSurveyTitle()" />
            </div>
            <survey-field
              :survey-id="this.survey.surveyId"
              :survey-field-object="this.getSurveyField('company')"
              :display-modal="true"
              modal-name="Company"
              :build-survey="true"
              field-class="form-control col-9 p-1"
              field-type="text-box"
              field-label="Company"
              default-field-name="company"
              :default-field-value="this.clientName"
              @save="this.saveSurveyField"
              :content-list="pageContent"
            ></survey-field>
            <survey-field
              :survey-id="this.survey.surveyId"
              :survey-field-object="this.getSurveyField('ideal')"
              :display-modal="true"
              :build-survey="true"
              modal-name="Ideal"
              field-class="form-control col-9 mb-1 p-1"
              field-type="text-box"
              field-label="Ideal"
              default-field-name="ideal"
              @save="this.saveSurveyField"
              :content-list="pageContent"
            ></survey-field>
            <!-- Pricing Power - Start -->
            <div class="header">Pricing Power Fields</div>
            <div class="mt-1">
              <div class="form-group form-inline no-gutters">
                <label for="pricingPowerQuestion" class="col-3 pr-3 text-right"> Pricing Power Question </label>
                <textarea
                  id="pricingPowerQuestion"
                  name="pricingPowerQuestion"
                  v-model="pricingPowerQuestionLabel"
                  type="text"
                  class="form-control col-9 p-1"
                  style="width: 100%"
                  @blur="saveQuestionLabelEvent($event)"
                  rows="3"
                />
              </div>
              <div class="row no-gutters mt-1">
                <div class="col-6">
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-option-1')"
                    :display-modal="false"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Display Option 1"
                    default-field-value="0%"
                    :build-survey="true"
                    default-field-name="pp-option-1"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-option-2')"
                    :display-modal="false"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Display Option 2"
                    :build-survey="true"
                    default-field-value="1-2%"
                    default-field-name="pp-option-2"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-option-3')"
                    :display-modal="false"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Display Option 3"
                    default-field-value="3-5%"
                    :build-survey="true"
                    default-field-name="pp-option-3"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-option-4')"
                    :display-modal="false"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Display Option 4"
                    :build-survey="true"
                    default-field-value="5-10%"
                    default-field-name="pp-option-4"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-option-5')"
                    :display-modal="false"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Display Option 5"
                    :build-survey="true"
                    default-field-name="pp-option-5"
                    default-field-value="10-15%"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-option-6')"
                    :display-modal="false"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Display Option 6"
                    :build-survey="true"
                    default-field-value="15-20%"
                    default-field-name="pp-option-6"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-option-7')"
                    :display-modal="false"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Display Option 7"
                    :build-survey="true"
                    default-field-value="20% or more"
                    default-field-name="pp-option-7"
                    @save="this.saveSurveyField"
                  ></survey-field>
                </div>
                <div class="col-6">
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-setting-1')"
                    :display-modal="false"
                    :build-survey="true"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Calculated Value 1"
                    default-field-value="0.0"
                    default-field-name="pp-setting-1"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-setting-2')"
                    :display-modal="false"
                    label-class="col-8"
                    :build-survey="true"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Calculated Value 2"
                    default-field-value="0.015"
                    default-field-name="pp-setting-2"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-setting-3')"
                    :display-modal="false"
                    :build-survey="true"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Calculated Value 3"
                    default-field-value="0.04"
                    default-field-name="pp-setting-3"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-setting-4')"
                    :display-modal="false"
                    :build-survey="true"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Calculated Value 4"
                    default-field-value="0.075"
                    default-field-name="pp-setting-4"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-setting-5')"
                    :display-modal="false"
                    :build-survey="true"
                    label-class="col-8"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Calculated Value 5"
                    default-field-name="pp-setting-5"
                    default-field-value="0.125"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-setting-6')"
                    :display-modal="false"
                    label-class="col-8"
                    :build-survey="true"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Calculated Value 6"
                    default-field-value="0.175"
                    default-field-name="pp-setting-6"
                    @save="this.saveSurveyField"
                  ></survey-field>
                  <survey-field
                    :survey-id="this.survey.surveyId"
                    :survey-field-object="this.getSurveyField('pp-setting-7')"
                    :display-modal="false"
                    label-class="col-8"
                    :build-survey="true"
                    field-class="form-control col-4 mb-1 p-1"
                    field-type="text-box"
                    field-label="Calculated Value 7"
                    default-field-value="0.25"
                    default-field-name="pp-setting-7"
                    @save="this.saveSurveyField"
                  ></survey-field>
                </div>
              </div>
            </div>
            <!-- Pricing Power - End -->
          </div>
          <div class="chart-criteria-border"></div>
          <div class="design-column">
            <client-competitors
              :input-client-competitors="competitors"
              :clientId="this.clientId"
              :surveyId="this.survey.surveyId"
              @save="saveCompetitors"
              @enable-save-order-button="enableSaveOrderButton"
              :auto-save="true"
            ></client-competitors>
            <div class="mt-1"></div>
          </div>
        </div>
        <div class="row no-gutters pt-2">
          <div class="col float-right" role="group">
            <button type="submit" class="btn btn-primary float-right" :disabled="this.error || this.disableButton" v-on:click="this.goToNeeds">
              <span>{{ buttonText }} </span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
            <button type="submit" class="btn btn-primary float-right mr-4" :disabled="this.error || this.disableSaveOrderButton" v-on:click="this.saveClientCompetitorOrder">
              <span>Save Order</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
        <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
          <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
        </div>
      </div>
    </div>
    <div v-else class="ideal text-center">You do not have permission to view this page</div>
  </div>
</template>
<script>
import jQuery from "jquery";

import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import SurveyField from "../../components/SurveyField.vue";
import ClientCompetitors from "../../components/ClientCompetitors.vue";
import AdminService from "../../services/admin.service";
import SurveyService from "../../services/survey.service";
import GoogleService from "../../services/google.service";
import ErrorService from "../../services/error.service";
import QuestionService from "../../services/question.service";
import ClientService from "../../services/client.service";
import ClientCompetitorService from "../../services/client.competitor.service";
import SurveyStatus from "../../components/SurveyStatus.vue";

export default {
  name: "TheIdeal",
  mixins: [StudyDesignMixins],
  components: {
    SurveyField,
    ClientCompetitors,
    SurveyStatus
  },
  props: {},
  data() {
    return {
      surveyName: "",
      surveyTitle: "",
      pageNumber: 2,
      title: "Competitors",
      competitors: ["", "", "", "", ""],
      buildSurvey: true,
      pricingPowerQuestionObject: {},
      pricingPowerQuestionLabel: "",
      disableSaveOrderButton: true,
    };
  },
  methods: {
    enableSaveOrderButton(){
      this.disableSaveOrderButton = false;
      this.disableButton = true;
    },
    saveClientCompetitorOrder(){
      this.$root.processServerRequest("Saving competitor order");
      GoogleService.logClickEvent(SurveyService.getSurveyName(), "Save Order", 0, this);

      this.disableSaveOrderButton = true;
      ClientService.clearCache();
      ClientCompetitorService.saveClientCompetitorOrder(this.competitors, this.survey.surveyId, this.saveClientCompetitorOrderCallback, this);
    },    
    saveClientCompetitorOrderCallback(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.saveCompetitors(response.data.data);
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.disableButton = false;
      } else {
        ErrorService.processError("TheIdeal.saveClientCompetitorOrderCallback", response.data.message, "survery-designer", this);
      }
    },
    saveCompetitors(result) {
      this.competitors = JSON.parse(JSON.stringify(result));
      this.saveBuildRequired("Y");
    },
    async saveSurveyTitle() {
      if (this.survey.title != this.surveyTitle) {
        this.$root.processServerRequest();
        SurveyService.saveSurveyTitle(this.surveyTitle, this.saveSurveyTitleCallback, this);
      }
    },
    saveSurveyTitleCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyTitle = response.data.data.title;
        if (!jQuery.isEmptyObject(response.data.data.surveyCodes)) {
          this.clientObject.surveyCodes = response.data.data.surveyCodes;
          ClientService.saveClient(this.clientObject);
          this.$root.setClientObject(this.clientObject);          
        }
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.error = false;
      } else {
        this.error = true;
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async saveSurveyName() {
      if (this.survey.name != this.surveyName) {
        this.$root.processServerRequest();
        SurveyService.saveSurveyName(this.surveyName, this.saveSurveyNameCallback, this);
      }
    },
    saveSurveyNameCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyName = response.data.data.name;
        this.survey.name = this.surveyName;
        if (!jQuery.isEmptyObject(response.data.data.surveyCodes)) {
          this.clientObject.surveyCodes = response.data.data.surveyCodes;
          ClientService.saveClient(this.clientObject);
          this.$root.setClientObject(this.clientObject);
        }
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.error = false;
      } else {
        this.error = true;
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    saveQuestionLabelEvent: function(event) {
      this.saveQuestionLabel(event.target.value);
    },
    async saveQuestionLabel(newValue) {
      if (this.pricingPowerQuestionObject.label != newValue) {
        var result = {
          questionId: this.pricingPowerQuestionObject.questionId,
          label: newValue
        };
        this.saveBuildRequired("Y");
        QuestionService.saveQuestionLabel(result, this);
      }
    },
    postLoadData: function() {
      this.setDisplayView(true);
      this.surveyName = this.survey.name;
      this.surveyTitle = this.survey.title;
      this.pricingPowerQuestionObject = QuestionService.getQuestionFromSurvey(this.survey.sections, "Ideal Pricing Power", "Ideal Pricing Power");
      this.pricingPowerQuestionLabel = this.pricingPowerQuestionObject.label;
      if (typeof this.pricingPowerQuestionLabel == "undefined" || this.pricingPowerQuestionLabel == "") {
        this.pricingPowerQuestionLabel = "If a company were to fully meet your Ideal, how much more would you be willing to pay, if any?";
      }
    }
  },
  created() {},
  mounted() {},
  computed: {}
};
</script>
