import $, { type } from "jquery";
import CommonService from "./common.service";
import ScatterService from "./scatter.service";

class RanksService extends CommonService{
    /***  Series Functions ***/
    getSeries(name,gridIndex,xAxisIndex,yAxisIndex,config = {},isEmotionalNeeds = false){
        let baseSeries = {
            name: name,
            type: 'scatter',
            gridIndex: gridIndex,
            xAxisIndex: xAxisIndex,
            yAxisIndex: yAxisIndex,
            data: []
        };    
        $.extend(true, baseSeries, config);
        return baseSeries;
    }
    /*** yAxis Functions ***/
    getMarkAreaData(){
        return [
            [
              {
                  itemStyle: {
                  color: "#f2dcdb"
                  }
              },            
              {
                  yAxis: 10
              }
            ],
            [
              {
                  itemStyle: {
                  color: "#FFFFC5"
                  },
                  yAxis: 10
              },            
              {
                  yAxis: 35
              }
            ],
            [
              {
                  itemStyle: {
                  color: "#FAFBF7"
                  },
                  yAxis: 35
              },            
              {
                  yAxis: 75
              }
            ],
            [
              {
                  itemStyle: {
                  color: "#EBF1DE"
                  },
                  yAxis: 75
              },            
              {
                  yAxis: 90
              }
            ],
            [
              {
                  itemStyle: {
                  color: "#D8E4BC"
                  },
                  yAxis: 90
              },            
              {
                  yAxis: 100
              }
            ]
        ];
      }
  
/*Tooltip Functions */
toolTipForRanksChart = (params) => {
    let returnValue = "";
    for (let i = 0; i < params.length; ++i) {
        let valueToDisplay = params[i].value;
        let seriesName = params[i].seriesName;
        //returnValue += params[i].marker += " ";
        returnValue += params[i].seriesName += " ";
        returnValue += "<span style='text-align:right'><b>" 
        if (valueToDisplay > 0){
           returnValue += Number(valueToDisplay).toFixed(1) + "%";
        } else {
            returnValue += "--";
        }
        returnValue += "</b></span>";
        returnValue += "<br>";
    }
    return returnValue;
  }      
}
export default new RanksService();             

