<template>
  <div class="panel-admin-body">
    <survey-status :input-survey="survey" :display-survey-status="!isSurveyEmpty" :url="liveSurveyUrl" :does-survey-url-work="this.doesSurveyUrlWork"></survey-status>
    <div class="d-flex title">
      {{ title }}
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <design-menu></design-menu>
    <div v-show="getDisplayView()" class="survey-preview container">
      <div v-if="!this.hasSurveyBeenBuilt" class="text-center text-danger">
        Your survey design changes have not been processed. Please return to the Constants page and click the Next button to generate the survey
      </div>
      <div class="text-center">Welcome to the Survey Preview. This is how the survey will display to the end user. The widgets are functional but the buttons are disabled.</div>
      <survey-taker
        :read-only="true"
        :view-only="true"
        :entry-client-id="this.clientId.toString()"
        :entry-survey-url="this.survey.url"
        :entry-survey-code="this.surveyCode"
      ></survey-taker>
    </div>
  </div>
</template>

<script>
import SurveyTaker from "../../interview-application/views/SurveyTaker.vue";
import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import SurveyStatus from "../../components/SurveyStatus.vue";
import AdminService from "../../services/admin.service";

export default {
  name: "SurveyPreview",
  mixins: [StudyDesignMixins],
  components: {
    SurveyTaker,
    SurveyStatus
  },
  data() {
    return {
      title: "Preview",
      pageNumber: 4
    };
  },
  methods: {},
  computed: {},
  created() {},
  props: {},
  mounted() {}
};
</script>
