<template>
  <div class="w-75 border-bottom pt-1 pb-3">
    <div class="row pt-3">
      <h4>Cache</h4>
    </div>
    <div class="row">
      <div class="pr-2">
        <button type="button" class="btn btn-primary ml-2" @click="clearCache()">Clear All Cache</button>
      </div>
    </div>
    <div class="row">
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import AdminService from "../../services/admin.service";
import TableService from "../../services/table.service";
import AlertService from "../../services/alert.service";

export default {
  name: "CacheStatistics",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      title: "CacheStatistics",
      table: TableService.getCacheStatisticsTableAttributes(),
      startDate: new Date(2020, 0, 1),
      endDate: new Date(),
      environment_type: "",
      environment_type_options: [
        { name: "All", value: "" },
        { name: "Production", value: "production" },
        { name: "Staging", value: "staging" },
        { name: "Development", value: "development" }
      ]
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      AdminService.getCacheStatistics(this.getCacheStatisticsCallback, this);
    },
    getCacheStatisticsCallback(response) {
      this.$root.processServerResponse();
      this.table.data = response.data.data.cacheStatisticsList;
      $.extend(this.table.configuration, { columns: this.table.columns });
      $.extend(this.table.configuration, { data: this.table.data });
      this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
      const vm = this;
      this.table.dataTable
        .on("click", "tr", function(e, dt, type, indexes) {
          vm.table.rowSelected = true;
          var rowData = vm.table.dataTable
            .rows(this)
            .data()
            .toArray();
          $("#" + vm.table.id + " .selected").removeClass("selected");
          $(this).toggleClass("selected");
          if (!$.isEmptyObject(rowData)) {
            vm.table.rowSelectedId = rowData[0].id;
            vm.postRowSelect(rowData[0], true);
          }
        })
        .on("deselect", function(e, dt, type, indexes) {
          vm.table.rowSelected = false;
          vm.table.rowSelectedId = 0;
          if ($(this).hasClass("selected")) {
            $(this).removeClass("selected");
          }
          var rowData = vm.table.dataTable
            .rows(this)
            .data()
            .toArray();
          if (!$.isEmptyObject(rowData)) {
            vm.postRowSelect(rowData[0], false);
          }
        });
        AlertService.closeAlert();

      $("#cache-statistics-list-table tbody").on("click", ".btn-clear-cache", function(e, dt, type, indexes) {
        var $row = $(this).closest("tr");
        var data = vm.table.dataTable.row($row).data();
        if (!$.isEmptyObject(data)) {
          vm.clearCacheByName(data.name);
        }
      });
      this.buildResponsiveTable();
    },
    async clearCacheByName(name) {
      this.$root.processServerRequest("Clearing cache");
      AdminService.clearCacheByName(name, this.clearCacheByNameCallback, this);
    },
    clearCacheByNameCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.loadData();
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async clearCache() {
      this.$root.processServerRequest("Clearing cache");
      AdminService.clearCache(this.clearCacheCallback, this);
    },
    warmCacheCallback(response) {
      this.$root.processServerResponse();      
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 },true);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message, duration: -1 },true);
      }
    },
    async clearCacheCallback(response) {
      this.$root.processServerResponse();      
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.loadData();
        AdminService.warmCache(this.warmCacheCallback, this);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData() {
      this.getTableData();
      this.table.configuration.order = [[1, "desc"]];
      this.table.configuration.dom = "frtpB";
      this.table.configuration.responsive = false;
    }
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.loadData();
    }
  },
  props: {},
  mounted() {}
};
</script>
