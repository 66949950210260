<template>
  <div></div>
</template>
<script>
import StackedAreaService from "../services/echarts/stacked.area.service";

import Vue from "vue"; 
import Echarts from "vue-echarts";
Vue.component("chart", Echarts);

export default {
  name: "EChartsButtonsMixins",
  mixins: [],
  components: {},
  data() {
    return {
      chartSelected: 0,
      showLabels: true,
      showLegend: true,
      colorPaletteId: 0,
      eChartType: "stacked-bar-vertical",
      colorPaletteOptions: StackedAreaService.getColorPaletteList(),
    };
  },
  props: {
    inputShowLabels: {
        type: Boolean,
        default: true
    },
    inputShowLegend: {
        type: Boolean,
        default: true
    },   
    inputColorPaletteId: {
        type: Number,
        default: 0
    }, 
    inputEChartType: {
        type: String,
        default: "stacked-bar-vertical"
    },

  },
  created() {},
  methods: {
    changeShowLegend: function(showLegend) {
        this.showLegend = showLegend;
        this.$emit("set-show-legend", this.showLegend);
    },
    changeShowLabels: function(showLabels) {
        this.showLabels = showLabels;
        this.$emit("set-show-labels", this.showLabels);
    },
    setChart: function(eChartType,index) {
       if (this.verifyChartCriteria(eChartType)) {
        this.eChartType = eChartType;
        this.chartSelected = index;
        this.$emit("set-chart", eChartType);
      }     
    },
    getChartIndex: function(chartType){
      if (chartType ==  "bar-horizontal"){
          return  1;
      } else if (chartType ==  "bar-vertical"){
          return  2;
      } else if (chartType ==  "pie"){
          return  3;        
      } else if (chartType ==  "doughnut"){
          return  4;
      } else if (chartType ==  "stacked-bar-vertical"){
          return  5;
      } else if (chartType ==  "stacked-bar-horizontal"){
          return  6;
      } else if (chartType ==  "stacked-area"){
          return  7;
      } else if (chartType ==  "line"){
          return  8;
      } 
    },
  },
  watch: {
    colorPaletteId: function() {
        this.$emit("set-color-palette", this.colorPaletteId);
    },    
    inputEChartType: function(){
        this.setChart(this.inputEChartType, this.getChartIndex(this.inputEChartType));      
    },
    inputShowLabels: function(){
        this.showLabels = this.inputShowLabels;
    },
    inputShowLegend: function(){
        this.showLegend = this.inputShowLegend;
    },
    inputColorPaletteId: function(){
        this.colorPaletteId = this.inputColorPaletteId;
    },
  },
  mounted() {},
  computed: {}
};
</script>
