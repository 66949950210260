<template>
  <div></div>
</template>
<script>
import $ from "jquery";
export default {
  name: "RankBodyMixins",
  mixins: [],
  components: {
    /* eslint-disable vue/no-unused-components */
  },
  data() {
    return {};
  },
  props: {
    tiers: {
      type: Array,
      default: function() {
        return [];
      }
    },    
    titleHtml: {
      type: String,
      default: ""
    },
    tier: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    score: {
      type: Number,
      default: 0
    },
    baseHeight: {
      type: Number,
      default: 215
    },
    isModal: {
      type: Boolean,
      default: false
    },
  },
  created() {},
  methods: {
    getTierClass: function(tier) {
      let className = "tier-block";
      if (tier.selected == true){
        className += " selected"
      } 
      return className;
    }
  },
  watch: {
  },
  mounted() {},
  computed: {
    rankChartDisplayIdName: function(){
      if (this.isModal){
        return "rank-chart-display-container-modal";
      } else {
        return "rank-chart-display-container";
      }
    },
    rankChartDisplayContainer: function(){
      return $("#"+this.rankChartDisplayIdName);
    },
  }
};
</script>
