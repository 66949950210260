<template>
    <div class="form-group form-inline mt-1">
        <input
        type="checkbox"
        class="form-check-input"
        id="showLegend"
        name="showLegend"
        v-model="showLegend"
        @click="changeShowLegend($event)"
        />
        <label for="showLegend">Legend</label>
    </div>                   
</template>
<script>
export default {
  name: "ShowLegend",
  mixins: [],
  components: {},
  data() {
    return {
        showLegend: true,
    };
  },
  props: {
    inputShowLegend: {
        type: Boolean,
        default: true
    },    
  },
  computed: {
  },
  mounted() {},
  created() {},
  methods: {
    changeShowLegend: function(event) {
        this.showLegend = event.target.checked;
        this.$emit("set-show-legend", this.showLegend);
    },
  },
  watch: {
    inputShowLegend: function(){
        this.showLegend = this.inputShowLegend;
    },    
  }
};
</script>
