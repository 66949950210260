<template>
  <div class="form-group">
    <label :for="optionObject.parameterName" :class="labelClass">{{ fullLabel }} </label>
    <modal-link v-if="fullLabel == 'NPS'" :modal-id="fullLabel"></modal-link>
    <div v-if="optionType === 'checkbox'" class="chart-criteria-opt">
      <div v-for="(option, chartCriteriaOptions) in optionObject.options" v-bind:key="chartCriteriaOptions">
        <input
          type="checkbox"
          class="chart-criteria-option"
          v-bind:id="option.value"
          v-bind:value="option.value"
          v-bind:name="optionObject.parameterName"
          @click="saveValue($event)"
          v-bind:class="{ required: isFieldRequired }"
        />{{ option.name }}
      </div>
    </div>
    <div v-if="optionType === 'radio'">
      <div v-for="(option, radioOptions) in optionObject.options" v-bind:key="radioOptions">
        <input :type="optionType" :name="optionObject.parameterName" :class="{ required: isFieldRequired }" :value="option.value" @click="saveValue($event)" />{{ option.name }}
      </div>
    </div>
    <div v-if="optionType === 'select'">
      <select
        :id="optionObject.parameterName"
        :name="optionObject.parameterName"
        class="custom-select shadow"
        @change="saveValue($event)"
        v-bind:class="{ required: isFieldRequired }"
      >
        <option value="">Select</option>
        <option v-for="(option, selectOptions) in optionObject.options" v-bind:value="option.value" v-bind:key="selectOptions">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div v-if="optionType == 'multiselect'">
      <multiselect
        v-model="values"
        :options="optionObject.options"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        @select="addValue"
        @remove="removeValue"
        :max-height="250"
        select-label=""
        deselect-label=""
        selected-label=""
        :name="optionObject.parameterName"
        placeholder="Select"
        label="name"
        track-by="name"
        :preselect-first="false"
      >
        <template #option="props">
          <span class="checkbox-label" @click.self="select(props.option)">
            <input class="mr-3" type="checkbox" v-model="props.option.checked" @focus.prevent />
            {{ props.option.name }}
          </span>
        </template>        
      </multiselect>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import ModalLink from "../ModalLink.vue";
import GoogleService from "../../services/google.service";

export default {
  name: "ChartCriteriaOption",
  components: {
    Multiselect, ModalLink
  },
  data() {
    return {
      values: []
    };
  },
  props: {
    labelClass: {
      type: String,
      default: "text-right control-label pt-3"
    },
    defaultValue: {
      type: String,
      default: ""
    },
    chartTitle: {
      type: String,
      default: ""
    },
    surveyName: {
      type: String,
      default: ""
    },
    optionType: {
      type: String,
      default: ""
    },
    optionObject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isFieldRequired: {
      type: Boolean,
      default: false
    },
    resetOption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fullLabel: function() {
      return this.optionObject.name;
    },
    updateValue: function() {
      return this.optionType === "select" || this.optionType === "radio";
    }
  },
  mounted() {
    if (this.optionType == "multiselect") {
      for (let valueIndex = 0; valueIndex < this.optionObject.values.length; valueIndex++) {
        let defaultValueKey = this.optionObject.values[valueIndex];
        let defaultValue = this.optionObject.options.find(option => option.value === defaultValueKey);
        if (typeof defaultValue != "undefined") {
          defaultValue.checked = true;
          this.values.push(defaultValue);
        }
      }
    }
  },
  created() {},
  methods: {
    addValue: function(selection) {
      selection.checked = true;
      GoogleService.logChartClickEvent(this.chartTitle, this.$parent.displayType, this.surveyName, this.fullLabel, this);
      this.$emit("add", selection.value, this.optionObject.name, this.optionObject.parameterName, false);
    },
    removeValue: function(selection) {
      selection.checked = false;
      this.$emit("add", selection.value, this.optionObject.name, this.optionObject.parameterName, false);
    },
    saveValue: function(event) {
      this.$emit("save", event, this.optionObject.name, this.optionObject.parameterName, this.updateValue);
    }
  },
  watch: {
    resetOption: function() {
      this.values = [];
      for (let optionIndex = 0; optionIndex < this.optionObject.options.length; optionIndex++) {
        let option = this.optionObject.options[optionIndex];
        option.checked = false;
      }
    }
  }
};
</script>
