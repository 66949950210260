<template>
  <div :class="footerClass">
    <div class="footer-content">
      <div>
        <a href="https://www.wratings.com" target="_blank">
          <span>© {{ new Date().getFullYear() }} wRatings </span>
        </a>
      </div>
      <div class="bullet">&bull;</div>
      <div>
        <a href="#!" @click="$modal.show(modalPrivacyName)">
          <span>{{ $t('privacyLabel') }}</span>
        </a>
      </div>
      <div class="bullet">&bull;</div>
      <div>
        <a href="#!" @click="$modal.show(modalTermsName)">
          <span>{{ $t('termsLabel') }}</span>
        </a>
      </div>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :width="modalWidth" :modal-content="pageContent.contentText" />
    </div>
  </div>
</template>

<script>
import ViewMixins from "../mixins/ViewMixins.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "FooterMain",
  mixins: [ViewMixins],
  components: {},
  data() {
    return {
      modalTermsName: "Terms",
      modalPrivacyName: "Privacy"
    };
  },
  props: {
    entryLanguageCode: {
      type: String,
      default: "en"
    },       
    footerClass: {
      type: String,
      default: "footer-main"
    }
  },
  computed: {
    modalWidth: function(){
      return isMobile ? 300 : 900;
    }
  },
  mounted() {
    this.setModalNames();
  },
  created() {
    if (typeof this.pageContent == "undefined" || this.pageContent.length == 0) {
      this.getPageContentByPage("All");
    }
  },
  methods: {
    setModalNames: function(){
/*      
      if (this.entryLanguageCode.toUpperCase() != "EN"){
        this.modalPrivacyName = "Privacy - " + this.entryLanguageCode.toUpperCase();
        this.modalTermsName = "Terms - " + this.entryLanguageCode.toUpperCase();
      } else {
*/        
        this.modalPrivacyName = "Privacy";
        this.modalTermsName = "Terms";
  //    }
    }
  },
  watch: {
    entryLanguageCode: function(){
      this.setModalNames();
    }
  },
};
</script>
