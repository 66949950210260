import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import moment from "moment-timezone";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;

const API_CLIENT_URL = AdminService.getBaseUrl() + "/api/v1/client";

class ClientNoteService {
    async getClientNotes(clientCode, callback, chartApp) {
        let userId = UserService.getUserId();
        if (typeof clientCode != "undefined" && typeof userId != "undefined") {
          const params = new URLSearchParams();
          params.append("clientCode", clientCode);
          params.append("userId", UserService.getUserId());
          await axios
            .post(API_CLIENT_URL + "/get-client-notes", params, { headers: await authHeader() })
            .then(response => callback(response))
            .catch(error => {
              error.message += ErrorService.mergeError(error.response);
              ErrorService.processError("ClientNoteService.getClientNotes", error, "admin", chartApp);
            });
        }
    }
    async deleteClientNote(clientNoteId, callback, chatApp) {
      const params = new URLSearchParams();
      params.append("clientNoteId", clientNoteId);
      params.append("userId", UserService.getUserId());
      await axios
        .post(API_CLIENT_URL + "/delete-note", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ClientNoteService.deleteClientNote", error, "admin", chatApp);
        });
    }   
    async saveNote(clientCode, clientNoteId, note ,callback, chartApp) {      
      const params = new URLSearchParams();
      params.append("userId", UserService.getUserId());
      params.append("clientCode", clientCode);
      params.append("clientNoteId", clientNoteId);
      params.append("note", note);

      await axios
        .post(API_CLIENT_URL + "/save-note", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("EditContent.saveContent", error, "admin", chartApp);
        });
    }      
    getClientNoteTableColumnData() {
        return  [
            {
              title: "Id",
              data: "id",
              createdCell: function(td, cellData, rowData, row, col) {
                $(td).addClass("text-center");
              },
              className: "",
              name: "id",
              width: "2rem"
            },
            { title: "Note", data: "note", name: "note", width: "35rem" },
            { title: "Entered By", data: "userName", name: "userName", width: "10rem" },
            {
              title: "Date Created",
              data: "dateCreated",
              type: "date",
              name: "dateCreated",
              width: "5rem",
              render: function(data, type, row) {
                return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
              }
            },
            {
              title: "Date Modified",
              data: "dateModified",
              type: "date",
              name: "dateModified",
              width: "5rem",
              render: function(data, type, row) {
                return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
              }
            }
          ]
    ;
    }
      
}

export default new ClientNoteService();
