<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <respondent-menu></respondent-menu>
    <div v-if="!permissionToView" class="no-permission text-center">You do not have permission to view this page</div>   
    <div v-else class="respondent-waves">
      <div class="row no-gutters pb-1 pt-1">
        <div class="col-12">Dates for Waves are set at the Organizational level. Changes in Start/End Dates impact all surveys within the Organization selected.</div>
      </div>
      <div class="form-row no-gutters align-items-center text-center pb-2 pt-2">
        <div class="d-none col-1 section-label">Period Name</div>
        <div class="col-1 section-label">n=</div>
        <div class="col-1 section-label">Year</div>
        <div class="col-1 section-label">Period</div>
        <div class="col-2 section-label">Chart Display</div>
        <div class="col-2 section-label">Respondent Start Date</div>
        <div class="col-2 section-label">Respondent End Date</div>
      </div>

      <div
        v-for="(respondentWave, idxRespondentWave) in this.respondentWaveListObject"
        :key="'respondentWave' + idxRespondentWave"
        class="respondent-waves-entry"
      >
        <respondent-wave-entry
          :new-respondent-wave-index="idxRespondentWave"
          :respondent-wave-object="respondentWave"
          @save="saveRespondentWave"
          @remove="removeRespondentWave" 
	        @update="updateRespondentWaveObject"
        ></respondent-wave-entry>
      </div>
      <div class="form-row">
        <div class="col">
          <button type="submit" class="btn btn-primary float-right mt-4 ml-2" @click="clickSaveAllRespondentWaves()">
            <span>Save All</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
      </div>
      <div id="gap" class="form-row" style="min-height: 300px"></div>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
    </div>
  </div>
</template>
<script>
import AdminService from "../../services/admin.service";
import ChartService from "../../services/chart.service";
import ClientSettingsService from "../../services/client.settings.service";
import ClientTimePeriodService from "../../services/client.time.period.service";
import GoogleService from "../../services/google.service";

import RespondentMenu from "../../views/menu/RespondentMenu.vue";

import RespondentMixins from "../../mixins/RespondentMixins.vue";
import ContentMixins from "../../mixins/ContentMixins.vue";
import ViewMixins from "../../mixins/ViewMixins.vue";

import RespondentWaveEntry from "../../components/RespondentWaveEntry.vue";
import { toRaw } from "vue";
export default {
  name: "RespondentWaves",
  mixins: [ContentMixins, ViewMixins, RespondentMixins],
  components: {
    RespondentWaveEntry,
    RespondentMenu
  },
  props: {},
  data() {
    return {
      pageNumber: 3,
      title: "Waves",
      respondentWaveListObject: [{}],
      year_options: [],
      clientSettings: {}
    };
  },
  methods: {
    async loadYearOptions() {
      await ChartService.getYearOptions(this.baseUrl, this.loadYearOptionsCallback);
      this.display = true;
    },

    loadYearOptionsCallback: function(data) {
      this.year_options = data;
    },
    clickSaveAllRespondentWaves(){
      GoogleService.logClickEvent(this.clientName, "Save All Waves", 0, this);
      this.saveAllRespondentWaves();
    },
    saveRespondentWave: function() {
      this.saveAllRespondentWaves();
    },
    getRespondentWave(clientTimePeriodId) {
      return this.respondentWaveListObject.find(x => x.clientTimePeriodId == clientTimePeriodId);
    },
    respondentWaveCallback: function(response) {
      this.$root.processServerResponse();      
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.respondentWaveListObject = response.data.data.waves;
        if (typeof response.data.data.settings != "undefined"){
          this.clientSettings = ClientSettingsService.parseClientSettings(response.data.data.settings);
        }

        let option = {
          clientTimePeriodId: 0,
          clientId: this.clientId
        };
        this.respondentWaveListObject.push(option);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async removeRespondentWave(index) {
      let removeRespondentWaveObject = this.getRespondentWave(index);
      this.$root.processServerRequest("Retrieving respondent wave data");
      ClientTimePeriodService.removeRespondentWave(index,this.clientCode,this);
    },
    async updateRespondentWaveObject(index, object) {
      const respondentWaveIndex = this.respondentWaveListObject.findIndex(x => x.clientTimePeriodId == index);
      if (respondentWaveIndex !== -1) {
        let updateRespondentWaveObject = toRaw(object);
        this.$set(this.respondentWaveListObject, respondentWaveIndex, updateRespondentWaveObject);
      } 
    },
    async getAllRespondentWaves() {
      this.resetData();
      this.$root.processServerRequest("Retrieving respondent wave data");
      ClientTimePeriodService.getAllRespondentWaves(this.clientCode, this);
    },
    async saveAllRespondentWaves() {
      this.$root.processServerRequest("Saving respondent wave data");
      ClientTimePeriodService.saveAllRespondentWaves(this.clientCode, this.respondentWaveListObject, this)
    },
    loadData: function() {
      if (!this.isW5OUser){
        this.getAllRespondentWaves();
        this.loadYearOptions();
      }
      this.$root.setDisableSurveyDropdown(true);
    },
    resetData() {
      this.respondentWaveListObject = [{}];
    }
  },
  created() {},
  mounted() {
    this.$root.setShowMenuIcon(true);
  },
  beforeRouteLeave: function(to, from, next) {
    this.$root.setDisableSurveyDropdown(false);
    next();
  },
  computed: {}
};
</script>
