<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container performance-summary">
      <div>
        <cache-statistics />
        <div class="row pt-3">
          <h4>Performance - Functions</h4>
        </div>
        <div class="row pt-1">
          <div class="col-2">
            <div class="form-group form-inline">
              <label for="startDate" class="mr-1">Start Date</label>
              <datepicker
                format="MMM dd yyyy"
                v-model="startDate"
                :typeable="true"
                wrapper-class=""
                input-class="form-control required w-100 bg-white"
                name="startDate"
              ></datepicker>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group form-inline ml-2">
              <label for="endDate" class="mr-1">End Date</label>
              <datepicker format="MMM dd yyyy" v-model="endDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="endDate"></datepicker>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group form-inline ml-2">
              <label for="environment_type" class="mr-1">Environment</label>
              <select id="environment_type" name="environment_type" class="custom-select required" v-model="environment_type">
                <option
                  v-for="(environment_type_option, idxapplication_error_clientOptions) in this.environment_type_options"
                  v-bind:value="environment_type_option.value"
                  v-bind:key="idxapplication_error_clientOptions"
                >
                  {{ environment_type_option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="pr-2">
            <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="loadData()">Run</button>
          </div>
          <div class="pr-2">
            <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="reset()">Reset</button>
          </div>
        </div>
      </div>
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";

import moment from "moment";
Vue.prototype.moment = moment;
import Datepicker from "vuejs-datepicker";

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import CacheStatistics from "./CacheStatistics.vue";
import AdminService from "../../services/admin.service";
import TableService from "../../services/table.service";
import AlertService from "../../services/alert.service";

export default {
  name: "Performance",
  mixins: [AdminMixins, DataTableMixins],
  components: { Datepicker, CacheStatistics },
  data() {
    return {
      title: "Performance",
      table: TableService.getPerformanceSummaryTableAttributes(),
      startDate: new Date(2020, 0, 1),
      endDate: new Date(),
      environment_type: "",
      environment_type_options: [
        { name: "All", value: "" },
        { name: "Production", value: "production" },
        { name: "Staging", value: "staging" },
        { name: "Development", value: "development" }
      ]
    };
  },
  methods: {
    reset() {
      this.startDate = new Date(2020, 0, 1);
      this.endDate = new Date();
    },
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      AdminService.getPerformanceSummaryList(this.startDate, this.endDate, this.environment_type, this.getPerformanceSummaryListCallback, this);
    },
    getPerformanceSummaryListCallback(response) {
      this.$root.processServerResponse();
      this.table.data = response.data.data.performanceSummaryList;
      $.extend(this.table.configuration, { columns: this.table.columns });
      $.extend(this.table.configuration, { data: this.table.data });
      this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
      const vm = this;
      this.table.dataTable
        .on("click", "tr", function(e, dt, type, indexes) {
          vm.table.rowSelected = true;
          var rowData = vm.table.dataTable
            .rows(this)
            .data()
            .toArray();
          $("#" + vm.table.id + " .selected").removeClass("selected");
          $(this).toggleClass("selected");
          if (!$.isEmptyObject(rowData)) {
            vm.table.rowSelectedId = rowData[0].id;
            vm.postRowSelect(rowData[0], true);
          }
        })
        .on("deselect", function(e, dt, type, indexes) {
          vm.table.rowSelected = false;
          vm.table.rowSelectedId = 0;
          if ($(this).hasClass("selected")) {
            $(this).removeClass("selected");
          }
          var rowData = vm.table.dataTable
            .rows(this)
            .data()
            .toArray();
          if (!$.isEmptyObject(rowData)) {
            vm.postRowSelect(rowData[0], false);
          }
        });
      AlertService.closeAlert();
      this.buildResponsiveTable();
    },
    loadData() {
      this.getTableData();
      this.table.configuration.order = [[5, "desc"]];
      this.table.configuration.dom = "frtpB";
      this.table.configuration.responsive = false;
    }
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.loadData();
    }
  },
  props: {},
  mounted() {}
};
</script>
