<template>
  <div>
    <div v-show="displaySectionHeader.length > 0" class="header">
      {{ displaySectionHeader }}
    </div>
    <div v-show="displaySectionDescription.length > 0" class="description">
      {{ displaySectionDescription }}
    </div>
    <div v-if="displayHeader && !displayEmotionalHeader" class="question-header row no-gutters">
      <div v-if="displaySectionLabels.length > 0" class="col-4"></div>
      <div v-if="displaySectionLabels.length > 0" class="col-8 d-flex flex-row">
        <div class="text-nowrap pt-2">{{ displaySectionLabels[0] }}&nbsp;&lt;</div>
        <hr style="width: 100%; text-align: left; margin-top: 1.1rem; margin-left: 0; color: white; background-color: white" />
        <div class="text-nowrap pt-2 pr-1">&gt;&nbsp;{{ displaySectionLabels[1] }}</div>
      </div>
    </div>
    <div v-if="displayHeader && !displayEmotionalHeader" class="question-header row no-gutters">
      <div class="col-3">&nbsp;</div>
      <div class="col-9 form-check form-check-inline no-gutters">
        <div v-for="(headerOption, idxHeader) in displayHeaderOptions" v-bind:key="idxHeader" class="form-check-input col-lg-1">
          {{ headerOption }}
        </div>
      </div>
    </div>
    <div v-if="displayHeader && displayEmotionalHeader" class="question-header row no-gutters d-flex flex-row">
      <div v-if="displaySectionLabels.length > 0" class="col-3 text-right pt-2">{{ displaySectionLabels[0] }}&nbsp;&lt;</div>
      <div v-if="displaySectionLabels.length > 0" class="col-6">
        <hr style="width: 100%; text-align: left; margin-left: 0px; color: white; background-color: white" />
      </div>
      <div v-if="displaySectionLabels.length > 0" class="col-3 text-nowrap pt-2">&gt;&nbsp;{{ displaySectionLabels[1] }}</div>
    </div>
    <div v-if="displayEmotionalSubHeader" class="p-0 question-header row no-gutters d-flex flex-row">
      <div class="col-3 text-right pt-2"></div>
      <div class="col-9 form-check form-check-inline no-gutters">
      <div class="form-check-input" style="width: 6%;"> {{ $t('5') }} </div>
      <div class="form-check-input" style="width: 6%;"> {{ $t('4') }} </div>
      <div class="form-check-input" style="width: 6%;"> {{ $t('3') }} </div>
      <div class="form-check-input" style="width: 6%;"> {{ $t('2') }} </div>
      <div class="form-check-input" style="width: 6%;border-right: 2px solid #fff;margin-right: 0px;"> {{ $t('1') }} </div>
      <div class="form-check-input" style="width: 6%;"> {{ $t('1') }} </div>
      <div class="form-check-input" style="width: 6%;"> {{ $t('2') }} </div>
      <div class="form-check-input" style="width: 6%;"> {{ $t('3') }} </div>
      <div class="form-check-input" style="width: 6%;"> {{ $t('4') }} </div>
      <div class="form-check-input" style="width: 6%;"> {{ $t('5') }} </div>
    </div>      
<!--
      <div class="col-9 form-check form-check-inline no-gutters">
        <div v-for="(headerOption, idxHeader) in displaySubHeaderOptions" v-bind:key="idxHeader" class="form-check-input" style="width:6%">
            {{ headerOption }}
        </div>
      </div>
-->            
      <div class="col-3 text-nowrap pt-2"></div>
    </div>
    <div :class="getQuestionClass(idxQuestion)" v-for="(question, idxQuestion) in questions" v-bind:key="idxQuestion">
      <survey-taker-question
        v-if="displayQuestion(question)"
        :base-url="baseUrl"
        :question-object="question"
        :disable-widgets="disableWidgets"
        :question-translations="questionTranslations"        
        @save="saveResult"
        @remove="removeResult"
      ></survey-taker-question>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import SurveyTakerQuestion from "./SurveyTakerQuestion.vue";
import SectionMixins from "../../mixins/SectionMixins.vue";
import jQuery from "jquery";

export default {
  name: "SurveyTakerSection",
  mixins: [SectionMixins],
  components: {
    SurveyTakerQuestion
  },
  data() {
    return {
      displaySectionHeader: "",
      displaySectionDescription: "",
      displaySectionLabels: [],
      displayHeaderOptions: [],
      displaySubHeaderOptions: [],
      originalDisplaySectionHeader: "",
      originalDisplaySectionDescription: "",
      originalDisplaySectionLabels: [],
      originalDisplayHeaderOptions: [],
    };
  },
  props: {
    surveyId: {
      type: Number,
      default: 0
    },
    surveyTakerId: {
      type: String,
      default: ""
    },
    surveyTakerIpAddress: {
      type: String,
      default: ""
    },
    disableWidgets: {
      type: Boolean,
      default: false
    },
    sectionTranslations:{
      type: Array,
      default: function() {
        return [];
      }    
    },
    questionTranslations: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  computed: {
    rotateRows: function() {
      return this.sectionTypeName == "Functional Needs" || this.sectionTypeName == "Emotional Needs" || this.sectionTypeName == "Segmentation Information";
    },
    leftLabelClass: function() {
      if (this.displayEmotionalHeader) {
        return "col-3 text-right";
      } else {
        return "col-4";
      }
    },
    rightLabelClass: function() {
      if (this.displayEmotionalHeader) {
        return "col-3";
      } else {
        return "col-2";
      }
    },
    displayHeader: function() {
      if (this.sectionName.includes("Functional") || this.sectionName.includes("Emotional")) {
        return true;
      }
      if (this.sectionName.includes("Net Promoters") && this.questions.length > 0) {
        for (let questionIndex = 0; questionIndex < this.questions.length; questionIndex++) {
          let question = this.questions[questionIndex];
          if (
            question.questionType != null &&
            question.questionType != "undefined" &&
            question.questionType.name == "Competitor Company Code" &&
            question.questionType.name == "Competitor Company Code"
          ) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    displayEmotionalSubHeader: function() {
      return this.displayHeader && this.displayEmotionalHeader && 
      ( this.surveyId == 36496 || this.surveyId == 41107 ||
        this.surveyId == 41112 || this.surveyId == 41114 || this.surveyId == 41115 ||
        this.surveyId == 41117 );
    },
    displayEmotionalHeader: function() {
      return this.sectionName.includes("Emotional");
    },
    isDescriptionPopupEnabled: function() {
      let field = this.$parent.getSurveyField("enable-description-popup");
      return !jQuery.isEmptyObject(field) && field.surveyFieldValue == "Y";
    }, 
    randomizeQuestions: function() {
      let field;
      let fieldName = this.randomizeFieldName;
      if (fieldName != "") {
        field = this.$parent.getSurveyField(fieldName);
      }
      return !jQuery.isEmptyObject(field) && field.surveyFieldValue == "Y";
    },
  },
  watch: {
    sectionTranslations: {
      handler(newVal, oldVal) {
        this.reset();
        if (newVal.length > 0){
          let sectionTranslations = newVal.filter(x => x.sectionId == this.sectionObject.sectionId)
          this.redrawSection(sectionTranslations);
        }
      },
      deep: true
    },    
/*  Remove once everything is working with vue 3 migration
    sectionTranslations: function() {
      this.reset();
      if (this.sectionTranslations.length > 0){
        let sectionTranslations = this.sectionTranslations.filter(x => x.sectionId == this.sectionObject.sectionId)
        this.redrawSection(sectionTranslations);
      }
    },  
*/      
    sectionObject: function() {
      this.reset();
    }
  },
  methods: {
    updateSectionObject(mutatedSectionObject) {
      this.sectionObject = mutatedSectionObject;
    },
    reset: function() {
      this.displaySectionHeader = this.sectionHeader;
      this.displaySectionDescription = this.sectionDescription;
      this.originalDisplaySectionHeader= this.displaySectionHeader;
      this.originalDisplaySectionDescription = this.displaySectionDescription;
      this.setHeaderOptions(this.originalDisplayHeaderOptions);
      this.setSectionLabels(this.originalDisplaySectionLabels);
      this.setSubHeaderOptions();
    },
    setDisplayHeaderOptions: function() {
      this.displayHeaderOptions = [];
      if (this.sectionObject.configuration != null && this.sectionObject.configuration != "undefined" && this.sectionObject.configuration != ""){
        let configuration = JSON.parse(this.sectionObject.configuration);
        if (configuration != null && configuration != "undefined" && configuration != ""){
          if (configuration.headerOptions != null && configuration.headerOptions != "undefined" && configuration.headerOptions != ""){
            this.displayHeaderOptions = JSON.parse(JSON.stringify(configuration.headerOptions)) ;
            this.originalDisplayHeaderOptions = JSON.parse(JSON.stringify(this.displayHeaderOptions)) ;
          }
        }
      }
      this.setSubHeaderOptions();
    },
    setDisplaySectionLabels: function(){
      this.displaySectionLabels = [];
      if (this.sectionObject.configuration != null && this.sectionObject.configuration != "undefined" && this.sectionObject.configuration != ""){
        let configuration = JSON.parse(this.sectionObject.configuration);
        if (configuration != null && configuration != "undefined" && configuration != ""){
          if (configuration.labels != null && configuration.labels != "undefined" && configuration.labels != ""){
            this.displaySectionLabels = JSON.parse(JSON.stringify(configuration.labels)) ;
            this.originalDisplaySectionLabels = JSON.parse(JSON.stringify(this.displaySectionLabels)) ;
          }
        }
      }
    },
    setSubHeaderOptions: function() {
      if (this.displayEmotionalSubHeader){
        this.displaySubHeaderOptions = [];
        this.displaySubHeaderOptions.push(this.$t('1'));
        this.displaySubHeaderOptions.push(this.$t('2'));
        this.displaySubHeaderOptions.push(this.$t('3'));
        this.displaySubHeaderOptions.push(this.$t('4'));
        this.displaySubHeaderOptions.push(this.$t('5'));
        this.displaySubHeaderOptions.push(this.$t('6'));
        this.displaySubHeaderOptions.push(this.$t('7'));
        this.displaySubHeaderOptions.push(this.$t('8'));
        this.displaySubHeaderOptions.push(this.$t('9'));
        this.displaySubHeaderOptions.push(this.$t('10'));
      }
    },
    setHeaderOptions: function(headerOptions) {
      this.displayHeaderOptions = [];
      for (let index = 0; index < headerOptions.length; index++) {
        let translationOption = headerOptions[index];
        this.displayHeaderOptions.push(translationOption);
      }
    },
    setSectionLabels: function(sectionLabels) {
      this.displaySectionLabels = [];
      for (let index = 0; index < sectionLabels.length; index++) {
        let translationOption = sectionLabels[index];
        this.displaySectionLabels.push(translationOption);
      }
    },
    redrawSection: function(sectionTranslations){
      for (let index = 0; index < sectionTranslations.length; index++) {
        let currentLocale = sectionTranslations[index];
        if (currentLocale.translationKey == "SECTION_HEADER"){
          if (currentLocale.languageCode == "en"){
            this.displaySectionHeader = this.originalDisplaySectionHeader;
          } else {
            this.displaySectionHeader = currentLocale.translatedText;
          }
        } else if (currentLocale.translationKey == "SECTION_DESCRIPTION"){
          if (currentLocale.languageCode == "en"){
            this.displaySectionDescription = this.originalDisplaySectionDescription;
          } else {    
            this.displaySectionDescription = currentLocale.translatedText;
          }
        } else if (currentLocale.translationKey == "SECTION_HEADER_OPTIONS"){
          let headerOptions = currentLocale.languageCode != "en" ? currentLocale.translatedText.split(",") : this.originalDisplayHeaderOptions;
          this.setHeaderOptions(headerOptions);
        } else if (currentLocale.translationKey == "SECTION_LABELS"){
          let sectionLabels = currentLocale.languageCode != "en" ? currentLocale.translatedText.split(",") : this.originalDisplaySectionLabels;
          this.setSectionLabels(sectionLabels);
        }
      }
    },
    displayQuestion: function(question){
      let displayQuestion = question.display == 'Y';
      if (typeof question.widgetType != "undefined" && question.widgetType.name != "undefined") {
        displayQuestion |= question.widgetType.name == 'hidden';
      }
      return displayQuestion;
    },
    getQuestionClass: function(index) {
      if (this.rotateRows) {
        if (index % 2 == 0) {
          return "question even";
        } else {
          return "question odd";
        }
      }
      return "question";
    },
    shuffle: function(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    removeResult: function(result) {
      var match = this.$parent.$data.surveyResults.find(x => Number(x.questionId) === Number(result));
      if (typeof match !== "undefined") {
        this.$parent.$data.surveyResults.splice($.inArray(match, this.$parent.$data.surveyResults), 1);
      }
    },
    saveResult: function(result) {
      var match = this.$parent.$data.surveyResults.find(x => Number(x.questionId) === Number(result.questionId));
      if (typeof match !== "undefined") {
        this.$parent.$data.surveyResults.splice($.inArray(match, this.$parent.$data.surveyResults), 1);
      }
      this.$parent.$data.surveyResults.push(result);
      if (!this.$parent.$data.surveyStarted && !result.initialLoad) {
        this.$parent.setSurveyStarted();
      }
    }
  },
  created() {
    if (this.randomizeQuestions) {
      this.shuffle(this.questions);
    }
    this.displaySectionHeader = this.sectionHeader;
    this.displaySectionDescription = this.sectionDescription;
    this.setDisplaySectionLabels();
    this.setDisplayHeaderOptions();
  },
  mounted() {}
};
</script>
<style scope="css">
.question-header i.right {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.question-header .left,
.question-header .right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%;
}
</style>
