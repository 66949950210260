import Vue from "vue";
/* Old Vue2/vue-router3 code
import VueRouter from "vue-router";
Vue.use(VueRouter);
*/
import { createRouter,createWebHistory } from "vue-router";

import adminRoutes from "./routes/admin.js";
import interviewRoutes from "./routes/interview.js";
import VueGtag from "vue-gtag";

const isInterviewApp = process.env.VUE_APP_BASE_IS_INTERVIEW_APP;
let routes =  (isInterviewApp == "true" || isInterviewApp == true) ? interviewRoutes : adminRoutes;


/* Old Vue2/vue-router3 code
const router = new VueRouter({
  mode: "history",
  routes
});
*/
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

import {  onAuthRequired } from "../okta";
if (isInterviewApp == "false" || !isInterviewApp) {
  router.beforeEach(onAuthRequired);    
} 
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Survey";
  });
});
if (isInterviewApp == "true" || isInterviewApp == true){
  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GA_ANALYTICS,
        params: {
          send_page_view: false
        }
      },
      appName: "Survey Taker",
      pageTrackerScreenviewEnabled: true
    },
    router
  );
  
} else {
  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GA_ANALYTICS_ADMIN,
        params: {
          send_page_view: false
        }
      },
      appName: "GT Admin",
      pageTrackerScreenviewEnabled: true
    },
    router
  );
}

export default router;
