<template>
  <div v-show="showHeaderMenu">
    <div class="d-flex md-stepper-horizontal">
      <div v-bind:class="[this.isActivePage(1) ? activeClass : '', notActiveClass]" v-on:click="goToReport">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'cubes']" />
        </div>
        <div class="md-step-title">Cards</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(9) ? activeClass : '', notActiveClass]" v-on:click="goToRates">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'thermometer-three-quarters']" />
        </div>
        <div class="md-step-title">Rates</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(2) ? activeClass : '', notActiveClass]" v-on:click="goToCompetitiveProfile">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'fighter-jet']" />
        </div>
        <div class="md-step-title">Profiles</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(8) ? activeClass : '', notActiveClass]" v-on:click="goToProfileMatch">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'random']" />
        </div>
        <div class="md-step-title">Match</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(3) ? activeClass : '', notActiveClass]" v-on:click="goToCompetitiveTrends">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'chart-line']" />
        </div>
        <div class="md-step-title">Trends</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(4) ? activeClass : '', notActiveClass]" v-on:click="goToRanks">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fa', 'tachometer-alt']" />
        </div>
        <div class="md-step-title">Ranks</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(6) ? activeClass : '', notActiveClass]" v-on:click="goToPredictiveAnalytics">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'hand-holding-usd']" />
        </div>
        <div class="md-step-title">Predictive</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
      <div v-bind:class="[this.isActivePage(7) ? activeClass : '', notActiveClass]" v-on:click="goToPrint">
        <div class="md-step-circle">
          <font-awesome-icon :icon="['fas', 'images']" />
        </div>
        <div class="md-step-title">Reports</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import Vue from "vue";
import MenuMixins from "../../mixins/MenuMixins.vue";
import {useRouter} from "vue-router";
Vue.use(useRouter);

export default {
  name: "ChartMenu",
  components: {},
  mixins: [MenuMixins],
  router,
  props: {},
  data() {
    return {};
  },
  methods: {
    goToRates: function() {
      this.$router.replace({ path: "/respondent/rates" });
    },
    goToReport: function() {
      this.$router.replace({ path: "/analytics/report" });
    },
    goToProfileMatch: function() {
      this.$router.replace({ path: "/analytics/match" });
    },
    goToCompetitiveProfile: function() {
      this.$router.replace({ path: "/analytics/profiles" });
    },
    goToCompetitiveTrends: function() {
      this.$router.replace({ path: "/analytics/trends" });
    },
    goToRanks: function() {
      this.$router.replace({ path: "/analytics/ranks" });
    },
    goToPredictiveAnalytics: function() {
      this.$router.replace({ path: "/analytics/predictive" });
    },
    goToPrint: function() {
      this.$router.replace({ path: "/print-report" });
    }
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {}
};
</script>
