<template>
  <div v-if="isContent" class="form-row" v-html="contentDisplay"></div>
  <div v-else-if="isHidden" class="form-row">
      <input
        v-model="responseValue"
        :disabled="readOnly"
        type="hidden"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionId"
        @blur="saveResponseEvent($event)"
        :placeholder="displayPlaceholder"
      />    
  </div>
  <div v-else-if="isPhoneNumber" class="form-row">
    <label :for="questionId" :class="getWideQuestionLabelClass('col-form-label text-right')">{{ questionLabel }}</label>
    <div :class="getWideQuestionWidgetClass('')">
      <input
        v-model="responseValue"
        :disabled="readOnly"
        type="tel"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionId"
        @blur="saveResponseEvent($event)"
        :placeholder="displayPlaceholder"
      />
      <div class="pt-2" v-show="readOnly">{{ responseValue }}</div>
      <small v-show="!readOnly">Format: {{ displayPlaceholder }}</small>
    </div>
  </div>
  <div v-else-if="isTextBox" class="form-row">
    <label :for="questionId" :class="getWideQuestionLabelClass('col-form-label text-right')">{{ questionLabel }}</label>
    <div :class="getWideQuestionWidgetClass('')">
      <input
        v-model="responseValue"
        :disabled="readOnly"
        type="text"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionId"
        @blur="saveResponseEvent($event)"
        :placeholder="displayPlaceholder"
      />
      <div class="pt-2" v-show="readOnly">{{ responseValue }}</div>
    </div>
  </div>
  <div v-else-if="isEmail" class="form-row">
    <label :for="questionId" :class="getWideQuestionLabelClass('col-form-label text-right')">{{ questionLabel }}</label>
    <div :class="getWideQuestionWidgetClass('')">
      <input
        type="email"
        v-model="responseValue"
        :disabled="readOnly"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        aria-describedby="emailHelp"
        :id="questionId"
        :name="questionId"
        @blur="saveResponseEvent($event)"
        :placeholder="displayPlaceholder"
      />
      <div class="pt-2" v-show="readOnly">{{ responseValue }}</div>
      <small id="emailHelp" class="form-text text-muted">{{ shareText }}</small>
    </div>
  </div>
  <div v-else-if="isTextArea" class="form-row">
    <label :for="questionId" class="col-12">{{ questionLabel }}</label>
    <textarea
      :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
      :id="questionId"
      :name="questionId"
      @blur="saveResponseEvent($event)"
      :rows="questionObject.widgetConfiguration.rows"
    ></textarea>
    <div class="pl-2" v-show="readOnly">{{ responseValue }}</div>
  </div>
  <div v-else-if="isRadio && !displayLabels" class="form-row question-radio-label">
    <div class="col-3">
      <modal-link v-if="displayDescriptionPopUp" :modal-title="questionObject.label" :modal-id="questionObject.label" :content-list="contentList"></modal-link>
      <label class="form-check-label" :for="questionId">
        {{ questionLabel }}
      </label>
    </div>
    <div class="col-9 form-check form-check-inline no-gutters">
      <div v-for="(radioOption, idxRadio) in questionObject.widgetConfiguration.options"
        class="interview-checkbox form-check-input col-lg-1 text-center"
        v-bind:key="idxRadio"
      >
      <input
        type="radio"
        :class="[questionObject.isRequired == 'Y' ? 'form-check-input radioNoLabelsRequired' : 'form-check-input']"
        :disabled="readOnly"
        :checked="radioOption.checked"
        :id="questionId"
        :name="questionId"
        :value="radioOption.value"
        @click="saveResponseEvent($event)"
      />
      </div>
    </div>
  </div>
  <div v-else-if="isRadio && displayLabels" class="form-row">
    <label :class="getWideQuestionLabelClass('form-check-label')" :for="questionId">
      {{ questionLabel }}
    </label>
    <div :class="getWideQuestionWidgetClass('form-check form-check-inline')">
      <div class="question-radio-label form-row">
        <div v-for="(option, idxOption) in questionObject.widgetConfiguration.options" :class="radioClass" v-bind:key="idxOption">
          <label class="clickable">
            <input
              type="radio"
              :disabled="readOnly"
              :title="$t('requiredError')"
              :class="[questionObject.isRequired == 'Y' ? 'form-check-input required ' : 'form-check-input']"
              :checked="option.checked"
              :id="questionId"
              :name="questionId"
              :value="option.value"
              @click="saveResponseEvent($event)"
            />
            {{ option.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isSlider" class="form-row">
    <label class="col-3 text-right">
        <modal-link v-if="displayDescriptionPopUp" :modal-title="questionObject.label" :modal-id="questionObject.label" :content-list="contentList"></modal-link>
        {{ leftLabel }}
    </label>
    <div class="col-6">
      <input
        type="text"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionId"
        :data-slider-min="questionObject.widgetConfiguration.min"
        :data-slider-max="questionObject.widgetConfiguration.max"
        :data-slider-step="questionObject.widgetConfiguration.step"
        :data-slider-value="questionObject.widgetConfiguration.value"
        :data-slider-tooltip="'always'"
        :data-slider-tooltip_position="'bottom'"
      />
      <p class="help">Basic example with custom formatter and colored selected region via CSS.</p>
    </div>
    <label class="col-3">{{ rightLabel }}</label>
  </div>
  <div v-else-if="isDropdown" class="form-row form-group">
    <label :class="getWideQuestionLabelClass('form-check-label text-right')" :for="questionId">
      {{ questionLabel }}
    </label>
    <div :class="getWideQuestionWidgetClass('form-check form-check-inline')">
      <select
        v-model="responseValue"
        :disabled="readOnly"
        :title="$t('requiredError')"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionId"
        @change="saveResponseEvent($event)"
      >
        <option
          v-for="(dropdownOption, idxDropdown) in questionObject.widgetConfiguration.options"
          :selected="dropdownOption.selected"
          :value="dropdownOption.value"
          v-bind:key="idxDropdown"
        >
          {{ dropdownOption.name }}
        </option>
      </select>
      <div class="pt-2" v-show="readOnly">{{ responseValue }}</div>
    </div>
  </div>
  <div v-else-if="isCheckbox" class="form-row">
    <label :class="getWideQuestionLabelClass('form-check-label')" :for="questionId">
      {{ questionLabel }}
    </label>
    <div :class="getWideQuestionWidgetClass('form-check form-check-inline')">
      <div class="question-radio-label form-row">
        <div v-for="(checkBoxOption, idxCheckbox) in questionObject.widgetConfiguration.options" :class="radioClass" v-bind:key="idxCheckbox">
          <label class="clickable">
            <input
              type="checkbox"
              :checked="values.includes(checkBoxOption.value)"
              :disabled="readOnly"
              :class="[questionObject.isRequired == 'Y' ? 'form-check-input required ' : 'form-check-input']"
              :name="questionId"
              :value="checkBoxOption.value"
              @click="saveResponseEvent($event)"
            />
            {{ checkBoxOption.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isDragDrop" class="form-row">
    <input type="hidden" 
      :class="[questionObject.isRequired == 'Y' ? 'drag-drop draggableRequired ' : 'drag-drop']"      
      :id="questionId"
      :name="questionId" 
      :value="dragInitialized"/>
    <label :for="questionId" class="col-form-label col-4">{{ questionLabel }}</label>
    <VueDraggable
      v-model="dragDropChoices"
      :disabled="!enabled"
      class="list-group draggable-container col-8"
      ghost-class="draggable-ghost"
      :move="checkMove"
      @start="dragging = true"
      @end="endOfDragChoices"
      options="people"
    >
      <div v-show="choice.name != ''" class="list-group-item draggable-card" v-for="choice in this.dragDropChoices" 
          :key="choice.questionId" 
          :index="choice.questionId">
          <i class="fa fa-caret-right" aria-hidden="true"></i>
          <span v-show="dragInitialized  && (Number(numberOfChoicesToRank) > Number(choice.sequenceNumber))" class="drag-drop-number">{{ choice.sequenceNumber+1 }}</span> <span class="pl-1">{{ choice.name }}</span>
      </div>
      </VueDraggable>     
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import AdminService from "../../services/admin.service";
import ErrorService from "../../services/error.service";
import DraggableService from "../../services/draggable.service";
import ReportService from "../../services/report.service";
import ModalLink from "../../components/ModalLink.vue";
import { VueDraggable } from 'vue-draggable-plus';

import bFormSlider from "vue-bootstrap-slider/es/form-slider";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import QuestionMixins from "../../mixins/QuestionMixins.vue";

export default {
  name: "SurveyTakerQuestion",
  mixins: [QuestionMixins],
  components: {
    VueDraggable, ModalLink
  },  
  data() {
    return {
      responseValue: "",
      values: [],
      emotionalNeedsOptions: { min: 6, max: 605, step: 1, value: 308 },
      dragDropChoices: [],
      contentList: [],
      numberOfChoicesToRank: 1,
      dragging: false,
      enabled: true,
      dragInitialized: false,
      maximumNumberOfValuesEnabled: "N",
      maximumNumberOfValues: 100,
      originalDisplayQuestionLabel: "",
      displayPlaceholder: "",
      originalDisplayPlaceholder: "",
      originalDragDropChoices: [],
      originalDisplayOptions: [],
      
    };
  },
  props: {
    disableWidgets: {
      type: Boolean,
      default: false
    },
    questionTranslations:{
      type: Array,
      default: function() {
        return [];
      }    
    }
  },
  methods: {
    parseEmotionalNeedsLabel: function(inputValue){
      if (Array.isArray(inputValue) && inputValue.length >= 2){
        return inputValue[0] + "/" + inputValue[1];
      }
      return inputValue;
    },
    checkMove: function(e) {},
    endOfDragChoices: function(event) {
      if (this.dragInitialized == false){
        this.dragInitialized = true;
      }
      this.dragDropChoices = DraggableService.setListOrder(this.dragDropChoices, event, this);
      this.saveResponse(DraggableService.buildResponse(this.dragDropChoices,this.numberOfChoicesToRank));
    },    
    shuffle: function(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    slideStart: function() {},
    slideStop: function() {},
    saveDefaultValue: function() {
      /* eslint-disable */
      if (this.questionObject.widgetConfiguration.hasOwnProperty("defaultValue")) {
        this.saveResponse(this.questionObject.widgetConfiguration.defaultValue);
      } else if (this.questionObject.widgetConfiguration.hasOwnProperty("value")) {
        this.saveResponse(this.questionObject.widgetConfiguration.value);
      }
      /* eslint-enable */
    },
    saveResponseCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let questionId = response.data.data;
        this.$emit("remove", questionId);
      } else {
        let errorMessage = response.data.message;
        errorMessage += this.$parent.surveyTakerMessage;
        ErrorService.processError("SurveyTakerQuestion.saveResponse", errorMessage, "survery-taker", this);
      }
    },
    async saveResponse(value) {
      if (this.disableWidgets) {
        return;
      }
      let result = {
        surveyId: this.$parent.surveyId,
        questionId: this.questionObject.questionId,
        label: this.questionObject.label,
        value: value,
        initialLoad: this.initialLoad
      };
      this.$emit("save", result);
      let response = {
        surveyId: this.$parent.surveyId,
        questionId: this.questionObject.questionId,
        value: value
      };
      if (!this.initialLoad) {
        const params = new URLSearchParams();
        params.append("surveyTakerId", this.$parent.surveyTakerId);
        params.append("response", JSON.stringify(response));
        await axios
          .post(this.baseUrl + "/api/v1/interview/save-response", params)
          .then(response => this.saveResponseCallback(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            error.message += this.$parent.surveyTakerMessage;
            ErrorService.processError("SurveyTakerQuestion.saveResponse", error, "survey-taker", this);
          });
      }
    },
    saveResponseEvent: function(event) {
      this.responseValue = event.target.value;
      if (this.isCheckbox) {
        if (event.target.checked) {
          if (this.maximumNumberOfValuesEnabled == "Y" && this.maximumNumberOfValues <= this.values.length){
            AdminService.displayErrorMessage({ text: this.selectMaximumMessage, duration: -1 });
            event.target.checked = false;
            return;
          }
          if (!this.values.includes(event.target.value)) {
            this.values.push(event.target.value);
          }
        } else {
          this.values.splice($.inArray(this.responseValue, this.values), 1);
        }
        this.responseValue = this.values.toString();
      }
      this.saveResponse(this.responseValue);
    },
    setupConfiguration: function() {
      var configuration = {};

      if (this.questionObject.widgetConfiguration != "") {
        this.questionObject.widgetConfiguration = JSON.parse(this.questionObject.widgetConfiguration);
      } else {
        this.questionObject.widgetConfiguration = {};
      }
      if (this.questionObject.widgetType.defaultConfiguration != "") {
        this.questionObject.widgetType.defaultConfiguration = JSON.parse(this.questionObject.widgetType.defaultConfiguration);
      } else {
        this.questionObject.widgetType.defaultConfiguration = {};
      }

      $.extend(configuration, this.questionObject.widgetType.defaultConfiguration);
      $.extend(configuration, this.questionObject.widgetConfiguration);
      this.questionObject.widgetConfiguration = configuration;
      if (this.questionObject.name.includes("Emotional")) {
        this.questionObject.widgetConfiguration = this.emotionalNeedsOptions;
      }
      if (this.isSlider) {
        this.questionObject.label = this.questionObject.label.split(",");
        this.questionLabel = this.questionObject.label;
      }
      if (this.displayDescriptionPopUp){
        this.contentList.push({title: this.questionObject.label, contentText: this.questionObject.description});
      }      
      this.setOriginalValues();
    },
    displayResponses: function() {
      if (this.$parent.$parent.entryResponses.length > 0) {
        let questionResponse = this.$parent.$parent.entryResponses.find(x => Number(x.questionId) == Number(this.questionObject.questionId));
        if (typeof questionResponse != "undefined") {
          if (this.isSlider && !isNaN(questionResponse.responseText)) {
            this.responseValue = Number(questionResponse.responseText);
            $("#" + this.questionId).bootstrapSlider("setValue", this.responseValue);
            $("#" + this.questionId).bootstrapSlider("disable");
          } else if (this.isRadio && !isNaN(questionResponse.responseText)) {
            for (var t = 0; t < this.questionObject.widgetConfiguration.options.length; t++) {
              if (this.questionObject.widgetConfiguration.options[t].value == questionResponse.responseText) {
                this.questionObject.widgetConfiguration.options[t].checked = true;
                this.responseValue = questionResponse.responseText;
              }
            }
          } else if (this.isCheckbox) {
            this.values = questionResponse.responseText.split(",");
          } else {
            this.responseValue = questionResponse.responseText;
          }
        }
      }
    },
    getCheckboxValue: function(value) {
      /* eslint-disable */
      if (this.questionObject.widgetConfiguration.hasOwnProperty("options")) {
        let checkboxOption = this.questionObject.widgetConfiguration.options.find(x => x.value == value);
        if (typeof checkboxOption != "undefined") {
          return checkboxOption.name;
        }
      }
      /* eslint-enable */
      return "";
    },
    getWideQuestionLabelClass: function(initialClass){
      /* eslint-disable */
      let isWide = this.questionObject.widgetConfiguration.hasOwnProperty("displayWideLabel") ? this.questionObject.widgetConfiguration.displayWideLabel  : false;
      if (isWide == true){
        initialClass += " col-8";
      } else {
        initialClass += " col-4";
      }
      /* eslint-enable */
      return initialClass;
    },
    getWideQuestionWidgetClass: function(initialClass){
      /* eslint-disable */
      let isWide = this.questionObject.widgetConfiguration.hasOwnProperty("displayWideLabel") ? this.questionObject.widgetConfiguration.displayWideLabel  : false;
      if (isWide == true){
        initialClass += " col-4";
      } else {
        initialClass += " col-8";
      }
      /* eslint-enable */
      return initialClass;
    },
    getRandomInt(max) {
       return Math.floor(Math.random() * max);
    },
    redrawQuestion: function(questionTranslations){
      for (let index = 0; index < questionTranslations.length; index++) {
        let currentLocale = questionTranslations[index];
        if (currentLocale.translationKey == "QUESTION_LABEL"){
          if (currentLocale.languageCode == "en"){
            this.questionLabel = this.originalDisplayQuestionLabel;
          } else {
            this.questionLabel = currentLocale.translatedText;
            if (this.isSlider) {
              this.questionLabel = this.questionLabel.split(",");
            }
          }
        } else if (currentLocale.translationKey == "QUESTION_PLACEHOLDER"){
          if (currentLocale.languageCode == "en"){
            this.displayPlaceholder = this.originalDisplayPlaceholder;
          } else {
            this.displayPlaceholder = currentLocale.translatedText;
          }
        } else if (currentLocale.translationKey == "QUESTION_CHOICES"){
          this.setDisplayChoiceOptions();
          if (currentLocale.languageCode != "en"){
            let choices = currentLocale.translatedText.split(",");
            for (let index = 0; index < choices.length; index++) {
              let choice = choices[index];
              let choiceObject = this.dragDropChoices.find(x => x.sequenceNumber == index + 1);
              if (typeof choiceObject != "undefined"){
                choiceObject.name = choice;
              }
            }
          }          
        } else if (currentLocale.translationKey == "QUESTION_OPTIONS"){
          let choices = currentLocale.languageCode != "en" ? this.convertQuestionOptions(currentLocale.translatedText) : this.originalDisplayOptions;
          this.setDisplayOptions(choices);
        }
      }
    },
    convertQuestionOptions(translatedText){
      if (translatedText.includes('\'')){
        let array = translatedText.split('\', \'');
        for (let i = 0; i < array.length; i++) {
            array[i] = array[i].replace("'", "");
        }
        return array;
      }
      return translatedText.split(",");
    },
    setOriginalValues: function() {
      this.originalDisplayQuestionLabel = this.questionLabel;
      if (Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "placeholder")) {    
          this.displayPlaceholder = this.questionObject.widgetConfiguration.placeholder;
          this.originalDisplayPlaceholder = this.displayPlaceholder;
      } else {
        this.displayPlaceholder = "";
      }
      this.setDisplayChoiceOptions();
      this.setOriginalDisplayOptions();
      this.originalDragDropChoices =  JSON.parse(JSON.stringify(this.dragDropChoices));
    },
    setDisplayChoiceOptions: function() {
      let hasChoices = Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "choices");
      if (hasChoices){
		    this.dragDropChoices = this.questionObject.widgetConfiguration.choices;
	    } else {
        this.dragDropChoices = [];
      }   
    },
    setDisplayOptions: function(choices) {
      for (let index = 0; index < choices.length; index++) {
        let choice = choices[index];
        let choiceObject = this.questionObject.widgetConfiguration.options[index];
        if (typeof choiceObject != "undefined"){
          choiceObject.name = choice;
        }
      }
    },
    setOriginalDisplayOptions: function() {
      this.originalDisplayOptions = [];
      if (Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "options")) {    
        for (let index = 0; index < this.questionObject.widgetConfiguration.options.length; index++) {
          let choice = this.questionObject.widgetConfiguration.options[index];
          this.originalDisplayOptions.push(choice.name);
        }
      }			    
    },
    translateNumber(value){
      if (value == 1){
        return this.$t('1');
      } else if (value == 2){
        return this.$t('2');
      } else if (value == 3){
        return this.$t('3');
      } else if (value == 4){
        return this.$t('4');
      } else if (value == 5){
        return this.$t('5');
      } else if (value == 6){
        return this.$t('6');
      } else if (value == 7){
        return this.$t('7');
      } else if (value == 8){
        return this.$t('8');
      } else if (value == 9){
        return this.$t('9');
      } else if (value == 10){
        return this.$t('10');
      }
    }
  },
  watch: {
    questionObject: function() {
      this.setupConfiguration();
    },
    questionTranslations: {
      handler(newVal, oldVal) {
        this.questionLabel = this.originalDisplayQuestionLabel;
        this.displayPlaceholder = this.originalDisplayPlaceholder;
        this.dragDropChoices = JSON.parse(JSON.stringify(this.originalDragDropChoices));
        this.setDisplayOptions(this.originalDisplayOptions);
        if (newVal.length > 0){
          let questionTranslations = newVal.filter(x => x.questionId == this.questionObject.questionId)
          this.redrawQuestion(questionTranslations);
        }
      },
      deep: true
    },     
/*    Remove once everything is working with vue 3 migration
    questionTranslations: function() {
      this.questionLabel = this.originalDisplayQuestionLabel;
      this.displayPlaceholder = this.originalDisplayPlaceholder;
      this.dragDropChoices = JSON.parse(JSON.stringify(this.originalDragDropChoices));
      this.setDisplayOptions(this.originalDisplayOptions);
      if (this.questionTranslations.length > 0){
        let questionTranslations = this.questionTranslations.filter(x => x.questionId == this.questionObject.questionId)
        this.redrawQuestion(questionTranslations);
      }
    }
*/      
  },
  created() {
    this.setupConfiguration();
  },
  mounted() {
    if (this.isSlider) {
      $("#" + this.questionId).bootstrapSlider();

      const vm = this;
      $("#" + this.questionId).on("slideStop", function(value) {
        vm.saveResponse($(this).val());
      });
    } else if (this.isDragDrop){
      let hasChoices = Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "choices");
      if (hasChoices){
        this.setDisplayChoiceOptions();
        let hasNumberOfChoicesToRank  = Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "numberOfChoicesToRank");
        this.numberOfChoicesToRank = hasNumberOfChoicesToRank ? Number(this.questionObject.widgetConfiguration.numberOfChoicesToRank) : this.dragDropChoices.length;
        let hasChoiceOrder = Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "choiceOrder");
        if(hasChoiceOrder) {
          if (this.questionObject.widgetConfiguration.choiceOrder == "random"){ 
            this.shuffle(this.dragDropChoices);
          }
        }
      }
    } else if (this.isCheckbox){
      let hasMaximumNumberOfValuesEnabled= Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "maximumNumberOfValuesEnabled");
      if (hasMaximumNumberOfValuesEnabled){
        this.maximumNumberOfValuesEnabled = this.questionObject.widgetConfiguration.maximumNumberOfValuesEnabled;
      }
      if (this.maximumNumberOfValuesEnabled == "Y"){
        let maximumNumberOfValues = Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "maximumNumberOfValues")
        if (maximumNumberOfValues){
          this.maximumNumberOfValues = this.questionObject.widgetConfiguration.maximumNumberOfValues;
        }
      }
      let hasChoiceOrder = Object.prototype.hasOwnProperty.call(this.questionObject.widgetConfiguration, "choiceOrder");
      if(hasChoiceOrder) {
        if (this.questionObject.widgetConfiguration.choiceOrder == "random"){ 
           for (let i = this.questionObject.widgetConfiguration.options.length - 1; i >= 0; i--) {
              this.questionObject.widgetConfiguration.options[i].sequenceNumber = this.getRandomInt(this.questionObject.widgetConfiguration.options.length - 1);
           }
           this.questionObject.widgetConfiguration.options.sort(ReportService.compareValues("sequenceNumber", "asc"));
        }
      }
    }
    this.displayResponses();
    this.saveDefaultValue();
    this.initialLoad = false;
  },
  computed: {
    displayDescriptionPopUp: function() {
      return (this.$parent.isDescriptionPopupEnabled && this.questionObject.description != "" && this.questionObject.description != null);
    },
    selectMaximumMessage: function(){
      let translatedMaximumNumberOfValues = this.translateNumber(this.maximumNumberOfValues);
      return this.$t('selectMaximum1') + ' ' + translatedMaximumNumberOfValues + ' ' + this.$t('selectMaximum2');
    },
    shareText: function(){
      if (this.$parent.surveyId == 39829 || this.$parent.surveyId == 39835){
        return "Vos données personnelles demeureront confidentielles."
      }
      return this.$t('noSharing');
    },
    readOnly: function() {
      return this.$parent.$parent.readOnly;
    },
    contentDisplay: function() {
      let content = "";
      if (typeof this.questionObject.widgetConfiguration.contentId != "undefined") {
        let contentSection = this.$parent.$parent.$data.pageContent.find(x => x.contentId == this.questionObject.widgetConfiguration.contentId);
        if (typeof contentSection != "undefined") {
          content = contentSection.contentText;
        }
      }
      return content;
    },
    radioClass: function() {
      /* eslint-disable */
      if ((this.isRadio || this.isCheckbox) && this.questionObject.widgetConfiguration.hasOwnProperty("number_of_columns")) {
        if (this.questionObject.widgetConfiguration.number_of_columns == 1) {
          return "col-12 form-check-inline";
        } else if (this.questionObject.widgetConfiguration.number_of_columns == 2) {
          return "col-6 form-check-inline";
        } else if (this.questionObject.widgetConfiguration.number_of_columns == 4) {
          return "col-3 form-check-inline";
        }
      }
      if (this.isRadio && this.questionObject.widgetConfiguration.hasOwnProperty("options")) {
        if (this.questionObject.widgetConfiguration.options.length >= 3) {
          return "col-lg-4 col-6 form-check-inline";
        } else if (this.questionObject.widgetConfiguration.options.length == 2) {
          return "col-6 form-check-inline";
        } else if (this.questionObject.widgetConfiguration.options.length == 1) {
          return "col-12 form-check-inline";
        }
      }
      /* eslint-enable */
      return "col-lg-4 col-6 form-check-inline";
    },
    displayLabels: function() {
      /* eslint-disable */
      return this.isRadio && this.questionObject.widgetConfiguration.hasOwnProperty("includeLabels") && this.questionObject.widgetConfiguration.includeLabels;
      /* eslint-enable */
    },
    leftLabel: function() {
      if (!this.isSlider) {
        return "";
      }
      if (this.questionObject.name == "Emotional 1" || this.questionObject.name == "Emotional 4") {
        return this.questionLabel[1];
      } else {
        return this.questionLabel[0];
      }
    },
    rightLabel: function() {
      if (!this.isSlider) {
        return "";
      }
      if (this.questionObject.name == "Emotional 1" || this.questionObject.name == "Emotional 4") {
        return this.questionLabel[0];
      } else {
        return this.questionLabel[1];
      }
    }
  }
};
</script>
