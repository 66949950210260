<template>
  <vue-modal :name="modalName" :scrollable="true" :width="268" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Color Picker</h5>
        <button type="button" class="close" @click="$modal.hide(modalName)">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <chrome-picker v-model="currentColor" :color="currentColor" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="saveColor">Save</button>
        <button type="button" class="btn btn-primary ml-2" @click="$modal.hide(modalName)">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import { Chrome } from "vue-color";

export default {
  name: "ColorPicker",
  components: {
    "chrome-picker": Chrome
  },
  methods: {
    saveColor: function() {
      this.$emit("save", this.currentColor.hex);
      this.$modal.hide(this.modalName);
    }
  },
  props: {
    modalName: {
      type: String,
      default: "color-picker"
    }
  },
  data() {
    return {
      currentColor: {}
    };
  },
  computed: {},
  mounted() {}
};
</script>
