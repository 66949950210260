<template>
  <div></div>
</template>
<script>
import PrintAndCopyService from "../services/print.and.copy.service";
import EChartsService from "../services/echarts.service";
import ReportService from "../services/report.service";
import AlertService from "../services/alert.service";

import $ from "jquery";
import Vue from "vue"; 
import Echarts from "vue-echarts";
import echarts from "echarts";
Vue.component("chart", Echarts);

export default {
  name: "EChartsMixins",
  mixins: [],
  components: {},
  data() {
    return {
        chart: {
            title: ""
        }, 
        displayType: "",
        imageUrl: "",
        addWrapper: true,
        eChartCriteria:{}
    };
  },
  props: {
    eChartId: {
        type: String,
        default: function() {
            return "response-rate-chart";
        }
    },
    eChartMiniId: {
        type: String,
        default: function() {
            return "response-rate-mini";
        }
    },
    isSavedCard: {
        type: Boolean,
        default: false
    },
    inputClientChart: {
        type: Object,
        default: function() {
            return {};
        }
    },
    rawData: {
        type: Object,
        default: function() {
            return {};
        }
    },
    inputReportTypeName: {
        type: String,
        default: function() {
            return "Chart - Response Rates";
        }
    },
    modalId: {
      type: String,
      default: "response-rates-chart"
    },   
     
  },
  created() {},
  methods: {
    initChart: function() {
        let chartDiv = $("#" + this.eChartId)[0];
        this.chart = echarts.init(chartDiv);
        let chartOptions = this.getChartOptions();
        this.printChartOptions(chartOptions);
        this.chart.setOption(chartOptions, true);
        if (this.addWrapper == true){
          $(".e-chart-container")
                  .parent()
                  .parent()
                  .addClass("chart-wrapper");            

        }
        let vm = this;
        this.chart.on('finished', () => {
            vm.saveImage();
        });  
    },
    clear(){
        this.chart.clear();
    },
    clearChart: function(chart){
        for (let i = 0; i < this.chart.series; i++) {        
            chart.series[i].data = [];
        }
        chart.xAxis.data = [];
        return chart;
    },    
    saveImage(forceSave = false){
      if (forceSave || ReportService.getReportAutoChartGenerateStatus() == "generate"){
        let vm = this;
        setTimeout(function() {
          vm.imageUrl =  vm.chart.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff'
          });      
        }, 500);  

        setTimeout(function() {
            let copyChart = $("#" + vm.eChartMiniId).clone();
            let reportPageChartContent = copyChart
            .wrapAll("<div>")
            .parent()
            .html();
            ReportService.saveReportPage(reportPageChartContent, vm.inputReportTypeName, vm.inputClientChart, vm.saveReportPageCallback, vm);
            ReportService.saveReportPageFile(vm.imageUrl, "png", vm.inputReportTypeName, vm.inputClientChart, vm.saveReportPageCallback, vm);
        }, 1000);  
        this.postProcessAutoGeneration();
      }
    },    
    printChartOptions: function(chartOptions){
        // eslint-disable-next-line no-console
        //console.log("Chart Options: " + JSON.stringify(chartOptions));
    },
    async executeSnap() {
        this.imageUrl =  this.chart.getDataURL({
              pixelRatio: 2,
              backgroundColor: '#fff',
              excludeComponents: ['toolbox']
        });  
        AlertService.showInProgressMessage('Snap in progress....');
        await PrintAndCopyService.copyImage(this.imageUrl);
        let vm = this;
            setTimeout(async function() {
            AlertService.closeAlert();
        }, 1000);              
    },
    postProcessAutoGeneration(){
        let nextClientChartLinkTimeout = Number(process.env.VUE_APP_NEXT_CLIENT_CHART_LINK_TIMEOUT);
        let vm = this;
        setTimeout(() => {
          vm.$parent.generateNextClientChartLink();
        }, nextClientChartLinkTimeout);        
    },
    saveReportPageCallback: function(){},
    setDataForBaseChart: function(needsData,chart1,chart2 = {},diplayType){
        this.charts = [];
        if (typeof needsData != "undefined" && !$.isEmptyObject(needsData)) {
            if (typeof needsData.legend != "undefined"){
                this.legend = JSON.parse(JSON.stringify(needsData.legend));
            }  
            if (typeof needsData.colors != "undefined"){
                this.colors = JSON.parse(JSON.stringify(needsData.colors));
            }         
        }
    },
  },
  watch: {},
  mounted() {},
  computed: {}
};
</script>
