<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/profiles" @click="$parent.logViewDetailsEvent(title)">
            Needs <br /> Profile
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Needs Profile" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('needs-profile-save-chart-confirmation')"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-competitive-rank">
        <dashboard-card-dropdown label="View" :options="view_options" :default-value="view" :read-only="readOnly" @change="saveView">
        </dashboard-card-dropdown>
        <dashboard-card-dropdown v-if="showCompetitorsDropdown" label="Competitors" :options="competitor_options" :read-only="readOnly" :default-value="competitor"
          @change="saveCompetitor"></dashboard-card-dropdown>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div class="dashboard-card-body">
      <div class="needs-profile-dashboard-chart" :style="dashboardChartStyle">
        <chart :options="options"></chart>
      </div>
      <div class="dashboard-x-axis-container" v-show="showSummaryImage">
        <img src="../../assets/img/dashboard-card-components/Card-Needs.png" alt="xAxis"/>
      </div>
      <div id="needs-profile-mini">
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
      </div> 
    </div>
    <!-- :updatedModalChartOptions.sync="options.series[0].data" -->
    <dashboard-modal :modal-id="dashboardId" :chart="this.chart" :client-chart="this.clientChart" :dashboard-card-report-page-type-name="reportTypeName" 
                     :modal-title="title" :modal-chart-options="options" 
                     :show-needs-summary-image="showSummaryImage" :displayModal="displayModal" :key="$displayModal"/>
    <vue-modal :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts"></save-chart>
    </vue-modal>

  </div>
</template>

<script>
import DashboardSummaryMixins from "../../mixins/DashboardSummaryMixins.vue";
import DashboardService from "../../services/dashboard.service";
import ClientCompetitorService from "../../services/client.competitor.service";
import DashboardCardDropdown from "./DashboardCardDropdown.vue";
import SaveChart from "../chart/SaveChart.vue";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";

import Vue from "vue";
import $ from "jquery";

export default {
  name: "DashboardNeedsProfile",
  mixins: [
    DashboardSummaryMixins
  ],
  components: { DashboardCardDropdown, SaveChart,DashboardCardSkeleton },
  data() {
    return {
      title: "Needs Profile",
      modalName: "needs-profile-save-chart-confirmation",
      view_options: [
        { name: "Needs Summary", value: "needs-summary" },
        { name: "Top Quartile", value: "top-quartile" },
        { name: "Second Quartile", value: "second-quartile" },
        { name: "Third Quartile", value: "third-quartile" },
        { name: "Bottom Quartile", value: "bottom-quartile" },
        { name: "Emotions", value: "emotions" }
      ],
    };
  },
  props: {},
  computed: {
    imgPath: function(){
      return require(`../../assets/img/dashboard-card-components/Card-Needs.png`);
    },    
    imgPathHiRes: function(){
      return require(`../../assets/img/dashboard-card-components/Card-Needs-HiRes.png`);
    }    
  },
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardNeedsProfile::mounted");
    this.view = "needs-summary";
    this.dashboardViewSettingType = DashboardService.DASHBOARD_NEEDS_PROFILE_VIEW_SETTING_TYPE;
    this.dashboardCompetitorSettingType = DashboardService.DASHBOARD_NEEDS_PROFILE_COMPETITOR_SETTING_TYPE;

    this.chartTitle = "Needs Profile";
    this.chartTypeName = "needs-profile";
    this.containerId = "needs-profile-mini"
	  this.reportTypeName = "Dashboard - Needs Profile"

  },
  created() { },
  methods: {
    initChart: function (dynamicOptions) {
      // https://echarts.apache.org/examples/en/editor.html?c=scatter-punchCard
      let chartDiv = $(".needs-profile-dashboard-chart")[0];
      this.initBaseChart(dynamicOptions, chartDiv);
    },
    loadData: async function () {
      // eslint-disable-next-line no-console
      //console.log("DashboardNeedsProfile::loadData");
      if (this.loadCompetitorData == true){
        await ClientCompetitorService.getCompetitors(this.$parent.$parent.clientId, this.timePeriodId, this.loadCompetitors);
      } 
      this.setUpCard();
      DashboardService.getNeedsProfile(this.timePeriodId, this.competitor, this.view, this.getSummaryCallback, this);
    },
    updateCard: function () {
      // eslint-disable-next-line no-console
      //console.log("DashboardNeedsProfile::updateCard");
      this.setUpCard();
      DashboardService.getNeedsProfile(this.timePeriodId, this.competitor, this.view, this.getSummaryCallback, this);
    },
  },
};
</script>
