<template>
  <vue-modal :name="modalName" :scrollable="true" :clickToClose="false" :draggable="true" :width="468" :maxHeight="600" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Counts By Location</h5>
        <button type="button" class="close" @click="$modal.hide(modalName)">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <table :id="table.id" :class="table.class" style="display: none" cellspacing="0" width="100%">            
            <thead>
              <tr>
                <th style="text-align: center; width: 1rem">Counts</th>
                <th style="text-align: center; width: 1rem">City</th>
                <th style="text-align: center; width: 1rem">Region</th>
                <th style="text-align: center; width: 1rem">Country</th>
              </tr>
            </thead>
            <tbody></tbody>   
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-2" @click="$modal.hide(modalName)">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import $ from "jquery";

import ChartService from "../services/chart.service";
import SurveyService from "../services/survey.service";
import TableService from "../services/table.service";

export default {
  name: "CountsByLocation",
  components: {
  },
  methods: {
      loadData() {
        $("#" + this.table.id).hide();
        if (typeof this.table.dataTable != "undefined") {
          this.table.dataTable.destroy();
        }
        
        ChartService.getCountsBySurveyLocations(this.$parent.baseUrl, SurveyService.getSurveyId(), this.period, this.getCountsBySurveyLocationsCallback);     
      },
      getCountsBySurveyLocationsCallback(response){
        this.table.data = response;
        $.extend(this.table.configuration, { columns: this.table.columns });
        $.extend(this.table.configuration, { data: this.table.data });
        this.table.configuration.order = [[3, "desc"]];
        setTimeout(() => {
            this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
          $("#" + this.table.id).show();
        }, 0);
      }
  },
  props: {
    modalName: {
      type: String,
      default: "counts-by-location"
    },
    period: {
      type: String,
      default: "0"
    },
  },
  data() {
    return {
      showModal: false,
      table: {
        id: "counts-by-location-table",
        class: "table display wrap row-border table-hover",
        configuration: {
          dom: '<"top"if>rtp<"bottom"><"clear">',
          searching: false,
          paging: true,
          pageLength: 25,
          select: true,
          processing: false,
          responsive: true,
          destroy: true,
          info: false,
          ordering: true,
          keys: true,
          sort: false,
          bSortable: false,
          bSort: false,
          buttons: [],
        },
        columns: TableService.getCountsByLocationTableAttributes()
      },      
    };
  },
  computed: {},
  watch:{},
  mounted() {}
};
</script>
