import axios from "axios";
import $ from "jquery";

import authHeader from "./auth-header";
import ErrorService from "./error.service";
import UserService from "./user.service";
import AdminService from "./admin.service";
import ClientService from "./client.service";
import GoogleService from "./google.service";

const BASE_URL = AdminService.getBaseUrl();
const API_WORD_COUNT_URL = BASE_URL + "/api/v1/word-cloud";

class WordCloudService {
  async saveExclusionList(list, type, callback, chartApp){
    GoogleService.logClickEvent("Word Cloud", "Save List", 0, chartApp);
    const params = new URLSearchParams();
    params.append("list", JSON.stringify(list));
    params.append("type", type);
    params.append("userId", UserService.getUserId());
    params.append("clientCode", ClientService.getClientCode());
    await axios
      .post(API_WORD_COUNT_URL + "/save-exclusion-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("WordCloudService.saveExclusionList", error, "admin", chartApp);
      });
  }
  async getExclusionList(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("clientCode", ClientService.getClientCode());
    await axios
      .post(API_WORD_COUNT_URL + "/get-exclusion-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("WordCloudService.getExclusionList", error, "admin", chartApp);
      });
  }
	async importFile(file, type, callback, chartApp) {
    GoogleService.logClickEvent("Word Cloud", "Import File", 0, chartApp);

    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    formData.append("clientCode", ClientService.getClientCode());
    formData.append("userId", UserService.getUserId());

    let headers = { "Content-Type": "multipart/form-data" };
    $.extend(headers, await authHeader());

    await axios
      .post(API_WORD_COUNT_URL + "/import-exclusive-list", formData, { headers: headers })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("WordCloudService.importFile", error, "survey-designer", chartApp);
      });
  }
  async getDownloadMasterExclusiveList(callback, chartApp) {
    GoogleService.logClickEvent("Word Cloud", "Download System Exclusive List", 0, chartApp);
    const params = new URLSearchParams();
    let url = API_WORD_COUNT_URL + "/download-master-exclusive-list";
    await axios({
      url: url,
      params: params,
      method: "POST",
      responseType: "blob"
    })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      chartApp.downloadItemError(error);
    });
  }  

  async getDownloadExclusiveListTemplate(callback, chartApp) {
    GoogleService.logClickEvent("Word Cloud", "Download Exclusive List Template", 0, chartApp);
      
    const params = new URLSearchParams();
    let url = API_WORD_COUNT_URL + "/download-exclusive-list-import-template";
    await axios({
      url: url,
      params: params,
      method: "POST",
      responseType: "blob"
    })
    .then(response => callback(response))
    .catch(error => {
      error.message += ErrorService.mergeError(error.response);
      chartApp.downloadItemError(error);
    });
  }  
  async getWordCounts(surveyId, period, includeCounts, callback) {
    const params = new URLSearchParams();
    params.append("surveyId", surveyId);
    params.append("userId", UserService.getUserId());
    params.append("period", period);
    params.append("includeCounts", includeCounts);
    params.append("clientCode", ClientService.getClientCode());

    await axios
      .post(API_WORD_COUNT_URL + "/get-word-counts", params, { headers: await authHeader() })
      .then(response => {
        callback(response.data);
      })
      .catch(error => {
        throw error;
      });
  }
  getManageExclusionTableAttributes() {
    return [
      {
        name: "word",
        width: "15rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        render: function(data, type, row) {
          return row;
        }        
      },
      {
        name: "word",
        width: "15rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        render: function(data, type, row) {
          return "<a class='exclusion-list-delete'>Delete</button>";
        }        
      }      
    ];
  }
  getWordListTableAttributes() {
    return [
      {
        name: "word",
        width: "15rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        render: function(data, type, row) {
          if (typeof row[0] != "undefined"){
            return row[0];
          } 
          return "";
        }        
      },
      {
        name: "count",
        width: "15rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        render: function(data, type, row) {
          if (typeof row[1] != "undefined"){
            return row[1];
          } 
          return "";
        }        
      }
    ];
  }
}
export default new WordCloudService();
