<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container sector-list">
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment-timezone";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";

export default {
  name: "SectorList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      importCompanyImportFile: "",
      returnMessage: "",
      title: "Sector List",
      table: {
        id: "sector-list-table",
        columns: [
          {
            title: "Sector Id",
            data: "sectorId",
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "id",
            width: "0rem",
            bVisible: false
          },
          { title: "Sector Code", data: "sectorCode", name: "sectorCode", width: "5rem" },
          { title: "Name", data: "name", name: "name", width: "25rem" },
          { title: "Active", data: "active", name: "active", width: "5rem" },
          {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            width: "5rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          },
          {
            title: "Date Updated",
            data: "dateModified",
            type: "date",
            name: "dateModified",
            width: "5rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          }
        ]
      }
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest("Loading table data");            
      await axios
        .get(this.baseUrl + "/api/v1/admin/get-sector-list", { headers: await authHeader() })
        .then(response => this.getTableDataCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SectorList.getTableData", error, "admin", this);
        });
    }
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [[2, "asc"]];
    }
  },
  props: {},
  mounted() {}
};
</script>
