<template>
    <vue-modal name="send-test-email" :width="368" :height="250" @opened="beforeOpen" > 
      <div class="advisor-email-test container">
        <form accept-charset="UTF-8" enctype="multipart/form-data" autocomplete="off" class="form" action="#" method="post" novalidate="novalidate" id="AdvisorEmailTestForm">
          <div class="form-group form-row p-2">
            <h5 class="font-weight-bold">Send Test Email</h5>
          </div>
          <div class="form-group form-row p-2 text-success">
            <span> {{ modalReturnMessage }}</span>
          </div>
          <div class="form-group form-row p-2">
            Please enter the email address to send the email to
              <input
                v-model="emailAddress"
                id="availableClientList"
                placeholder="Enter Email"
                name="emailAddress"
                type="text"
                class="form-control w-100 required"
                emailCheck="emailCheck"
              />
          </div>
          <div class="form-group form-row d-flex justify-content-end">
            <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.sendTestAdvisorEmail">
              <span>Send Test Email</span>
              <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
            <button
              type="button"
              class="btn btn-primary ml-4"
              @click="
                $modal.hide('send-test-email');
                $router.go(0);
              "
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </vue-modal>
</template>

<script>
import $ from "jquery";

import ContentMixins from "../mixins/ContentMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";

import AdminService from "../services/admin.service";
import AdvisorService from "../services/advisor.service";
import FormService from "../services/form.service";

export default {
  name: "AdvisorEmailTest",
  components: {},
  mixins: [ContentMixins, ViewMixins],

  methods: {
    sendTestAdvisorEmail(){
      if (!this.advisorEmailTestForm.valid()) {
        return false;
      } else {       
        this.$root.processServerRequest("Sending email to " + this.emailAddress);
        AdvisorService.sendTestAdvisorEmail(this.advisorId, this.emailAddress, this.sendTestAdvisorEmailCallback, this);
      }
    },
    sendTestAdvisorEmailCallback(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        AdminService.displaySuccessMessage({ text: response.data.message, duration: 1000 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }      
    },
    setupAdvisorEmailForm() {
      this.advisorEmailTestForm = $("#AdvisorEmailTestForm");
      FormService.setupForm(this.advisorEmailTestForm);
      this.validator = this.advisorEmailTestForm.validate(FormService.getBaseValidator());
    },
    beforeOpen(){
      this.emailAddress = this.initialEmailAddress;
      this.setupAdvisorEmailForm();
    }
  },
  props: {
    advisorId: {
      type: Number,
      default: -1,
    },
    initialEmailAddress: {
      type: String,
      default: ""
    }    
  },
  data() {
    return {
      advisorEmailTestForm: null,
      validator: null,
      emailAddress: "",
      modalReturnMessage: ""
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
};
</script>
