<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber) + ' dashboard-welcome'">
    <div class="dashboard-card-body">
      <dashboard-welcome-slider/>
    </div>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import SmartCenterService from "../../services/smart.center.service";
import GoogleService from "../../services/google.service";
import DashboardWelcomeSlider from "./DashboardWelcomeSlider.vue";

export default {
  name: "DashboardWelcome",
  mixins: [DashboardMixins],
  components: {DashboardWelcomeSlider},
  data() {
    return {
      title: "Welcome",
      fastStartLink: "",
      keyConceptsLink: "",
    };
  },
  props: {},
  computed: {},
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardWelcome::mounted");
  },
  created() {},
  methods: {
    goToSmartCenter: function (location) {
      let label = "Dashboard - " + location;
      GoogleService.logClickEvent("Smart Center Link", label, 0, this);
      let link = SmartCenterService.buildSmartCenterLink(location, "Dashboard");
      if (location == "Fast Start") {
        this.fastStartLink = link;
      } else if (location == "Key Concept Training") {
        this.keyConceptsLink = link;
      }
    },
  },
  watch: {},
};
</script>
