<template>
  <div></div>
</template>
<script>
import DraggableService from "../services/draggable.service";

export default {
  name: "DragAndDropMixins",
  mixins: [],
  components: {
    
  },
  data() {
    return {
        choiceOrder: "",
        choices: []
    };
  },
  props: {
    initialChoiceOrder: {
        type: String,
        value: "random"
    },
    initialChoices: {
      type: Array,
      default: function() {
        return [{name: "",sequenceNumber: 1},{name: "",sequenceNumber: 2}];
      }
    }    
  },
  created() {},
  methods: {
    checkMove: function(e) {},
    endOfDragChoices: function(event) {
        this.choices = DraggableService.setListOrder(this.choices, event, this);
        this.saveDragDrop();
    },			    
    saveOrderEvent: function(event){
        let value = event.target.value;
        this.choiceOrder = value;
    },    
  },
  watch: {
    initialChoices: function(){
      this.choices = JSON.parse(JSON.stringify(this.initialChoices));   
    },
  },
  mounted() {
    this.choices = JSON.parse(JSON.stringify(this.initialChoices)); 
    this.choiceOrder = this.initialChoiceOrder
  },
  computed: {
    draggableEnabled: function(){
        return this.choiceOrder == "set";
    }
  }
};
</script>
