<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>
      Ranks
      <smart-center-link page="Moats" location="Header" />
      <div class="ml-auto">
        <span class="wizard-icon">
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()"
          class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()"
          class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <chart-menu></chart-menu>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">1</div>
          <div class="wizard-text">
            Click Moat Ranks or Trends to view how
            your organization measures up against
            the benchmark companies.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">2</div>
          <div class="wizard-text">
            Click the Time Period and Group for the
            analytics you’d like to generate. Click
            the box to include partial responses,
            which are respondents that did not
            complete all the survey fields.
          </div>
        </div>
      </div>
      <div class="wizard-content-column col-4 wizard-border">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            Click up to 5 competitors to include in your Profiles. Click
            the Benchmark Companies to compare your data to any of our
            well-recognized companies. Moat rankings use forced
            percentile ranks of how well an organization meets their
            customer expectations. Organizations with smaller gaps
            between performance and expectations rank higher since
            they are delivering better on what customers need.

          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">4</div>
          <div class="wizard-text">
            Segments are created through your survey
            questions. Click any segment criteria to select
            which respondents to include in your Moat Chart.
            Selecting no criteria includes all respondents for
            that question. Segments for NPS, Location and
            Word are included as defaults.

          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">5</div>
          <div class="wizard-text">
            Click Generate Chart to build your Chart. Click
            Reset to start the build process from scratch.

          </div>
        </div>
      </div>
    </div>

    <div class="chart-app">
      <chart-criteria ref="chartCriteria" display-type-modal-name="Ranks" display-type-label="Rank Type" :show-equal-weight="false" :use-chart-type-card="true" :show-display-type="true" :content-list="pageContent" :is-comparative="false">
      </chart-criteria>
      <div class="row no-gutters">
        <div class="col">
          <div id="chartDislayCard">
            <div id="performance_dashboard">
              <div id="chart-filters" class="row">
                <chart-criteria-results ref="chartCriteriaResults" v-bind:criteria-results-list="criteriaResultsList"
                  v-show="!generatingChart"
                  v-bind:criteria-results-company-list="criteriaResultsCompanyList"></chart-criteria-results>
              </div>
              <div v-show="getDisplayView() && !generatingChart" class="row mt-3 mb-3">
                <div class="col-lg-6">
                  <button id="save_chart" class="btn btn-primary ml-2" type="button"
                    @click="$modal.show('save-chart-confirmation')" :disabled="disableSaveAsCardButton">Save as
                    Card</button>
                </div>
                <div v-show="!isMoatRank" class="col-lg-6">
                  <button id="auto-create-groups-of-tiers" class="btn btn-primary ml-2" type="button"
                    @click="$modal.show('auto-create-groups-of-tiers')"
                    :disabled="disableAutoCreateGroupsOfTiersButton">Auto-Create Groups of Tiers</button>
                </div>
              </div>
              <chart-display-skeleton v-show="generatingChart"/>
              <MoatsRank v-show="isMoatRank && !generatingChart" :input-data="moatsRankData" :content-list="pageContent"></MoatsRank>
              <BaseRank v-show="!generatingChart" :input-data="baseRankData" :content-list="pageContent" ></BaseRank>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay d-none">
      <img class="overlay-inner d-none" src="../../assets/img/overlap.png"  style="width: 50%"/>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
    <vue-modal name="save-chart-confirmation" :width="350" :height="200">
      <save-chart :chart-title="this.chart.title" :initial-client-chart="this.clientChart" @save="updateClientCharts">
      </save-chart>
    </vue-modal>
    <vue-modal name="auto-create-groups-of-tiers" :width="350" :height="250">
      <auto-create-groups :base-group-name="baseGroupName" :survey-group-by-tier-list="surveyGroupByTierList"
        @save="closeAutoGenerate"></auto-create-groups>
    </vue-modal>
  </div>


</template>
<script>

import ChartCompanyMixins from "../../mixins/ChartCompanyMixins.vue";
import ChartDisplaySkeleton from "../../components/skeleton/ChartDisplaySkeleton.vue";
import SaveChart from "../../components/chart/SaveChart.vue";
import AutoCreateGroups from "../../components/chart/AutoCreateGroups.vue";
import MoatsRank from "./type/MoatsRank.vue";
import BaseRank from "./type/BaseRank.vue";
import ChartService from "../../services/chart.service";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";

export default {
  name: "Ranks",
  mixins: [ChartCompanyMixins],
  components: {
    SaveChart,
    AutoCreateGroups,
    MoatsRank,
    BaseRank,
    ChartDisplaySkeleton
  },
  data() {
    return {
      title: "Ranks",
      pageNumber: 4,
      
      chartData: [],
      baseRankData: {},
      moatsRankData: {},
      surveyGroupByTierList: [],
      baseGroupName: "",
      isMoatRank: true,
      chartWidth: "",
      
    };
  },
  methods: {
    closeAutoGenerate: function (value, message) {
      AdminService.displaySuccessMessage({ text: message });
      this.$modal.hide("auto-create-groups-of-tiers");
    },
    async displayChartCallback(response) {
      try {
        this.$root.processServerResponse();
        this.generatingChart = false;

        this.chartData = response.data;
        this.baseRankData = JSON.parse(JSON.stringify(this.chartData[0])); 
        if (this.chartData.length > 1){
          this.moatsRankData = JSON.parse(JSON.stringify(this.chartData[1])); 
        }
        this.isMoatRank = this.$refs.chartCriteria.$data.displayType == "moat-rank";
      } catch (err) {
        ErrorService.processException("Ranks.displayChartCallback", err, "chart", this);
      }
    }
  },
  umnounted() { },
  computed: {},
  mounted() { },
  created() {
    this.chart = ChartService.getChartAttributes("Ranks");
  },
  props: {},
  watch: {}
};
</script>
