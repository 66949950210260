<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import AdminService from "../services/admin.service";

export default {
  name: "QuestionMixins",
  data() {
    return {
      questionLabel: "",
      questionRequired: "N",
      displayQuestion: "Y",
      includeAsSegment: "N",
      segmentLabel: "",
      initialLoad: true
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    },
    disableEditMode: {
      type: Boolean,
      default: false
    },
    displayDetails: {
      type: Boolean,
      default: false
    },
    displayRequired: {
      type: Boolean,
      default: false
    },
    showDisplayField: {
      type: Boolean,
      default: false
    },
    displayIncludeAsSegment: {
      type: Boolean,
      default: false
    },
    isQuestionInEmotionalSection: {
      type: Boolean,
      default: false
    },
    nextSequenceNumber: {
      type: Number,
      default: 0
    },
    questionIndex: {
      type: Number,
      default: 0
    },
    questionObject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    widgetTypeOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },
    sectionObject: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  created() {
    this.initializeQuestion();
  },
  methods: {
    initializeQuestion: function() {
      this.questionLabel = this.questionObject.label;
      this.questionRequired = this.questionObject.isRequired;
      this.displayQuestion = this.questionObject.display;
      this.includeAsSegment = this.questionObject.includeAsSegment;
      this.segmentLabel = this.questionObject.segmentLabel;
      this.question = this.questionObject;
    }
  },
  watch: {
    questionObject: function() {
      this.initializeQuestion();
    }
  },
  mounted() {},
  computed: {
    sectionId: function() {
      return this.sectionObject.sectionId;
    },
    sectionTypeName: function() {
      return this.sectionObject.sectionType.name;
    },
    questionId: function() {
      return "question_" + this.questionObject.questionId;
    },
    questionName: function() {
      if (this.questionObject.name) {
        return this.questionObject.name;
      } else {
        return "";
      }
    },
    isTextBox: function() {
      return this.questionObject.widgetType.name === "text-box";
    }, // text-box
    isPhoneNumber: function() {
      return this.questionObject.widgetType.name === "phone-number";
    }, // text-box
    isContent: function() {
      return this.questionObject.widgetType.name === "content";
    }, // text-box
    isDropdown: function() {
      return this.questionObject.widgetType.name === "dropdown";
    }, // dropdown
    isSlider: function() {
      return this.questionObject.widgetType.name === "slider";
    }, // slider
    isHidden: function() {
      return this.questionObject.widgetType.name === "hidden";
    }, // slider
    isRadio: function() {
      return this.questionObject.widgetType.name === "radio" || this.questionObject.widgetType.name === "functional";
    }, // radio
    isTextArea: function() {
      return this.questionObject.widgetType.name === "text-area";
    }, //
    isEmail: function() {
      return this.questionObject.widgetType.name === "email";
    }, // email
    isCheckbox: function() {
      return this.questionObject.widgetType.name === "checkbox";
    },
    isDragDrop: function() {
      return this.questionObject.widgetType.name === "drag-and-drop";
    }
  }
};
</script>
