<template>
  <div class="question-order">
    <div class="message pl-2">Drag and Drop a question's name to set the order of how they will always appear in the survey.</div>
        <VueDraggable
            v-model="questions"
            :disabled="!enabled"
            class="list-group draggable-container"
            ghost-class="draggable-ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="endOfDragQuestions"
            options="people"
        >
            <div v-show="question.name != ''" class="list-group-item draggable-card" v-for="question in this.questions" 
                :key="question.questionId" 
                :index="question.questionId">
                <i class="fa fa-caret-right" aria-hidden="true"></i>
                <span class="pl-1">{{ question.label }}</span>
            </div>
        </VueDraggable>      
  </div>
</template>

<script>
import { VueDraggable } from 'vue-draggable-plus';

export default {
  name: "QuestionOrder",
  components: {
    VueDraggable
  },
  data() {
    return {
      enabled: true,
      questions: []
    };
  },
  methods: {
    checkMove: function(e) {},
    endOfDragQuestions: function(event) {
      let oldIndex = event.oldIndex;
      let newIndex = event.newIndex;
      this.dragging = false;
      if (typeof this.questions[oldIndex] != "undefined" &&
          typeof this.questions[newIndex] != "undefined" ){
          this.questions[newIndex].sequenceNumber = newIndex;
          this.questions[oldIndex].sequenceNumber = oldIndex;
          for (let index = 0; index < this.questions.length; index++) {
            this.questions[index].sequenceNumber = index;
          }
          this.$emit("save",this.questions);
          this.$emit("enable-save-order-button");
      }
    },			    
  },
  computed: {},
  mounted(){
    this.questions = JSON.parse(JSON.stringify(this.inputQuestions));
  },
  props: {
    inputQuestions: {
      type: Array,
      default: () => [
        { name: "", questionId: 0},
        { name: "", questionId: 0},
        { name: "", questionId: 0},
        { name: "", questionId: 0},
        { name: "", questionId: 0}
      ]
    },
  }
};
</script>
