<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container nps-email-list">
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import Vue from "vue";

import moment from "moment-timezone";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";

export default {
  name: "NPAEmailList",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      title: "Survey NPS Email",
      table: {
        id: "nps-email-list-table",
        columns: [
          {
            title: "NPS Email Id",
            data: "surveyNpaEmailId",
            createdCell: function(td, cellData, rowData, row, col) {
              $(td).addClass("text-center");
            },
            className: "",
            name: "surveyNpaEmailId",
            width: "5rem"
          },
          { title: "Survey Name", data: "surveyName", name: "surveyName", width: "5rem" },
          { title: "Sent to Email", data: "sentToEmailAddress", name: "sentToEmailAddress", width: "5rem" },
          { title: "NPS Score", data: "npaScoreNotifier", name: "npaScoreNotifier", class: "text-left", width: "5rem" },
          { title: "Include Personal Information", data: "includePersonalInformation", name: "includePersonalInformation", width: "5rem" },
          { title: "Message", data: "message", name: "shortUrl", width: "5rem" },
          {
            title: "Status",
            data: "status",
            className: "text-left",
            name: "status",
            width: "5rem",
            render: function(data, type, row) {
              var statusMap = {
                C: { label: "Complete", style: "success" },
                R: { label: "Ready", style: "warning" },
                E: { label: "Error", style: "danger" }
              };
              if (type === "display") {
                return "<p class='m-0 p-0' style='width: 8rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
              } else {
                return data;
              }
            }
          },
          {
            title: "Last Email Send Date",
            data: "lastEmailSendDate",
            type: "date",
            name: "lastEmailSendDate",
            class: "text-left",
            width: "5rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          },
          {
            title: "Date Created",
            data: "dateCreated",
            type: "date",
            name: "dateCreated",
            class: "text-left",
            width: "5rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          },
          {
            title: "Completed Date",
            data: "dateCompleted",
            type: "date",
            name: "dateCompleted",
            class: "text-left",
            width: "5rem",
            render: function(data, type, row) {
              return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
            }
          }
        ]
      }
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest("Loading table data");            
      await axios
        .get(this.baseUrl + "/api/v1/survey-admin/get-nps-email-list", { headers: await authHeader() })
        .then(response => this.getTableDataCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("NPAEmailList.getTableData", error, "survery-designer", this);
        });
    }
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.getTableData();
    }
  },
  props: {},
  mounted() {}
};
</script>
