<template>
  <div></div>
</template>
<script>
import UserSettingsService from "../services/user.settings.service"
import UserService from "../services/user.service";
import SurveyService from "../services/survey.service";
import GoogleService from "../services/google.service";
import ErrorService from "../services/error.service";
import { oktaAuth, onAuthRequired } from "../okta";

export default {
  name: "AuthRedirect",
  async beforeMount() {
    try {
      if (oktaAuth.isLoginRedirect()){
        await oktaAuth.token.parseFromUrl().then(async function(res) {
            var state = res.state; // passed to getWithRedirect(), can be any string
            var tokens = res.tokens;
            oktaAuth.tokenManager.setTokens(tokens);
        });
        let user = await oktaAuth.token.getUserInfo();
        this.processLogin(user);
      }
    } catch (e) {
      e.message += ErrorService.mergeError(e.response);
      ErrorService.processError("LoginOkta.mounted", e, "admin", this);
      // eslint-disable-next-line no-console
      console.log("Error: " + e.toString());
      this.error = e.toString();
    }
  },
  methods: {
    processLogin: function(user) {
      this.$store.dispatch("auth/loadUser", user).then(
        () => {
//            this.$gtag.set("userId", UserService.getUserId());
            GoogleService.logBaseEvent("login", "Login Successful", user.email, 0, this);
            if (UserSettingsService.getShowMainMenu() == "N"){
              this.$root.setShowMainMenu(false);
              this.$parent.$refs.mainMenu.collapsed = true;
            }
            if (UserSettingsService.getShowHeaderMenu() == "Y"){
              this.$root.setShowHeaderMenu(true);
            }
            if (UserService.getUserTypeName() == "Insider User" && UserService.getNumberOfLogins() <= 1){
              this.$root.setShowWizardContent(true);
            }
            let savedSurveyCode = UserSettingsService.getSavedSurveyCode();
            if (savedSurveyCode != "" && SurveyService.contains(savedSurveyCode)){
                this.$parent.surveyCode = savedSurveyCode;
            }
            if (this.$route.name != "competitive-dashboard") {
              this.$router.push("/competitive-dashboard");
            }
        },
        error => {
          this.$root.setLoading(false);
          let returnMessage = (error.response && error.response.data) || error.message || error.toString();
          //this.$log.error(returnMessage.message);
          // eslint-disable-next-line no-console
          console.log(returnMessage.message);
          this.$router.push({name: "login", params: {displayErrorMessage: true, errorMessage : returnMessage } });
        }
      );
    }
  },
};
</script>
