<template>
  <div>
    <div id="chart-container" class="by-need">
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="cp-by-functional-need-e-chart" class="chart-row" >
        <profile-chart-header
          :title="'Match Profile'"
          :display="this.$parent.getDisplayView() && getDisplayView()"
          :input-display-number-of-responses="eChartCriteria.displayNumberOfResponses"
          @set-display-number-of-responses="setDisplayNumberOfResponses"
        />                 
        <profile-chart ref="eChart"
            :e-chart-mini-id="'profile-match-chart-mini'"
            :e-chart-id="'profile-match-chart'"
            :input-report-type-name="'Chart - Match Needs Profile'"
            :modal-id="'functional-needs-chart'"
            :is-saved-card="$parent.clientChart.clientChartId != 0" 
            :input-client-chart="this.$parent.clientChart"
            @save-table="saveProfileDataTable"
            @save-criteria="buildClientChart"/>        
      </div>    
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" :id="functionalTable.tableContainerId" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
        <div v-show="isInsiderUser" class="overlay">
          <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png" style="width: 50%"/>
        </div>
        <chart-data-table
          ref="functionalDataTable"
          v-show="this.$parent.getDisplayView() && getDisplayView()"
          :chart-data="this.functionalTable.data"
          :display-direction="'vertical'"
          :data-container-id="functionalTable.tableContainerId"
          :container-id="'profile_match_by_need_functional_chart'"
          :column-defs="this.chart.columnDefsFunctional"
          :table-class="'table display row-border table-hover'"
          :is-saved-card="$parent.clientChart.clientChartId != 0"           
          :table-width="'auto'"
          :table-title="this.functionalTable.title"
          :report-page-type-name="'Table - Match Functional Needs'"
          :insight-modal-id="'functional-needs-table'"
          :content-list="this.contentList"
        ></chart-data-table>
      </div>
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" :id="emotionalTable.tableContainerId" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
        <div v-show="isInsiderUser" class="overlay">
          <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png" style="width: 50%"/>
        </div>
        <chart-data-table
          ref="emotionalDataTable"
          :chart-data="this.emotionalTable.data"
          :data-container-id="emotionalTable.tableContainerId"
          :container-id="'profile_match_by_need_emotional_chart'"
          :column-defs="this.chart.columnDefsEmotional"
          :table-title="this.emotionalTable.title"
          :table-class="'table display row-border table-hover'"
          :table-width="'auto'"
          :report-page-type-name="'Table - Match Emotional Needs'"
          :insight-modal-id="'emotional-needs-table'"
          :content-list="this.contentList"
        ></chart-data-table>
      </div>
    </div>
    
    <insights
      ref="insightsFunctionalChart"
      :modal-name="'functional-needs-chart'"
      :report-page-type-name="'Chart - Match Needs Profile'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsFunctionalTable"
      :modal-name="'functional-needs-table'"
      :report-page-type-name="'Table - Match Functional Needs'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsEmotionalTable"
      :modal-name="'emotional-needs-table'"
      :report-page-type-name="'Table - Match Emotional Needs'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div>
</template>
<script>
import $ from "jquery";
import ProfileChart from "../../../views/echarts/ProfileChart.vue";
import ProfileChartHeader from "../../../components/echarts/ProfileChartHeader.vue";
import ProfileService from "../../../services/echarts/profile.service";

import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";
import ChartService from "../../../services/chart.service";
import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import NeedsService from "../../../services/needs.service";
import TableService from "../../../services/table.service";
import ReportService from "../../../services/report.service";


export default {
  name: "MatchByNeed",
  mixins: [ChartCompanyMixins],
  components: {  ProfileChart, ProfileChartHeader},
  data() {
    return {
      displayType: "match-need",
      functionalTable: {
        id: "",
        data: {},
        tableContainerId: "functional_needs_data_container",
        title: "<div class='title'><h4 class='text-primary mb-0'>Functional Needs</h4><div class='chart-sub-title'><span>Importance/Satisfaction Gap</span> vs. <span>Pricing Power</span></div></div>"
      },
      emotionalTable: {
        id: "",
        data: {},
        tableContainerId: "emotional_needs_data_container",
        title: "<div class='title'><h4 class='text-primary mb-0'>Emotional Needs</h4><div class='chart-sub-title'><span>Ideal/Company Gap</span> vs. <span>Pricing Power</span></div></div>"
      },
      isMobile: false,
      functionalDrivers: ChartService.getDefaultFunctionalDrivers(),
      emotionalDrivers: ChartService.getDefaultEmotionalDrivers(),
    };
  },
  props: {
    chartData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    surveyCode: function() {
      return this.$parent.$data.survey.surveyCode;
    },    

  },
  mounted() {
    $.extend(this.$refs.functionalDataTable.configuration, { orderFixed: [[0, "asc"]] });
    $.extend(this.$refs.emotionalDataTable.configuration, { orderFixed: [[0, "asc"]] });
  },
  created() {},
  methods: {
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Match Needs Profile") {
        this.$refs.insightsFunctionalChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsFunctionalChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Match Functional Needs") {
        this.$refs.insightsFunctionalTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsFunctionalTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Match Emotional Needs") {
        this.$refs.insightsEmotionalTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsEmotionalTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    async processChartData() {
      try {
        
        this.chart = ChartService.getChartAttributes("Match");
        for (let i = 0; i < this.chartData.length; i++) {
          let needsData = JSON.parse(JSON.stringify(this.chartData[i]));
          let otherData = needsData["otherData"];
          let needsList = otherData["needsList"];
          let companyColorList = otherData["companyColorList"];
          TableService.setCompanyColorList(companyColorList);

          let containerId = needsData["containerId"];
          this.table.id = containerId;
          let chartContainerWidth = $("#chart-container").width();
          if (chartContainerWidth < ChartService.getMobileBreakpoint()) {
            this.isMobile = true;
          } else {
            this.isMobile = false;
          }
          let dataLengthMax = 100;
          
          if (containerId === "profile_match_by_need_functional_chart") {
            dataLengthMax = this.isMobile ? 15 : 25;
            NeedsService.setFunctionalNeedsList(needsList);
            this.functionalTable.id = containerId;
            this.functionalTable.data = JSON.parse(JSON.stringify(needsData));
            this.$parent.setChartResults(needsData.companyList);
            this.$parent.$data.criteriaResultsList = AdminService.removeByName(needsData.criteriaList, "Competitor");
          } else if (containerId === "profile_match_by_need_emotional_chart") {
            dataLengthMax = this.isMobile ? 15 : 35;
            let labelIndex = 1;
            NeedsService.setEmotionalNeedsList(needsList);
            this.emotionalTable.id = containerId;
            let copyOfChartData = JSON.parse(JSON.stringify(needsData));
            for (let indexRows = 0; indexRows < needsData.rows.length; indexRows++) {
              let copyOfChartDataRow = copyOfChartData.rows[indexRows].c;
              let originalName = copyOfChartDataRow[labelIndex].v;
              let copyValue = copyOfChartDataRow[labelIndex].v.split(",");
              if (typeof copyValue != "undefined") {
                if (indexRows <= 4 ) {
                  this.setEmotionDriverName(copyValue[0], indexRows);
                }
                copyOfChartDataRow[labelIndex].v = copyValue[0];
                if (typeof copyValue[1] != "undefined") {
                  copyOfChartDataRow[labelIndex].v += " (vs. " + copyValue[1] + ")";
                  NeedsService.replaceNeedName(originalName, copyOfChartDataRow[labelIndex].v, "emotional");
                }
              }
            }
            this.emotionalTable.data = copyOfChartData;
          }
        }
        this.$root.$emit("check-number-of-responses");  
        this.setDisplayView(true);
        this.$parent.setDisplayView(true);
        this.setEChartCriteria(this.$parent.$refs.chartCriteria.eChartCriteria);
        this.eChartCriteria.eChartType = "stacked-bar-vertical";
        this.parseMatchProfileDataForNeed(JSON.parse(JSON.stringify(this.chartData)));
        this.$refs.eChart.processChart(true,"match-need",this.eChartData,this.eChartCriteria,this.$parent.showPricingPower);
        $("#cp-by-functional-need-e-chart").addClass("chart-wrapper");
      } catch (err) {
        ErrorService.processException("MatchByNeed.displayChartCallback", err, "chart", this);
      }
    },
    parseMatchProfileDataForNeed: function(inputData){
      let itemsToRemove = {
        firstColumns: 1,
        lastRows: 1
      };

      let data = {};
      let chart1Data = inputData[0];
      let chart2Data = inputData[1];
      data = ProfileService.parseData(1,chart1Data,data,itemsToRemove);
      data = ProfileService.parseData(2,chart2Data,data,itemsToRemove);
      data = ProfileService.parseLegendAndColorData(chart1Data,data,false);
      this.eChartData = JSON.parse(JSON.stringify(data));
    },       
    async saveProfileDataTable() {
      if (this.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
        const vm = this;
        setTimeout(function() {    
          ReportService.saveReportTableFile(vm.$refs.functionalDataTable.getTableData(), "Table - Match Functional Needs", vm.$parent.clientChart, vm.saveReportTableFileCallback, vm);
          ReportService.saveReportTableFile(vm.$refs.emotionalDataTable.getTableData(), "Table - Match Emotional Needs", vm.$parent.clientChart, vm.saveReportTableFileCallback, vm);
          ReportService.saveReportPage(vm.getReportTableContent("profile_match_by_need_functional_chart"), "Table - Match Functional Needs", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
          ReportService.saveReportPage(vm.getReportTableContent("profile_match_by_need_emotional_chart"), "Table - Match Emotional Needs", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
        }, 1500);        
      }
    },    
    saveAllReportPages() {
      this.saveProfileDataTable();
    },
  },
  watch: {
    surveyCode: function() {
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try{this.$refs.eChart.clear();} catch(e){}
    },
    chartData: function() {
      if (this.$parent.$refs.chartCriteria.$data.displayType == "need") {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    }
  }
};
</script>
