<template>
  <div class="container">
    <div class="form-group form-row p-1">
      <h5 class="font-weight-bold">Auto-Create Survey Groups</h5>
    </div>
    <div class="form-group form-row pb-1 text-success">
        <span class="text-success"> {{ modalReturnMessage }}</span>
    </div>
    <div class="form-group form-row align-items-center">
      <label for="name" class="m-1 font-weight-bold">Base Group Name</label>
      <input v-model="surveyGroupName" id="name" name="name" type="text" class="form-control col-8 w-100" />
    </div>
    <div class="form-group form-row align-items-center">
        Clicking the 'Create Survey Groups' button will automatically put the surveys from each tier into a new survey group
    </div>
    <div role="group" class="chart-buttons btn-group-horizontal">
      <button class="btn btn-primary" type="button" @click="autoCreateGroupsofTiers">
        <span>Create Survey Groups</span>
        <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
      </button>
      <button type="button" class="btn btn-primary ml-4" @click="$modal.hide('auto-create-groups-of-tiers')">Close</button>
    </div>
  </div>
</template>
<script>
import SurveyGroupService from "../../services/survey.group.service";
import GoogleService from "../../services/google.service";

export default {
  name: "AutoCreateGroups",
  data() {
    return {
      modalReturnMessage: "",
      surveyGroupName: "",
      surveyGroupId: 0
    };
  },
  props: {
    baseGroupName: {
      type: String,
      default: ""
    },
    surveyGroupByTierList: {
      type: Array,
      default: function() {
        return [];
      }
    },    
  },
  methods: {
    getSurveyGroupCallback(response) {},
    async autoCreateGroupsofTiers() {
      GoogleService.logAutoCreateGroupsEventCriteria(this.baseGroupName, this);
      this.$root.processServerRequest();
      for (let i = 0; i < this.surveyGroupByTierList.length; i++) {
        let surveyGroupByTierObject = this.surveyGroupByTierList[i];
        let surveyGroup = {};
        let currentSurveyGroupNumber = i+1;
        surveyGroup.mode = "add-survey-group";
        surveyGroup.surveyGroupId = 0;
        surveyGroup.surveyGroupName = surveyGroupByTierObject.name + " - " + this.surveyGroupName;
        surveyGroup.stringSurveyGroupSurveyList = JSON.stringify(surveyGroupByTierObject.surveyList);
        this.modalReturnMessage = "Create survey group " + currentSurveyGroupNumber + " out of " + this.surveyGroupByTierList.length+ " : " + surveyGroup.surveyGroupName;
        await SurveyGroupService.saveSurveyGroup(surveyGroup, this.getSurveyGroupCallback, this);
      }
      this.modalReturnMessage = "All groups have been created";
      this.$root.processServerResponse();
    },
  },
  umnounted() {},
  computed: {},
  created() {
    this.surveyGroupName = this.baseGroupName;
  },
  watch: {}
};
</script>
