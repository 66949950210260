<template>
  <div class="survey-status-header form-group" v-if="displaySurveyStatus">
    <span class="section-label">Current Survey: {{ this.inputSurvey.name }}</span>
    <span class="section-label pl-3">Survey Status: </span>
    <span v-if="this.inputSurvey.active == 'Y'" class="text-success"> Active </span>
    <span v-else class="text-danger"> Not Active </span>
    <span class="section-label pl-3">Build Survey Required: </span>
    <span v-if="this.inputSurvey.buildRequired == 'Y'" class="text-danger"> Yes </span>
    <span v-else class="text-success">No</span>
    <span class="section-label pl-3">URL Status for </span>
    <span v-if="this.doesSurveyUrlWork"> {{ url }} </span>
    <span v-else class="text-danger">{{ url }} </span>
    <span>: </span>
    <span v-if="this.doesSurveyUrlWork" class="text-success"> Working </span>
    <span v-else class="text-danger">Not Working</span>
  </div>
</template>
<script>
export default {
  name: "SurveyStatus",
  components: {},
  data() {
    return {};
  },
  props: {
    doesSurveyUrlWork: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    displaySurveyStatus: {
      type: Boolean,
      default: true,
    },
    inputSurvey: {
      type: Object,
      default: function () {
        return {};
      },
    },      
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
