<template>
  <div></div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";

import DesignMenu from "../views/menu/DesignMenu.vue";
import ContentMixins from "./ContentMixins.vue";
import ViewMixins from "./ViewMixins.vue";
import GlobalMixins from "./GlobalMixins.vue";

import authHeader from "../services/auth-header";
import ClientService from "../services/client.service";
import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";
import UserService from "../services/user.service";
import SurveyService from "../services/survey.service";

export default {
  name: "StudyDesignMixins",
  mixins: [ContentMixins, ViewMixins, GlobalMixins],
  components: {
    /* eslint-disable vue/no-unused-components */
    DesignMenu
  },
  data() {
    return {
      disableButton: false,
      doesSurveyUrlWork: true,
      survey: {}
    };
  },
  props: {
    baseUrl: {
      type: String,
      default: AdminService.getBaseUrl()
    }
  },
  created() {},
  methods: {
    displayDefaultSuccessMessage: function() {
      AdminService.displaySuccessMessage({ text: "Configuration saved successfully" });
    },
    async saveBuildRequired(buildRequired) {
      const params = new URLSearchParams();
      params.append("buildRequired", buildRequired);
      params.append("surveyId", this.survey.surveyId);
      params.append("clientCode", this.clientCode);
      params.append("userId", UserService.getUserId());
      await axios
        .post(this.baseUrl + "/api/v1/survey-design/save-build-required", params, { headers: await authHeader() })
        .then(response => this.saveBuildRequiredCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("TheIdeal.saveBuildRequired", error, "survey-designer", this);
        });
    },
    saveBuildRequiredCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.error = false;
        this.survey.buildRequired = response.data.data;
      } else {
        this.error = true;
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async saveSurvey() {
      if (this.survey.buildRequired == "Y") {
        this.resetData();
        this.$root.processServerRequest("Saving survey setup");
        const params = new URLSearchParams();
        params.append("surveyCode", this.surveyCode);
        params.append("userId", UserService.getUserId());
        await axios
          .post(this.baseUrl + "/api/v1/survey-design/save-survey-design", params, { headers: await authHeader() })
          .then(response => this.saveSurveyCallBack(response))
          .catch(error => {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("StudyDesignMixins.saveSurvey", error, "survey-designer", this);
          });
      } else {
        this.goToSurveyDesign();
      }
    },
    saveSurveyCallBack(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.survey = response.data.data;
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.goToSurveyDesign();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    resetData() {
      this.setDisplayView(false);
      this.survey = {};
      if (typeof this.competitors != undefined) {
        this.competitors = ["", "", "", "", ""];
      }
    },
    async setupSurveyDesignerCallBack(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.survey = response.data.data.survey;
        this.$parent.survey = response.data.data.survey;
        if (typeof this.surveyDrivers != undefined) {
          this.surveyDrivers = response.data.data.surveyDrivers;
        }
        if (typeof this.competitors != undefined) {
          this.competitors = response.data.data.competitors;
        }
        if (typeof this.numberOfCompletedSurveys != undefined) {
          this.numberOfCompletedSurveys = response.data.data.numberOfCompletedSurveys;
        }
        this.doesSurveyUrlWork = await SurveyService.doesSurveyUrlWork(this.survey);

        this.postLoadData();
      } else {
        this.goToCreateSurvey();
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async setupSurveyDesigner() {
      this.resetData();
      this.$root.processServerRequest("Loading survey setup");
      const params = new URLSearchParams();
      params.append("surveyCode", this.surveyCode);
      params.append("userId", UserService.getUserId());

      await axios
        .post(this.baseUrl + "/api/v1/survey-design/get-existing-survey-design", params, { headers: await authHeader() })
        .then(response => this.setupSurveyDesignerCallBack(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("StudyDesignMixins.setupSurveyDesigner", error, "survey-designer", this);
        });
    },
    saveSurveyField: function(result) {
      var match = this.surveyFields.find(x => Number(x.surveyFieldId) === Number(result.surveyFieldId));
      if (typeof match !== "undefined") {
        this.surveyFields.splice($.inArray(match, this.surveyFields), 1);
      }
      this.surveyFields.push(result);
      if (result.buildSurvey) {
        this.saveBuildRequired("Y");
      }
    },
    saveSurveyFieldWithSuccessMessage: function(result) {
      this.saveSurveyField(result);
      this.displayDefaultSuccessMessage();
    },
    getSection(sectionName) {
      if (this.sections) {
        return this.sections.find(x => x.name === sectionName);
      } else {
        return {};
      }
    },
    getSurveyField(surveyFieldName) {
      if (this.surveyFields) {
        return this.surveyFields.find(x => x.surveyFieldName == surveyFieldName);
      } else {
        return {};
      }
    },
    goToCreateSurvey: function() {
      if (this.$router.currentRoute.name != "create-survey") {
        this.$router.replace({ path: "/design/create-survey" });
      }
    },
    goToDownload: function() {
      if (this.$router.currentRoute.name != "respondent-download") {
        this.$router.replace({ path: "/respondent/download" });
      }
    },
    goToIncludeSection: function() {
      if (this.$router.currentRoute.name != "include-sections") {
        this.$router.replace({ path: "/design/sections" });
      } else {
        AdminService.displayInfoMessage({ text: " Please click the Next button to continue with the survey setup" });
        this.setDisplayView(true);
      }
    },
    goToTheCompetitors: function() {
      if (this.$router.currentRoute.name != "ideal") {
        this.$router.replace({ path: "/design/ideal" });
      }
    },
    goToNeeds: function() {
      if (this.$router.currentRoute.name != "needs") {
        this.$router.replace({ path: "/design/needs" });
      }
    },
    goToSurveyPreview: function() {
      if (this.hasSurveyBeenBuilt || this.isSurveyEmpty) {
        if (this.$router.currentRoute.name != "survey-preview") {
          this.$router.replace({ path: "/design/preview" });
        }
      } else {
        AdminService.displayInfoMessage({ text: "You are being redirected to the Constants page. Please click the Next button to generate the survey", duration: -1 });
        let vm = this;
        setTimeout(function() {
          vm.goToTheCompetitors();
        }, 3000);
      }
    },
    goToSurveyDesign: function() {
      if (this.$router.currentRoute.name != "survey-design") {
        this.$router.replace({ path: "/design/segments" });
      }
    },
    goToInvitations: function() {
      if (this.hasSurveyBeenBuilt || this.isSurveyEmpty) {
        if (this.$router.currentRoute.name != "manage-invitations") {
          this.$router.replace({ path: "/respondent/invitations" });
        }
      } else {
        AdminService.displayInfoMessage({ text: "You are being redirected to the Constants page. Please click the Next button to generate the survey", duration: -1  });
        let vm = this;
        setTimeout(function() {
          vm.goToTheCompetitors();
        }, 3000);
      }
    },
    loadData: function() {
      this.setupSurveyDesigner();
    }
  },
  watch: {
    surveyCode: function() {
      this.loadData();
    },
  },
  mounted() {
    if (this.clientId != 0) {
      this.loadData();
    }
    this.$root.setShowMenuIcon(true);
  },
  computed: {
    baseInterviewUrl: function() {
      return SurveyService.getBaseInterviewUrl();
    },    
    liveSurveyUrl: function() {
      if (this.survey.url != "" && this.baseInterviewUrl != "") {
        return this.baseInterviewUrl + "/" + this.survey.clientId + "/s/" + this.survey.url;
      } else {
        return "";
      }
    },
    surveyFields: function() {
      if (this.survey.surveyFields) {
        return this.survey.surveyFields;
      } else {
        return [];
      }
    },
    sections: function() {
      if (this.survey.sections) {
        return this.survey.sections;
      } else {
        return [];
      }
    },
    isSurveyEmpty: function() {
      return jQuery.isEmptyObject(this.survey);
    },
    hasSurveyBeenBuilt: function() {
      return this.survey.buildRequired == "N";
    },
    permissionToView: function() {
      return (this.isAdvancedUser || this.isAdmin) && !this.isW5OUser;
    },
    buttonText: function() {
      return this.survey.buildRequired == "N" ? "Save" : "Save & Next";
    }
  }
};
</script>
