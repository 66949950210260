<template>
  <div>
      <div id="okta-signin-container"></div>
  </div>
</template>

<script>
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { oktaSignIn } from "../okta";
import AdminService from "../services/admin.service";
import AuthService from "../services/auth.service";

import ErrorService from "../services/error.service";

export default {
  name: "LoginOkta",
  data() {
    return {};
  },
  mounted: async function() {
    if (this.displayErrorMessage){
      AuthService.logout();
      AdminService.displayErrorMessage({ text: this.errorMessage, duration: -1 });
    }
    this.$nextTick(function () {
      oktaSignIn.showSignInAndRedirect({ 
        el: '#okta-signin-container' 
      }).then(tokens => {
        this.$auth.handleLoginRedirect(tokens)
      }).catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("LoginOkta.mounted", error, "okta", this);
        throw error;
      })
    })
  },
  props: {
    displayErrorMessage: {
      type: Boolean,
      default: false
    },    
    errorMessage: {
      type: String,
      default: ""
    },    
  },
  methods: {},
  unmounted() {
    // Remove the widget from the DOM on path change
    if (typeof oktaSignIn != "undefined" && oktaSignIn != null) {
        try {
          oktaSignIn.remove();
        } catch (err) {
            ErrorService.logException("LoginOkta.unmounted", err, "okta", this);
        }      
    }
  }
};
</script>
