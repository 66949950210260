<template>
  <div class="question-checkbox">
    <div class="choices-options">
      <div class="form-group form-inline pt-1 pb-1 no-gutters">
              <label for="segment" class="control-label pr-1">Limit Responses</label>
              <input type="checkbox" id="enableMaximumNumberOfValues" name="enableMaximumNumberOfValues" 
                  v-model="enableMaximumNumberOfValues" true-value="Y" false-value="N" 
                  @click="checkEnableMaximumNumberOfValues($event)" />
      </div>
      <div v-show="enableMaximumNumberOfValues == 'Y'" class="form-group form-inline pt-1 pb-1 no-gutters">
          <label for="maxNumberOfResponses">Max number of responses</label>
          <div class="maxNumberOfResponsesLeft">
              <a href="#!" @click="addMaxResponseCount" class="btn-lg">
                  <font-awesome-icon :icon="['fa', 'plus-circle']" />
              </a>
          </div>
          <input v-model="maxNumberOfResponses" id="maxNumberOfResponses" name="maxNumberOfResponses" 
              step="1" min="min" max="max" type="text" class="form-control required text-center" />
          <div class="maxNumberOfResponsesRight">
              <a href="#!" @click="removeMaxResponseCount" class="btn-lg">
                  <font-awesome-icon :icon="['fa', 'minus-circle']" />
              </a>
          </div>
      </div>
      <div class="form-group form-inline pt-1 pb-1 no-gutters">
              <label for="orderSet" >Order</label>
              <div class="form-check form-check-inline">
                  <input type="radio" id="orderRandom" name="orderRandom" class="form-check-input" value="random"  v-model="choiceOrder" @click="saveOrderEvent($event);saveChoice()">Random 
              </div>
              <div class="form-check form-check-inline">
                  <input type="radio" id="orderSet" name="orderSet" class="form-check-input" value="set" v-model="choiceOrder" @click="saveOrderEvent($event);saveChoice()">Set (below) 
              </div>
      </div>        
      <div v-show="draggableEnabled" class="question-order">
          <div class="message pl-2">Drag and Drop a choice's name to set the order of how they will always appear in the survey.</div>
          <VueDraggable
              v-model="choices"
              :disabled="!draggableEnabled"
              class="list-group draggable-container"
              ghost-class="draggable-ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="endOfDragChoices"
              options="people"            
          >
              <div v-show="choice.name != ''" class="list-group-item draggable-card" v-for="choice in this.choices" 
                  :key="choice.questionId" 
                  :index="choice.questionId">
                  <i class="fa fa-caret-right" aria-hidden="true"></i>
                  <span class="pl-1">{{ choice.name }}</span>
              </div>
          </VueDraggable>      
      </div>
    </div>
  </div>
</template>
<script>
import { VueDraggable } from 'vue-draggable-plus';

import DragAndDropMixins from "../mixins/DragAndDropMixins.vue";
export default {
  name: "QuestionCheckbox",
  components: {VueDraggable},
  mixins: [DragAndDropMixins],
  computed: {
    max: function(){
      if (typeof this.choices != "undefined"){
        return this.choices.length;
      }
      return 100;
    }
  },
  watch: {
    initialChoices: function(){
      this.choices = this.initialChoices;
      if (this.maxNumberOfResponses > this.max){
        this.maxNumberOfResponses = this.max;
      }
    },
  },
  data() {
    return {
        maxNumberOfResponses: 2,
        enableMaximumNumberOfValues: "N",
        min: 1,
    };
  },
  props: {  
    initialMaxEnabled: {
        type: String,
        value: "N"
    },
    initialMaxNumber: {
        type: Number,
        value: 1
    },

  },
  methods: {
    checkEnableMaximumNumberOfValues: function(event){
      /* */
      this.numberOfChoicesToRank = this.maxNumberOfResponses;
      if (event.target.checked) {
        this.enableMaximumNumberOfValues = "Y";
      } else {
        this.enableMaximumNumberOfValues = "N";
      }
      this.saveLimitResponses();
    },
    addMaxResponseCount: function(result) {
       if (this.maxNumberOfResponses < this.max){
            this.maxNumberOfResponses++;
            this.saveCheckbox();
        }
    },
    removeMaxResponseCount: function() {
        if (this.maxNumberOfResponses > this.min){
            this.maxNumberOfResponses--;
            this.saveCheckbox();
        }
    },  
    saveDragDrop: function(){
        this.saveCheckbox(); 
    },
    saveCheckbox: function(){
        this.$emit("save",this.enableMaximumNumberOfValues, this.maxNumberOfResponses,this.choices, this.choiceOrder);
    },
    saveChoice: function(){
        this.$emit("saveOrder", this.choiceOrder);
    },
    saveLimitResponses: function(){
        this.$emit("saveLimitResponses", this.enableMaximumNumberOfValues);
    },
  },
  mounted() {
    this.maxNumberOfResponses = this.initialMaxNumber
    this.enableMaximumNumberOfValues = this.initialMaxEnabled
    
  },  
  unmounted() {}
};
</script>
