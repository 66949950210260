import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import ClientService from "./client.service";
import TableService from "./table.service";

import moment from "moment";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;

const API_ADMIN_URL = AdminService.getBaseUrl() + "/api/v1/survey-admin";

class SurveyGroupService {
  surveyGroupListByClient = [];
  setSurveyGroupListByClient(list) {
    this.surveyGroupListByClient = list;
  }
  getSurveyGroupName(surveyGroupId) {
    let searchableSurveyGroup = this.surveyGroupListByClient.find(x => x.surveyGroupId == surveyGroupId);
    if (typeof searchableSurveyGroup != "undefined") {
      return searchableSurveyGroup.name;
    } else {
      return "";
    }
  }

  isSurveyGroup(element) {
    return element.subSurveyGroupId > 0;
  }
  getSurveyGroupOptions(surveyGroupList,showPublicGroups,clientId){
    let options = [];
    let surveyGroups = showPublicGroups == "Y" ? 
        surveyGroupList.filter(x => x.isPublic == "Y" || Number(x.clientId) == Number(clientId))  :
        surveyGroupList.filter(x => Number(x.clientId) == Number(clientId)) ;

    for (var i = 0; i < surveyGroups.length; i++) {        
      let surveyGroup = surveyGroups[i];
      options.push({
          name: surveyGroup.name,
          checked: false,
          value: String(surveyGroup.surveyGroupId)
        }
      );
    }      
    return options;
  }  
  async getSurveyGroup(surveyGroupId, mode, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("mode", mode);
    params.append("surveyGroupId", surveyGroupId);
    params.append("userId", UserService.getUserId());
    params.append("clientCode", ClientService.getClientCode());
    await axios
      .post(API_ADMIN_URL + "/get-survey-group", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyGroups.getSurveyGroup", error, "admin", chartApp);
      });
  }
  async saveSurveyGroup(surveyGroup, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("mode", surveyGroup.mode);
    params.append("surveyGroupId", surveyGroup.surveyGroupId);
    params.append("clientCode", ClientService.getClientCode());
    params.append("surveyGroupName", surveyGroup.surveyGroupName);
    params.append("isPublic", surveyGroup.surveyGroupIsPublic);
    params.append("userId", UserService.getUserId());
    params.append("stringSurveyGroupSurveyList", surveyGroup.stringSurveyGroupSurveyList);
    await axios
      .post(API_ADMIN_URL + "/save-survey-group", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyGroups.saveSurveyGroup", error, "admin", chartApp);
      });
  }
  async getSurveyGroupsByClientId(clientCode, callback, chartApp) {
      if (!UserService.isLoggedIn() || clientCode == "") {
        return;
      }
      const params = new URLSearchParams();
      params.append("userId", UserService.getUserId());
      params.append("clientCode", clientCode);
      await axios
        .post(API_ADMIN_URL + "/get-survey-groups-by-client-id", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveyGroupService.getSurveyGroupsByClientId", error, "admin", chartApp);
        });
  }
  async getSurveyGroupList(callback, chartApp) {
    const params = new URLSearchParams();
    params.append("userId", UserService.getUserId());
    params.append("clientCode", ClientService.getClientCode());
    await axios
      .post(API_ADMIN_URL + "/get-survey-group-list", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyGroupService.getSurveyGroupList", error, "admin", chartApp);
      });
  }
  async deleteSurveyGroup(surveyGroupId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("surveyGroupId", surveyGroupId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_ADMIN_URL + "/delete-survey-group", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyGroupService.deleteSurveyGroup", error, "admin", chartApp);
      });
  }
  getTableColumnData() {
    return [
      {
        data: "surveyGroupId",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "id",
        width: "1rem"
      },
      { data: "name", name: "name", width: "5rem" },
      { data: "isPublic", name: "isPublic", width: "5rem" },
      { data: "name", name: "name", width: "5rem" },
      TableService.getActiveColumn(),
      TableService.getDateColumn("dateCreated"),
      TableService.getDateColumn("dateModified")
    ];
  }
  async importFile(importsSurveyGroupFile, surveyGroup, mode, callback, chartApp) {
    let formData = new FormData();
    formData.append("file", importsSurveyGroupFile);
    formData.append("mode", mode);
    formData.append("userId", UserService.getUserId());
    formData.append("clientCode", ClientService.getClientCode());
    formData.append("surveyGroupId", surveyGroup.surveyGroupId);
    formData.append("surveyGroupName", surveyGroup.name);

    let headers = { "Content-Type": "multipart/form-data" };
    $.extend(headers, await authHeader());

    await axios
      .post(API_ADMIN_URL + "/import-surveys-into-survey-group", formData, { headers: headers })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("SurveyGroupService.importFile", error, "admin", chartApp);
      });
  }
  getDefaultSurveyGroup(){
    return {
      name: "",
      surveyGroupId: 0
    }
  }
}

export default new SurveyGroupService();
