<template>
    <div class="e-chart-buttons mt-2 mb-2">
      <div class="multi-wave-charts">
        <div class="d-flex">
            <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('stacked-bar-vertical',5)"><img :class="getClass(5)" src="../../assets/img/chart-buttons/Chart-Bar-Stack-Color.png" /></a></div>
            <div class="response-rates-chart-button"><a href="javascript:void(0);" @click="setChart('stacked-bar-horizontal',6)"><img :class="getClass(6)" src="../../assets/img/chart-buttons/Chart-Bar-Stack-Horizontal-Color.png" /></a></div>
        </div>        
      </div>
      <div class="mt-4">
        <show-label :input-show-labels="showLabels" @set-show-labels="changeShowLabels" :content-list="pageContentForNPS"></show-label>
        <show-legend :input-show-legend="showLegend" @set-show-legend="changeShowLegend"></show-legend>
        <div class="form-group form-inline mt-1 d-flex">
          <input
          type="checkbox"
          class="form-check-input"
          id="showGoalLine"
          name="showGoalLine"
          v-model="showGoalLine"
          @click="changeShowGoalLine($event)"
          />
          <label for="showGoalLine"><span>Promoter Goal Line</span><modal-link modal-id="Promoter Goals" :content-list="pageContentForNPS"></modal-link></label>
       </div>                   
       <div v-show="displayAngleText" class="form-group form-inline mt-1">
          <input
          type="checkbox"
          class="form-check-input"
          id="angleText"
          name="angleText"
          v-model="angleText"
          @click="changeAngleText($event)"
          />
          <label for="angleText">Angle X-axis Text </label>
       </div>                   
  
      </div>
      <div v-for="(pageContent, idxPageContent) in pageContentForNPS" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>      
    </div>
</template>

<script>
import EChartsButtonsMixins from '../../mixins/EChartsButtonsMixins.vue';
import ShowLabel from './ShowLabel.vue';
import ShowLegend from './ShowLegend.vue';
import ModalLink from '../ModalLink.vue';
import Modal from "../Modal.vue";
import ContentService from '../../services/content.service';
export default {
  name: "NPSChartButtons",
  mixins: [EChartsButtonsMixins],
  components: {Modal,ModalLink,ShowLabel,ShowLegend},
  data() {
    return {
      showGoalLine: true,
      angleText: false,
      pageContentForNPS: [],
    };
  },
  props: {
    inputShowGoalLine: {
        type: Boolean,
        default: true
    },       
    inputAngleText: {
        type: Boolean,
        default: false
    },   
  
  },
  computed: {
    displayAngleText: function(){
        return this.eChartType == "stacked-bar-vertical";
    },
  },
  mounted() {
    this.setChart(this.inputEChartType, this.getChartIndex(this.inputEChartType,this.inputRecalculate), this.inputRecalculate);      
  },
  created() {
    ContentService.getPageContent("NPS", this.getPageContentByNPSCallBack, this);    
  },
  methods: {
    getPageContentByNPSCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForNPS = response.data.data;
      }
    },      
    changeShowGoalLine: function(event) {
        this.showGoalLine = event.target.checked;
        this.$emit("set-show-goal-line", this.showGoalLine);
    },    
    changeAngleText: function(event) {
        this.angleText = event.target.checked;
        this.$emit("set-angle-text", this.angleText);
    },
    getClass:function(index){
        return this.chartSelected == index ? "selected" : "";
    },
    verifyChartCriteria: function(eChartType){
        let verified = true;
        return verified;
    },
  },
  watch: {
    inputAngleText: function(){
        this.angleText = this.inputAngleText;
    },
    inputShowGoalLine: function(){
        this.showGoalLine = this.inputShowGoalLine;
    },
  },
};
</script>
