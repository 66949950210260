import Vue from "vue";
import { useRouter } from "vue-router";
Vue.use(useRouter);

import CreateSurvey from "../../views/design/CreateSurvey.vue";

import PrintReport from "../../views/PrintReport.vue";
import Playground from "../../views/Playground.vue";

import CompetitiveProfile from "../../views/charts/CompetitiveProfile.vue";
import ProfileMatch from "../../views/charts/ProfileMatch.vue";
import ContactUs from "../../views/ContactUs.vue";
import QuickGuide from "../../views/QuickGuide.vue";

import CompetitiveTrends from "../../views/charts/CompetitiveTrends.vue";
import Ranks from "../../views/charts/Ranks.vue";
import PredictiveAnalytics from "../../views/charts/PredictiveAnalytics.vue";
import ResponseRates from "../../views/charts/ResponseRates.vue";

import Needs from "../../views/design/Needs.vue";
import TheIdeal from "../../views/design/TheIdeal.vue";
import SurveyDesign from "../../views/design/SurveyDesign.vue";
import IncludeSections from "../../views/design/IncludeSections.vue";
import ManageInvitations from "../../views/design/ManageInvitations.vue";
import RespondentDownload from "../../views/design/RespondentDownload.vue";
import SurveyPreview from "../../views/design/SurveyPreview.vue";

import SurveyGroupList from "../../views/company/SurveyGroupList.vue";
import EditSurveyGroup from "../../views/company/EditSurveyGroup.vue";

import Library from "../../views/Library.vue";
import Report from "../../views/charts/Report.vue";
import RespondentWaves from "../../views/company/RespondentWaves.vue";
import CompanyLogo from "../../views/company/CompanyLogo.vue";
import ClientSettings from "../../views/company/ClientSettings.vue";
import MetricGroupList from "../../views/company/MetricGroupList.vue";
import EditMetricGroup from "../../views/company/EditMetricGroup.vue";
import SurveyList from "../../views/admin/SurveyList.vue";
import SavedCardList from "../../views/admin/SavedCardList.vue";
import WavesList from "../../views/admin/WavesList.vue";
import AddClient from "../../views/admin/AddClient.vue";
import AddUser from "../../views/admin/AddUser.vue";
import DashboardLayout from "../../views/admin/DashboardLayout.vue";
import ViewSavedDashboardCards from "../../views/ViewSavedDashboardCards.vue";
import Advisor from "../../views/admin/Advisor.vue";
import AdvisorUnsubscribe from "../../views/admin/AdvisorUnsubscribe.vue";
import SurveyStatus from "../../views/admin/SurveyStatus.vue";
import Responses from "../../views/admin/Responses.vue";
import UserList from "../../views/admin/UserList.vue";
import EditContent from "../../views/admin/EditContent.vue";
import ClientList from "../../views/admin/ClientList.vue";
import ContentList from "../../views/admin/ContentList.vue";
import NPAEmailList from "../../views/admin/NPAEmailList.vue";
import AdvisorList from "../../views/admin/AdvisorList.vue";
import CompanyList from "../../views/admin/CompanyList.vue";
import BenchmarkCompanyList from "../../views/admin/BenchmarkCompanyList.vue";
import DashboardCardList from "../../views/admin/DashboardCardList.vue";
import LocaleList from "../../views/admin/LocaleList.vue";
import SurveyLocaleList from "../../views/admin/SurveyLocaleList.vue";
import AllMetricGroupList from "../../views/admin/AllMetricGroupList.vue";
import AllMetricList from "../../views/admin/AllMetricList.vue";
import SectorList from "../../views/admin/SectorList.vue";
import Performance from "../../views/monitor/Performance.vue";
import JobList from "../../views/monitor/JobList.vue";

import ApplicationErrorList from "../../views/monitor/ApplicationErrorList.vue";
import ResponseMessageList from "../../views/monitor/ResponseMessageList.vue";
import PartialList from "../../views/admin/PartialList.vue";
import LoginOkta from "../../views/LoginOkta.vue";
import Dashboard from "../../views/Dashboard.vue";
import AuthRedirect from "../../components/AuthRedirect.vue";
import Error from "../../views/Error.vue";
import ResetPassword from "../../views/ResetPassword.vue";
import LogoutRedirect from "../../components/LogoutRedirect.vue";
import ForgotPasswordWrapper from "../../views/ForgotPasswordWrapper.vue";
import SmartCenterService from "../../services/smart.center.service";
import GoogleService from "../../services/google.service";

const SmartCenter = { template: "<div>SmartCenter</div>" };
const GoogleAnalyticsAdmin = { template: "<div>GoogleAnalyticsAdmin</div>" };
const GoogleAnalyticsInterview = { template: "<div>GoogleAnalyticsInterview</div>" };
const HelpWratings = { template: "<div>HelpWratings</div>" };
const isInterviewApp = process.env.VUE_APP_BASE_IS_INTERVIEW_APP;
const adminAppRoutes = [
  {
    path: process.env.VUE_APP_OKTA_CALLBACK_FUNCTION,
    component: AuthRedirect,    
  },
  {
    path: "/logout/callback",
    component: LogoutRedirect
  },
  {
    name: "error",
    path: "/error",
    component: Error,
    meta: {
      title: "404 Page",
      breadcrumb: []
    }
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: ForgotPasswordWrapper,
    meta: {
      title: "Forgot Password",
      breadcrumb: [],
      requiresAuth: false
    },
    props: true
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
      breadcrumb: []
    },
    props: true
  },
  {
    name: "user-settings",
    path: "/user-settings/:initialMode/:initialUserId/:initialUserCode?",
    props: route=>({initialMode: 'user-settings', initialUserId: parseInt(route.params.initialUserId), initialUserCode: route.params.initialUserCode}),     
    component: AddUser,
    meta: {
      title: "User",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "User", link: "user-settings" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "view-saved-dashboard-cards",
    path: "/view-saved-dashboard-cards/",
    component: ViewSavedDashboardCards,
    meta: {
      title: "View Saved Dashboard Cards",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "View Saved Dashboard Cards", link: "view-saved-dashboard-cards" }
      ],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "dashboard-layout",
    path: "/dashboard-layout/",
    component: DashboardLayout,
    meta: {
      title: "Dashboard",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Dashboard", link: "dashboard-layout" }
      ],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "advisor",
    path: "/advisor/",
    component: Advisor,
    meta: {
      title: "Advisor",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Advisor", link: "advisor" }
      ],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "edit-advisor",
    path: "/advisor/:initialMode?/:initialClientCode?/:initialClientName?/:initialAdvisorConfigurationId?",
    component: Advisor,
    meta: {
      title: "Advisor",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Advisor", link: "advisor" }
      ],
      requiresAuth: true
    },
    props: route=>({initialMode: 'admin-edit', initialClientName: route.params.initialClientName, initialClientCode: route.params.initialClientCode, initialAdvisorConfigurationId: parseInt(route.params.initialAdvisorConfigurationId)}),
  },
  {
    name: "logo",
    path: "/user/logo",
    component: CompanyLogo,
    meta: {
      title: "Logo",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "User" }, { name: "Logo", link: "logo" }],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "library",
    path: "/library",
    component: Library,
    meta: {
      title: "Library",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Library", link: "library" }
      ],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "client-settings",
    path: "/client-settings/",
    component: ClientSettings,
    meta: {
      title: "Settings",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Settings", link: "client-settings" }
      ],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "respondent-waves",
    path: "/respondent/waves",
    component: RespondentWaves,
    meta: {
      title: "Waves",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Respondent" }, { name: "Waves", link: "waves" }],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "create-survey",
    path: "/design/create-survey",
    component: CreateSurvey,
    props: true,
    meta: {
      title: "Create Survey",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Design" }, { name: "Create Survey", link: "create-survey" }],
      requiresAuth: true
    }
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: ContactUs,
    meta: {
      title: "Contact Us",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Contact Us" }],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "quick-guide",
    path: "/quick-guide",
    component: QuickGuide,
    meta: {
      title: "Quick Guide",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Quick Guide" }],
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/",
    redirect: "/competitive-dashboard",
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "report",
    path: "/analytics/report",
    component: Report,
    meta: {
      title: "Report",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Report", link: "report" }
      ],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "profile-match-default",
    path: "/analytics/match/",
    component: ProfileMatch,
    meta: {
      title: "Match",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Match", link: "match" }
      ],
      requiresAuth: true
    },
    props: route=>({autoGenerateChart: false, saveClientChartId: parseInt(0)}),
  },  
  {
    name: "profile-match",
    path: "/analytics/match/:autoGenerateChart?/:saveClientChartId?",
    props: route=>({autoGenerateChart: route.params.autoGenerateChart || false, saveClientChartId: parseInt(route.params.saveClientChartId)}),
    component: ProfileMatch,
    meta: {
      title: "Match",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Match", link: "match" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "competitive-profile-default",
    path: "/analytics/profiles/",
    component: CompetitiveProfile,
    meta: {
      title: "Profiles",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Profiles", link: "profiles" }
      ],
      requiresAuth: true
    },
    props: route=>({autoGenerateChart: false, saveClientChartId: parseInt(0)})
  },
  {
    name: "competitive-profile",
    path: "/analytics/profiles/:autoGenerateChart?/:saveClientChartId?",
    props: route=>({autoGenerateChart: route.params.autoGenerateChart || false, saveClientChartId: parseInt(route.params.saveClientChartId)}),
    component: CompetitiveProfile,
    meta: {
      title: "Profiles",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Profiles", link: "profiles" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "ranks-default",
    path: "/analytics/ranks/",
    component: Ranks,
    props: route=>({autoGenerateChart: false, saveClientChartId: parseInt(0)}),
    meta: {
      title: "Ranks",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Ranks", link: "ranks" }
      ],
      requiresAuth: true
    }
  },  
  {
    name: "ranks",
    path: "/analytics/ranks/:autoGenerateChart/:saveClientChartId?",
    props: route=>({autoGenerateChart: route.params.autoGenerateChart || false, saveClientChartId: parseInt(route.params.saveClientChartId)}),
    component: Ranks,
    meta: {
      title: "Ranks",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Ranks", link: "ranks" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "print-report",
    path: "/print-report/:clientChartIdsInput?",
    component: PrintReport,
    props: route => {
      const clientChartIdsInput = route.params.clientChartIdsInput ? route.params.clientChartIdsInput.split(',').map(Number) : [];
      return { clientChartIdsInput };
    },    
    meta: {
      title: "Print Report",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Print Report", link: "print-report" }
      ],
      requiresAuth: true
    }
  },  
  {
    name: "edit-print-report",
    path: "/edit-print-report/:initialMode/:initialClientReportId",
    component: PrintReport,
    props: route=>({initialMode: 'edit', initialClientReportId: parseInt(route.params.initialClientReportId)}),
    meta: {
      title: "Print Report",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Print Report", link: "print-report" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "playground",
    path: "/playground",
    component: Playground,
    props: true,
    meta: {
      title: "Playground",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Playground", link: "playground" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "predictive-analytics",
    path: "/analytics/predictive/:autoGenerateChart/:saveClientChartId",
    props: route=>({autoGenerateChart: route.params.autoGenerateChart || false, saveClientChartId: parseInt(route.params.saveClientChartId)}),
    component: PredictiveAnalytics,
    meta: {
      title: "Predictive",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Predictive", link: "predictive" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "predictive-analytics-default",
    path: "/analytics/predictive/",
    props: route=>({autoGenerateChart: false, saveClientChartId: parseInt(0)}),
    component: PredictiveAnalytics,
    meta: {
      title: "Predictive",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Predictive", link: "predictive" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "response-rates-default",
    path: "/respondent/rates/",
    component: ResponseRates,
    props: route=>({autoGenerateChart: false, saveClientChartId: parseInt(0)}),
    meta: {
      title: "Rates",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Analytics", link: "/analytics/report" }, { name: "Rates", link: "rates" }],
      requiresAuth: true
    }
  },  
  {
    name: "response-rates",
    path: "/respondent/rates/:autoGenerateChart?/:saveClientChartId?",
    props: route=>({autoGenerateChart: route.params.autoGenerateChart || false, saveClientChartId: parseInt(route.params.saveClientChartId)}),
    component: ResponseRates,
    meta: {
      title: "Rates",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Analytics", link: "/analytics/report" }, { name: "Rates", link: "rates" }],
      requiresAuth: true
    }
  },
  {
    name: "competitive-trends-default",
    path: "/analytics/trends/",
    component: CompetitiveTrends,
    props: route=>({autoGenerateChart: false, saveClientChartId: parseInt(0)}),
    meta: {
      title: "Trends",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Trends", link: "trends" }
      ],
      requiresAuth: true
    }
  },  
  {
    name: "competitive-trends",
    component: CompetitiveTrends,
    path: "/analytics/trends/:autoGenerateChart?/:saveClientChartId?",
    props: route=>({autoGenerateChart: route.params.autoGenerateChart || false, saveClientChartId: parseInt(route.params.saveClientChartId)}),
      meta: {
      title: "Trends",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Analytics", link: "report" },
        { name: "Trends", link: "trends" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "metric-list",
    path: "/respondent/metrics",
    component: MetricGroupList,
    props: true,
    meta: {
      title: "Metrics",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Respondent" }, { name: "Metrics", link: "metrics" }],
      requiresAuth: true
    }
  },
  {
    name: "add-metric-group",
    path: "/company/add-metric-group",
    component: EditMetricGroup,
    props: true,
    meta: {
      title: "Add Metric Group",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Respondent" },
        { name: "Metrics", link: "metrics" },
        { name: "Add Metric Group", link: "add-metric-group" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "edit-metric-group",
    path: "/company/metric-group/:initialMode/:initialMetricGroupId",
    component: EditMetricGroup,
    props: route=>({initialMode: 'edit-metric-group', initialMetricGroupId: parseInt(route.params.initialMetricGroupId)}),
    meta: {
      title: "Edit Metric Group",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Respondent" },
        { name: "Metrics", link: "metrics" },
        { name: "Edit Metric Group", link: "edit-metric-group" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "survey-group-list",
    path: "/respondent/clusters",
    component: SurveyGroupList,
    props: true,
    meta: {
      title: "Groups",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Respondent" }, { name: "Groups", link: "clusters" }],
      requiresAuth: true
    }
  },
  {
    name: "add-survey-group",
    path: "/company/add-survey-group",
    component: EditSurveyGroup,
    props: true,
    meta: {
      title: "Add Survey Group",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Company" },
        { name: "Groups", link: "survey-group-list" },
        { name: "Add Survey Group", link: "add-survey-group" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "edit-survey-group",
    path: "/company/survey-group/:initialMode/:initialSurveyGroupId",
    component: EditSurveyGroup,
    props: route=>({initialMode: 'edit-survey-group', initialSurveyGroupId: parseInt(route.params.initialSurveyGroupId)}),
    meta: {
      title: "Edit Survey Group",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Respondent" },
        { name: "Groups", link: "survey-group-list" },
        { name: "Edit Survey Group", link: "edit-survey-group" }
      ],
      requiresAuth: true
    }
  },  
  {
    name: "copy-survey-group",
    path: "/company/survey-group/:initialMode/:initialSurveyGroupId",
    component: EditSurveyGroup,
    props: route=>({initialMode: 'copy-survey-group', initialSurveyGroupId: parseInt(route.params.initialSurveyGroupId)}),
    meta: {
      title: "Add Survey Group",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Respondent" },
        { name: "Groups", link: "survey-group-list" },
        { name: "Add Survey Group", link: "copy-survey-group" }
      ],
      requiresAuth: true
    }
  },  
  {
    name: "responses",
    path: "/admin/responses",
    component: Responses,
    props: true,
    meta: {
      title: "Responses",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Responses", link: "responses" }],
      requiresAuth: true
    }
  },
  {
    name: "survey-status",
    path: "/admin/survey-status",
    component: SurveyStatus,
    props: true,
    meta: {
      title: "Survey Status",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Survey Status", link: "survey-status" }],
      requiresAuth: true
    }
  },
  
  {
    name: "saved-card-list",
    path: "/admin/saved-card-list",
    component: SavedCardList,
    props: true,
    meta: {
      title: "Saved Card List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Survey List", link: "survey-list" }],
      requiresAuth: true
    }
  },
  {
    name: "waves-list",
    path: "/admin/waves-list",
    component: WavesList,
    props: true,
    meta: {
      title: "Waves List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Waves List", link: "waves-list" }],
      requiresAuth: true
    }
  },

  
  {
    name: "survey-list",
    path: "/admin/survey-list",
    component: SurveyList,
    props: true,
    meta: {
      title: "Survey List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Survey List", link: "survey-list" }],
      requiresAuth: true
    }
  },
  {
    name: "content-list",
    path: "/admin/content-list",
    component: ContentList,
    props: true,
    meta: {
      title: "Content List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Content List", link: "content-list" }],
      requiresAuth: true
    }
  },
  {
    name: "partial-responses",
    path: "/admin/partial-responses",
    component: PartialList,
    props: true,
    meta: {
      title: "Partial Survey Responses",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Partial Survey Responses", link: "partial-responses" }],
      requiresAuth: true
    }
  },
  {
    name: "add-content",
    path: "/admin/content/:initialMode",
    component: EditContent,
    props: route=>({initialMode: 'add'}),    
    meta: {
      title: "Add Content",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Admin" },
        { name: "Content List", link: "content-list" },
        { name: "Add Content", link: "add-content" }
      ],
      requiresAuth: true
    }
  },

  {
    name: "edit-content",
    path: "/admin/content/:initialMode/:initialContentId",
    component: EditContent,
    props: route=>({initialMode: 'edit', initialContentId: parseInt(route.params.initialContentId)}),    
    meta: {
      title: "Edit Content",
      breadcrumb: [
        { name: "Home", link: "/competitive-dashboard" },
        { name: "Admin" },
        { name: "Content List", link: "content-list" },
        { name: "Add/Edit Content", link: "add-content" }
      ],
      requiresAuth: true
    }
  },
  {
    name: "advisor-list",
    path: "/admin/advisor-list",
    component: AdvisorList,
    props: true,
    meta: {
      title: "Advisor List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Advisor List", link: "advisor-list" }],
      requiresAuth: true
    }
  },
  {
    name: "nps-email",
    path: "/admin/nps-email",
    component: NPAEmailList,
    props: true,
    meta: {
      title: "NPS Email",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "NPS Email", link: "nps-email" }],
      requiresAuth: true
    }
  },
  {
    name: "application-error",
    path: "/monitor/application-error",
    component: ApplicationErrorList,
    props: true,
    meta: {
      title: "Application Error",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Monitor" }, { name: "Application Error", link: "application-error" }],
      requiresAuth: true
    }
  },
  {
    name: "response-message",
    path: "/monitor/response-message",
    component: ResponseMessageList,
    props: true,
    meta: {
      title: "Response Message List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Monitor" }, { name: "Response Message", link: "response-message" }],
      requiresAuth: true
    }
  },
  {
    name: "job-list",
    path: "/monitor/job-list",
    component: JobList,
    props: true,
    meta: {
      title: "Job List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Monitor" }, { name: "Job List", link: "job-list" }],
      requiresAuth: true
    }
  },
  {
    name: "performance",
    path: "/monitor/performance",
    component: Performance,
    props: true,
    meta: {
      title: "Performance",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Monitor" }, { name: "Performance", link: "performance" }],
      requiresAuth: true
    }
  },
  {
    name: "company-list",
    path: "/admin/company-list",
    component: CompanyList,
    props: true,
    meta: {
      title: "Company List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Company List", link: "company-list" }],
      requiresAuth: true
    }
  },
  {
    name: "admin-metric-list",
    path: "/admin/admin-metric-list",
    component: AllMetricList,
    props: true,
    meta: {
      title: "Metric List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Metric List", link: "admin-metric-list" }],
      requiresAuth: true
    }
  },  
  {
    name: "admin-metric-group-list",
    path: "/admin/admin-metric-group-list",
    component: AllMetricGroupList,
    props: true,
    meta: {
      title: "Metric Group List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Metric Group List", link: "admin-metric-group-list" }],
      requiresAuth: true
    }
  },
  {
    name: "dashboard-card-list",
    path: "/admin/dashboard-card-list",
    component: DashboardCardList,
    props: true,
    meta: {
      title: "Company List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Dashboard Card List", link: "dashboard-card-list" }],
      requiresAuth: true
    }
  },
  {
    name: "benchmark-company-list",
    path: "/admin/benchmark-company-list",
    component: BenchmarkCompanyList,
    props: true,
    meta: {
      title: "Company List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Benchmark Company List", link: "benchmark-company-list" }],
      requiresAuth: true
    }
  },
  {
    name: "locale-list",
    path: "/admin/locale-list",
    component: LocaleList,
    props: true,
    meta: {
      title: "Locale List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Locale List", link: "locale-list" }],
      requiresAuth: true
    }
  },
  {
    name: "survey-locale-list",
    path: "/admin/survey-locale-list",
    component: SurveyLocaleList,
    props: true,
    meta: {
      title: "Survey Locale List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Survey Locale List", link: "survey-locale-list" }],
      requiresAuth: true
    }
  },
  
  {
    name: "sector-list",
    path: "/admin/sector-list",
    component: SectorList,
    props: true,
    meta: {
      title: "Sector List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Sector List", link: "sector-list" }],
      requiresAuth: true
    }
  },

  {
    name: "client-list",
    path: "/admin/client-list",
    component: ClientList,
    props: true,
    meta: {
      title: "Client List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Client List", link: "client-list" }],
      requiresAuth: true
    }
  },
  {
    name: "add-client",
    path: "/admin/add-client",
    component: AddClient,
    props: true,
    meta: {
      title: "Add Client",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Client List", link: "client-list" }, { name: "Add Client", link: "add-client" }],
      requiresAuth: true
    }
  },
  {
    name: "edit-client",
    path: "/admin/:initialMode/:initialClientId/:initialClientCode?",
    component: AddClient,
    props: route=>({initialMode: 'edit', initialClientId: parseInt(route.params.initialClientId), initialClientCode: route.params.initialClientCode}),  
    meta: {
      title: "Add Client",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "Client List", link: "client-list" }, { name: "Edit Client", link: "edit-client" }],
      requiresAuth: true
    }
  },
  {
    name: "edit-user",
    path: "/admin/:initialMode/:initialUserId/:initialUserCode?",
    component: AddUser,
    props: route=>({initialMode: 'edit-user', initialUserId: parseInt(route.params.initialUserId), initialUserCode: route.params.initialUserCode}), 
    meta: {
      title: "Edit User",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "User List", link: "user-list" }, { name: "Edit User", link: "edit-user" }],
      requiresAuth: true
    }
  },
  {
    name: "duplicate-user",
    path: "/admin/:initialMode/:initialUserId/:initialUserCode?",
    component: AddUser,
    props: route=>({initialMode: 'duplicate-user', initialUserId: parseInt(route.params.initialUserId), initialUserCode: route.params.initialUserCode}), 
    meta: {
      title: "Edit User",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "User List", link: "user-list" }, { name: "Duplicate User", link: "edit-user" }],
      requiresAuth: true
    }
  },
  {
    name: "add-user",
    path: "/admin/add-user",
    component: AddUser,
    props: true,
    meta: {
      title: "Add User",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "User List", link: "user-list" }, { name: "Add User", link: "add-user" }],
      requiresAuth: true
    }
  },
  {
    name: "user-list",
    path: "/admin/user-list",
    component: UserList,
    props: true,
    meta: {
      title: "User List",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Admin" }, { name: "User List", link: "user-list" }],
      requiresAuth: true
    }
  },
  {
    name: "respondent-download",
    path: "/respondent/download",
    component: RespondentDownload,
    meta: {
      title: "Download",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Respondent" }, { name: "Download", link: "download" }],
      requiresAuth: true
    },
    props: true
  },
  {
    name: "ideal",
    path: "/design/ideal",
    component: TheIdeal,
    props: true,
    meta: {
      title: "Competitors",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Design" }, { name: "Competitors", link: "ideal" }],
      requiresAuth: true
    }
  },
  {
    name: "needs",
    path: "/design/needs",
    component: Needs,
    props: true,
    meta: {
      title: "Needs",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Design" }, { name: "Needs", link: "needs" }],
      requiresAuth: true
    }
  },

  {
    name: "include-sections",
    path: "/design/sections",
    component: IncludeSections,
    props: true,
    meta: {
      title: "Sections",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Design" }, { name: "Sections", link: "sections" }],
      requiresAuth: true
    }
  },
  {
    name: "survey-design",
    path: "/design/segments",
    component: SurveyDesign,
    props: true,
    meta: {
      title: "Segments",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Design" }, { name: "Segments", link: "segments" }],
      requiresAuth: true
    }
  },
  {
    name: "manage-invitations",
    path: "/respondent/invitations",
    component: ManageInvitations,
    props: true,
    meta: {
      title: "Invitations",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Respondent" }, { name: "Invitations", link: "invitations" }],
      requiresAuth: true
    }
  },
  {
    name: "survey-preview",
    path: "/design/preview",
    component: SurveyPreview,
    props: {
      viewOnly: true
    },
    meta: {
      title: "Preview",
      breadcrumb: [{ name: "Home", link: "/competitive-dashboard" }, { name: "Design" }, { name: "Study Preview", link: "preview" }],
      requiresAuth: true
    }
  },
  {
    name: "help-wratings",
    path: "/help-wratings",
    beforeEnter() {
      window.open(process.env.VUE_APP_SMART_WRATINGS_LINK, "_blank");
    },
    component: HelpWratings
  },
  {
    name: "google-analytics-admin",
    path: "/google-analytics-admin",
    beforeEnter() {
      window.open(process.env.VUE_APP_GA_ANALYTICS_LINK_ADMIN, "_blank");
      return false;
    },
    component: GoogleAnalyticsAdmin
  },
  {
    name: "training-center",
    path: "/training-center",
    beforeEnter() {
      let link = SmartCenterService.SMART_CENTER_URL;
      link += "/";
      link += SmartCenterService.SMART_CENTER_LOGIN_PARAMETERS;      
      GoogleService.logClickEvent("Smart Center Link", "Main Menu - Training Center", 0, this);
      window.open(link, "_blank");
      return false;
    },
    component: SmartCenter
  },
  {
    name: "google-analytics-interview",
    path: "/google-analytics-interview",
    beforeEnter() {
      window.open(process.env.VUE_APP_GA_ANALYTICS_LINK_INTERVIEW, "_blank");
      return false;   
    },
    component: GoogleAnalyticsInterview
  },
  {
    name: "unsubscribe",
    path: "/advisor/unsubscribe/:advisorConfigurationCode/:emailAddress/:isPrimary",
    component: AdvisorUnsubscribe,
    props: true,
    meta: {
      title: "Unsubscribe From Advisor"
    }
  },  
  { path: '/:pathMatch(.*)*',  name: 'not-found', component: Error },  
  { path: '/:pathMatch(.*)',  name: 'bad-not-found', component: Error }
];
const competitiveDashboardRoute = {
  name: "competitive-dashboard",
  path: "/competitive-dashboard",
  component: Dashboard,
/*  
  component: function(resolve) {
    if (isInterviewApp == "false" || !isInterviewApp) {
      require(["../../views/" + "Dashboard" + ".vue"], resolve);
    } else {
      require(["../../views/" + "Login" + ".vue"], resolve);
    }
  },
*/  
  meta: {
    title: "Home",
    breadcrumb: [{ name: "Home" }],
    requiresAuth: true
  },
  props: true
};
adminAppRoutes.push(competitiveDashboardRoute);
const dashboardRoute = {
  name: "dashboard",
  path: "/dashboard/",
  component: function(resolve) {
    if (isInterviewApp == "false" || !isInterviewApp) {
      require(["../../views/" + "Dashboard" + ".vue"], resolve);
    } else {
      require(["../../views/" + "Login" + ".vue"], resolve);
    }
  },
  meta: {
    title: "Dashboard",
    breadcrumb: [
      { name: "Home", link: "/competitive-dashboard" },
      { name: "Dashboard", link: "dashboard" }
    ],
    requiresAuth: true
  },
  props: true
};
adminAppRoutes.push(dashboardRoute);
const loginRoute = {
  name: "login",
  path: "/login",
  component: LoginOkta,
/*  
  component: function(resolve) {
    if (isInterviewApp == "false" || !isInterviewApp) {
      require(["../../views/" + "LoginOkta" + ".vue"], resolve);
    } else {
      require(["../../views/" + "Login" + ".vue"], resolve);
    }
  },
*/  
  meta: {
    title: "Login",
    breadcrumb: []
  },
  props: true
};
adminAppRoutes.push(loginRoute);

export default adminAppRoutes;
