<template>
  <div class="client-note">
    <div class="divider"></div>
    <div class="title">
      Notes
      <font-awesome-icon :icon="['fa', 'caret-down']" style="float: right; font-size: 30px;" size="2x"
        @click="toggleSection" />
    </div>
    <div v-show="displaySection" class="client-note-section">
      <div class="button-container">
        <div class="button-left">
          <button type="button" class="btn btn-success" id="AddNewClientNoteButton" @click="displayAddNote = true">
            Add Note
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'plus-circle']" />
            </span>
          </button>
          <button type="button" class="btn btn-primary ml-2" id="EditClientNoteButton" :disabled="!editNoteButtonEnabled"
            @click="showEditNote">
            Edit Note
            <span class="ml-auto">
              <font-awesome-icon :icon="['fas', 'edit']" />
            </span>
          </button>
          <button type="button" class="btn btn-primary ml-2" id="EditClientNoteButton" :disabled="!table.rowSelected"
            @click="showViewNote">
            View Note
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'eye']" />
            </span>
          </button>
          <button type="button" class="btn btn-primary ml-2" id="DeleteNoteButton" :disabled="!editNoteButtonEnabled"
            @click="initiateDeleteNote">
            Delete Note
            <span class="ml-auto">
              <font-awesome-icon :icon="['fa', 'minus-circle']" />
            </span>
          </button>
        </div>
      </div>
      <div v-show="displayAddNote" class="form-group">
        <label for="note" class="">Note</label>
        <textarea id="note" name="note" class="form-control w-100"> </textarea>
      </div>
      <div v-show="displayAddNote" class="mt-2 mb-2 row" role="group">
        <button type="submit" class="btn btn-primary float-right ml-2" @click="saveNote()">
          <span>Save</span>
          <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
        </button>
        <button type="submit" class="btn btn-primary float-right ml-2" @click="closeNote()">
          <span>Close</span>
          <span v-show="false" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
      <div class="data-container">
        <div class="col-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
          </table>
        </div>
      </div>
    </div>
    <modal :modal-id="'View Note'" :modal-title="'View Note'" :width="700" :modal-content="noteText" />
    <modal :modal-id="'Confirmation Note'" :modal-title="'Delete Confirmation'"
      :modal-content="'Are you sure you want delete the note ?'" :modal-result-id="table.rowSelectedId"
      :include-confirmation="true" @confirm="deleteNote" />
  </div>
</template>

<script>
import $ from "jquery";

import DataTableMixins from "../mixins/DataTableMixins.vue";
import ContentMixins from "../mixins/ContentMixins.vue";

import AdminService from "../services/admin.service";
import ContentService from "../services/content.service";
import ClientService from "../services/client.service";
import ClientNoteService from "../services/client.note.service";

import "summernote/dist/summernote.css";
import "bootstrap";
import "summernote";
import UserService from "../services/user.service";

export default {
  name: "ClientNote",
  components: {},
  mixins: [DataTableMixins, ContentMixins],
  data() {
    return {
      table: {
        id: "client-note-table",
        columns: []
      },
      summernoteConfiguration: ContentService.getContentConfiguration(),
      displayAddNote: false,
      displaySection: true,
      noteText: "",
      clientNoteId: 0
    };
  },
  mounted() { },
  methods: {
    getTableData: async function () {
      await ClientNoteService.getClientNotes(this.clientCode, this.getTableDataCallback, this);
      const vm = this;
      this.table.dataTable.on('dblclick', 'tr', function () {
        vm.showViewNote();
      });
    },
    saveNote: function () {
      let note = $("#note").summernote("code");
      this.$root.processServerRequest("Saving note");
      ClientNoteService.saveNote(this.clientCode, this.clientNoteId, note, this.saveNoteCallback, this);
    },
    saveNoteCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        $("#note").summernote("code", "");
        this.closeNote();
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.getTableDataCallback(response);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.getTableDataCallback(response);
    },
    showEditNote: function () {
      this.clientNoteId = this.getClientNoteId();
      $("#note").summernote("code", this.getNoteText());
      this.displayAddNote = true;
    },
    showViewNote: function () {
      this.noteText = this.getNoteText();
      this.$modal.show("View Note");
    },
    getClientNoteId() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject.clientNoteId;
      }
      return 0;
    },
    getNoteText() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject.note;
      }
      return "";
    },
    getUserId(){
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject.userId;
      }
      return 0;
    },
    closeNote: function () {
      this.displayAddNote = false;
    },
    toggleSection: function () {
      this.displaySection = !this.displaySection;
    },
    initiateDeleteNote() {
      this.$modal.show("Confirmation Note");
    },
    deleteNote: function () {
      this.clientNoteId = this.getClientNoteId();
      this.$root.processServerRequest("Deleting note");
      ClientNoteService.deleteClientNote(this.clientNoteId, this.deleteNoteCallback, this);
    },
    deleteNoteCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
        this.getTableDataCallback(response);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  computed: {
    editNoteButtonEnabled: function(){
      return this.table.rowSelected && this.getUserId() == UserService.getUserId();
    }
  },
  props: {
    clientCode: {
      type: String,
      default: ClientService.getClientCode()
    },
  },
  created() {
    this.table.columns = ClientNoteService.getClientNoteTableColumnData();
    if (this.$parent.isAdmin) {
      this.getTableData();
      this.table.configuration.order = [[4, "desc"]];

      let vm = this;
      $(document).ready(function () {
        $("#note").summernote(vm.summernoteConfiguration);
      });
    }
  },
};
</script>
