<template>
    <div class="col-9 container">
        <div class="e-chart-container">
            <div class="title-and-chart">
                <div :id="eChartId" class="e-chart">
                    <chart :options="chart.options"></chart>
                </div>
                <div :id="eChartMiniId">
                    <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
                </div>             
            </div>
        </div>
    </div>
</template>

<script>
import LineVerticalService from "../../services/echarts/line.vertical.service";
import BarVerticalService from "../../services/echarts/bar.vertical.service";
import BarHorizontalService from "../../services/echarts/bar.horizontal.service";
import StackedAreaService from "../../services/echarts/stacked.area.service";
import AdminService from "../../services/admin.service";
import PieService from "../../services/echarts/pie.service";
import DoughnutService from "../../services/echarts/doughnut.service";
import ReportService from "../../services/report.service";

import EChartsMixins from "../../mixins/EChartsMixins";

import $ from "jquery";

export default {
  name: "ResponseRateChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        questions: [],
        rowTypes: ["question-label", "question-data", "option-label", "option-data"],
        questionLabel : "",
        selectedOptionsList: [],
        recalculate: false,
        wavesWithData: [],
    };
  },
  props: {
    mostRecentTimePeriod: {
        type: String,
        default: ""
    },
    inputTimePeriodName: {
        type: String,
        default: ""
    },
    inputTimePeriods: {
        type: Array,
        default: function() {
            return [];
        }
    },
    inputQuestionList: {
        type: Array,
        default: function() {
            return [];
        }
    }
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    getNewQuestion: function() {
        return {
            id: 0,
            label: "",
            options: [],
            counts: []
        }
    },
    getNewOption: function() {
        return {
            index: 0,
            label: "",
            counts: []
        }
    },
    setData: function(){
        this.questions = [];
        this.wavesWithData = [];
        let baseQuestionId = 0;
        let baseOptionId = 0;
        let question = this.getNewQuestion();    
        let option = this.getNewOption();
        let defaultOrderIndex = 1;
        if (typeof this.rawData != "undefined" && this.rawData.rowsMap != "undefined" && this.rawData.rowsMap.rows != "undefined" && !$.isEmptyObject(this.rawData.rowsMap)) {
            let filteredRows = [];
            for (let i = 0; i < this.rawData.rowsMap.rows.length; i++) {
                let row = this.rawData.rowsMap.rows[i].filter(item => this.rowTypes.includes(item.rowType));
                if (row.length > 0){
                    filteredRows.push(row);
                }
            }
            for (let i = 0; i < filteredRows.length; i++) {
                let row = filteredRows[i];                
                for (let i = 0; i < row.length; i++) {
                    let rowType = row[i].rowType;
                    let id = row[i].id;
                    let optionId = row[i].optionId;
                    if (baseQuestionId != id) {
                        if (baseQuestionId != 0) {
                            question.options.push(option);
                            this.questions.push(question);
                        }
                        question = this.getNewQuestion();
                        baseQuestionId = id;
                    }
                    if (baseOptionId != optionId) {
                        if (baseOptionId != 0) {
                            question.options.push(option);
                        }
                        baseOptionId = optionId;
                    }
                    //console.log("Row type: " + rowType + "  ID: " + id + "  OptionId: " + optionId +  "  Data: " + row[i].data);
                    if (rowType == "question-label") {
                        question.label = row[i].data.trim();
                        question.id = id;
                    } else if (rowType == "question-data"){
                        question.counts.push(row[i].data);
                    } else if (rowType == "option-label") {
                        option = this.getNewOption();                        
                        option.label = row[i].data.trim();
                        option.index = defaultOrderIndex;
                        defaultOrderIndex++;
                    } else if (rowType == "option-data") {
                        let optionData = row[i].data.trim();
                        let optionTimePeriod = row[i].periodDisplayName;
                        let optionRecordCount = row[i].recordCount;
                        if (optionData == "--") {
                            optionData = 0;
                        } else {
                            optionData = optionData.replace("%","");
                            optionData = parseFloat(optionData);
                        }
                        let countObject = {
                            timePeriod: optionTimePeriod,
                            count: optionData,
                            recordCount: optionRecordCount
                        }
                        if (optionData > 0){
                            if (this.wavesWithData.includes(optionTimePeriod) == false && this.inputTimePeriods.includes(optionTimePeriod) == true){
                                this.wavesWithData.push(optionTimePeriod);
                            }
                        }
                        option.counts.push(countObject);
                    }
                }
            }
            question.options.push(option);
            this.questions.push(question);
        }
    },
    recalculateData: function(question){
        let filteredQuestion = JSON.parse(JSON.stringify(question));
        filteredQuestion.options = filteredQuestion.options.filter(item => this.selectedOptionsList.includes(item.label));
        let responseCountByTimePeriod = [];
        //add counts for each time period
        for (let i = 0; i < this.inputTimePeriods.length; i++) {
            let timePeriod = this.inputTimePeriods[i];
            let recordCountTotal = 0;
            for (let i = 0; i < filteredQuestion.options.length; i++) {
                let optionForResponseTotal = filteredQuestion.options[i];
                let countsForResponseTotal = optionForResponseTotal.counts;
                for (let j = 0; j < countsForResponseTotal.length; j++) {
                    if (countsForResponseTotal[j].timePeriod == timePeriod) {
                        recordCountTotal += countsForResponseTotal[j].recordCount;
                    }
                }
            }
            responseCountByTimePeriod.push({responseCount: recordCountTotal, timePeriod: timePeriod});
        }
        for (let i = 0; i < filteredQuestion.options.length; i++) {
            let option = filteredQuestion.options[i];
            let counts = option.counts;
            let newCounts = [];
            
            for (let j = 0; j < counts.length; j++) {
                let responseObject = counts[j];
                let newResponseObject = {
                    timePeriod: responseObject.timePeriod,
                    count: responseObject.count,
                    recordCount: responseObject.recordCount
                }
                let record = responseCountByTimePeriod.find(item => item.timePeriod == responseObject.timePeriod);
                if (typeof record == "undefined" || record == null) {
                    continue;
                }
                let recordCountTotal = record.responseCount;
                let percentOptionAnswered = responseObject.recordCount > 0 ? (responseObject.recordCount / recordCountTotal) * 100 : 0;
                newResponseObject.count = Math.round(percentOptionAnswered * 10) / 10;
                newCounts.push(newResponseObject);
            }
            option.counts = newCounts;
        }
        return filteredQuestion;
    },
    assignCountIndex: function(question, currentTimePeriod, reverse = false){
        let countData = {
            label: "",
            count: 0,
            countIndex: 0
        };  
        let countIndexArray = [];        
        for (let i = 0; i < question.options.length; i++) {
            let option = question.options[i];
            countData = {
                label: option.label,
                count: 0,
                countIndex: 0
            };  
            let countItem = option.counts.find(item => item.timePeriod === currentTimePeriod);
            if (typeof countItem != 'undefined' && countItem.count != null) {
                countData.count = countItem.count;
            } 
            countIndexArray.push(countData);
        }
        countIndexArray = countIndexArray.sort((a, b) => {
            if (a.count < b.count) return 1;
            if (a.count > b.count) return -1;
            return 0;
        });
        for (let i = 0; i < countIndexArray.length; i++) {
            countIndexArray[i].countIndex = i + 1;
        }
        for (let i = 0; i < question.options.length; i++) {
            let option = question.options[i];
            let countItem = countIndexArray.find(item => item.label === option.label);
            if (typeof countItem != 'undefined') {
                question.options[i].countIndex = countItem.countIndex;
            }
        }
        return question;
    },
    sortCountIndexInQuestion: function(a, b) {
        if (a.index < b.index) return -1;
        if (a.index > b.index) return 1;
        return 0;
    },
    sortCountIndexInOrder: function(a, b) {
        if (a.countIndex < b.countIndex) return -1;
        if (a.countIndex > b.countIndex) return 1;
        return 0;
    },
    sortCountIndexReverse: function(a, b) {
        if (a.countIndex < b.countIndex) return 1;
        if (a.countIndex > b.countIndex) return -1;
        return 0;
    },
    sortOptions: function(selectedQuestion){
        if (this.eChartCriteria.displayOrder == "question" || this.eChartCriteria.displayOrder == "") {
            selectedQuestion.options = selectedQuestion.options.sort(this.sortCountIndexInQuestion);             
        } else if (this.eChartCriteria.displayOrder == "high-to-low") {
            selectedQuestion.options = selectedQuestion.options.sort(this.sortCountIndexInOrder);             
        } else if (this.eChartCriteria.displayOrder == "low-to-high") {
            selectedQuestion.options = selectedQuestion.options.sort(this.sortCountIndexReverse);             
        } 
        return selectedQuestion;
    },
    getChartOptions: function(){
        let showToolbox =  !(ReportService.getReportAutoChartGenerateStatus() == "generate");
        let colorPaletteId = this.eChartCriteria.colorPaletteId;
        let showLegend = this.eChartCriteria.showLegend;
        let showLabel = this.eChartCriteria.showLabels;
        let displayChart = false;
        let selectedQuestion;
        let searchableQuestion = this.questions.find(item => item.label == this.questionLabel);
        if (typeof searchableQuestion != "undefined" && searchableQuestion != null) {
            selectedQuestion = JSON.parse(JSON.stringify(searchableQuestion));
        } else  {
            return {};
        }
        
        let dataSource = this.inputTimePeriods.length <= 1 ? "response" : "wave";
        let isMultipleWaves = this.inputTimePeriods.length > 1;
        // Filter by options selected
        if (this.selectedOptionsList.length > 0) {
            let filteredOptions = selectedQuestion.options.filter(item => this.selectedOptionsList.includes(item.label));
            selectedQuestion.options = JSON.parse(JSON.stringify(filteredOptions));
        }
        if (typeof selectedQuestion != "undefined" && selectedQuestion != null && selectedQuestion.options.length > 0) {
            displayChart = true;
        } else  {
            return {};
        }
       selectedQuestion = this.assignCountIndex(selectedQuestion,this.mostRecentTimePeriod);
       selectedQuestion = this.sortOptions(selectedQuestion);
        if (this.eChartCriteria.eChartType == "stacked-area") {
            if (this.recalculate == true) {
                selectedQuestion = this.recalculateData(selectedQuestion);
            }
            if (this.inputTimePeriods.length <= 1){
                let errorMessage = "Displaying this chart as a single wave is not possible. Please select additional waves to display this chart.";
                AdminService.displayErrorMessage({ text: errorMessage });
                return {};
            }
            StackedAreaService.setShowToolbox(showToolbox);
            StackedAreaService.setColorPaletteId(colorPaletteId);
            StackedAreaService.setShowLabel(showLabel);
            StackedAreaService.setShowLegend(showLegend);
            StackedAreaService.setData(this.inputTimePeriods,selectedQuestion);
            return StackedAreaService.getOptions(this.recalculate == true,this,this.modalId, this.inputReportTypeName);
        } else if (this.eChartCriteria.eChartType == "stacked-bar-vertical"){
            let isStacked = true;
            if (this.recalculate == true) {
                selectedQuestion = this.recalculateData(selectedQuestion);
            }
            BarVerticalService.setShowSnap(this.isSavedCard);
            BarVerticalService.setShowToolbox(showToolbox);
            BarVerticalService.setColorPaletteId(colorPaletteId);
            BarVerticalService.setShowLabel(showLabel);
            BarVerticalService.setShowLegend(showLegend);
            BarVerticalService.setIsMultiWave(isMultipleWaves);
            BarVerticalService.setChartType(this.eChartCriteria.eChartType);
            BarVerticalService.setXAxisData(this.inputTimePeriods);
            BarVerticalService.setData(this.inputTimePeriods,selectedQuestion);
            return BarVerticalService.getOptions(this.recalculate == true,isStacked,this,this.modalId, this.inputReportTypeName,this.inputTimePeriods.length);
        } else if (this.eChartCriteria.eChartType == "stacked-bar-horizontal"){
            let isStacked = true;
            if (this.recalculate == true) {
                selectedQuestion = this.recalculateData(selectedQuestion);
            }
            BarHorizontalService.setShowSnap(this.isSavedCard);
            BarHorizontalService.setShowToolbox(showToolbox);
            BarHorizontalService.setColorPaletteId(colorPaletteId);
            BarHorizontalService.setShowLabel(showLabel);
            BarHorizontalService.setShowLegend(showLegend);
            BarHorizontalService.setIsMultiWave(isMultipleWaves);
            BarHorizontalService.setChartType(this.eChartCriteria.eChartType);
            BarHorizontalService.setYAxisData(this.inputTimePeriods);
            BarHorizontalService.setDataByResponses(this.inputTimePeriods,selectedQuestion);
            return BarHorizontalService.getOptions(this.recalculate == true,isStacked,this,this.modalId, this.inputReportTypeName,this.inputTimePeriods.length);
        } else if (this.eChartCriteria.eChartType == "pie"){
            PieService.setShowSnap(this.isSavedCard);
            PieService.setShowToolbox(showToolbox);
            PieService.setColorPaletteId(colorPaletteId);
            PieService.setShowLabel(showLabel);
            PieService.setShowLegend(showLegend);
            PieService.setData(selectedQuestion,this.inputTimePeriods,this.wavesWithData);
            return PieService.getOptions(this,this.modalId, this.inputReportTypeName);
        } else if (this.eChartCriteria.eChartType == "doughnut"){
            DoughnutService.setShowSnap(this.isSavedCard);
            DoughnutService.setShowToolbox(showToolbox);
            DoughnutService.setColorPaletteId(colorPaletteId);
            DoughnutService.setShowLabel(showLabel);
            DoughnutService.setShowLegend(showLegend);
            DoughnutService.setData(selectedQuestion,this.inputTimePeriods,this.wavesWithData);
            return DoughnutService.getOptions(this,this.modalId, this.inputReportTypeName);
        } else if (this.eChartCriteria.eChartType == "bar-vertical"){
            let isStacked = false;
            BarVerticalService.setShowSnap(this.isSavedCard);
            BarVerticalService.setShowToolbox(showToolbox);
            BarVerticalService.setColorPaletteId(colorPaletteId);
            BarVerticalService.setShowLabel(showLabel);
            BarVerticalService.setShowLegend(showLegend);
            BarVerticalService.setIsMultiWave(isMultipleWaves);
            BarVerticalService.setChartType(this.eChartCriteria.eChartType);
            BarVerticalService.setXAxisData(this.inputTimePeriods);
            BarVerticalService.setData(this.inputTimePeriods,selectedQuestion);
            return BarVerticalService.getOptions(false,isStacked,this,this.modalId, this.inputReportTypeName);
        } else if (this.eChartCriteria.eChartType == "bar-horizontal"){
            selectedQuestion.options = selectedQuestion.options.reverse();             
            let isStacked = false;
            BarHorizontalService.setShowSnap(this.isSavedCard);
            BarHorizontalService.setShowToolbox(showToolbox);
            BarHorizontalService.setColorPaletteId(colorPaletteId);
            BarHorizontalService.setShowLabel(showLabel);
            BarHorizontalService.setShowLegend(showLegend);
            BarHorizontalService.setIsMultiWave(isMultipleWaves);
            BarHorizontalService.setChartType(this.eChartCriteria.eChartType);
            BarHorizontalService.setYAxisData(selectedQuestion.options.map(item => item.label));
            BarHorizontalService.setDataByWaves(this.inputTimePeriods,selectedQuestion);
            return BarHorizontalService.getOptions(false,isStacked,this,this.modalId, this.inputReportTypeName);
        } else if (this.eChartCriteria.eChartType == "line"){
            LineVerticalService.setShowSnap(this.isSavedCard);
            LineVerticalService.setShowToolbox(showToolbox);
            LineVerticalService.setColorPaletteId(colorPaletteId);
            LineVerticalService.setShowLabel(showLabel);
            LineVerticalService.setShowLegend(showLegend);
            LineVerticalService.setDataSource(dataSource);
            if (dataSource == "response") {
                LineVerticalService.setXAxisData(selectedQuestion.options.map(item => item.label));
            } else {    
                LineVerticalService.setXAxisData(this.inputTimePeriods);
            }            
            LineVerticalService.setData(this.inputTimePeriods,selectedQuestion);
            let isSingleWave = this.inputTimePeriods.length <= 1;
            return LineVerticalService.getOptions(this.recalculate == true,isSingleWave,this,this.modalId, this.inputReportTypeName);
        }
        return {};
    },
    processChart: function(runChart = false,eChartCriteria){
        if (runChart == true) {
            this.eChartCriteria = JSON.parse(JSON.stringify(eChartCriteria));
            this.questionLabel = this.eChartCriteria.questionLabel;
            this.selectedOptionsList = this.eChartCriteria.selectedOptionsList;
            this.recalculate = this.eChartCriteria.recalculate;
            this.setData();
            if (this.questions.length > 0) {
                this.initChart();
                this.$emit('save-criteria',true);
            }
        }
    },
  },
  watch: {},
};
</script>
