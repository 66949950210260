<template>
  <div v-show="showOption" class="w-100 form-inline p-3">
    <div class="col-3 text-right">Option {{ newQuestionOptionIndex + 1 }}</div>
    <div class="col-7">
      <input
        v-model="newQuestionOptionLabel"
        id="newQuestionOptionLabel"
        :name="newQuestionOptionLabel"
        :disabled="!isEditable"
        type="text"
        class="form-control w-100"
        @blur="saveOptionEvent($event)"
      />
    </div>
    <div v-show="isEditable" class="col-2">
      <a href="#!" @click="addOption" class="btn-lg">
        <font-awesome-icon :icon="['fa', 'plus-circle']" />
      </a>
      <a href="#!" @click="removeOption" class="btn-lg">
        <font-awesome-icon :icon="['fa', 'minus-circle']" />
      </a>
    </div>
  </div>
</template>
<script>
import AdminService from "../services/admin.service";

export default {
  name: "QuestionOption",
  computed: {
    showOption: function() {
      return this.optionObject.name != "";
    }
  },
  watch: {},
  data() {
    return {
      newQuestionOptionLabel: ""
    };
  },
  props: {
    newQuestionOptionIndex: {
      type: Number,
      default: 0
    },
    optionObject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    addOption: function(result) {
      let maxValue = -1;
      let item;
      let options = this.$parent.$data.widgetConfiguration.options;
      for (let i = 0; i < options.length; i++) {
        item = options[i];
        if (Number(item.value) > maxValue) {
          maxValue = Number(item.value);
        }
      }
      maxValue += 1;
      let option = { name: " ", value: maxValue.toString() };
      options.push(option);
    },
    removeOption: function() {
      var result = {
        optionId: this.newQuestionOptionIndex,
        value: this.optionObject.value
      };
      this.$emit("remove", result);
    },
    saveOptionEvent: function(event) {
      if (event.target.value != "") {
        this.saveOptionLabel(event.target.value);
      } else {
        AdminService.displayErrorMessage({ text: "This field should not be blank. If you want to remove the option use the - button" });
      }
    },
    async saveOptionLabel(newValue) {
      if (this.optionObject.name != newValue) {
        this.newQuestionOptionLabel = newValue;
        var result = {
          optionId: this.newQuestionOptionIndex,
          value: this.optionObject.value,
          name: newValue
        };
        this.$emit("save", result);
      }
    }
  },
  mounted() {
    this.newQuestionOptionLabel = this.optionObject.name.trim();
  },
  umnounted() {}
};
</script>
