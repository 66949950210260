<template>
  <div>
    <div id="chart-container" class="by-moat">
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="cp-by-moat-e-chart" class="chart-row">
        <profile-chart-header
            :title="'Moat Profile'"
            :display="this.$parent.getDisplayView() && getDisplayView()"
            :input-display-number-of-responses="eChartCriteria.displayNumberOfResponses"
            @set-display-number-of-responses="setDisplayNumberOfResponses"
        />           
        <profile-chart ref="eChart"
            :e-chart-mini-id="'profile-chart-moat-mini'"
            :e-chart-id="'profile-chart-moat'"
            :input-report-type-name="'Chart - Moat Profile'"
            :modal-id="'profile-by-moat-chart'"
            :is-saved-card="$parent.clientChart.clientChartId != 0" 
            :input-client-chart="this.$parent.clientChart"
            @save-table="saveProfileDataTable"
            @save-criteria="buildClientChart"/>        
      </div>      
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" :id="moatNeedsTable.tableContainerId" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
        <div v-show="isInsiderUser" class="overlay">
          <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png" style="width: 50%"/>
        </div>
        <chart-data-table
          ref="moatDataTable"
          v-show="this.$parent.getDisplayView() && getDisplayView()"
          :chart-data="this.moatNeedsTable.data"
          :display-direction="'vertical'"
          :data-container-id="moatNeedsTable.tableContainerId"
          :container-id="'moat_functional_needs_chart'"
          :is-saved-card="$parent.clientChart.clientChartId != 0"           
          :content-list="this.pageContentForMoats"
          :column-defs="this.chart.columnDefs"
          :table-class="'table display row-border table-hover'"
          :table-width="'auto'"
          :report-page-type-name="'Table - Moat Profile'"
          :insight-modal-id="'profile-by-moat-table'"
          :table-title="this.moatNeedsTable.title"
        ></chart-data-table>
      </div>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContentForMoats" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
    
    <insights
      ref="insightsChart"
      :modal-name="'profile-by-moat-chart'"
      :report-page-type-name="'Chart - Moat Profile'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsTable"
      :modal-name="'profile-by-moat-table'"
      :report-page-type-name="'Table - Moat Profile'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div>
</template>
<script>
import $ from "jquery";
import ProfileChart from "../../../views/echarts/ProfileChart.vue";
import ProfileChartHeader from "../../../components/echarts/ProfileChartHeader.vue";
import ProfileService from "../../../services/echarts/profile.service";

import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";
import ChartService from "../../../services/chart.service";
import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import NeedsService from "../../../services/needs.service";
import TableService from "../../../services/table.service";
import ReportService from "../../../services/report.service";
import ContentService from "../../../services/content.service";

export default {
  name: "ByMoat",
  mixins: [ChartCompanyMixins],
  components: {  ProfileChart, ProfileChartHeader},
  data() {
    return {
      displayType: "moat",
      pageContentForMoats: [],
      moatNeedsTable: {
        id: "",
        data: {},
        title: '<div class="title"><h4 class="text-primary">Moat Profile</h4></div>',
        tableContainerId: "moat_needs_data_container",
      },
      isMobile: false
    };
  },
  props: {
    chartData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    surveyCode: function() {
      return this.$parent.$data.survey.surveyCode;
    },    

  },
  mounted() {},
  created() {
        ContentService.getPageContent("Moats", this.getPageContentByMoatsCallBack, this);
  },
  methods: {
    getPageContentByMoatsCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForMoats = response.data.data;
      }
    },
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Moat Profile") {
        this.$refs.insightsChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Moat Profile") {
        this.$refs.insightsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    async processChartData() {
      try {
        
        this.chart = ChartService.getChartAttributes("Profile By Moat");
        this.$parent.pageContent.push(...this.contentList);
        this.pageContentForMoats.push(...this.contentList);
        let needsData = JSON.parse(JSON.stringify(this.chartData[0]));
        let otherData = needsData["otherData"];
        
        let needsList = otherData["needsList"];
        let moatsList = otherData["moatsList"];

        let companyColorList = otherData["companyColorList"];
        let breakPointList = otherData["breakPointList"];
        TableService.setCompanyColorList(companyColorList);

        let containerId = needsData["containerId"];

        let chartContainerWidth = $("#" + containerId).width();
        if (chartContainerWidth < ChartService.getMobileBreakpoint()) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }

        this.table.id = containerId;

        NeedsService.setFunctionalNeedsList(needsList);
        NeedsService.setEmotionalNeedsList(needsList);
        NeedsService.setMoatsList(moatsList);
        ChartService.setVerticalLineBreakPoints(breakPointList);
        let copyOfChartData = JSON.parse(JSON.stringify(needsData));

        
        let originalEmotionalNeedsData = JSON.parse(JSON.stringify(this.chartData[1]));
        copyOfChartData.rows.push(...originalEmotionalNeedsData.rows);
        this.$parent.setChartResults(needsData.companyList);
        this.$parent.$data.criteriaResultsList = AdminService.removeByName(needsData.criteriaList, "Competitor");

        needsData = this.drawSingleEmotionalNeedsName(needsData);

        if (otherData["moatMapDataList"] != "undefined") {
          this.$parent.valueMapData.data = otherData["moatMapDataList"];
        }
        this.$parent.valueMapData.title = this.$parent.survey.name + " Moat Map";
        this.moatNeedsTable.id = containerId;
        this.moatNeedsTable.data = copyOfChartData;
        
        this.$root.$emit("check-number-of-responses");
        this.setEChartCriteria(this.$parent.$refs.chartCriteria.eChartCriteria);
        this.eChartCriteria.eChartType = "stacked-bar-vertical";
        this.parseProfileDataForNeed(JSON.parse(JSON.stringify(this.chartData)));
        this.$refs.eChart.processChart(true,"moat",this.eChartData,this.eChartCriteria,this.$parent.showPricingPower);
        $("#cp-by-moat-e-chart").addClass("chart-wrapper");
        this.setDisplayView(true);
        this.$parent.setDisplayView(true);
      } catch (err) {
        ErrorService.processException("ByMoat.displayChartCallback", err, "chart", this);
      }
    },
    parseProfileDataForNeed: function(inputData){
      let itemsToRemove = {
        lastColumns: 2,
        firstColumns: 1,
        gapColumn: 1,
      };      
      let data = {};
      // Functional Needs
      let chart1Data = inputData[0];
      // Emotional Needs
      let chart2Data = inputData[1];
      data = ProfileService.parseData(1,chart1Data,data,itemsToRemove);
      data = ProfileService.parseData(2,chart2Data,data,itemsToRemove);
      data = ProfileService.parseLegendAndColorData(inputData[0],data);
      this.eChartData = JSON.parse(JSON.stringify(data));
    },    
    async saveProfileDataTable(){
      if (this.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
        let vm = this;
        setTimeout(function() {
          ReportService.saveReportTableFile(vm.$refs.moatDataTable.getTableData(), "Table - Moat Profile", vm.$parent.clientChart, vm.saveReportTableFileCallback,vm);
          ReportService.saveReportPage(vm.getReportTableContent("moat_functional_needs_chart"),"Table - Moat Profile", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
        }, 1500);        
      }
    },
    saveAllReportPages() {
        this.saveProfileDataTable();
    },
  },
  watch: {
    surveyCode: function() {
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try{this.$refs.eChart.clear();} catch(e){}
    },
    chartData: function() {
      if (this.$parent.isByMoat) {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    }
  }
};
</script>
