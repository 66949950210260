<template>
  <div class="w-100">
    <div id="survey-taker-app">
      <h4 class="text-center m-4">
        <span>{{ displaySurveyTitle != "" ? displaySurveyTitle : displaySurveyName }}</span>
      </h4>
      <survey-taker-localization
        :survey-locale-list="localization.surveyLocale"
        :entry-language-code="entryLanguageCode"
        @selectedLocale="setLocale"
      />
      <div class="container">
        <div v-show="!displaySurvey && !displayCompletedSurveyMessage" class="text-center m-3 info-message">
          <span class="error-message" v-if="error">{{ message }}</span>
          <span v-if="!error">{{ message }}</span>
        </div>

        <div v-show="displayCompletedSurveyMessage" class="completed-survey-message">
          <div v-if="isContentEmpty">
            {{ $t('baseThankYouMessage') }}
            <span v-if="!includeSignUpMessage()">
              <br />
            {{ $t('visitMessage1') }} <a href="https://wRatings.com" alt="wRatings Corporation">https://wRatings.com</a> {{ $t('visitMessage2') }}
            </span>
          </div>
          <div v-if="!isContentEmpty">
            <p v-html="this.thankYouMessage"></p>
          </div>
          <survey-taker-post-message v-if="includeSignUpMessage()"/>
        </div>
        <div v-show="displaySurvey" class="card-body">
          <form enctype="multipart/form-data" autocomplete="off" class="form surveyPreviewForm" @submit.prevent="surveySubmitAction" method="post" id="SurveyPreviewFormId">
            <div class="section" v-for="(section, idxSection) in this.sections" v-bind:key="idxSection">
              <survey-taker-section
                v-if="displaySection(section)"
                :base-url="baseUrl"
                :section-object="section"
                :survey-taker-id="surveyTakerId"
                :survey-taker-ip-address="surveyTakerIpAddress"
                :survey-id="surveyId"
                :section-translations="sectionTranslations"
                :question-translations="questionTranslations"
                :disable-widgets="viewOnly"
              ></survey-taker-section>
            </div>
          </form>
          <div v-show="!readOnly" class="survey-buttons btn-group-horizontal" role="group">
            <button type="submit" class="btn btn-primary mr-2" v-on:click="submitForm" :disabled="viewOnly">{{ $t('submitButton') }}</button>
          </div>
          <div style="clear: both">
            {{ $t('haveQuestions') }} <a :href="'mailto: support@wratings.com?subject='+supportEmailSubject">support@wratings.com</a>
          </div>
          <div class="footer">
            &copy; 1999-{{ new Date().getFullYear() }} wRatings. {{ $t('allRightsReserved') }}<br /><br />
            {{ $t('patent') }}
          </div>
        </div>
      </div>
    </div>
    <footer-main
    :entry-language-code="$i18n.locale"
    ></footer-main>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import SurveyTakerPostMessage from "../components/SurveyTakerPostMessage.vue";
import SurveyTakerLocalization from "../components/SurveyTakerLocalization.vue";
import SurveyTakerSection from "../components/SurveyTakerSection.vue";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import AlertService from "../../services/alert.service";
import ThirdPartyService from "../../services/third.party.service";
import SurveyTakerService from "../../services/survey.taker.service";
import FooterMain from "../../components/FooterMain.vue";

import ContentMixins from "../../mixins/ContentMixins.vue";

import axios from "axios";
import Vue from "vue";
import "../../sass/_admin.scss";

require("jquery-validation");

export default {
  name: "SurveyTaker",
  components: {
    SurveyTakerSection,
    SurveyTakerLocalization,
    SurveyTakerPostMessage,
    FooterMain
  },
  mixins: [ContentMixins],
  data() {
    return {
      survey: {},
      localization: {},
      title: "Preview",
      surveyPreviewForm: null,
      validator: null,
      surveyDuration: 0,
      surveyTakerId: "",
      externalSurveyTakerId: "",
      surveyStarted: false,
      surveyTakerIpAddress: "",
      surveyTakerLocation: {},
      surveyResults: [],
      sectionTranslations: [],
      questionTranslations: [],
      displaySurveyName: "",
      originalDisplaySurveyName: "",
      displaySurveyTitle: "",
      originalDisplaySurveyTitle: "",
      displaySurvey: false,
      displayCompletedSurveyMessage: false,
      message: "",
      pageNumber: 5,
      uniqueCodeResponse: {
        message: "",
        verified: true
      },
      statusResponse: {
        message: "",
        verified: true
      },
      error: false,
      thankYouMessage: "",
      defaultThankYouMessage: "",
    };
  },
  methods: {
    setLocale: function(selectedLocale){
      this.displaySurveyName = this.survey.name;
      this.originalDisplaySurveyName = this.displaySurveyName;
      this.displaySurveyTitle = this.survey.title;
      this.originalDisplaySurveyTitle = this.displaySurveyTitle;

      // Set the selected attribute to all of the nodes in the list to false
      this.localization.surveyLocale.forEach(function(item){
        item.selected = false;
      });
      if (typeof this.localization.surveyLocale.find(x => x.id == selectedLocale.id) !== 'undefined'){
        this.localization.surveyLocale.find(x => x.id == selectedLocale.id).selected = true;
        let currentLocale = this.localization.surveyLocale.find(x => x.id == selectedLocale.id);
        // check to see if a property exists for currentLocale

        if (typeof currentLocale.surveyTranslations !== 'undefined'){
          this.redrawSurvey(currentLocale.surveyTranslations);
        }
        if (typeof currentLocale.sectionTranslations !== 'undefined'){
          this.sectionTranslations = currentLocale.sectionTranslations;
        }
        if (typeof currentLocale.questionTranslations !== 'undefined'){
          this.questionTranslations = currentLocale.questionTranslations;
        }
        this.thankYouMessage = this.getThankYouMessage(currentLocale);
      }
      this.setupSurveyPreviewForm();
    },
    redrawSurvey: function(surveyTranslations){
      // find an attribute in the surveyList that has the same surveyId as the current surveyId
      let surveyTranslation = surveyTranslations.find(x => x.translationKey == "SURVEY_NAME");
      if (typeof surveyTranslation !== 'undefined'){
        if (surveyTranslation.languageCode == "en"){
          this.displaySurveyName = this.originalDisplaySurveyName
        } else {
          this.displaySurveyName = surveyTranslation.translatedText;
        }
      }
      let surveyTitleTranslation = surveyTranslations.find(x => x.translationKey == "SURVEY_TITLE");
      if (typeof surveyTitleTranslation !== 'undefined'){
        if (surveyTitleTranslation.languageCode == "en"){
          this.displaySurveyTitle = this.originalDisplaySurveyTitle;
        } else {
          this.displaySurveyTitle = surveyTitleTranslation.translatedText;
        }
      }
    },
    displaySection: function(section){
      return section.active == 'Y';
    },    
    getSurveyField(surveyFieldName) {
      if (this.surveyFields) {
        return this.surveyFields.find(x => x.surveyFieldName == surveyFieldName);
      } else {
        return {};
      }
    },
    includeSignUpMessage: function() {
      let field = this.getSurveyField("include-sign-up-message");
      return !jQuery.isEmptyObject(field) && field.surveyFieldValue == "Y";
    },    
    setSurveyStarted() {
      if (!this.surveyStarted) {
/*        
        this.$gtag.event("survey started", {
          event_category: this.surveyName,
          event_label: "",
          value: 0
        });
*/        
        this.surveyStarted = true;
        this.saveSurveySummary("I");
      }
    },
    async submitForm() {
      if (!this.surveyPreviewForm.valid()) {
        AdminService.displayErrorMessage({ text:  this.$t('requiredFields')});
        return false;
      } else {
        AdminService.displayInfoMessage({ text: this.$t('submittingForm') });

        await SurveyTakerService.submitForm(this.surveyId,this.surveyTakerId, this.externalSurveyTakerId, this.surveyTakerIpAddress,
            this.surveyTakerLocation, this.surveyResults ,this.submitFormCallback, this);        

        this.displaySurvey = false;
        this.displayCompletedSurveyMessage = true;
      }
    },
    processSurveyCallback() {
      AlertService.closeAlert();
      /*
      this.$gtag.event("survey processed", {
        event_category: this.surveyName,
        event_label: "",
        value: 0
      });
      */
      ThirdPartyService.processQualtricsRedirect(this);
      ThirdPartyService.processIpsosRedirect(this);
      ThirdPartyService.processHomeInnovation(this);
    },
    async submitFormCallback(response) {
      /*
      this.$gtag.event("survey submitted to wData", {
        event_category: this.surveyName,
        event_label: "",
        value: 0
      });
      */
      this.displaySurvey = false;
      AdminService.displayInfoMessage({ text: this.$t('surveyProcessing') });

      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let durationInSecs = parseInt(response.data.data.surveyDuration);
        this.surveyDuration = this.surveyDuration + durationInSecs;
      }
      SurveyTakerService.processSurvey(this.entrySurveyUrl,this.surveyTakerId,
          this.externalSurveyTakerId,this.uniqueCode,this.entryClientId,this.processSurveyCallback, this);
    },
    getThankYouMessage(currentLocale){
      let thankYouMessage = this.defaultThankYouMessage;
      let thankYouMessageMap = this.localization.thankYouMessages.find(x => x.languageCode == currentLocale.languageCode);
      if (!jQuery.isEmptyObject(thankYouMessageMap)) {
        thankYouMessage = thankYouMessageMap.thankYouMessage;
      }
      return thankYouMessage;
    },
    setupSurveyCallback(response) {
      AlertService.closeAlert();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.survey = response.data.data.survey;
        this.displaySurveyName = this.survey.name;
        this.originalDisplaySurveyName = this.displaySurveyName;

        this.displaySurveyTitle = this.survey.title;
        this.originalDisplaySurveyTitle = this.displaySurveyTitle;

        if (!jQuery.isEmptyObject(response.data.data.uniqueCodeResponse)) {
          this.uniqueCodeResponse = response.data.data.uniqueCodeResponse;
        }
        if (!jQuery.isEmptyObject(response.data.data.statusResponse)) {
          this.statusResponse = response.data.data.statusResponse;
        }
        if (!jQuery.isEmptyObject(response.data.data.defaultThankYouMessage)) {
          this.defaultThankYouMessage = response.data.data.defaultThankYouMessage;
          this.thankYouMessage = this.defaultThankYouMessage;
        }
        if (!jQuery.isEmptyObject(response.data.data.localization)) {
          this.localization = response.data.data.localization;
        }
        if (this.isSurveyEmpty) {
          AdminService.displayErrorMessage({ text: this.$t('unableToLoadSurvey') + this.entrySurveyCode });
          this.displaySurvey = false;
        } else {
          this.displaySurvey = true;
          this.initializeSurvey();
          this.setupSurveyPreviewForm();
        }
        this.getPageContentByPage(this.surveyName);
        ThirdPartyService.initializeIpsosRedirect(this);
        ThirdPartyService.initializeHomeInnovation(this);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      if (!this.uniqueCodeResponse.verified && !this.viewOnly) {
        AdminService.displayErrorMessage({ text: this.uniqueCodeResponse.message, duration: -1 });
        this.displaySurvey = false;
      }
      if (!this.statusResponse.verified && !this.viewOnly) {
        AdminService.displayErrorMessage({ text: this.statusResponse.message, duration: -1 });
        this.displaySurvey = false;
      }
    },
    async saveSurveySummary(status) {
      SurveyTakerService.saveSurveySummary(this.surveyId, this.surveyTakerId, this.externalSurveyTakerId,this.surveyTakerIpAddress, 
          this.surveyTakerLocation, status, this);
    },
    async initializeSurvey() {
      if (!this.viewOnly) {
        this.surveyTakerId = Math.random()
          .toString(36)
          .replace("0.", "");
//        this.$gtag.set("userId", this.surveyTakerId);
/*
        this.$gtag.event("survey viewed", {
          event_category: this.surveyName,
          event_label: "",
          value: 0
        });
*/        
        const vm = this;
        try {
          await $.get(
            "https://api.ipdata.co?api-key=bfd8320202519ed6762f86b313112330e7bd72e8f47b38b3a0218109",
            function(response) {
              vm.surveyTakerIpAddress = response.ip;
              vm.surveyTakerLocation.countryCode = response.country_code;
              vm.surveyTakerLocation.regionCode = response.region;
              vm.surveyTakerLocation.city = response.city;
              vm.surveyTakerLocation.ipDataResponse = JSON.stringify(response);
              vm.surveyTakerLocation.isThreat = response.threat.is_threat;
              vm.saveSurveySummary("O");
            },
            "jsonp"
          );
        } catch (err) {
          //this.$log.info("Error getting users ip Address with the error: " + err.message);
          // eslint-disable-next-line no-console
          console.log("Error getting users ip Address with the error: " + err.message);
          this.saveSurveySummary("O");
        }
      }
    },
    async setupSurvey() {
      let vm = this;
      setTimeout(function() {
          if (vm.loading){
            AdminService.displayInfoMessage({ text: vm.$t('surveyStandBy') });
          }
        }, 100);             
      if (this.entrySurveyUrl != "") {
          SurveyTakerService.getSurveyBySurvey(this.uniqueCode, this.entryClientId, this.entrySurveyUrl, this.setupSurveyCallback, this);
      } else if (this.entrySurveyCode != "") {
          SurveyTakerService.getSurvey(this.uniqueCode, this.entryClientId, this.entrySurveyCode, this.setupSurveyCallback, this);
      }
    },
    setupSurveyPreviewForm() {
      this.surveyPreviewForm = $("#SurveyPreviewFormId");
      this.validator = this.surveyPreviewForm.validate(ErrorService.setupValidationForm(this.$i18n));
      $(document).ready(function() {
        $.validator.addClassRules({
            'draggableRequired': {
              dragInitialized: true,
            }
        });
        $.validator.addClassRules({
            'radioNoLabelsRequired': {
              radioNoLabelsRequired: true,
            }
        });
        $('input[type=tel][name^="question_"]').each(function(i, el) {
          $(this).rules("add", {
            phoneUS: true
          });
        });
        $('input[type=radio][name^="question_"].required').each(function(i, el) {
          if (typeof el.rules != "undefined") {
            el.rules("add", {
              notSure: true
            });
          }
        });
        $('select[name^="question_"].required').each(function(i, el) {
          if (typeof el.rules != "undefined") {
            el.rules("add", {
              selectNone: true
            });
          }
        });
      });
    },
    goToDownload: function() {
      this.$router.replace({ path: "/respondent/download" });
    },
    goToIncludeSection: function() {
      this.$router.replace({ path: "/design/sections" });
    },
    goToSurveyPreview: function() {
      this.$router.replace({ path: "/design/preview" });
    },
    goToTheCompetitors: function() {
      this.$router.replace({ path: "/design/ideal" });
    },
    goToSurveyDesign: function() {
      this.$router.replace({ path: "/design/segments" });
    },
    goToInvitations: function() {
      this.$router.replace({ path: "/respondent/invitations" });
    }
  },
  computed: {
    supportEmailSubject: function() {
      return "Support for " + this.surveyName;
    },
    isContentEmpty: function() {
      return this.thankYouMessage == "";
    },
    surveyTakerMessage: function() {
      return "\n" + this.type + " taker id: " + this.surveyTakerId;
    },
    isSurveyEmpty: function() {
      return jQuery.isEmptyObject(this.survey);
    },
    sections: function() {
      if (this.isSurveyEmpty) {
        return {};
      }
      return this.survey.sections;
    },
    surveyName: function() {
      if (this.isSurveyEmpty) {
        return "";
      }
      return this.survey.name;
    },
    surveyId: function() {
      if (this.isSurveyEmpty) {
        return "";
      }
      return this.survey.surveyId;
    },
    surveySubmitAction: function() {
      return this.baseUrl + "/api/v1/survey/submit-survey";
    },
    surveyFields: function() {
      if (this.survey.surveyFields) {
        return this.survey.surveyFields;
      } else {
        return [];
      }
    }
  },
  props: {
    baseUrl: {
      type: String,
      default: process.env.VUE_APP_BASE_URL
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "survey"
    },
    uniqueCode: {
      type: String,
      default: ""
    },
    entrySurveyUrl: {
      type: String,
      default: ""
    },
    entryLanguageCode: {
      type: String,
      default: "en"
    },
    entrySurveyCode: {
      type: String,
      default: ""
    },
    entryClientId: {
      type: String,
      default: "0"
    },
    entryResponses: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  created() {},
  watch: {
    entrySurveyCode: function() {
      this.setupSurvey();
    },
  },
  mounted() {
    this.setupSurvey();
    var currentUrl = window.location.pathname;
 //   this.$gtag.pageview(currentUrl);
    //this.$log.info("Vue version : " + Vue.version);
    //this.$log.info("Starting " + process.env.VUE_APP_TITLE);
    // eslint-disable-next-line no-console
    console.log("Vue version : " + Vue.version);
    // eslint-disable-next-line no-console
    console.log("Starting " + process.env.VUE_APP_TITLE);
    ThirdPartyService.initializeQualtricsRedirect(this);
    ThirdPartyService.initializeIpsosRedirect(this);
    ThirdPartyService.initializeHomeInnovation(this);
  }
};
</script>
