<template>
    <div class="e-chart-buttons d-flex justify-content-end">
      <div class="">
       <div v-show="displayAngleText" class="form-group form-inline">
          <input
          type="checkbox"
          class="form-check-input"
          id="angleText"
          name="angleText"
          v-model="angleText"
          @click="changeAngleText($event)"
          />
          <label for="angleText">Angle X-axis Text </label>
       </div>                   
       <div v-show="displayShowRespondents" class="form-group form-inline">
          <input type="checkbox" 
            id="displayNumberOfResponses" 
            name="displayNumberOfResponses" 
            v-model="displayNumberOfResponses" 
            @click="changeDisplayNumberOfResponses($event)"/>
          <label for="segment" class="control-label pl-1">Show Respondents (n=)</label>
       </div>
      </div>
      <div v-for="(pageContent, idxPageContent) in pageContentForNPS" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>      
    </div>
</template>

<script>
import EChartsButtonsMixins from '../../mixins/EChartsButtonsMixins.vue';
import Modal from "../Modal.vue";
import ContentService from '../../services/content.service';
export default {
  name: "ProfileChartButtons",
  mixins: [EChartsButtonsMixins],
  components: {Modal},
  data() {
    return {
      showGoalLine: true,
      angleText: true,
      displayNumberOfResponses: false,
      pageContentForNPS: [],
    };
  },
  props: {
    inputShowGoalLine: {
        type: Boolean,
        default: true
    },       
    inputAngleText: {
        type: Boolean,
        default: true
    },   
    inputDisplayNumberOfResponses: {
        type: Boolean,
        default: true
    },   
    displayShowRespondents: {
        type: Boolean,
        default: true
    },
  },
  computed: {
    displayAngleText: function(){
        //return this.eChartType == "stacked-bar-vertical";
        return false;
    },
  },
  mounted() {
    this.setChart(this.inputEChartType, this.getChartIndex(this.inputEChartType,this.inputRecalculate), this.inputRecalculate);      
    this.displayNumberOfResponses = this.inputDisplayNumberOfResponses;
  },
  created() {
    ContentService.getPageContent("NPS", this.getPageContentByNPSCallBack, this);    
  },
  methods: {
    getPageContentByNPSCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForNPS = response.data.data;
      }
    },      
    changeDisplayNumberOfResponses: function(event) {
        this.displayNumberOfResponses = event.target.checked;
        this.$emit("set-display-number-of-responses", this.displayNumberOfResponses);
    },    
    changeAngleText: function(event) {
        this.angleText = event.target.checked;
        this.$emit("set-angle-text", this.angleText);
    },
    getClass:function(index){
        return this.chartSelected == index ? "selected" : "";
    },
    verifyChartCriteria: function(eChartType){
        let verified = true;
        return verified;
    },
  },
  watch: {
    inputAngleText: function(){
        this.angleText = this.inputAngleText;
    },
    inputShowGoalLine: function(){
        this.showGoalLine = this.inputShowGoalLine;
    },
    inputDisplayNumberOfResponses: function(){
        this.displayNumberOfResponses = this.inputDisplayNumberOfResponses;
    },
  },
};
</script>
