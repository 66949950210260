import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";

const BASE_URL = AdminService.getBaseUrl();
const API_SURVEY_DESIGN_URL = BASE_URL + "/api/v1/survey-design";

class QuestionService {
  getNumberOfColumnOptions(){
    return [
      {name: "1",value: "1"},
      {name: "2",value: "2"},
      {name: "3",value: "3"},
      {name: "4",value: "4"}
    ];
  }
  getQuestionFromSurvey(sections, sectionName, questionTypeName) {
    let sectionMatch = sections.find(x => x.name == sectionName);
    if (typeof sectionMatch !== "undefined") {
      let questionMatch = sectionMatch.questions.find(x => x.questionType.name == questionTypeName);
      if (typeof questionMatch !== "undefined") {
        return questionMatch;
      }
    }
    return {};
  }
  convertQuestiontoQuestionDto(questions) {  
    let questionsInOrder = questions.map(question => {
      const questionDto = {};
      questionDto.sequenceNumber = question.sequenceNumber;
      questionDto.label = question.label;
      questionDto.questionId = question.questionId;
      return questionDto;
    });
    return questionsInOrder;
  }  
  async saveQuestionOrder(questions, sectionId, callback, chartApp) {
    const params = new URLSearchParams();
    params.append("questionsObject", JSON.stringify(questions));
    params.append("userId", UserService.getUserId());
    params.append("sectionId", sectionId);
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-question-order", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("QuestionService.saveQuestionOrder", error, "survey-designer", chartApp);
      });
  }  
  async saveQuestion(result, callback, panelApp) {
    const params = new URLSearchParams();
    params.append("label", result.label);
    params.append("display", result.display);
    params.append("required", result.required);
    params.append("questionId", result.questionId);
    params.append("widgetTypeId", result.widgetTypeId);
    params.append("widgetConfiguration", result.widgetConfiguration);
    params.append("isCompetitorCompanyCodeQuestion", result.isCompetitorCompanyCodeQuestion);
    params.append("includeAsSegment", result.includeAsSegment);
    params.append("segmentLabel", result.segmentLabel);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/save-question", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("QuestionService.saveQuestion", error, "survey-designer", panelApp);
      });
  }
  async addQuestion(newQuestion, callback, panelApp) {
    const params = new URLSearchParams();
    if (typeof newQuestion.name != "undefined") {
      params.append("name", newQuestion.name);
    }

    params.append("surveyId", newQuestion.surveyId);
    params.append("sectionId", newQuestion.sectionId);
    params.append("label", newQuestion.label);
    params.append("required", newQuestion.required);
    params.append("widgetConfiguration", newQuestion.widgetConfiguration);
    params.append("sequenceNumber", newQuestion.sequenceNumber);
    params.append("widgetTypeId", newQuestion.widgetTypeId);
    params.append("includeAsSegment", newQuestion.includeAsSegment);
    params.append("segmentLabel", newQuestion.segmentLabel);
    params.append("display", newQuestion.display);
    params.append("userId", UserService.getUserId());

    if (typeof newQuestion.questionTypeName != "undefined") {
      params.append("questionTypeName", newQuestion.questionTypeName);
    }
    await axios
      .post(API_SURVEY_DESIGN_URL + "/add-question", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("QuestionService.addQuestion", error, "survey-designer", panelApp);
      });
  }
  async deleteQuestion(questionId, callback, panelApp) {
    const params = new URLSearchParams();
    params.append("questionId", questionId);
    params.append("userId", UserService.getUserId());
    await axios
      .post(API_SURVEY_DESIGN_URL + "/delete-question", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("QuestionService.deleteQuestion", error, "survey-designer", panelApp);
      });
  }
  async saveQuestionLabel(result, panelApp) {
    if (typeof result.questionId != "undefined") {
      const params = new URLSearchParams();
      params.append("label", result.label);
      params.append("questionId", result.questionId);
      params.append("userId", UserService.getUserId());
      await axios.post(API_SURVEY_DESIGN_URL + "/save-question-label", params, { headers: await authHeader() }).catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("QuestionService.saveQuestionLabel", error, "survey-designer", panelApp);
      });
    }
  }
  async saveQuestionDescription(result, panelApp) {
    if (typeof result.questionId != "undefined") {
      const params = new URLSearchParams();
      params.append("description", result.description);
      params.append("questionId", result.questionId);
      params.append("userId", UserService.getUserId());
      await axios.post(API_SURVEY_DESIGN_URL + "/save-question-description", params, { headers: await authHeader() }).catch(error => {
        error.message += ErrorService.mergeError(error.response);
        ErrorService.processError("QuestionService.saveQuestionDescription", error, "survey-designer", panelApp);
      });
    }
  }  
}

export default new QuestionService();
