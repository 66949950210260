<template>
  <vue-modal v-show="displayModal" :name="modalId" :clickToClose="false" @opened="setOptions" :draggable="true" :adaptive="true" :resizable="true" width="50%" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-auto d-flex">
        <div class="dashboard-button-container">
            <chart-buttons
            :display="true"
            :tooltip-configuration="snapChartTooltipConfig"
            :include-advisor-button="false"
            :snap-container-id="'modal-chart'"
            :snap-label="'Snap'">
          </chart-buttons>
          <div v-show="displayAdvisorButton" class="chart-button-container">
            <div class="chart-button-column">
              <button class="btn ml-4" type="button" @click="displayInsights(dashboardInsightModalId, dashboardCardReportPageTypeName)">
                <i class='fa-duotone fa-thought-bubble fa-lg'></i>
              </button>
            </div>
          </div>
        </div>
        <div id="modal-chart" class="d-flex flex-column">
          <div :id="modalId" v-if="displayECharts">
            <chart :options="modalChartOptions"></chart>
          </div>
          <div class="dashboard-x-axis-container" v-if="showNeedsSummaryImage">
            <img src="../assets/img/dashboard-card-components/Card-Needs-HiRes.png" alt="xAxis" width="500"/>
          </div>
          <div class="dashboard-x-axis-container" v-if="showWinnersSummaryImage">
            <img src="../assets/img/dashboard-card-components/Card-Winners-HiRes.png" alt="xAxis" width="500"/>
          </div>
          <div class="dashboard-x-axis-container" v-if="showMoatSummaryImage">
            <img src="../assets/img/dashboard-card-components/Card-Moats-HiRes2.png" alt="xAxis" width="500"/>
          </div>
          <respondent-counts v-if="showRespondentCounts" :respondent-counts="respondentCounts"></respondent-counts>
          <w-score-rank-body 
            v-if="rankType == 'w-score-rank' &&  showRankDisplay"
            :tiers="tiers" 
            :title-html="titleHtml" 
            :score="score" 
            :message="message" 
            :tier="tier" 
            :is-modal="true"
            :base-height="baseRankHeight"
            />
          <moat-score-rank-body 
            v-if="rankType == 'moat-score-rank' && showRankDisplay"
            :tiers="tiers" 
            :title-html="titleHtml" 
            :score="score" 
            :tier="tier" 
            :message="message" 
            :is-modal="true"
            :base-height="baseRankHeight"
            moat-rank-chart-display-id-name="modal-moat-rank-chart-display"
            />
          <nps-score-rank-body 
            v-if="rankType == 'nps-rank' && showRankDisplay"
            :tiers="tiers" 
            :title-html="titleHtml" 
            :score="score" 
            :tier="tier" 
            :net-promoter-score="netPromoterScore"
            :message="message" 
            :is-modal="true"
            :base-height="baseRankHeight"
            />
            <moat-comps-body 
              v-if="showMoatCompsDisplay"
              :display-dashboard="showMoatCompsDisplay"
              :rankings-by-company="rankingsByCompany" 
              :rankings-by-framework="rankingsByFramework" 
              :no-data="noData"
              :not-enough-data="notEnoughData"
              :is-modal="true"
            />

            <div v-if="showNoRankingDisplay" class="dashboard-rank no-data-for-chart-container align-middle">
              <div class="no-data-for-chart">
                No Rankings are currently 
                <br/>
                available to display.
                <br/><br/>
                Try selecting a different 
                <br/>Period or Group, 
                <br/>or create a new Group
              </div>
            </div>            
      </div>
      </div> 
      <div class="modal-footer">
        <button id="save_chart" class="btn btn-primary ml-2" type="button" @click="$modal.show('modal-save-chart-confirmation')">
          Save for Report
          <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>          
        </button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
    <insights
      ref="insightsDashboardModal"
      :modal-name="dashboardInsightModalId"
      :report-page-type-name="dashboardCardReportPageTypeName"
      :entry-page-content="this.$parent.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />    
    <vue-modal name="modal-save-chart-confirmation" :width="350" :height="200">
      <save-chart modal-name="modal-save-chart-confirmation" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.$parent.updateClientCharts"></save-chart>
    </vue-modal>
  </vue-modal>
</template>

<script>
import Vue from "vue";
import Echarts from "vue-echarts";
Vue.component("chart", Echarts);
import ChartButtons from "../components/chart/ChartButtons.vue";
import SaveChart from "../components/chart/SaveChart.vue";
import RespondentCounts from "../components/RespondentCounts.vue";
import Insights from "../modals/Insights.vue";
import ReportService from "../services/report.service";
import AdminService from "../services/admin.service";
import WScoreRankBody from '../components/dashboard/rank/WScoreRankBody.vue';
import MoatScoreRankBody from '../components/dashboard/rank/MoatScoreRankBody.vue';
import NpsScoreRankBody from '../components/dashboard/rank/NpsScoreRankBody.vue';
import MoatCompsBody from '../components/dashboard/body/MoatCompsBody.vue';
import RankBodyMixins from '../mixins/RankBodyMixins.vue';
export default {
  name: "DashboardModal",
  mixins: [RankBodyMixins],
  components: {ChartButtons,SaveChart,Insights,RespondentCounts,
    WScoreRankBody,
    MoatScoreRankBody,
    NpsScoreRankBody,
    MoatCompsBody
  },
  data() {
    return {
      dashboardInsightModalId: "",
      reportPagesList: [],
      baseRankHeight: 450,
      snapChartTooltipConfig: {
        content: "Click to take snapshot of the chart. ",
        trigger: "hover",
        delay: { show: 500, hide: 500 }
      },      
    };
  },
  props: {
    chart: {
      type: Object,
      default: function() {
        return {};
      }
    },
    clientChart: {
      type: Object,
      default: function() {
        return {};
      }
    },
    modalChartData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    modalChartOptions: {
      type: Object,
      default: function() {
        return {};
      }
    },
    chartTitle: {
      type: String,
      default: ""
    },
    rankType: {
        type: String,
        default: ""
    },
    showRankDisplay: {
      type: Boolean,
      default: false
    },
    showNoRankingDisplay: {
      type: Boolean,
      default: false
    },
    netPromoterScore: {
      type: Array,
      default: function() {
        return [];
      }
    },    
    respondentCounts: {
      type: Object,
      default: function() {
        return {};
      }
    },
    modalId: {
      type: String,
      default: "expanded-dashboard"
    },
    modalTitle: {
      type: String,
      default: "Expanded"
    },
    modalContent: {
      type: String,
      default: ""
    },
    dashboardCardReportPageTypeName: {
      type: String,
      default: ""
    },
    showMoatSummaryImage: {
      type: Boolean,
      default: false
    },
    showNeedsSummaryImage: {
      type: Boolean,
      default: false
    }, 
    showWinnersSummaryImage: {
      type: Boolean,
      default: false
    },
    showRespondentCounts: {
      type: Boolean,
      default: false
    },
    showMoatCompsDisplay: {
      type: Boolean,
      default: false
    },
    rankingsByCompany: {
      type: Array,
      default: function() {
        return [];
      }    
    },
    rankingsByFramework: {
      type: Array,
      default: function() {
        return [];
      }   
    },
    noData: {
      type: Boolean,
      default: false
    },
    notEnoughData: {
      type: Boolean,
      default: false
    },
    displayModal: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    displayAdvisorButton: function(){
      return typeof this.clientChart != "undefined" && this.clientChart.clientChartId != 0;
    },
    displayECharts: function(){
      return this.rankType != 'w-score-rank' && this.rankType != 'nps-rank' && this.rankType != 'moat-score-rank' && this.showMoatCompsDisplay == false;
    },    
  },
  mounted() {},
  created() {
    this.dashboardInsightModalId = "dashboard-card-" + this.modalId;
  },
  methods: {
    displayInsights(modalId, reportPageTypeName) {
      if (reportPageTypeName == this.dashboardCardReportPageTypeName) {
        this.$refs.insightsDashboardModal.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsDashboardModal.initializeInsights(this.clientChart);
        this.$modal.show(modalId);
      } 
    },    
    getReportPagesCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.reportPagesList = response.data.data;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },    
    saveClientChart(value, message) {
      this.$parent.saveClientChart(value, message);
    },
    updateReportPages(value) {
      this.reportPagesList.push(value);
    },    
    saveAllReportPages() {
      this.$parent.saveReportPage(this.modalId,this.$parent.reportTypeName);
    },    
    closeModal() {
      this.$parent.displayModal = false;
    },
    setOptions(){
      /* eslint-disable */
      if (this.modalChartOptions.hasOwnProperty("legend")){
        this.modalChartOptions.legend.show = true;
       // this.modalChartOptions.legend.orient = 'vertical';
      }
      if (this.modalChartOptions.hasOwnProperty("grid") && this.modalChartOptions.grid.top < 40){
        this.modalChartOptions.grid.top = 40;
      }
/*      
      if (this.modalChartOptions.hasOwnProperty("grid")) {
        this.modalChartOptions.grid.left = '20%';
        this.modalChartOptions.grid.top = 10;
      }
*/      
      /* eslint-enable */
      this.$parent.saveReportPage(this.modalId,this.$parent.reportTypeName);
    }
  },
  watch: {
    // There is no need for a two-way binding here and therefore not required to modify the props.
    // Also, mutating the props directly is not allowed in vue3. This is part of vue migration.
    // modalChartData: function() {
    //   this.modalChartOptions.series[0].data = this.modalChartData;
    // },
    clientChart: function() {
      if (typeof this.clientChart != "undefined" && this.clientChart.clientChartId != 0) {
        ReportService.getReportPages(this.clientChart.clientChartId, this.getReportPagesCallback, this);
      } else {
        this.reportPagesList = [];
      }
    }
  }
};
</script>
