<template>
  <div class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <design-menu></design-menu>
    <div class="create-survey form-group form-inline">
      <label for="newSurveyName" class="col-3 text-right">Survey Name</label>
      <input v-model="newSurveyName" id="newSurveyName" name="newSurveyName" type="text" class="form-control col-6" />
      <div class="btn-group-horizontal p-3 col-3" role="group">
        <button type="submit" class="btn btn-primary" v-on:click="createSurvey">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

import StudyDesignMixins from "../../mixins/StudyDesignMixins.vue";
import authHeader from "../../services/auth-header";
import ClientService from "../../services/client.service";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import UserService from "../../services/user.service";

export default {
  name: "CreateSurvey",
  mixins: [StudyDesignMixins],
  components: {},
  props: {},
  data() {
    return {
      newSurveyName: "",
      surveyTemplateName: "Default Survey",
      title: "Create Survey"
    };
  },
  methods: {
    async createSurvey() {
      this.$root.processServerRequest("Creating new survey design/setup");
      const params = new URLSearchParams();
      params.append("surveyName", this.newSurveyName);
      params.append("surveyTemplateName", this.surveyTemplateName);
      params.append("clientCode", this.clientCode);
      params.append("userId", UserService.getUserId());
      await axios
        .post(this.baseUrl + "/api/v1/survey-design/create-survey", params, { headers: await authHeader() })
        .then(response => this.createSurveyCallBack(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("CreateSurvey.createSurvey", error, "survey-designer", this);
        });
    },
    createSurveyCallBack(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$parent.$data.clientObject.surveyCodes.push(response.data.data.surveyCode);
        ClientService.saveClient(this.$parent.$data.clientObject);
        this.$root.setClientObject(this.$parent.$parent.clientObject);        
        this.$parent.$data.surveyCode = response.data.data.surveyCode.surveyCode;
        this.$parent.$data.survey = response.data.data.survey;
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.goToIncludeSection();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    }
  },
  created() {},
  mounted() {
    if (this.newSurveyName == "") {
      this.newSurveyName = this.clientName + " Survey";
    }
  },
  computed: {}
};
</script>
