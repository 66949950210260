<template>
  <a class="mr-1" href="#/" @click="showPopup">
    <i class="fa-duotone fa-circle-info" ></i>
  </a>
</template>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
library.add(faInfoCircle);
import Swal from 'sweetalert2'

import GoogleService from "../services/google.service";
export default {
  name: "ModalLink",
  data() {
    return {
        modalTitle: "",
        modalContent: "",
        displayModal: false
    };
  },
  umnounted() {},
  computed: {},
  mounted() {
    this.setTitleAndContent();
  },
  methods: {
    setTitleAndContent() {
      let content = this.contentList.find((content) => content.title === this.modalId);
      if (typeof content !== 'undefined') {
        this.modalTitle = content.title;
        this.modalContent = content.contentText;
        this.displayModal = true;
      }
    },
    showPopup() {
      GoogleService.logClickEvent("Display Modal", this.modalId, 0, this);
      if (this.displayModal == true) {
        Swal.fire({
          title: '<h5 class="modal-title">'+this.modalTitle+'</h5>',
          html: this.modalContent,
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: "Close",
          cancelButtonColor: "#2f5eb3",
          showConfirmButton: false,
        })      
      }
    }
  },
  watch: {
    contentList: {
      handler(newVal, oldVal) {
        this.setTitleAndContent();
      },
      deep: true
    }, 
    /* remove once the vue 3 migration is over      
    contentList: function() {
        this.setTitleAndContent();
    }
        */
  },
  props: {
    modalId: {
      type: String,
      default: ""
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    },    

  }
};
</script>
