<template>
  <div v-if="displayFeature" class="form-group form-inline">
    <label for="defaultValue" class="ml-2 mr-2">Default Value</label>
    <input v-model="defaultValue" id="defaultValue" name="defaultValue" type="text" class="" @blur="updateDefaultValue" />
  </div>
</template>
<script>
export default {
  name: "QuestionDefaultValue",
  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      defaultValue: "",
    };
  },
  props: {
    initialDefaultValue: {
      type: String,
      default: ""
    },
    displayFeature: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    updateDefaultValue: function() {
      this.$emit("save", this.defaultValue);
    }
  },
  mounted() {
    this.defaultValue = this.initialDefaultValue;
  },
  umnounted() {}
};
</script>
